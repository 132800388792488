import { useSearchParams } from "react-router-dom";

export const useStateParams = () => {
   const [params, setSearchParams] = useSearchParams();
   const getStateParams = (str: string, fb?: string ) => {
      const state = params.get(str) || fb || "";
      const setState = (s: string) => {
         params.set(str, s);
         setSearchParams(params);
      };
      return [state, setState] as const;
   };

   return { params, setSearchParams, getStateParams };
};
