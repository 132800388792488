import Spreadsheet, { CellBase, Matrix } from "react-spreadsheet";
import styles from "./UwUBulkCreate.module.scss";
import MainButton from "../../../common/mainButton/MainButton";
import MainInput from "../../../common/mainInput/MainInput";
import { useEffect, useState } from "react";
import useTheme from "../../../../hooks/react/useTheme";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import { Link, useNavigate } from "react-router-dom";
import { useStateParams } from "../../../../hooks/react/useStateParams";
import useQVersions from "../../../../hooks/querries/versions/useQVersions";
import useAxios from "../../../../hooks/axios/useAxios";
import { SHu_socketio_events, TSocketIO_uwuBulk_creatorPing } from "@simplyhomes/utils";
import { useSocketIo } from "../../../../hooks/socketio/useSocketIo";

export const handleUwUBulkCreateData = ({ data }: { data: Matrix<CellBase<string>> }) => {
   let isValid = true;
   const newData = [
      ...data.slice(0, 1),
      ...data.slice(1).map((r) => {
         const rowHasAnyData = r.reduce((prev, curr) => (curr?.value ? true : prev), false);
         return (rowHasAnyData ? new Array(data[0].length).fill(undefined) : r)
            .map((_, ai) => r[ai])
            .map((c, ci) => {
               if (!rowHasAnyData) return { value: undefined };
               if (data[0][ci]?.value?.includes("*") && (!c || !c.value)) {
                  isValid = false;
                  return { ...c, className: `${styles.warn}` };
               }
               return { ...c, className: undefined };
            });
      }),
   ] as Matrix<CellBase<string>>;
   return { data: newData, isValid };
};
const UwUBulkCreate = () => {
   const { aioAxios } = useAxios();
   const { theme } = useTheme();
   const { getStateParams } = useStateParams();
   const nav = useNavigate();

   const headers = ["Address*", "Unit Count*", "Total Beds*", "Total Baths", "Total Reno"];
   const [tableData, setTableData] = useState<Matrix<CellBase<string>>>([
      [...headers.map((str) => ({ value: str, readOnly: true }))],
      ...new Array(10).fill([]),
   ]);
   const [fetchAVM, setFetchAVM] = useState(false);
   const [rowsToAdd, setRowsToAdd] = useState(0);
   const [submitting, setSubmitting] = useState(false);
   const [progress, setProgress] = useState(0);
   const [ver, setVer] = getStateParams("ver");
   const [portfolio, setPortfolio] = useState("");
   const [description, setDescription] = useState("");

   const { data: qVersData } = useQVersions({ obj: "underwritings" });
   const { versions, latest } = qVersData || {};

   const { socket } = useSocketIo({
      event: SHu_socketio_events.uwuBulk_creatorPing.event,
      options: { onMessage: (p: TSocketIO_uwuBulk_creatorPing) => setProgress(p.progress) },
   });

   useEffect(() => {
      if (!ver && latest) setVer(latest);
   }, [latest, setVer, ver]);

   const filteredData = tableData.slice(1).filter((r) => r.filter((c) => c != null && c.value != null).length > 0);
   const validSubmit =
      !!portfolio && !!ver && filteredData.length > 0 && handleUwUBulkCreateData({ data: tableData }).isValid;
   const mappedData: { address: string; unitCount: number; beds: number; baths: number; reno: number }[] = tableData
      .slice(1)
      .filter((r) => r.length > 0 && !r.every((c) => !c || !c.value))
      .map((r) => ({
         address: r[0]?.value || "",
         unitCount: Number(r[1]?.value),
         beds: Number(r[2]?.value),
         baths: Number(r[3]?.value),
         reno: Number(r[4]?.value),
      }));
   const handleSubmit = async () => {
      if (!validSubmit) return;
      setSubmitting(true);
      const body = { data: mappedData, version: ver, fetchAVM, socketId: socket.id, portfolio, description };
      await aioAxios
         .post(`/aio/underwritings/bulk/parse`, body)
         .then(({ data }) => nav(`/uwubulk/${data.bid}`))
         .catch((er) => console.log({ er }));
      setSubmitting(false);
   };

   const handleAddRows = () => setTableData([...tableData, ...new Array(rowsToAdd).fill([])]);

   const handleSSData = (data: Matrix<CellBase<string>>) => {
      if (JSON.stringify(data) !== JSON.stringify(tableData)) setTableData(handleUwUBulkCreateData({ data }).data);
   };

   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               {submitting ? (
                  <p>
                     (Parsing {progress}/{mappedData.length})
                  </p>
               ) : (
                  <p>Let's fill in some required data</p>
               )}
            </div>
            <div className={`${styles.headerR}`}>
               <Link to={"/uwubulk"}>
                  <MainButton title="Back" type="scnd" />
               </Link>
               <MainButton title="Submit" onClick={handleSubmit} enable={validSubmit} />
            </div>
         </div>
         {submitting ? (
            <BodyFiller
               loading
               fillerMsg="You can wait here while the new bulk underwriting is being created. But feel free to navigate away, you will receive an notification once it is done."
            />
         ) : (
            <>
               <ul className={`${styles.notesC}`}>
                  <li>
                     <span>
                        Please fill out all the required fields marked with (*) and make sure to use the full address
                        (Example: "510 Transverse Ave, Pittsburgh, PA 15210, USA")
                     </span>
                  </li>
                  <li>
                     <span>
                        New rows will be automatically added if you paste in data larger than currently displayed rows
                     </span>
                  </li>
                  <li>
                     <span>You will be able to return to this step to make further edits</span>
                  </li>
                  <li>
                     <span>
                        Rental source and utilities settings will be determined based on zipcode. (Note: when tract rent
                        is preferred but we don't have data for the tract ID, SAFMR 100% will be used instead)
                     </span>
                  </li>
                  <li>
                     <b>Hint: </b>
                     <span>
                        You can copy the current entries into MS Excel or Google Sheet as a backup or to share with
                        others
                     </span>
                  </li>
               </ul>
               <div className={`${styles.configC}`}>
                  <div className={`${styles.configHeaderC}`}>
                     <h1 className={`cardHeader-s`}>Configurations</h1>
                  </div>
                  <div className={`${styles.config}`}>
                     <MainInput title="Portfolio name*" value={portfolio} handleValue={setPortfolio} disableCollapse />
                     <MainInput
                        title="Description"
                        className={`${styles.configExpand}`}
                        value={description}
                        handleValue={setDescription}
                        disableCollapse
                     />
                  </div>
                  <div className={`${styles.config}`}>
                     <MainInput title="Version" type="dropdown" value={ver} options={versions} handleValue={setVer} />
                     <MainInput type="toggle" title="Enable AVM fetching?" value={fetchAVM} handleValue={setFetchAVM} />
                  </div>
               </div>
               <Spreadsheet
                  className={`${styles.ss}`}
                  data={tableData}
                  onChange={handleSSData}
                  darkMode={theme === "dark"}
               />
               <div className={`${styles.footerControlls}`}>
                  <MainInput
                     title="Rows To Add"
                     style={{ width: "9rem" }}
                     value={rowsToAdd}
                     type="number"
                     handleValue={setRowsToAdd}
                  />
                  <MainButton
                     title={`Add ${rowsToAdd} rows`}
                     enable={rowsToAdd > 0}
                     type="scnd"
                     className={`${styles.addRows}`}
                     onClick={handleAddRows}
                  />
               </div>
            </>
         )}
      </article>
   );
};

export default UwUBulkCreate;
