import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppUsers_Schema } from "@simplyhomes/utils";

export const useQUsers = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["users"],
      queryFn: () => aioAxios.get(`/aio/users`).then<TAxiosReps>(({ data }) => data),
   });
};
const cols = ["email", "sub"] as const;
type TAxiosReps = {
   users: Pick<TPg_Aio_AppUsers_Schema, (typeof cols)[number]>[];
};
