import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./AssetsSearch.module.scss";
import { useQViews } from "../../../hooks/querries/views/useQViews";
import { useQView } from "../../../hooks/querries/views/useQView";
import MainInput from "../../../components/common/mainInput/MainInput";
import { useEffect, useState } from "react";
import { useQAssets } from "../../../hooks/querries/asssets/useQAssets";
import { SHu_js_swapTwoElementsInArray, TPg_Aio_AppViews_Schema } from "@simplyhomes/utils";
import MainButton from "../../../components/common/mainButton/MainButton";
import { useQFields } from "../../../hooks/querries/fields/useQFields";
import { ResizableBox } from "react-resizable";
import GGM from "../../../components/common/GGM/GGM";
import AssetCard from "../../../components/children/assets/AssetCard/AssetCard";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import Popup from "../../../components/common/popup/Popup";
import ViewConfig from "../../../components/common/viewConfig/ViewConfig";
import TableV2, {
   TTableV2_OnHeaderDrop,
   TTableV2_headerData,
   TTableV2_onCellClickParams,
} from "../../../components/common/tableV2/TableV2";
import FiltersConfig from "../../../components/children/underwritings/filtersConfig/FiltersConfig";
const defaultView: TPg_Aio_AppViews_Schema = {
   object: "assets",
   created_by_sub: "Admin",
   name: "Default View",
   filters: [[]],
   order: [],
   shared_to_subs: [],
   sort_by: "assets.address",
   sort_direction: 1,
   vid: 0,
};
const AssetsSearch = () => {
   const nav = useNavigate();
   const [params, setSearchParams] = useSearchParams();
   const viewId = params.get("viewId") || "";

   const { data: qFieldsData, isFetching: qFieldsFetching } = useQFields({
      tables: ["assets", "properties"],
      custom: ["xero"],
   });
   const { fields = [] } = qFieldsData || {};

   const { data: qViewsData, isFetching: qViewsFetching } = useQViews({ object: "assets" });
   const { views = [] } = qViewsData || {};

   const { data: qViewData, isFetching: qViewFetching } = useQView({ object: "assets", viewId });
   const { view: ogView } = qViewData || {};

   const [search, setSearch] = useState("");
   const [showMap, setShowMap] = useState(false);
   const [showViewTab, setShowViewTab] = useState(false);
   const [showFiltersTab, setShowFiltersTab] = useState(false);
   const [tableMode, setTableMode] = useState(false);
   const [view, setView] = useState(defaultView);
   const [highlightedMapAssetId, setHighlightedMapAssetId] = useState<null | string>(null);

   const { data: qAssetsData, isFetching: qAssetsFetching } = useQAssets({
      params: {
         search,
         columns: [...view.order].sort(),
         sortBy: view.sort_by || "",
         sortDirection: view.sort_direction || 1,
         filters: view.filters,
      },
   });
   const { assets = [], total: assetsTotal = 0 } = qAssetsData || {};

   const hlMapAsset = assets.find((a) => a["assets.hs_object_id"]?.toString() === highlightedMapAssetId);
   useEffect(() => {
      if (viewId === "") {
         params.set("viewId", "0");
         setSearchParams(params);
      }
   }, [params, setSearchParams, viewId]);

   useEffect(() => {
      if (ogView) setView({ ...ogView, object: "underwritings" });
   }, [ogView]);

   const getFieldLabel = (name: string) => fields.find((f) => f.name === name.split(".").pop())?.label || name;

   const tableHeaders: TTableV2_headerData = [
      { key: "option_label", label: "Stage", format: [{ type: "bold" }] },
      ...view.order.map((key) => ({ key, label: getFieldLabel(key) })),
   ];
   const tableColKeys = tableHeaders.map((h) => h.key);
   const isViewModified =
      JSON.stringify([ogView?.order, ogView?.sort_by, ogView?.sort_direction, ogView?.filters]) !==
      JSON.stringify([view.order, view.sort_by, view.sort_direction, view.filters]);

   const handleViewSelect = (viewId: string) => {
      params.set("viewId", viewId);
      setSearchParams(params);
   };
   const handleCellClick = ({ c, r, v }: TTableV2_onCellClickParams) => {
      const col = tableHeaders[c].key || "";
      if (r === -1) return setView({ ...view, sort_by: col, sort_direction: view.sort_direction === 1 ? -1 : 1 });
      // nav(`/uwu/${uws[r].simply_object_id}?ver=${ver || ""}`);
   };
   const handleHeaderDrop: TTableV2_OnHeaderDrop = ({ from, to }) => {
      const newOrder = SHu_js_swapTwoElementsInArray(view.order, from - 1, to - 1);
      setView({ ...view, order: [...newOrder] });
   };

   if (qFieldsFetching || qViewFetching || qViewsFetching || qAssetsFetching) return <BodyFiller loading />;
   return (
      <section className={`${styles.contentC}`}>
         {showViewTab && (
            <Popup onClose={() => setShowViewTab(false)} pos="rightSlideIn">
               <ViewConfig currView={view} object="assets" onClose={() => setShowViewTab(false)} />
            </Popup>
         )}
         {showFiltersTab && (
            <Popup onClose={() => setShowFiltersTab(false)} pos="rightSlideIn">
               <FiltersConfig
                  externalFilters={view.filters}
                  onClose={() => setShowFiltersTab(false)}
                  onApply={(filters) => setView({ ...view, filters })}
                  objects={["assets", "properties"]}
               />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <MainInput
               value={search}
               handleValue={setSearch}
               title="Search Assets"
               disableCollapse
               className={`${styles.searchInput}`}
            />
            <MainInput title="Show Map" value={showMap} handleValue={setShowMap} type="toggle" />
         </div>
         <article className={`${styles.bodyC}`}>
            <div className={`${styles.assetsC}`}>
               <div className={`${styles.assetsHeaderC}`}>
                  <div className={`${styles.assetsHeaderL}`}>
                     <p className={`header-m`}>
                        {assets.length < assetsTotal ? `${assets.length}/` : ""} {assets.length} Assets
                     </p>
                     <div>
                        <MainInput title="Table Mode" value={tableMode} handleValue={setTableMode} type="toggle" />
                     </div>
                  </div>
                  <div className={`${styles.assetsHeaderR}`}>
                     <div className={`${styles.assetsHeaderR1}`}>
                        <MainInput
                           type="search"
                           title="View"
                           value={view.name || ""}
                           handleValue={(str) => handleViewSelect(str.split("|").pop() || "")}
                           options={views.map((v) => `${v.name}|${v.vid}`)}
                           style={{ width: "14rem" }}
                           hideSeparators
                           disableCollapse
                        />
                        <MainButton title="Create View" type="scnd" onClick={() => setShowViewTab(true)} />
                     </div>
                     <div className={`${styles.assetsHeaderR2}`}>
                        <MainInput
                           type="search"
                           title="Sort By"
                           style={{ width: "14rem" }}
                           value={getFieldLabel(view.sort_by || "")}
                           options={Object.keys(assets?.[0] || {}).map((col) => `${getFieldLabel(col)}|${col}`)}
                           handleValue={(str) => setView({ ...view, sort_by: str?.split("|").pop() || "" })}
                           hideSeparators
                        />
                        <MainInput
                           type="dropdown"
                           style={{ width: "6rem" }}
                           title="Sort"
                           value={view.sort_direction === 1 ? "ASC" : "DESC"}
                           options={["DESC", "ASC"]}
                           handleValue={(str) => setView({ ...view, sort_direction: str === "ASC" ? 1 : -1 })}
                        />
                     </div>
                     <div className={`${styles.assetsHeaderR3}`}>
                        <MainButton
                           title={`Filters (${view.filters.flat().length})`}
                           type="bland"
                           onClick={() => setShowFiltersTab(true)}
                        />
                     </div>
                  </div>
               </div>
               <div className={`${styles.assets}`}>
                  {!tableMode && assets.map((a, key) => <AssetCard asset={a} key={key} view={view} />)}
                  {tableMode && (
                     <div className={`${styles.tableC}`}>
                        <TableV2
                           tableData={assets}
                           headerData={tableHeaders}
                           sortColIndex={tableColKeys.findIndex((key) => key === view.sort_by)}
                           sortDirection={view.sort_direction === 1 ? "asc" : "desc"}
                           onHeaderDrop={handleHeaderDrop}
                           onCellClick={handleCellClick}
                        />
                     </div>
                  )}
               </div>
            </div>
            {showMap && (
               <ResizableBox
                  width={300}
                  height={0}
                  axis="x"
                  className={`${styles.resizeBox}`}
                  onResize={(e, { size }) => size.width < 172 && setShowMap(false)}
                  resizeHandles={["w"]}
               >
                  <>
                     <GGM
                        center={{ lat: 40.4406, lng: -79.9959 }}
                        zoom={8}
                        pins={assets.map((asset) => ({ type: "assetPin", item: asset }))}
                        onOverlayClick={(aid) => setHighlightedMapAssetId(aid)}
                     />
                     {hlMapAsset && (
                        <div className={`${styles.hlAsset} `}>
                           <AssetCard asset={hlMapAsset} key={hlMapAsset["assets.hs_object_id"]} />
                        </div>
                     )}
                  </>
               </ResizableBox>
            )}
         </article>
      </section>
   );
};

export default AssetsSearch;
type TAssetsSearchProps = {};
