import { useQuery } from "react-query";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import useAxios from "../../axios/useAxios";

const useQProperty = ({ pid, ver }: IuseQPropertyParams) => {
   const { aioAxios } = useAxios();

   return useQuery({
      queryKey: [`property`, pid, ver],
      queryFn: () =>
         aioAxios
            .get(`/aio/properties/${pid}`, { params: { ver }, headers: { "Cache-Control": "no-cache" } })
            .then<IuseQueryRes>((res) => res.data),
      retryDelay: 5000,
      enabled: !!pid && !!ver,
   });
};

export default useQProperty;
interface IuseQPropertyParams {
   ver: string | undefined;
   pid: string | undefined;
}
interface IuseQueryRes {
   property: TUnknownObject;
}
