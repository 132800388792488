import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Crm_Underwritings_Schema, TPg_Utils_FilterNode } from "@simplyhomes/utils";

const useQUws = (p?: TUseQUws) => {
   const { params, enabled = true } = p || {};
   const { aioAxios } = useAxios();
   return useQuery({
      enabled,
      queryKey: ["useQUws", { ...params }],
      queryFn: () => aioAxios.get(`/aio/underwritings`, { params }).then<TAxiosResp>(({ data }) => data),
   });
};
type TUseQUws = {
   params?: TAxiosParams;
   enabled?: boolean;
   options?: UseQueryOptions<TAxiosResp, unknown, TAxiosResp, (string | TAxiosParams)[]>;
};
type TAxiosParams = {
   search: string | undefined;
   sortBy?: string | undefined;
   sortDirection?: 1 | -1 | undefined;
   columns?: string[] | undefined;
   pageLimit?: number | undefined;
   filters?: TPg_Utils_FilterNode[][] | undefined;
};
type PrefixedUw<T> = {
   [K in keyof T as `underwritings.${string & K}`]: T[K];
};
type TAxiosResp = {
   underwritings: TqUws_Underwriting[];
   total: number;
   pages: number;
};
export type TqUws_Underwriting = PrefixedUw<Partial<TPg_Crm_Underwritings_Schema>> & {
   "associations.hs_object_id_a": string | number | null;
   "properties.hs_object_id": string | number | null;
} & {
   pipeline_stage_label: string | null;
};
export default useQUws;
