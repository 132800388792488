import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../react/useDebounce";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import useAxios from "../../axios/useAxios";

const useQDbTable = (props?: IuseQDbTableParams) => {
   const { aioAxios } = useAxios();

   const [params] = useSearchParams();
   const table = props?.table ?? params.get("table");
   const limit = props?.limit ?? params.get("limit") ?? 50;
   const cols = props?.cols;
   const col = props?.col ?? params.get("col");
   const order = props?.order ?? params.get("order") ?? "ASC";
   const searchCol = props?.searchCol ?? params.get("searchCol") ?? "";
   const db = props?.db ?? "";

   const searchVal = useDebounce(props?.searchVal || params.get("searchVal") || "", 1000);
   const offset = useDebounce(params.get("offset") || 0, 1000);

   const axiosParams = props?.dontUseParams ? props : { limit, col, cols, order, searchCol, searchVal, offset, db };
   const queryFn = async () =>
      await aioAxios
         .get(`/aio/database/${table}`, { params: axiosParams, headers: { "Cache-Control": "no-cache" } })
         .then<Iresp>(({ data }) => data);
   return useQuery({ queryKey: ["DbTable", table, axiosParams], enabled: !!table && props?.enable !== false, queryFn });
};
export default useQDbTable;

interface IuseQDbTableParams {
   cols?: string[];
   table?: string | null;
   limit?: string | null;
   col?: string | null;
   order?: string | null;
   searchCol?: string | null;
   searchVal?: string | null;
   db?: "crm" | undefined;
   enable?: boolean;
   dontUseParams?: boolean;
}
interface Iresp {
   rows: TUnknownObject[];
   columns: { name: string; format: string }[];
   count: string;
   relations: any[];
}
