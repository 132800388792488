import React from "react";
import "./global.scss";
import "./common.scss";
import "@simplyhomes/react/src/styles/color1.css";
import "@simplyhomes/react/src/styles/global1.css";
import "react-resizable/css/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppState from "./contexts/appContext/AppState";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth0Provider } from "@auth0/auth0-react";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import Helm from "./components/common/helm/Helm";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ThemeProvider from "./provider/themeProvider/ThemeProvider";
import { HotkeysProvider } from "react-hotkeys-hook";
import { IoProvider } from "socket.io-react-hook";

const { REACT_APP_AUTH0_AUDIENCE } = process.env;
const queryClient = new QueryClient({
   defaultOptions: { queries: { refetchOnWindowFocus: false, refetchOnMount: false, retry: false } },
});
const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
   // <React.StrictMode> //*Temporarily removed to fix react-beautiful-dnd bug
      <Auth0Provider
         domain="dev-rjrzqrzj.us.auth0.com"
         clientId="H4BgK8aXE1vpcGF1NJSgQ6SW6ng8iKts"
         authorizationParams={{ redirect_uri: window.location.origin, audience: REACT_APP_AUTH0_AUDIENCE }}
         // issuer="ledger-api"
      >
         <IoProvider>
            <AppState>
               <HelmetProvider>
                  <Helm title="Home" />
                  <QueryClientProvider client={queryClient}>
                     <DndProvider backend={HTML5Backend}>
                        <ThemeProvider>
                           <HotkeysProvider>
                                 <App />
                           </HotkeysProvider>
                        </ThemeProvider>
                     </DndProvider>
                  </QueryClientProvider>
               </HelmetProvider>
            </AppState>
         </IoProvider>
      </Auth0Provider>
   // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
