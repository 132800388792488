import { SHrBodyFiller, SHrMainButton } from "@simplyhomes/react";
import styles from "./AioBulkUws.module.scss";
import { Link } from "react-router-dom";
import { useQAioBUws, useQAioBUws_BulkUnderwriting } from "../../hooks/querries/aio/bulkUnderwritings/useQAioBUws";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
const AioBulkUws = () => {
   const qBUws = useQAioBUws({ options: { refetchOnMount: true, refetchInterval: 10000 } });
   const buws = qBUws.data?.bulkUnderwritings || [];
   const loadingMsg = qBUws.isLoading ? "Loading..." : null;
   if (loadingMsg) return <SHrBodyFiller message={loadingMsg} />;

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <h1 className={`header-m`}>Bulk Underwriting</h1>
            </div>
            <div className={`${styles.headerR}`}>
               <Link to={"create"}>
                  <SHrMainButton title="Create Bulk Underwriting" />
               </Link>
            </div>
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.buwsC}`}>
               <div className={`cardHeader-s ${styles.buwsH}`}>{buws.length} Bulk Underwriting</div>
               <ul className={`${styles.buws}`}>
                  {buws.map((b) => (
                     <li key={b.bid}>
                        <BulkUw buw={b} />
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </section>
   );
};
type TBulkUwParams = {
   buw: useQAioBUws_BulkUnderwriting;
};
const BulkUw = ({ buw: b }: TBulkUwParams) => {
   return (
      <Link to={`/aio-bulk-underwriting/${b.bid}`} className={`${styles.buw}`}>
         <div className={`${styles.p1}`}>
            {Number(b.percentage) < 1 && b.status === "processing" ? (
               <SHrBodyFiller message={`Processing: ${SHu_js_percentageFormat(b.percentage)}`} />
            ) : (
               <b>{SHu_js_currencyFormat(b.total_target_purchase_price)}</b>
            )}
            <p>{b.name}</p>
         </div>
         <div className={`${styles.p2}`}>
            <div>
               <p>Properties</p>
               <b>{b.addresses.length}</b>
            </div>
            <div>
               <p>Version</p>
               <b>{b.version}</b>
            </div>
         </div>
         <div className={`${styles.p3}`}>
            <div>
               <span>Created at: </span>
               <b>{b.created_at.split("T")[0]}</b>
            </div>
         </div>
      </Link>
   );
};
export default AioBulkUws;
