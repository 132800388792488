import { useState } from "react";
import MainButton from "../../components/common/mainButton/MainButton";
import { useStateParams } from "../../hooks/react/useStateParams";
import { cfAIO } from "../../utils/s3/cfAIO";
import styles from "./Home.module.scss";
import Changelogs from "../../components/children/home/changelogs/Changelogs";
const Home = () => {
   const tabs: { label: string; icon?: string; component?: React.ReactNode }[] = [
      { label: "What's new?", icon: "/home/changelog.svg", component: <Changelogs /> },
      { label: "Dashboard (Coming soon)" },
   ];

   const { getStateParams } = useStateParams();
   const [tabIndex, setTabIndex] = getStateParams("tab", "0");
   const [expandTab, setExpandTab] = useState(false);
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.tabsC} ${expandTab && styles.expandTabs}`}>
            <div className={`${styles.tabsHeader}`}>
               <h1 className={`header-m`}>Homepage</h1>
               <MainButton
                  title={expandTab ? `Collapse` : `Expand`}
                  type="bland"
                  onClick={() => setExpandTab(!expandTab)}
               />
            </div>
            <div className={`${styles.tabs}`}>
               {tabs.map((i, key) => (
                  <button
                     key={key}
                     className={`${Number(tabIndex) === key && styles.active}`}
                     onClick={() => setTabIndex(key.toString())}
                  >
                     <img src={cfAIO(i.icon || "/nav/simply-short.svg")} alt="Logo" />
                     <b>{i.label}</b>
                  </button>
               ))}
            </div>
         </div>
         {tabs[Number(tabIndex)].component}
      </section>
   );
};

export default Home;
