import { useEffect, useReducer } from "react";
import FileUploader from "../../components/common/fileUploader/FileUploader";
import Helm from "../../components/common/helm/Helm";
import MainButton from "../../components/common/mainButton/MainButton";
import styles from "./UwUb.module.scss";
import csvToJson from "csvtojson";
import MainInput from "../../components/common/mainInput/MainInput";
import useQVersions from "../../hooks/querries/versions/useQVersions";
import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import useQVersion from "../../hooks/querries/versions/useQVersion";
import uwubReducer, { TUwUbState, uwubDefaultStage } from "../../contexts/UwUbContext/uwubReducer";
import { TUwUbReducerAction } from "../../types/interfaces/reducer.interface";
import SmallSpinner from "../../components/common/smallSpinner/SmallSpinner";
import BodyFiller from "../../components/common/bodyFiller/BodyFiller";
import { useResolveUwU } from "../../hooks/formulas/useResolveUwU";

const UwUb = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const ver = searchParams.get("ver") || "";

   const { data: qVersData, isFetching: qVersFetching } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersData || {};

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });

   const [uwubState, uwubDispatch] = useReducer(uwubReducer, uwubDefaultStage);
   const { uwCache, uwBase, uwOptions, uwProcessing, csvFile, jsFile = [], progress } = uwubState;

   const { recalcUwUFields } = useResolveUwU({ ver });

   const handleCsvUpload = async (files: File[]) => {
      const file = files[0];
      console.log({ file, files });
      if (!file || file.type !== "text/csv") return;
      const parsed = await csvToJson().fromString(await file.text());
      uwubDispatch({ csvFile: file, jsFile: parsed });
   };

   const handleVer = (ver: string) => {
      searchParams.set("ver", ver);
      setSearchParams(searchParams);
   };

   const isValidFile =
      csvFile && jsFile.every((r) => r.Address && r.City && r.State && r.Zip && r.County && r.Bedrooms);
   const isValidVer = ver && !!versions.find((version) => version === ver);

   useEffect(() => {
      const initBaseCalc = async () => {
         uwubDispatch({ uwProcessing: true });
         const recalced = await recalcUwUFields({ uws: [uwBase], cache: uwCache, options: [uwOptions] });
         uwubDispatch({ type: "recalced", payload: recalced });
      };
      if (qVerData) initBaseCalc();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [qVerData]);

   // if (isValidFile && isValidVer) nav({ pathname: "bulk", search: createSearchParams({ ver }).toString() });

   if (qVersFetching) return <BodyFiller loading />;
   return (
      <section className={`${styles.contentC}`}>
         <Helm titles={["Bulk Underwriting - UwUb"]} />
         <div className={`${styles.progressC}`}>
            {progress && (
               <span>
                  {progress.count}/{progress.total}
               </span>
            )}
            {uwProcessing && <SmallSpinner style={{ width: 32, height: 32 }} />}
         </div>
         <div className={`${styles.controlsC}`}>
            <a
               href="https://selltosimply-cdn.s3.amazonaws.com/aio/underwritings/bulk/BulkTemplate.xlsm"
               download={"BulkTemplate"}
               title="BulkTemplate"
               target="_blank"
               rel="noreferrer noopener"
            >
               <MainButton title="Download Template" />
            </a>
            <FileUploader handleFiles={handleCsvUpload}>
               <MainButton title="Drop File On Me" />
            </FileUploader>
            {csvFile && (
               <>
                  <div className={`${styles.csvUploadedC}`}>
                     <div>
                        <b>Uploaded: </b>
                     </div>
                     <div>{csvFile.name}</div>
                     <div>({jsFile.length} lines)</div>
                  </div>
                  <MainInput
                     value={ver || ""}
                     title={"Select a version"}
                     type="dropdown"
                     options={versions}
                     handleValue={handleVer}
                  />
               </>
            )}
         </div>
         <article>
            {!csvFile ? (
               <BodyFiller fillerMsg="Upload a valid CSV file" />
            ) : !ver ? (
               <BodyFiller fillerMsg="Select a version to start!" />
            ) : // : !isValidFile ? (
            //    <BodyFiller fillerMsg="Corrupted File" />
            // )
            !isValidVer ? (
               <BodyFiller fillerMsg="Version not found! Select another version" />
            ) : qVerFetching ? (
               <BodyFiller loading />
            ) : (
               <Outlet context={{ uwubState, uwubDispatch }} />
            )}
         </article>
      </section>
   );
};

export default UwUb;
type TContext = {
   uwubState: TUwUbState;
   uwubDispatch: React.Dispatch<TUwUbReducerAction>;
};
export const useUwUbContext = () => useOutletContext<TContext>();
