import { TPG_AIO2_AppBulkUnderwritings_Schema } from "@simplyhomes/utils";
import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";

export const useQAioBUws = (p?: TuseQAioBUws) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioBUw"],
      queryFn: () => aioAxios.get(`/aio/v2/bulk-underwritings`).then<axiosResp>(({ data }) => data),
   });
};
const cols = ["bid", "created_at", "created_by", "name", "percentage", "status", "version", "addresses"] as const;
type axiosResp = {
   bulkUnderwritings: useQAioBUws_BulkUnderwriting[];
};
type TuseQAioBUws = {
   options: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
export type useQAioBUws_BulkUnderwriting = Pick<TPG_AIO2_AppBulkUnderwritings_Schema, (typeof cols)[number]> & {
   total_target_purchase_price: string;
};
