const allTabs: TauthTab[] = [
   "database",
   "map",
   "properties",
   "versions",
   "underwritings",
   "assets",
   "uwu",
   "simpler",
   "uwub",
   "uwubulk",
   "renovations",
   "apitable"
];

const afRoles: IauthRole[] = [
   { role: "basic", tabs: ["uwu"] },
   { role: "admin", tabs: allTabs },
   { role: "ops", tabs: allTabs },
];
export default afRoles;
interface IauthRole {
   role: TauthRole;
   tabs: TauthTab[];
}
export type TauthRole = "basic" | "admin" | "ops" | string;
export type TauthTab =
   | "properties"
   | "versions"
   | "map"
   | "database"
   | "underwritings"
   | "assets"
   | "uwu"
   | "uwub"
   | "uwubulk"
   | "simpler"
   | "renovations"
   | "quick-calculator"
   | "apitable";
