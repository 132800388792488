import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import useAxios from "../../axios/useAxios";

const useQDbLedgerSnapshots = () => {
   const { aioAxios } = useAxios();
   const [params] = useSearchParams();
   const table = params.get("table") || "";
   return useQuery({
      queryKey: ["useQDbSnapshots", table],
      queryFn: async () => await aioAxios.get(`/aio/snapshots/ledger`).then<TaxiosReps>(({ data }) => data),
   });
};
export default useQDbLedgerSnapshots;
type TaxiosReps = {
   snapshots?: string[];
};
