import apiKeys from "../../../../const/apiKeys";
import { IggPin } from "../../../../contexts/appContext/AppState";
import styles from "./GgPreviewPane.module.scss";
const GgPreviewPane = ({ item }: IGgPreviewPaneProps) => {
   if (!item) return <div>Could not find Info for this address</div>;
   const { lat, lng, address, place_id } = item;
   const { googleAPIs } = apiKeys;
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.address}`}>{address}</div>
         <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
               address
            )}&query_place_id=${place_id}`}
            className={`${styles.link}`}
            target="_blank"
            rel="noreferrer"
         >
            Go to Map
         </a>
         <img
            src={`https://maps.googleapis.com/maps/api/streetview?size=800x800&key=${googleAPIs}&location=${lat},${lng}`}
            alt=""
            className={`${styles.preview}`}
         />
      </div>
   );
};

export default GgPreviewPane;
interface IGgPreviewPaneProps {
   item?: IggPin;
}
