import styles from "./ImagesViewer.module.scss";
const ImagesViewer = ({ highlightImageSrc, imageSrcs }: TImagesViewerProps) => {
   return (
      <div className={`${styles.contentC}`}>
         {highlightImageSrc && <img src={highlightImageSrc} alt="Streetview" className={`${styles.highlight}`} />}
         {imageSrcs.length > 0 && (
            <div className={`${styles.reelC}`}>
               {imageSrcs.map((src) => (
                  <img src={src} alt="house images" key={src} className={`${styles.reelImages}`} />
               ))}
            </div>
         )}
      </div>
   );
};

export default ImagesViewer;
type TImagesViewerProps = {
   imageSrcs: string[];
   highlightImageSrc?: string;
};
