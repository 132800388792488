import { TTableV2_header } from "../../components/common/tableV2/TableV2";
import { TUwData } from "../../types/hsObjects";
import { TUwUbReducerAction } from "../../types/interfaces/reducer.interface";
import { TUnknownObject } from "../../types/interfaces/unknownObj";

function swapElements(arr: any[], index1: number, index2: number) {
   if (!arr[index1] || !arr[index2]) return arr;
   var temp = arr[index1];
   arr[index1] = arr[index2];
   arr[index2] = temp;
   return arr;
}

export const uwubDefaultStage: TUwUbState = {
   uwBase: { $childUnits: [{ bedrooms: 1 }] },
   uwCache: {},
   uwOptions: {},
   uwsFinal: [],
   uwsOptions: [],
   uwProcessing: false,
   orPropFields: [],
   orUnitFields: [],
   opPropFields: [],
   opUnitFields: [],
   outputOrder: [],
};

const uwubReducer = (state: TUwUbState, action: TUwUbReducerAction): TUwUbState => {
   // console.log({ action });
   if (!action.type) return { ...state, ...action };
   switch (action.type) {
      case "swapHeaders": {
         const from = action.payload.from;
         const to = action.payload.to;
   
         const newOutputOrder = swapElements([...state.outputOrder], from, to);

         return { ...state, outputOrder: [...newOutputOrder] };
      }
      case "recalced":
         return {
            ...state,
            uwBase: action.payload?.uws[0].uw || {},
            uwCache: action.payload?.cache || {},
            uwOptions: action.payload?.uws[0].options || {},
            uwProcessing: false
         };
      default:
         return { ...state };
   }
};

export default uwubReducer;

export type TUwUbState = {
   uwBase: TUwData;
   uwCache: TUnknownObject;
   uwOptions: TUnknownObject;
   uwsFinal: TUwData[];
   uwsOptions: TUnknownObject[];
   uwProcessing: boolean;
   csvFile?: File;
   jsFile?: TJsFile;

   orPropFields: string[];
   orUnitFields: string[];
   opPropFields: string[];
   opUnitFields: string[];

   outputOrder: TTableV2_header[];

   progress?: { count: number; total: number };
};

type TJsFile = (TUnknownObject & {
   property_address_one_line_: string;
   unit: string;
   beds: string;
   baths: string;
   zip_code: string;
   arv_override_value: string;
   $housing_authority: string;
   $unit_type: string;
   $cooking_utility: string;
   $heating_utility: string;
   $water_heating_utility: string;
})[];
