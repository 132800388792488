import { useMemo, useState } from "react";
import translateFormula from "../../../../../utils/formula/translateFormula";
import AFInput from "../../../../common/AFInput/AFInput";
import MainButton from "../../../../common/mainButton/MainButton";
import Popup from "../../../../common/popup/Popup";
import styles from "./FieldsEditor.module.scss";
import FieldsEditorPopup from "./fieldsEditorPopup/FieldsEditorPopup";
import { useVersionContext } from "../../../../../pages/Versions/Versions";
import { TaioVersionObj } from "../../../../../types/aioVersion/types";
const FieldsEditor = ({ obj }: TFieldsEditorParams) => {
   const { version } = useVersionContext();
   const { fields = [] } = version;

   const [fieldId, setFieldId] = useState("");
   const [search, setSearch] = useState("");
   const [showPopup, setShowPopup] = useState(false);
   const filteredFields = useMemo(
      () =>
         fields.filter(
            ({ name, column, formula, uwColumn }) =>
               name?.toLowerCase().includes(search.toLowerCase()) ||
               column?.toLowerCase().includes(search.toLowerCase()) ||
               formula?.toLowerCase().includes(search.toLowerCase()) ||
               uwColumn?.toLowerCase().includes(search.toLowerCase())
            // column === ''
         ),
      [fields, search]
   );
   const handleCreateBtn = () => {
      setFieldId("");
      setShowPopup(true);
   };
   const handleEditBtn = (id: string) => {
      setFieldId(id);
      setShowPopup(true);
   };
   return (
      <article className={`${styles.contentC}`}>
         {showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <FieldsEditorPopup id={fieldId} handleClose={() => setShowPopup(false)} obj={obj} />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <div className={`header-s underline`}>Fields Editor</div>
            <MainButton title="Create new Field" onClick={() => handleCreateBtn()} />
         </div>
         <AFInput
            value={search}
            handleChange={(v) => setSearch(v as string)}
            title={"Search created fields"}
            style={{ width: "30rem" }}
         />
         <div className={`${styles.fields}`}>
            {filteredFields.map(({ name, formula, column, id, readonly, uwColumn }, key) => (
               <div key={key} onClick={() => handleEditBtn(id)} className={`${styles.fieldC}`} title={name}>
                  <div className={`${styles.field}`}>
                     <div className={`${styles.name}`}>{name}</div>
                     {readonly && <div className={`${styles.readonly}`}>readonly</div>}
                     {column && (
                        <div className={`${styles.column}`}>
                           <b>P: </b>
                           <span>{column}</span>
                        </div>
                     )}
                     {uwColumn && (
                        <div className={`${styles.uwColumn}`}>
                           <b>U: </b>
                           <span>{uwColumn}</span>
                        </div>
                     )}
                     {formula && (
                        <div className={`${styles.formula}`}>{translateFormula(formula, fields).translated}</div>
                     )}
                  </div>
               </div>
            ))}
         </div>
      </article>
   );
};

export default FieldsEditor;
type TFieldsEditorParams = {
   obj: TaioVersionObj;
};
