import { useEffect, useMemo, useState } from "react";
import useQColumns from "../../../../../../../hooks/querries/versions/useQColumns";
import useQFormulas from "../../../../../../../hooks/querries/versions/useQFormulas";
import { useVersionContext } from "../../../../../../../pages/Versions/Versions";
import { IformulaItemDnD } from "../../../../../../../types/DnD/formulaDnD";
import jsManipulateNestedObj from "../../../../../../../utils/JS/jsManipulateNestedObj";
import parseFormula from "../../../../../../../utils/formula/parseFormula";
import stringifyFormula from "../../../../../../../utils/formula/stringifyFormula";
import styles from "./FieldFormulaBuilder.module.scss";
import FieldFormulaComponent from "./fieldFormulaComponent/FieldFormulaComponent";
import FieldFormulaDrag from "./fieldFormulaDrag/FieldFormulaDrag";
import { Node } from "excel-formula-ast";
import { TformulaNode } from "../../../../../../../types/DnD/formulaDnD";
import FieldFormulaDragNode from "./fieldFormulaDrag/fieldFormulaDragNode/FieldFormulaDragNode";
import FieldFormulaDrop from "./fieldFormulaNodeDrop/FieldFormulaDrop";
import FieldFormulaDropNode from "./fieldFormulaNodeDrop/fieldFormulaDropNode/FieldFormulaDropNode";
import { TUnknownObject } from "../../../../../../../types/interfaces/unknownObj";
import MainInput from "../../../../../../common/mainInput/MainInput";
import MainButton from "../../../../../../common/mainButton/MainButton";

const FieldFormulaBuilder = ({ formula: exFx, setFormula: setExFx }: IFieldFormulaBuilderProps) => {
   const [formula, setFormula] = useState(exFx);

   const { version, setVersion } = useVersionContext();
   const { fields = [] } = version;

   const { data: columnsData } = useQColumns();
   const { columns = [] } = columnsData || {};

   const { data: formulasData } = useQFormulas();
   const { formulas = [] } = formulasData || {};
   const parsedFormula = parseFormula(formula);

   const [nodeSearch, setNodeSearch] = useState("");
   const [nodeCount, setNodeCount] = useState(0);

   // useEffect(() => setFormula(exFx), [exFx]);

   const handleNodeUpdate = ({ item, path }: IhandleNodeUpdate) => {
      // console.log({ item, path });
      const pathLength = path.split(".").length;

      const { id, type } = item || {};
      const newNode: TUnknownObject | null =
         type === "Fx"
            ? id === "INPUTTEXT"
               ? { type: "text", value: "" }
               : id === "INPUTNUMB"
               ? { type: "number", value: 0 }
               : {
                    type: "function",
                    arguments:
                       jsManipulateNestedObj({
                          obj: parsedFormula || {},
                          path: path + ".arguments",
                          retrieve: true,
                       }) || [],
                    name: id,
                 }
            : type === "InputText"
            ? { type: "text", value: id }
            : type === "InputNumb"
            ? { type: "number", value: Number(id) }
            : type === "Var"
            ? { type: "cell", key: id }
            : type === "Ops"
            ? {
                 type: "binary-expression",
                 left: jsManipulateNestedObj({ obj: parsedFormula || {}, path, retrieve: true }) || parsedFormula,
                 operator: id,
                 right: { type: "cell", key: "PlaceHolder" },
              }
            : type === "OpsOverwrite"
            ? {
                 type: "binary-expression",
                 left: jsManipulateNestedObj({ obj: parsedFormula || {}, path: path + ".left", retrieve: true }),
                 operator: id,
                 right: jsManipulateNestedObj({ obj: parsedFormula || {}, path: path + ".right", retrieve: true }),
              }
            : type === "NodeDel"
            ? { type: "cell", key: "PlaceHolder" }
            : null;
      // console.log({ newNode });
      // if (!newNode) return;
      const newParsedFormula = jsManipulateNestedObj({
         obj: parsedFormula || {},
         path,
         value: newNode,
         remove: type === "ArgDel",
      }) as Node;
      // console.log({ newParsedFormula });
      setFormula(stringifyFormula(newParsedFormula));
   };
   const filteredNodes = useMemo(
      () =>
         [
            formulas
               .filter(({ formula }) => formula.toLowerCase().includes(nodeSearch.toLowerCase()))
               .map(({ formula, type }) => ({ id: formula, name: formula, type: "Fx", note: type ? "Custom" : "" })),
            fields
               .filter(({ name }) => name.toLowerCase().includes(nodeSearch.toLowerCase()))
               .map(({ id, name }) => ({ id: `@${id}`, name, type: "Var", note: "Custom" })),
            columns
               .filter((str) => str.toLowerCase().includes(nodeSearch.toLowerCase()))
               .map((str) => ({ id: str, name: str, type: "Var", note: "" })),
         ].flat() as IfilteredNodes[],
      [columns, fields, formulas, nodeSearch]
   );
   const operators = ["+", "-", "&", "=", "<", "<=", ">", ">=", "/", "*"];

   const debug = () => console.log({ parsedFormula });
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.builder}`}>
            <div className={`${styles.components}`}>
               <div>
                  <FieldFormulaComponent key={formula} node={parsedFormula} handleDrop={handleNodeUpdate} path="" />
                  {parsedFormula && (
                     <FieldFormulaDrop
                        accept="Operator"
                        handleDrop={(item) => handleNodeUpdate({ item, path: "" })}
                        classname="green"
                     >
                        <FieldFormulaDropNode title="Ops" />
                     </FieldFormulaDrop>
                  )}
               </div>
            </div>
            <div className={`${styles.dropables}`}>
               <div>
                  <MainInput value={nodeSearch} handleValue={setNodeSearch} title="Search Nodes" />
                  <MainButton title="Save" onClick={() => setExFx(formula)} />
               </div>
               <ul className={`${styles.nodes}`}>
                  {filteredNodes.map(({ id, name, note, type }, key) => (
                     <FieldFormulaDrag key={key + id} type={"Node"} item={{ id, type }}>
                        <FieldFormulaDragNode name={name} type={type} note={note} />
                     </FieldFormulaDrag>
                  ))}
               </ul>
               <ul className={`${styles.nodes}`}>
                  {operators.map((id, key) => (
                     <li key={key}>
                        <FieldFormulaDrag key={key} type={"Operator"} item={{ id, type: "Ops" }}>
                           <FieldFormulaDragNode name={id} type={"Ops"} />
                        </FieldFormulaDrag>
                     </li>
                  ))}
                  {formulas
                     .filter(({ formula }) => formula === "INPUTTEXT" || formula === "INPUTNUMB")
                     .map(({ type, formula }, key) => (
                        <FieldFormulaDrag key={key + formula} type={"Node"} item={{ id: formula, type: "Fx" }}>
                           <FieldFormulaDragNode name={formula} type={"Fx"} note={"Custom"} />
                        </FieldFormulaDrag>
                     ))}
               </ul>
            </div>
         </div>
      </div>
   );
};

export default FieldFormulaBuilder;
interface IfilteredNodes {
   id: string;
   name: string;
   type: TformulaNode;
   note: string;
}
interface IhandleNodeUpdate {
   item: IformulaItemDnD;
   path: string;
}
interface IFieldFormulaBuilderProps {
   formula: string;
   setFormula: (str: string) => void;
}
