import { TPG_AIO2_AppBulkUnderwritings_Schema, TPG_AIO2_AppUnderwritings_Schema } from "@simplyhomes/utils";
import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";

export const useQAioBUw = (p: TuseQAioBUw) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioBUw"],
      queryFn: () => aioAxios.get(`/aio/v2/bulk-underwritings/${p.bid}`).then<axiosResp>(({ data }) => data),
   });
};
const cols = ["bid", "created_at", "created_by", "name", "percentage", "status", "version", "addresses"] as const;
const uwCols = ["uid", "appdata", "address"] as const;
type axiosResp = {
   bulkUnderwriting: Pick<TPG_AIO2_AppBulkUnderwritings_Schema, (typeof cols)[number]> & {
      total_target_purchase_price: string;
   };
   underwritings: TuseQAioBUw_underwriting[];
};
export type TuseQAioBUw_underwriting = Pick<TPG_AIO2_AppUnderwritings_Schema, (typeof uwCols)[number]>;
type TuseQAioBUw = {
   bid: number | null | undefined;
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
