import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppViews_Schema } from "@simplyhomes/utils";

export const useQView = ({ viewId, object }: TUseQViewParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["view", viewId],
      enabled: !!viewId,
      queryFn: () =>
         aioAxios
            .get(`/aio/views/${viewId}`, { params: { object } })
            .then<TAxiosResp>(({ data }) => ({ ...data, object })),
   });
};
type TUseQViewParams = {
   viewId: string;
   object: "underwritings" | "assets";
};
const cols = [
   "name",
   "created_by_sub",
   "filters",
   "order",
   "shared_to_subs",
   "sort_by",
   "sort_direction",
   "vid",
] as const;
type TAxiosResp = { view: Pick<TPg_Aio_AppViews_Schema, (typeof cols)[number]>; object: "underwritings" | "assets" };
