import { useQuery, UseQueryOptions } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TAIO_Underwritings2_AppData } from "@simplyhomes/utils";

export const useQAioUwGenerateAppData = (p: TUseQAioUwCreateInfoParams) => {
   const { aioAxios } = useAxios();
   const { address } = p;
   return useQuery({
      ...p?.options,
      enabled: !!address,
      queryKey: ["useQAioUwCreateInfo", address || ""],
      queryFn: () =>
         aioAxios.post(`/aio/v2/underwritings/generate-app-data`,{ address }).then<axiosResp>(({ data }) => data),
   });
};
type TUseQAioUwCreateInfoParams = {
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
   address: string;
};
type axiosResp = {
   appdata: TAIO_Underwritings2_AppData;
   unitTypes: string[];
   localityDefault: {
      [x: string]: string;
   };
};
