import { TAIO_Underwritings2_AppData, TAIO_Version2_Config } from "@simplyhomes/utils";
import { createContext } from "react";

export const AioUwViewerReducer = (state: TAioUwViewerState, action: TAioUwViewerAction): TAioUwViewerState => {
   if ("overwrite" in action) return { ...state, ...action.overwrite };
   if (!("type" in action)) return { ...state };
   switch (action.type) {
      case "tracer_push":
         return { ...state, tracer: [...state.tracer, action.payload] };
      case "tracer_pop":
         return { ...state, tracer: state.tracer.slice(0, -1) };
      case "tracer_flush":
         return { ...state, tracer: [] };
      case "tracer_goTo":
         return { ...state, tracer: state.tracer.slice(0, action.payload + 1) };
      default:
         return { ...state };
   }
};

type TAioUwViewerState = {
   config?: TAIO_Version2_Config;
   version?: string;
   appdata?: TAIO_Underwritings2_AppData;
   address?: string;
   processing?: boolean;
   tracer: TAioUwViewer_Tracer[];
   tabView_activeViewId?: number;
   tabView_activeGroupId?: number;
   tabView_unitIndex?: number ;
};
export type TAioUwViewer_Tracer = { fid: string; unitIndex: number | undefined };
type TAioUwViewerAction =
   | { overwrite: Partial<TAioUwViewerState> }
   | { type: "tracer_push"; payload: TAioUwViewer_Tracer }
   | { type: "tracer_pop" }
   | { type: "tracer_flush" }
   | { type: "tracer_goTo"; payload: number };
export const aioUwViewerDefaultState: TAioUwViewerState = {
   tracer: [],
};

export const AioUwViewerContext = createContext({
   uwViewerState: {} as TAioUwViewerState,
   uwViewerDispatch: () => {},
} as {
   uwViewerState: TAioUwViewerState;
   uwViewerDispatch: React.Dispatch<TAioUwViewerAction>;
});
