import { useState } from "react";
import { useQAioVersions } from "../../../hooks/querries/aio/versions/useQAioVersions";
import MainButton from "../../common/mainButton/MainButton";
import styles from "./AioVersionCreatePopup.module.scss";
import MainInput from "../../common/mainInput/MainInput";
import useAxios from "../../../hooks/axios/useAxios";
import BodyFiller from "../../common/bodyFiller/BodyFiller";
const AioVersionCreatePopup = ({ onClose }: TVersion2BrowserCreatePopupProps) => {
   const { aioAxios } = useAxios();
   const qVers2 = useQAioVersions({ options: { refetchOnMount: true } });

   const versions = qVers2.data?.versions || [];

   const [copy, setCopy] = useState("");
   const [version, setVersion] = useState("");
   const [description, setDescription] = useState("");
   const [submitting, setSubmitting] = useState(false);

   const isExisting = versions.some((v) => v.version === version);

   const handleSubmit = async () => {
      setSubmitting(true);
      const body = { version, description };
      await aioAxios["post"](`/aio/v2/versions/`, body, { params: { copy } }).catch((er) => console.log({ er }));
      qVers2.refetch();
      onClose();
      setSubmitting(false);
   };

   if (submitting || qVers2.isLoading)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`header-s`}>Create Version 2.0</h1>
            <MainButton type="close" onClick={onClose} />
         </div>
         <div className={`${styles.inputsC}`}>
            {versions.length > 0 && (
               <MainInput
                  title="Copy from"
                  type="search"
                  options={versions.map(({ version }) => version)}
                  value={copy}
                  handleValue={setCopy}
                  loading={qVers2.isFetching}
               />
            )}
            <MainInput title="Version Name*" value={version} handleValue={setVersion} />
            <MainInput title="Description" value={description} handleValue={setDescription} type="textarea" />
         </div>
         <div className={`${styles.errorC}`}>
            {isExisting && <p className={`${styles.dupe}`}>Version name already exists</p>}
         </div>
         <div className={`${styles.controlsC}`}>
            <MainButton title="Create" enable={!!version} onClick={handleSubmit} />
         </div>
      </div>
   );
};

export default AioVersionCreatePopup;
type TVersion2BrowserCreatePopupProps = {
   onClose: () => void;
};
