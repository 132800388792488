import { useState } from "react";
import MainButton from "../common/mainButton/MainButton";
import styles from "./AioVersions.module.scss";
import Popup from "../common/popup/Popup";
import AioVersionCreatePopup from "./AioVersionCreatePopup/AioVersionCreatePopup";
import { useQAioVersions } from "../../hooks/querries/aio/versions/useQAioVersions";
import { SHu_js_timeSince } from "@simplyhomes/utils";
import { Link } from "react-router-dom";
import MainInput from "../common/mainInput/MainInput";
import { useQUsers } from "../../hooks/querries/users/useQUsers";
import { SHrBodyFiller } from "@simplyhomes/react";
const AioVersions = () => {
   const qVers2 = useQAioVersions({ options: { refetchOnMount: true } });
   const versions = qVers2.data?.versions || [];

   const qUsers = useQUsers();
   const users = qUsers.data?.users || [];

   const [showCreatePopup, setShowCreatePopup] = useState(false);
   const [search, setSearch] = useState("");

   const filteredVersions = versions
      .filter((v) => v.version.toLowerCase().includes(search.toLowerCase()))
      .sort((a) => (a.official ? -1 : 1));

   const getUser = (sub: string) => users.find((u) => u.sub === sub)?.email.split("@")[0] || "Unknown";

   if (qVers2.isLoading)
      return (
         <section className={`${styles.contentC}`}>
            <SHrBodyFiller message="Loading versions" style={{ height: "100%" }} />
         </section>
      );
   return (
      <section className={`${styles.contentC}`}>
         {showCreatePopup && (
            <Popup onClose={() => setShowCreatePopup(false)}>
               <AioVersionCreatePopup onClose={() => setShowCreatePopup(false)} />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <h1 className={`header-m`}>Versions 2.0</h1>
            </div>
            <div className={`${styles.headerR}`}>
               <MainButton title="Create" type="main" onClick={() => setShowCreatePopup(true)} />
            </div>
         </div>
         <div className={`${styles.versionsC}`}>
            {/* <div className={`${styles.versionsCountC}`}> */}
            {/* <p className={`header-s`}>{filteredVersions.length} Versions</p> */}
            {/* </div> */}
            <div className={`${styles.versionsSearch}`}>
               <MainInput title="Search" value={search} handleValue={setSearch} />
            </div>
            <div className={`${styles.versions}`}>
               {filteredVersions.map(
                  ({ version, description, created_at, created_by, updated_by, updated_at, official }) => (
                     <Link to={`/aio-versions/${version}`} className={`${styles.versionC}`} key={version}>
                        <div className={`${styles.versionHeaderC}`}>
                           <p className={`${styles.version}`}>
                              {version}
                              {official && <b>(Official)</b>}
                           </p>
                           <p className={`${styles.description}`}>{description}</p>
                        </div>
                        <div className={`${styles.versionBodyC}`}>
                           <p className={`${styles.created}`}>
                              <b>Creator: </b>
                              {getUser(created_by).toUpperCase()}-{created_at?.split("T")[0]} (
                              {SHu_js_timeSince(new Date(created_at).getTime())})
                           </p>
                           <p className={`${styles.created}`}>
                              <b>Updated: </b>
                              {getUser(updated_by).toUpperCase()}-{updated_at?.split("T")[0]} (
                              {SHu_js_timeSince(new Date(updated_at).getTime())})
                           </p>
                           {description && (
                              <p className={`${styles.description}`}>
                                 <b>Description: </b>
                                 {description}
                              </p>
                           )}
                        </div>
                     </Link>
                  )
               )}
            </div>
         </div>
      </section>
   );
};

export default AioVersions;
