import { useWindowSize } from "@uidotdev/usehooks";
import styles from "./Popup.module.scss";
import { ResizableBox } from "react-resizable";
const Popup = ({ children, onClose, pos = "center", screenStyles }: IPopupParams) => {
   const { width, height } = useWindowSize();

   return (
      <section className={`${styles.contentC} ${styles[pos]}`}>
         <div className={`${styles.screen}`} onClick={() => onClose(false)} style={screenStyles}></div>
         {pos === "rightSlideIn" ? (
            <ResizableBox
               width={(width || 0) < 700 ? width || 0 : (width || 0) * 0.7}
               height={height || 0}
               axis="x"
               onResize={(e, { size }) => size.width < 200 && onClose(false)}
               resizeHandles={["w"]}
               className={`${styles.ResizableBox}`}
            >
               {children}
            </ResizableBox>
         ) : (
            <>{children}</>
         )}
      </section>
   );
};

export default Popup;

interface IPopupParams {
   onClose: (bool: boolean) => void;
   children: React.ReactNode;
   pos?: "center" | "rightSlideIn";
   screenStyles?: React.CSSProperties;
}
