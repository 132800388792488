import styles from "./GGM.module.scss";
import { GoogleMap, useJsApiLoader, OverlayViewF } from "@react-google-maps/api";
import { IggPin } from "../../../contexts/appContext/AppState";
import PropertyMarker from "../../children/map/propertyMarker/PropertyMarker";
import { Icoordinates } from "../../../types/googleMap";
import getLatLng from "../../../utils/map/getLatLng";
import apiKeys from "../../../const/apiKeys";
import googleLibs from "../../../const/googleLibs";
import GgMarker from "../../children/map/ggMarker/GgMarker";
import { Iproperty } from "../../../hooks/querries/properties/useQProperties";
import { TUseQAssets_asset } from "../../../hooks/querries/asssets/useQAssets";
import GgMapAssetMarker from "../../children/map/ggMapAssetMarker/GgMapAssetMarker";
import BodyFiller from "../bodyFiller/BodyFiller";
const defaultCoord = { lat: 39.0164745, lng: -94.7263118 };

const GGM = ({ center = defaultCoord, zoom = 4, onOverlayClick = () => {}, pins = [] }: IGoogleMapProps) => {
   const { isLoaded, loadError } = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: apiKeys.googleAPIs,
      libraries: googleLibs,
   });
   if (loadError) return <div>Map load errored</div>;
   if (!isLoaded) return <BodyFiller loading />;
   const propPins = pins.filter(({ type }) => type === "property") as IpinProperty[];
   const ggPins = pins.filter(({ type }) => type === "ggPin") as IpinGg[];
   const assetPins = pins.filter(({ type }) => type === "assetPin") as TGGMapAssetPin[];

   return (
      <GoogleMap
         mapContainerClassName={styles.mapC}
         center={center}
         zoom={zoom}
         options={{ disableDoubleClickZoom: true }}
      >
         {propPins.map(({ item }, key) => (
            <OverlayViewF mapPaneName="overlayMouseTarget" key={key} position={getLatLng(item.coordinates)}>
               <div onClick={() => onOverlayClick(item.hs_object_id)}>
                  <PropertyMarker property={item} />
               </div>
            </OverlayViewF>
         ))}
         {ggPins.map(({ item }, key) => (
            <OverlayViewF mapPaneName="overlayMouseTarget" key={key} position={{ lat: item.lat, lng: item.lng }}>
               <div onClick={() => onOverlayClick(item.address)}>
                  <GgMarker item={item} />
               </div>
            </OverlayViewF>
         ))}
         {assetPins.map(({ item }, key) => (
            <OverlayViewF
               mapPaneName="overlayMouseTarget"
               key={key}
               position={{
                  lat: item["assets.coordinates"] ? Number(item["assets.coordinates"].split("|")[0]) : 0,
                  lng: item["assets.coordinates"] ? Number(item["assets.coordinates"].split("|")[1]) : 0,
               }}
            >
               <div onClick={() => onOverlayClick(item["assets.hs_object_id"])}>
                  <GgMapAssetMarker asset={item} />
               </div>
            </OverlayViewF>
         ))}
      </GoogleMap>
   );
};

// export default memo(GoogleMap);
export default GGM;
interface IGoogleMapProps {
   center?: Icoordinates;
   zoom?: number;
   onOverlayClick?: (objId: any) => void;
   pins?: (IpinProperty | IpinGg | TGGMapAssetPin)[];
}
export interface IpinProperty {
   type: "property";
   item: Iproperty;
}

export interface IpinGg {
   type: "ggPin";
   item: IggPin;
}

export type TGGMapAssetPin = {
   type: "assetPin";
   item: TUseQAssets_asset;
};
