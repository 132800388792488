import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPg_Aio_AppProperties_Schema } from "@simplyhomes/utils";

export const useQAioProps = (p?: TUseQAioPropsParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioProps"],
      queryFn: () => aioAxios.get(`/aio/v2/properties`).then<axiosResp>(({ data }) => data),
   });
};
const cols = ["address", "bedrooms", "bathrooms"] as const;
//optional options for useqaioprops
type TUseQAioPropsParams = {
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
type axiosResp = {
   properties: Pick<TPg_Aio_AppProperties_Schema, (typeof cols)[number]>[];
};
