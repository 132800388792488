import styles from "./Note.module.scss";
import { TQNotes_note, TQNotes_object, useQNotes } from "../../../hooks/querries/notes/useQNotes";
import ReactHtmlParser from "react-html-parser";
import jsTimeSince from "../../../utils/JS/jsTimeSince";
import { useQUsers } from "../../../hooks/querries/users/useQUsers";
import MainButton from "../mainButton/MainButton";
import { useEffect, useRef, useState } from "react";
import RichTextTinyMce from "../richTextTinyMce/RichTextTinyMce";
import { TNotesPanel_handleSubmit } from "../notesPanel/NotesPanel";
import AppContext from "../../../contexts/appContext/AppContext";
import { useContext } from "react";

const Note = ({ note, submitting, onSubmit, object, objectid, subnotes = [] }: TNoteProps) => {
   const { appState } = useContext(AppContext);
   const { notes_highlightNoteId } = appState;

   const { author, content, nid, time } = note;
   const { data: qUsersData } = useQUsers();
   const { users = [] } = qUsersData || {};

   const { data: qNotesData } = useQNotes({ object, objectid });
   const { notes = [] } = qNotesData || {};

   const [showReplyBox, setShowReplyBox] = useState(false);
   const [replyContent, setReplyContent] = useState("");
   const [showReplies, setShowReplies] = useState(subnotes.length > 0 && true);

   const refContainer = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (nid === notes_highlightNoteId && refContainer.current)
         refContainer.current.scrollIntoView({ behavior: "smooth" });
   }, [nid, notes_highlightNoteId]);

   const authorName = users.find((u) => u.sub === author)?.email.split("@")[0];

   return (
      <div className={`${styles.contentC}`}>
         <div
            className={`${styles.note} ${showReplyBox && styles.replying}
            ${notes_highlightNoteId === nid && styles.highlightedNote}`}
            ref={refContainer}
         >
            <div className={`${styles.hoverControls}`}>
               {<MainButton title="Reply" onClick={() => setShowReplyBox(true)} />}
            </div>
            <div className={`${styles.noteHeader}`}>
               <b>{authorName}</b>
               <p>
                  {time.split("T")[0]} ({jsTimeSince(new Date(time).getTime())})
               </p>
            </div>
            <div>{ReactHtmlParser(content)}</div>
         </div>

         {showReplyBox && (
            <div className={`${styles.tiny}`}>
               <RichTextTinyMce
                  value={replyContent}
                  setValue={setReplyContent}
                  onSubmit={() => onSubmit({ parentid: nid, content: replyContent, object, objectid })}
                  submitting={!!submitting}
                  showCloseBtn
                  onClose={() => setShowReplyBox(false)}
               />
            </div>
         )}
         {subnotes.length > 0 && (
            <MainButton
               type="bland"
               title={`${showReplies ? "Hide" : "Show"} ${subnotes.length} replies`}
               onClick={() => setShowReplies(!showReplies)}
               className={`${styles.repliesBtn}`}
            />
         )}
         {showReplies && (
            <div className={`${styles.repliesC} `}>
               <button className={`${styles.sideBarC}`} onClick={() => setShowReplies(false)}>
                  <div className={`${styles.sideBar}`}></div>
               </button>
               {subnotes.map((n, i) => (
                  <Note
                     key={i}
                     note={n}
                     object={object}
                     objectid={objectid}
                     onSubmit={onSubmit}
                     submitting={submitting}
                     subnotes={notes.filter(({ parentid }) => parentid === n.nid)}
                  />
               ))}
            </div>
         )}
      </div>
   );
};

export default Note;

type TNoteProps = {
   note: TQNotes_note;
   subnotes?: TQNotes_note[];
   submitting: boolean;
   onSubmit: TNotesPanel_handleSubmit;
   object: TQNotes_object;
   objectid: string | number;
};
