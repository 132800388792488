import { SHu_js_currencyFormat, SHu_js_percentageFormat, SHu_js_timeSince } from "@simplyhomes/utils";
import { TQAioUws_UW } from "../../../hooks/querries/aio/underwritings/useQAioUws";
import styles from "./AioUwCard.module.scss";
const AioUwCard = ({ underwriting: uw }: TAioUwCardProps) => {
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.p1C}`}></div>
         <div className={`${styles.p2C}`}>
            <b className={`${styles.price}`}>{SHu_js_currencyFormat(uw.target_purchase_price)}</b>
            <p className={`${styles.address}`}>{uw.address}</p>
         </div>
         <div className={`${styles.p3C}`}>
            <div>
               <p>ARV Uplift</p>
               <b>{SHu_js_percentageFormat(uw.arv_uplift)}</b>
            </div>
            <div>
               <p>Cap Rate</p>
               <b>{SHu_js_percentageFormat(uw.cap_rate)}</b>
            </div>
            <div>
               <p>Unit Count</p>
               <b>{uw.units}</b>
            </div>
         </div>
         <div className={`${styles.p4C}`}>
            <div>
               <span>AIO Price: </span>
               <b>{SHu_js_currencyFormat(uw.offer_2 || uw.offer_1)}</b>
            </div>
            <div>
               <span>ARV: </span>
               <b>{SHu_js_currencyFormat(uw.arv)}</b>
            </div>
            <div>
               <span>Last Updated: </span>
               <b>{SHu_js_timeSince(Date.parse(uw.updated_at))}</b>
            </div>
         </div>
      </div>
   );
};

export default AioUwCard;
type TAioUwCardProps = {
   underwriting: TQAioUws_UW;
};
