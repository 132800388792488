import { TPg_Aio_AppUnderwritingsMetadata_Schema } from "@simplyhomes/utils";

import { useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPassThroughUseQueryOptions } from "../../../../types/useQuery/types";

export const useQAioUwsMeta = (p?: TuseQAioUwsMeta) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      enabled: false, //TODO fix this
      queryKey: ["useQAioUwsMeta"],
      queryFn: () => aioAxios.get(`/aio/v2/underwritings/metadata`).then<axiosResp>(({ data }) => data),
   });
};
const cols = ["name", "allow_delete", "description", "label", "format", "type"] as const;
type axiosResp = {
   metadata: Pick<TPg_Aio_AppUnderwritingsMetadata_Schema, (typeof cols)[number]>[];
};
type TuseQAioUwsMeta = {
   options: TPassThroughUseQueryOptions<axiosResp, string[]>;
};
