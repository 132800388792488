import styles from "./Census.module.scss";
const Census = ({ source }: TCensusProps) => {
   return (
      <section className={`${styles.contentC}`}>
         <iframe
            width={"100%"}
            src={
               source === "external"
                  ? "https://census-external.simplyhomes.dev/"
                  : "https://census-internal.simplyhomes.dev/"
            }
            title={source === "external" ? "Census Insights External" : "Census Insights Internal"}
         />
      </section>
   );
};

export default Census;
type TCensusProps = {
   source: "internal" | "external";
};
