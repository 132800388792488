import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppViews_Schema } from "@simplyhomes/utils";

export const useQViews = ({ object }: TUseQViewsParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["Views", object],
      queryFn: () => aioAxios.get("/aio/views", { params: { object } }).then<axiosReps>(({ data }) => data),
   });
};
type TUseQViewsParams = {
   object: "underwritings" | "assets";
};
const cols = ["name", "vid"] as const; // (keyof TPg_Aio_AppViews_Schema)[];
type axiosReps = { views: Pick<TPg_Aio_AppViews_Schema, (typeof cols)[number]>[] };
