import { TversionMisc } from "../../../../../hooks/querries/versions/useQVersion";
import { useVersionContext } from "../../../../../pages/Versions/Versions";
import MainInput from "../../../../common/mainInput/MainInput";
import styles from "./MiscEditor.module.scss";
const MiscEditor = () => {
   const { version, setVersion } = useVersionContext();
   const { misc } = version;
   const { exportSheetTemplateId = "", exportBulkSheetTemplateId = "" } = misc || {};

   const handleMisc = (obj: TversionMisc) => setVersion({ ...version, misc: { ...misc, ...obj } });
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.title} header-s`}>Miscellaneous</div>
         <MainInput
            title="Export Sheet Template ID"
            value={exportSheetTemplateId}
            handleValue={(v) => handleMisc({ exportSheetTemplateId: v })}
            disableCollapse
            />
         <MainInput
            title="Export Bulk Sheet template"
            value={exportBulkSheetTemplateId}
            handleValue={(v) => handleMisc({ exportBulkSheetTemplateId: v })}
            disableCollapse
         />
      </div>
   );
};

export default MiscEditor;
