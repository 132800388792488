import { useContext } from "react";
import AppContext from "../../contexts/appContext/AppContext";
import axios from "axios";

const useAxios = () => {
   const { REACT_APP_API_URL } = process.env;
   const { appState } = useContext(AppContext);
   const { accessToken } = appState;
   const aioAxios = axios.create({ headers: { Authorization: `Bearer ${accessToken}` }, baseURL: REACT_APP_API_URL });
   return {  aioAxios };
};
export default useAxios;
