import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "./Asset.module.scss";
import { useQAsset } from "../../../hooks/querries/asssets/useQAsset";
import MainButton from "../../../components/common/mainButton/MainButton";
import { useEffect } from "react";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import TabButton from "../../../components/common/tabButton/TabButton";
import ImagesViewer from "../../../components/common/imagesViewer/ImagesViewer";
import { cfAIO } from "../../../utils/s3/cfAIO";
import { SHu_JSONtryParse, SHu_js_currencyFormat, TAttom_Neighborhood_Community_Data } from "@simplyhomes/utils";
const Asset = () => {
   const { aid } = useParams();
   const [params, setParams] = useSearchParams();
   const nav = useNavigate();

   const tab = params.get("tab");

   const { data: qAssetData, isFetching: qAssetFetching } = useQAsset({ aid });
   const { asset } = qAssetData || {};
   const nhPayload: TAttom_Neighborhood_Community_Data | undefined = SHu_JSONtryParse(
      asset?.["neighborhoods.oeprawpayload"] || "",
      undefined
   );
   useEffect(() => {
      if (!tab) {
         params.set("tab", "asset");
         setParams(params);
      }
   }, [params, setParams, tab]);

   const handleTabClick = (tab: string) => {
      params.set("tab", tab);
      setParams(params);
   };

   if (qAssetFetching) return <BodyFiller loading style={{ height: "50vh" }} />;
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <MainButton type="back" onClick={() => nav("/assets/search")} />
            <span className={`header-s`}>{asset?.["assets.address"]}</span>
         </div>
         <div className={`${styles.tabsC}`}>
            <TabButton title="Asset Overview" isActive={tab === "asset"} onClick={() => handleTabClick("asset")} />
            <TabButton
               title="Property Details"
               isActive={tab === "property"}
               onClick={() => handleTabClick("property")}
            />
            <TabButton
               title="Neighborhood Details"
               isActive={tab === "neighborhood"}
               onClick={() => handleTabClick("neighborhood")}
            />
         </div>
         {tab === "asset" && (
            <>
               <ImagesViewer
                  highlightImageSrc={
                     asset?.["properties.hs_object_id"]
                        ? cfAIO(`/properties/street-view/${asset["properties.hs_object_id"]}`)
                        : undefined
                  }
                  imageSrcs={
                     asset?.["assets.photos"]
                        ?.split("|")
                        .filter(Boolean)
                        .map((id) => cfAIO(`/assets/photos/${id}`)) || []
                  }
               />
               <article className={`${styles.assetC}`}>
                  <div className={`${styles.assetPropDetails}`}>
                     <ul className={`${styles.assetPropDetailsHeader}`}>
                        <li>
                           <div>
                              <p>{asset?.["properties.bedrooms"] || "-"}</p>
                           </div>
                           <span>Bedrooms</span>
                        </li>
                        <li>
                           <div>
                              <p>{asset?.["properties.bathrooms"] || "-"}</p>
                           </div>
                           <span>Baths</span>
                        </li>
                        <li>
                           <div>
                              <p>{asset?.["properties.sqft"] || "-"}</p>
                           </div>
                           <span>Square Feet</span>
                        </li>
                        <li>
                           <div>
                              <p>{asset?.["properties.year_built"] || "-"}</p>
                           </div>
                           <span>Year Built</span>
                        </li>
                     </ul>
                     <ul className={`${styles.assetPropDetailsCards}`}>
                        <li>
                           <p>Offer Type</p>
                           <b>
                              {asset?.["deals.is_contingent_offer"]?.toString() === "true"
                                 ? "Contingent"
                                 : "Non Contingent"}
                           </b>
                        </li>
                     </ul>
                     <ul className={`${styles.assetPropDetailsDesc}`}>
                        <li>
                           <p className={`cardHeader-s`}>Neighborhood Description</p>
                           <div>
                              <b>Name: </b>
                              <span>{asset?.["neighborhoods.name"] || "-"}</span>
                           </div>
                           <div>
                              <b>Type: </b>
                              <span>{nhPayload?.community.geography.geographyTypeName || "-"}</span>
                           </div>
                        </li>
                     </ul>
                  </div>
                  <div className={`${styles.assetRentDetails}`}>
                     <h1 className={`cardHeader-s`}>Rent Details</h1>
                     <ul>
                        <li>
                           <p>Rental Source</p>
                           <b>{asset?.["properties.rental_source"] || "-"}</b>
                        </li>
                        <li>
                           <p>Rentometer Avg</p>
                           <b>{SHu_js_currencyFormat(asset?.["properties.rentometer_average_rent"])}</b>
                        </li>
                        <li>
                           <p>SAFMR</p>
                           <b>{SHu_js_currencyFormat(asset?.["properties.safmr_rent"])}</b>
                        </li>
                        <li>
                           <p>SAFMR 110</p>
                           <b>{SHu_js_currencyFormat(asset?.["properties.safmr_rent_110_"])}</b>
                        </li>
                        <li>
                           <p>Tract Rent</p>
                           <b>{SHu_js_currencyFormat(asset?.["properties.tract_rent"])}</b>
                        </li>
                        <li>
                           <p>Expected Rent</p>
                           <b>{SHu_js_currencyFormat(asset?.["properties.expected_rent"])}</b>
                        </li>
                     </ul>
                  </div>
                  {nhPayload && (
                     <div className={`${styles.assetNhInsights}`}>
                        <h1 className={`cardHeader-s`}>Neighborhood Insights:</h1>
                        <ul>
                           <li>
                              <p>Crime Index</p>
                              <b>{nhPayload.community.crime.crime_Index}</b>
                           </li>
                           <li>
                              <p>Air Pollution</p>
                              <b>{nhPayload.community.airQuality.air_Pollution_Index}</b>
                           </li>
                           <li>
                              <p>Annual Temp Avg</p>
                              <b>{nhPayload.community.climate.annual_Avg_Temp}</b>
                           </li>
                           <li>
                              <p>Median HH Income</p>
                              <b>{SHu_js_currencyFormat(nhPayload.community.demographics.median_Household_Income)}</b>
                           </li>
                           <li>
                              <p>Average HH Income</p>
                              <b>{SHu_js_currencyFormat(nhPayload.community.demographics.avg_Household_Income)}</b>
                           </li>
                           <li>
                              <p># of HH</p>
                              <b>{nhPayload.community.demographics.households}</b>
                           </li>
                           <li>
                              <p>Median Work Travel Time</p>
                              <b>{nhPayload.community.demographics.median_Travel_Time_To_Work_Mi}</b>
                           </li>
                           <li>
                              <p>Median Age</p>
                              <b>{nhPayload.community.demographics.median_Age}</b>
                           </li>
                           <li>
                              <p>Own/Rent/Vacant</p>
                              <b>
                                 {nhPayload.community.demographics.housing_Units_Owner_Occupied}/
                                 {nhPayload.community.demographics.housing_Units_Renter_Occupied}/
                                 {nhPayload.community.demographics.housing_Units_Vacant}
                              </b>
                           </li>
                        </ul>
                     </div>
                  )}
               </article>
            </>
         )}
      </div>
   );
};

export default Asset;
type TAssetProps = {};
