import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppUnderwritingsBulk_Schema } from "@simplyhomes/utils";
import useDebounce from "../../react/useDebounce";

export const useQUwBulks = (p?: TUseQUwBulks) => {
   const { options, params } = p || {};
   const { aioAxios } = useAxios();
   const deSearch = useDebounce(params?.search || "", 1000);
   return useQuery({
      ...options,
      queryKey: ["useQUwBulks", deSearch],
      queryFn: () => aioAxios.get("/aio/underwritings/bulk", { params }).then<TAxiosResp>(({ data }) => data),
   });
};

const cols = [
   "bid",
   "addresses",
   "progress",
   "version",
   "created_at",
   "updated_at",
   "created_by",
   "updated_by",
   "name",
   "description",
] as const;
type TAxiosResp = {
   bulks: Pick<TPg_Aio_AppUnderwritingsBulk_Schema, (typeof cols)[number]>[];
};

type TUseQUwBulks = {
   options?: UseQueryOptions<TAxiosResp, unknown, TAxiosResp, string[]>;
   params?: { search: string };
};
