import { useSearchParams } from "react-router-dom";
import styles from "./EntryImportPopup.module.scss";
import useQDbTable from "../../../../../../hooks/querries/database/useQDbTable";
import { useState } from "react";
import { TUnknownObject } from "../../../../../../types/interfaces/unknownObj";
import Table from "../../../../../common/table/Table";
import AFInput from "../../../../../common/AFInput/AFInput";
import BodyFiller from "../../../../../common/bodyFiller/BodyFiller";
import useQDb from "../../../../../../hooks/querries/database/useQDb";
const EntryImportPopup = ({ setEntry, close }: IEntryImportPopupProps) => {
   const [searchCol, setSearchCol] = useState("");
   const [searchVal, setSearchVal] = useState("");

   const [params] = useSearchParams();
   const table = params.get("table");

   const { data: qDbData, isFetching: qDbImportIsFetching } = useQDb();
   const { importData = [] } = qDbData || {};
   const { import_cols = [], import_from } = importData.find((obj) => obj.table === table) || {};

   const { data: qDbTableData, isFetching: qDbTableIsFetching } = useQDbTable({
      db: "crm",
      table: import_from,
      cols: import_cols,
      searchCol,
      searchVal,
      // enable: !!searchCol && !!searchVal,
   });
   const { rows = [] } = qDbTableData || {};
   const rowData = rows.map((obj) => import_cols.map((name: string) => obj[name]));
   const rowClick = (obj: TUnknownObject) => {
      setEntry(obj);
      close();
   };
   if (qDbImportIsFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading={qDbImportIsFetching} />
         </div>
      );
   return (
      <div className={`${styles.contentC}`}>
         <div className={`header-s`}>Import From HubSpot({import_from})</div>
         <div className={`${styles.search}`}>
            <AFInput
               type="dropdown"
               title="Search Column"
               value={searchCol}
               options={import_cols}
               handleChange={setSearchCol}
            />
            <AFInput title="Search Value" value={searchVal} handleChange={setSearchVal} />
         </div>
         {qDbTableIsFetching ? (
            <BodyFiller style={{ height: "10rem", width: "10rem", margin: "auto" }} loading={qDbTableIsFetching} />
         ) : (
            <Table
               type="none"
               data={rowData}
               columnHeaders={import_cols}
               internalScroll
               tbodyHeight="10rem"
               onRowClick={rowClick}
            />
         )}
      </div>
   );
};

export default EntryImportPopup;
interface IEntryImportPopupProps {
   setEntry: (obj: TUnknownObject) => void;
   close: () => void;
}
