import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import {
   TPg_Crm_Assets_Schema,
   TPg_Crm_Deals_Schema,
   TPg_Crm_Neighborhoods_Schema,
   TPg_Crm_Properties_Schema,
} from "@simplyhomes/utils";

export const useQAsset = ({ aid }: TUseQAssetParams) => {
   const { aioAxios } = useAxios();

   return useQuery({
      enabled: !!aid,
      queryKey: [aid],
      queryFn: () => aioAxios.get(`/aio/assets/${aid}`).then<TAxiosReps>(({ data }) => data),
   });
};

type TUseQAssetParams = {
   aid: string | number | undefined;
};
const assetCols = ["address", "photos"] as const;
const dealCols = ["is_contingent_offer"] as const;
const propCols = [
   "contract_price",
   "hs_object_id",
   "bedrooms",
   "bathrooms",
   "sqft",
   "year_built",
   "tract_rent",
   "rentometer_average_rent",
   "safmr_rent",
   "safmr_rent_110_",
   "rental_source",
   "expected_rent",
] as const;
const nhCols = ["name", "oeprawpayload"] as const;

type TAxiosReps = {
   asset: PrefixedAsset<Pick<TPg_Crm_Assets_Schema, (typeof assetCols)[number]>> &
      PrefixedProp<Pick<TPg_Crm_Properties_Schema, (typeof propCols)[number]>> &
      PrefixedNh<Pick<TPg_Crm_Neighborhoods_Schema, (typeof nhCols)[number]>> &
      PrefixedDeal<Pick<TPg_Crm_Deals_Schema, (typeof dealCols)[number]>>;
};

type PrefixedAsset<T> = {
   [K in keyof T as `assets.${string & K}`]: T[K];
};
type PrefixedProp<T> = {
   [K in keyof T as `properties.${string & K}`]: T[K];
};
type PrefixedNh<T> = {
   [K in keyof T as `neighborhoods.${string & K}`]: T[K];
};
type PrefixedDeal<T> = {
   [K in keyof T as `deals.${string & K}`]: T[K];
};
