import { useAuth0 } from "@auth0/auth0-react";
import MainButton from "../mainButton/MainButton";
import styles from "./ViewConfig.module.scss";
import { useSearchParams } from "react-router-dom";
import {
   SHu_aio_assets_baseFields,
   SHu_aio_underwritings_baseFields,
   SHu_js_swapTwoElementsInArray,
   TPg_Aio_AppViews_Schema,
} from "@simplyhomes/utils";
import { useReducer, useState } from "react";
import MainInput from "../mainInput/MainInput";
import TabButton from "../tabButton/TabButton";
import Popup from "../popup/Popup";
import FiltersConfig from "../../children/underwritings/filtersConfig/FiltersConfig";
import { useQFields } from "../../../hooks/querries/fields/useQFields";
import TableV2, { TTableV2_onCellClickParams } from "../tableV2/TableV2";
import { useDrop } from "react-dnd";
import UwSCard from "../../children/underwritings/uwsCard/UwSCard";
import { useQUsers } from "../../../hooks/querries/users/useQUsers";
import useAxios from "../../../hooks/axios/useAxios";
import { useQViews } from "../../../hooks/querries/views/useQViews";
import SmallSpinner from "../smallSpinner/SmallSpinner";
import { useQView } from "../../../hooks/querries/views/useQView";
import AssetCard from "../../children/assets/AssetCard/AssetCard";
import { useHotkeys } from "react-hotkeys-hook";
const ViewConfig = ({ currView, onClose, object }: TViewConfigProps) => {
   useHotkeys("esc", onClose);

   const { aioAxios } = useAxios();
   const { user } = useAuth0();
   const [params, setSearchParams] = useSearchParams();
   const viewId = params.get("viewId") || "";

   const { data: qFieldsData } = useQFields({
      tables: object === "underwritings" ? ["underwritings"] : ["properties", "assets"],
      custom: ["xero"],
   });
   const { fields = [] } = qFieldsData || {};

   const { refetch: qViewsRefetch } = useQViews({ object });

   const { data: qViewData, refetch: qViewRefetch } = useQView({ viewId, object });
   const { view: ogView } = qViewData || {};

   const { data: qUsersData } = useQUsers();
   const { users = [] } = qUsersData || {};

   const [tab, setTab] = useState("config");
   const [viewFilters, setViewFilters] = useState(false);
   const [submitting, setSubmitting] = useState(false);

   const [view, viewDispatch] = useReducer(ViewConfigReducer, {
      ...currView,
      filters: currView.filters || [[]],
      sort_by: currView.sort_by || "",
      sort_direction: currView.sort_direction || -1,
      shared_to_subs: currView.shared_to_subs || [],
      order: [...new Set([...(!currView.vid ? SHu_aio_underwritings_baseFields : []), ...(currView.order || [])])],
   });
   const baseCols: string[] =
      object === "underwritings" ? [...SHu_aio_underwritings_baseFields] : [...SHu_aio_assets_baseFields];

   const isOwner = currView.created_by_sub === user?.sub;
   const isValidName = !!view.name && view.name !== "Default View";
   const isValidCreate = isValidName && view.name !== ogView?.name;

   const getFieldLabel = (name: string) => fields.find((f) => f.name === name.split(".").pop())?.label || name;
   const getUserEmail = (sub: string) => users.find((u) => u.sub.includes(sub))?.email || sub;
   const handleCellClick = ({ c, r, v }: TTableV2_onCellClickParams) => {
      if (r !== -1) return;
      viewDispatch({ overwrite: { sort_by: view.order[c], sort_direction: view.sort_direction === 1 ? -1 : 1 } });
   };

   const handleSubmit = async (mode: "create" | "update" | "delete") => {
      setSubmitting(true);
      const newViewId = await aioAxios[mode === "delete" ? "delete" : "post"](
         `/aio/views${mode === "delete" ? `/${view.vid}` : ""}`,
         { view: { ...view, vid: mode === "create" ? 0 : view.vid }, params: { object } },
         { params: { object } }
      )
         .then(({ data }) => data?.viewId)
         .catch((er) => console.log({ er }));
      params.set("viewId", newViewId || "0");
      setSearchParams(params);
      qViewRefetch();
      qViewsRefetch();
      onClose();
      setSubmitting(false);
   };
   const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: "header",
      drop: ({ colIndex }: { colIndex: number }) => viewDispatch({ type: "delCol", payload: { colIndex } }),
      collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
   }));

   return (
      <article className={`${styles.viewTabC}`}>
         {viewFilters && (
            <Popup onClose={() => setViewFilters(false)} pos="rightSlideIn">
               <FiltersConfig
                  externalFilters={view.filters}
                  onClose={() => setViewFilters(false)}
                  onApply={(filters) => viewDispatch({ overwrite: { filters } })}
                  objects={object === "underwritings" ? ["underwritings"] : ["properties", "assets"]}
               />
            </Popup>
         )}
         <div className={`${styles.viewTabHeader}`}>
            <MainButton type="close" onClick={onClose} />
            <span className={`header-s`}>{isOwner ? "Edit" : "Create"} View</span>
            {submitting ? (
               <SmallSpinner style={{ width: "1rem" }} />
            ) : (
               <>
                  {isOwner && (
                     <MainButton
                        title="Delete"
                        onClick={() => handleSubmit("delete")}
                        confirmMsg={`Delete view "${view.name}"?`}
                        type="warn"
                     />
                  )}
                  {isOwner && (
                     <MainButton
                        title="Update"
                        onClick={() => handleSubmit("update")}
                        confirmMsg={`Overwrite view "${view.name}"?`}
                     />
                  )}
                  <MainButton
                     title="Create"
                     enable={isValidCreate}
                     onClick={() => handleSubmit("create")}
                     confirmMsg={`Create new view?`}
                  />
               </>
            )}
         </div>
         <div className={`${styles.tabs}`}>
            <TabButton title="Config" isActive={tab === "config"} onClick={() => setTab("config")} />
            <TabButton title="Share" isActive={tab === "share"} onClick={() => setTab("share")} />
         </div>
         {tab === "config" && (
            <div className={`${styles.inputsC}`}>
               <div className={`${styles.inputs1}`}>
                  <MainInput
                     title="Name"
                     value={view.name}
                     handleValue={(name) => viewDispatch({ overwrite: { name } })}
                     style={{ width: "20rem" }}
                     bad={!isValidName}
                     tooltip={!isValidName ? { type: "info", msg: "Must not be empty or default" } : undefined}
                     disableCollapse
                  />
                  <MainButton
                     title={`Filters (${view.filters.flat().length})`}
                     type="bland"
                     onClick={() => setViewFilters(true)}
                  />
               </div>
               <div className={`${styles.inputs2}`}>
                  <div className={`${styles.inputs2L}`}>
                     <MainInput
                        title={`Fields (selected ${view.order.length})`}
                        type="search"
                        value={""}
                        handleValue={(str) =>
                           viewDispatch({
                              overwrite: { order: [...view.order, str.split("|").pop() || ""].filter(Boolean) },
                           })
                        }
                        options={fields.map((f) => `${f.object[0].toUpperCase()}-${f.label}|${f.object}.${f.name}`)}
                        style={{ width: "20rem" }}
                        hideSeparators
                     />
                     {canDrop && (
                        <div className={`${styles.fieldTrashBin}`} ref={drop}>
                           <MainButton title="Drop here to discard" type={isOver ? "main" : "scnd"} />
                        </div>
                     )}
                  </div>
                  <div className={`${styles.inputs2R}`}>
                     <MainInput
                        title="Sort By"
                        type="search"
                        value={getFieldLabel(view.sort_by || "")}
                        handleValue={(str) => viewDispatch({ overwrite: { sort_by: str.split("|").pop() || "" } })}
                        options={view.order.map((col) => `${getFieldLabel(col)}|${col}`)}
                        // tooltip={{ type: "info", msg: "Showing only selected fields" }}
                        style={{ width: "8rem" }}
                        hideSeparators
                     />
                     <MainInput
                        title="Sort"
                        type="dropdown"
                        style={{ width: "6rem" }}
                        value={view.sort_direction === 1 ? "ASC" : "DESC"}
                        options={["DESC", "ASC"]}
                        handleValue={(str) => viewDispatch({ overwrite: { sort_direction: str === "ASC" ? 1 : -1 } })}
                     />
                  </div>
               </div>
               <div className={`${styles.selected}`}>
                  <b>Selected: </b>
                  {view.order
                     .map((col) => `${getFieldLabel(col)}|${col}`)
                     .map((str, key) => (
                        <button
                           key={key}
                           onClick={() =>
                              viewDispatch({
                                 overwrite: { order: view.order.filter((col) => col !== str.split("|")[1]) },
                              })
                           }
                        >
                           <span>
                              {str.split("|")[0]}({str.split("|")[1]?.split(".")[0][0]})
                           </span>
                           <span>+</span>
                        </button>
                     ))}
               </div>
               <div className={`${styles.tableC}`}>
                  <TableV2
                     tableData={[view.order.reduce((prev, curr) => ({ ...prev, [curr]: "-" }), {})]}
                     headerData={view.order.map((key) => ({ key, label: getFieldLabel(key) }))}
                     onCellClick={handleCellClick}
                     sortColIndex={view.order.findIndex((col) => col === view.sort_by)}
                     sortDirection={view.sort_direction === 1 ? "asc" : "desc"}
                     onHeaderDrop={(payload) => viewDispatch({ type: "swapCol", payload })}
                  />
               </div>
               {object === "assets" && (
                  <AssetCard
                     asset={{
                        option_label: "Stage",
                        property_object_id: "5939046433",
                        "xero.sum_total": 1000,
                        "xero.sum_due": 1000,
                        "properties.oec_arv": "1000",
                        "assets.address": "510 Transverse Ave, Pittsburgh, PA 15210, USA",
                     }}
                     view={view}
                  />
               )}
               {object === "underwritings" && (
                  <UwSCard
                     uw={{
                        ...[...baseCols, ...view.order].reduce(
                           (prev, curr) => ({ ...prev, [curr]: getFieldLabel(curr) }),
                           {}
                        ),
                        "associations.hs_object_id_a": "5939046433",
                        hs_pipeline: "41400397",
                        renovation_quote_accepted: "true",
                     }}
                     view={view}
                     previewMode
                     selectMode
                  />
               )}
            </div>
         )}
         {tab === "share" && (
            <div className={`${styles.shareC}`}>
               <MainInput
                  title={`Shared to ${view.shared_to_subs.length} user${view.shared_to_subs.length > 1 ? "s" : ""}`}
                  value={""}
                  type="search"
                  options={[`All|all`, ...users.map((u) => `${u.email}|${u.sub}`)]}
                  handleValue={(str) =>
                     viewDispatch({
                        overwrite: {
                           shared_to_subs: str
                              ? [...new Set([...view.shared_to_subs, str.split("|").pop() || ""])]
                              : [],
                        },
                     })
                  }
                  hideSeparators
               />
               <ul className={`${styles.usersC}`}>
                  {view.shared_to_subs.map((sub, key) => (
                     <li
                        key={sub + key}
                        onClick={() =>
                           viewDispatch({ overwrite: { shared_to_subs: view.shared_to_subs.filter((s) => s !== sub) } })
                        }
                     >
                        <span>{getUserEmail(sub)}</span>
                     </li>
                  ))}
               </ul>
            </div>
         )}
      </article>
   );
};

export default ViewConfig;
type TViewConfigProps = {
   onClose: () => void;
   currView: TPg_Aio_AppViews_Schema;
   object: "assets" | "underwritings";
};

const ViewConfigReducer = (
   state: TPg_Aio_AppViews_Schema,
   action: { type?: "swapCol" | "delCol"; payload?: any; overwrite?: Partial<TPg_Aio_AppViews_Schema> }
) => {
   if (action.overwrite) return { ...state, ...action.overwrite };
   switch (action.type) {
      case "swapCol": {
         const order = SHu_js_swapTwoElementsInArray([...state.order], action.payload.from, action.payload.to);
         return { ...state, order };
      }

      case "delCol": {
         const col = state.order[action.payload.colIndex];

         const order = state.order.filter((_, i) => i !== action.payload.colIndex);
         return { ...state, order, sort_by: state.sort_by === col ? "" : state.sort_by };
      }
      default:
         break;
   }
   return { ...state };
};
