import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";

const useQUwSnapshot = ({ pid, snapshot, version }: IuseQUwSnapshotParams) => {
   const { aioAxios } = useAxios();

   return useQuery({
      enabled: !!pid && !!snapshot,
      queryKey: ["PropertySnapshot", pid, snapshot],
      queryFn: () =>
         aioAxios
            .get(`/aio/properties/snapshot/${pid}`, { params: { snapshot, version } })
            .then<{ data: TUnknownObject; note: string | null }>(({ data }) => data) || {},
   });
};
export default useQUwSnapshot;
interface IuseQUwSnapshotParams {
   pid: string;
   version: string;
   snapshot: string;
}
