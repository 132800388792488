import { useState } from "react";
import styles from "./MainButton.module.scss";
import Popup from "../popup/Popup";
const MainButton = ({
   title,
   onClick = () => {},
   enable = true,
   style,
   className,
   confirmMsg,
   type = "main",
   children,
}: IMainButtonParams) => {
   const [showPopup, setShowPopup] = useState(false);
   const click = () => (confirmMsg ? setShowPopup(true) : onClick());
   const handleCancel = () => setShowPopup(false);
   const handleConfirm = () => {
      setShowPopup(false);
      onClick();
   };
   if (type === "close" || type === "back" || type === "children")
      return (
         <>
            <button onClick={click} style={style} disabled={!enable} className={`${styles[type]} ${className}`}>
               {children}
            </button>
            {showPopup && (
               <Popup onClose={() => setShowPopup(false)}>
                  <div className={`${styles.confirmC}`}>
                     <p className={`header-s`}>{confirmMsg}</p>
                     <div className={`${styles.controlsC}`}>
                        <MainButton title="Cancel" onClick={handleCancel} type="bland" />
                        <MainButton title="Confirm" onClick={handleConfirm} type="warn" />
                     </div>
                  </div>
               </Popup>
            )}
         </>
      );
   return (
      <>
         <button
            type="button"
            className={`${styles.contentC} ${enable && styles.enabled} ${styles[type]} ${className}`}
            style={style}
            onClick={click}
            disabled={!enable}
         >
            {title}
         </button>
         {showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <div className={`${styles.confirmC}`}>
                  <b>{confirmMsg}</b>
                  <div className={`${styles.controlsC}`}>
                     <MainButton title="Cancel" onClick={handleCancel} type="bland" />
                     <MainButton title="Confirm" onClick={handleConfirm} type="warn" />
                  </div>
               </div>
            </Popup>
         )}
      </>
   );
};

export default MainButton;

interface IMainButtonParams {
   title?: string;
   onClick?: () => void;
   enable?: boolean;
   style?: React.CSSProperties;
   className?: string;
   confirmMsg?: string;
   type?: "main" | "scnd" | "warn" | "bland" | "close" | "back" | "children";
   children?: React.ReactNode;
}
