import { TformulaNode } from "../../../../../../../../../types/DnD/formulaDnD";
import styles from "./FieldFormulaDragNode.module.scss";
const FieldFormulaDragNode = ({ name, type, note }: IFieldFormulaDragNodeProps) => {
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.type}`}>
            <span>{type}</span>
            {note && <span>({note})</span>}
         </div>
         <div className={`${styles.name}`}>{name}</div>
      </div>
   );
};

export default FieldFormulaDragNode;
interface IFieldFormulaDragNodeProps {
   type: TformulaNode;
   name: string;
   note?: string;
}
