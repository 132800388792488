import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import useAxios from "../../axios/useAxios";

const useQDbRelation = (props?: IuseQDbRelationParams) => {
   const { aioAxios } = useAxios();

   const [params] = useSearchParams();
   const table = params.get("table");
   const id = params.get("id");
   const queryFn = async () =>
      await aioAxios.get(`/aio/relations/${table}`, { params: { id } }).then<IaxiosResp>(({ data }) => data);
   return useQuery({ queryKey: ["relation", table, id], enabled: !!table && !!id, queryFn });
};

export default useQDbRelation;
interface IuseQDbRelationParams {}
interface IaxiosResp {
   related: { table: string; labels: string[]; objs: TUnknownObject[] }[];
}
