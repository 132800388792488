import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import styles from "./TableV2.module.scss";
import { assetGetStageColor } from "../../../utils/color/assetGetStageColor";
import { cfAIO } from "../../../utils/s3/cfAIO";
import { useDrag, useDrop } from "react-dnd";

const HeaderDrag = ({
   header,
   colIndex,
   className,
   showFilterIcon,
}: {
   header: string;
   colIndex: number;
   className?: string;
   showFilterIcon?: boolean;
}) => {
   const [, drag] = useDrag(() => ({ type: "header", item: { colIndex } }));
   return (
      <div ref={drag} className={`${className}`}>
         <span>{header}</span>
         {showFilterIcon && <img src={cfAIO(`/icons/assets/table/sort-arrow.svg`)} alt="" />}
      </div>
   );
};

export type TTableV2_OnHeaderDrop = (p: { from: number; to: number }) => void;
const HeaderDrop = ({
   onHeaderDrop = () => {},
   onClick = () => {},
   header,
   dropClassName,
   dragClassName,
   colIndex,
   showFilterIcon,
}: {
   onHeaderDrop?: TTableV2_OnHeaderDrop;
   onClick?: () => void;
   header: string;
   colIndex: number;
   dropClassName?: string;
   dragClassName?: string;
   showFilterIcon?: boolean;
}) => {
   const [{ canDrop, isOver }, drop] = useDrop(() => ({
      accept: "header",
      drop: (item: { colIndex: number }) => onHeaderDrop({ from: item.colIndex, to: colIndex }),
      collect: (monitor) => ({ isOver: monitor.isOver(), canDrop: monitor.canDrop() }),
   }));

   return (
      <th
         ref={drop}
         className={`${dropClassName} ${canDrop && styles.dropCanDrop} ${isOver && styles.dropIsOver}`}
         onClick={onClick}
      >
         <HeaderDrag showFilterIcon={showFilterIcon} header={header} className={dragClassName} colIndex={colIndex} />
      </th>
   );
};

const TableV2 = ({
   headerData,
   tableData = [],
   sortColIndex,
   sortDirection = "desc",
   showFilterIcon = true,
   highlightRows = [],
   onCellClick = () => {},
   onHeaderDrop = () => {},
   stickyFirstColumn,
   stickyFirstRow,
}: TTablev2Props) => {
   return (
      <table className={`${styles.contentC}`}>
         <thead>
            <tr className={`${stickyFirstRow && styles.stickyFirstRow}`}>
               {headerData.map((header, colIndex) => (
                  <HeaderDrop
                     key={colIndex}
                     colIndex={colIndex}
                     onClick={() => onCellClick({ r: -1, c: colIndex, v: header.label })}
                     header={header?.label || ""}
                     dragClassName={`${sortColIndex === colIndex && sortDirection && styles[sortDirection]}`}
                     onHeaderDrop={onHeaderDrop}
                     showFilterIcon={showFilterIcon}
                  />
               ))}
            </tr>
         </thead>
         <tbody>
            {tableData.map((rowData, rowIndex) => (
               <tr key={rowIndex} className={`${highlightRows.includes(rowIndex) && styles.highlight}`}>
                  {headerData.map(({ key, format = [], label }, colIndex) => (
                     <td
                        key={colIndex}
                        title={label}
                        className={`${format.find(({ type }) => type === "bold") && styles.bold}
                                    ${colIndex === sortColIndex && styles.sorted}
                                    ${colIndex === 0 && stickyFirstColumn && styles.stickyFirstColumn}`}
                        style={
                           format.find(({ type }) => type === "assetStage")
                              ? { backgroundColor: assetGetStageColor(rowData[key]), color: "#fff" }
                              : undefined
                        }
                        onClick={() => onCellClick({ r: rowIndex, c: colIndex, v: rowData[key] })}
                     >
                        {rowData[key] === "" || rowData[key] == null
                           ? "-"
                           : format.find(({ type }) => type === "$")
                           ? SHu_js_currencyFormat(rowData[key])
                           : format.find(({ type }) => type === "%")
                           ? SHu_js_percentageFormat(rowData[key])
                           : rowData[key]}
                     </td>
                  ))}
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default TableV2;
type TTablev2Props = {
   headerData: TTableV2_headerData;
   tableData: Record<string, any>[];
   sortColIndex?: number;
   sortDirection?: TTableV2_sortDirection;
   showFilterIcon?: boolean;
   highlightRows?: number[];
   onCellClick?: (p: TTableV2_onCellClickParams) => void;
   onHeaderDrop?: TTableV2_OnHeaderDrop;
   stickyFirstColumn?: boolean;
   stickyFirstRow?: boolean;
};
export type TTableV2_onCellClickParams = { r: number; c: number; v: string | number };
export type TTableV2_headerData = TTableV2_header[];
export type TTableV2_sortDirection = "asc" | "desc";
export type TTableV2_header = {
   label: string;
   key: string;
   format?: (
      | { type: "bold"; value?: number }
      | { type: "sqft" }
      | { type: "$" }
      | { type: "%" }
      | { type: "link"; value: string }
      | { type: "assetStage" }
   )[];
};
