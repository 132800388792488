import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { TversionUnitBehavior } from "../../../const/UWcalc/unitBehaviors";
import { TgroupType } from "../../../const/UWcalc/groupTypes";
import { TaioVersionObj } from "../../../types/aioVersion/types";
const useQVersion = ({ ver, obj, enabled }: TuseQVersionParams) => {
   const { aioAxios } = useAxios();
   const nav = useNavigate();
   return useQuery({
      enabled: enabled != null ? enabled : !!obj,
      queryKey: ["version-config", ver, obj],
      queryFn: () =>
         aioAxios
            .get(`/aio/versions/${ver}`, { params: { obj }, headers: { "Cache-Control": "no-cache" } })
            .then<{ config: TversionConfig; roles: string[] }>((res) => res.data)
            .catch((err: AxiosError) => (err.response?.status === 403 ? nav("/unauthorized") : console.log({ err }))),
   });
};

export default useQVersion;
type TuseQVersionParams = {
   ver: string;
   obj: TaioVersionObj;
   enabled?: boolean;
};
export type TversionConfig = {
   fields?: TversionField[];
   groups?: TversionGroup[];
   overview?: TversionGroup[];
   units?: TversionGroup[];
   bulk?: TversionGroup[];
   misc?: TversionMisc;
};

export type TversionMisc = {
   exportSheetTemplateId?: string;
   exportBulkSheetTemplateId?: string;
};

export type TversionGroup = {
   id: string;
   type?: TgroupType;
   columns?: string;
   rows?: string;
   zone?: TVersionZone;
   title: string;
   children: string[];
   defaultShown?: boolean;
};

export type TversionField = {
   id: string;
   name: string;
   column?: string;
   uwColumn?: string;
   formula?: string;
   format?: Tformat;
   readonly?: boolean;
   required?: boolean;
   showInBulk?: boolean;
   type?: TtypeDropDown;
   options?: string;
   fallback?: string;
   condFormat?: string;
   unitBehavior?: TversionUnitBehavior;
};
export type TVersionZone = "top" | "left" | "right" | "bottom";
export type TtypeDropDown = "text" | "number" | "checkbox";
export type Tformat = "Percentage" | "Currency" | "";
