import { useContext } from "react";
import AppContext from "../../contexts/appContext/AppContext";
import { useSearchParams } from "react-router-dom";
import useQVersion from "../querries/versions/useQVersion";
import { TUwData } from "../../types/hsObjects";
import { TUnknownObject } from "../../types/interfaces/unknownObj";
import { calcUwU } from "../../utils/underwritings/calcUwU";
import { TUwUbState } from "../../contexts/UwUbContext/uwubReducer";

const useResolveUwUBulk = () => {
   const { appState } = useContext(AppContext);
   const { accessToken = "" } = appState;

   const [params] = useSearchParams();
   const ver = params.get("ver") || "";

   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const { units = [], fields = [] } = qVerData?.config || {};

   const uwubRecalcUw = async ({
      curCache,
      curOptions,
      curUw,
      fieldIds,
      unitIndex,
   }: TuwubRecalcUw): Promise<Partial<TUwUbState>> => {
      const calcUwUProps = { accessToken, config: qVerData?.config || {}, forceRecalcFirstLayer: true };
      if (fieldIds === "all") {
         const { $childUnits = [] } = curUw;
         const allFieldsIds = fields.map(({ id }) => id);
         let tempResult: TUwData = {};
         let tempOptions: TUnknownObject = {};
         let tempCache: TUnknownObject = {};

         const { calcCache, calcOptions, calcResult } = await calcUwU({
            ...calcUwUProps,
            fieldIds: allFieldsIds,
            curUw,
            curCache,
            curOptions,
         });
         tempResult = calcResult;
         tempOptions = calcOptions;
         tempCache = { ...tempCache, ...calcCache };
         for (let i = 0; i < $childUnits.length; i++) {
            const unitFieldIds = units.map(({ children }) => children).flat();
            const { calcCache, calcOptions, calcResult } = await calcUwU({
               fieldIds: unitFieldIds,
               unitIndex: i,
               curUw: tempResult,
               curCache: tempCache,
               curOptions: tempOptions,
               ...calcUwUProps,
            });
            tempResult = calcResult;
            tempOptions = calcOptions;
            tempCache = { ...tempCache, ...calcCache };
         }
         return {
            uwBase: tempResult,
            uwOptions: tempOptions,
            uwCache: tempCache,
            uwProcessing: false,
         };
      }
      const { calcCache, calcOptions, calcResult } = await calcUwU({
         ...calcUwUProps,
         curCache,
         curOptions,
         curUw,
         fieldIds,
         unitIndex,
      });
      return { uwCache: calcCache, uwOptions: calcOptions, uwBase: calcResult, uwProcessing: false };
   };

   return { uwubRecalcUw };
};
export default useResolveUwUBulk;
type TuwubRecalcUw = {
   curUw: TUwData;
   curCache: TUnknownObject;
   curOptions: TUnknownObject;
   fieldIds: "all" | string[];
   unitIndex?: number;
};
