import styles from "./TabButton.module.scss";
const TabButton = ({ className, isActive, onClick, title }: TTabButtonProps) => {
   return (
      <div className={`${className} ${styles.contentC} ${isActive && styles.activeView}`} onClick={onClick}>
         <span>{title}</span>
      </div>
   );
};

export default TabButton;
type TTabButtonProps = {
   title: string;
   isActive: boolean;
   onClick?: () => void;
   className?: string;
};
