const jsTimeSince = (pass: number , now: number = Date.now()) => {
   var seconds = Math.floor((now - Number(pass)) / 1000);

   var interval = seconds / 31536000;

   if (interval > 1) {
      return Math.floor(interval) + "Y ago";
   }
   interval = seconds / 2592000;
   if (interval > 1) {
      return Math.floor(interval) + "Mo ago";
   }
   interval = seconds / 86400;
   if (interval > 1) {
      return Math.floor(interval) + "D ago";
   }
   interval = seconds / 3600;
   if (interval > 1) {
      return Math.floor(interval) + "H ago";
   }
   interval = seconds / 60;
   if (interval > 1) {
      return Math.floor(interval) + "Mi ago";
   }
   return Math.floor(seconds) + "S ago";
};
export default jsTimeSince;
