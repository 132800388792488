import styles from "./RelationEditor.module.scss";
import useQDbRelation from "../../../../../hooks/querries/database/useQDbRelation";
import BodyFiller from "../../../../common/bodyFiller/BodyFiller";
import MainButton from "../../../../common/mainButton/MainButton";
import RelationEditorPopup from "./relationEditorPopup/RelationEditorPopup";
import Popup from "../../../../common/popup/Popup";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
const RelationEditor = (props: IRelationEditorProps) => {
   const [showPopup, setShowPopup] = useState(false);

   const { data: qDbRelationData, isFetching: qDbRelationIsFetching } = useQDbRelation();
   const { related = [] } = qDbRelationData || {};

   const [params, setParams] = useSearchParams();
   const relTable = params.get("relTable");

   const handleAddRel = (str: string) => {
      setShowPopup(true);
      params.set("relTable", str);
      setParams(params);
   };

   if (qDbRelationIsFetching) return <BodyFiller loading={qDbRelationIsFetching} />;
   return (
      <div className={`${styles.contentC} padding-s`}>
         {relTable && showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <RelationEditorPopup handleClose={() => setShowPopup(false)} />
            </Popup>
         )}
         <div className={`${styles.title} header-s`}>Relation Editor</div>
         <ul className={`${styles.relationships}`}>
            {related.map(({ table, objs }, key) => (
               <li key={key} className={`${styles.relationship}`}>
                  <div className={`${styles.tableTitle}`}>Table: {table}</div>
                  <ul className={`${styles.entries}`}>
                     {objs.map(({ table, obj }, key) => (
                        <li key={key} className={`${styles.entry}`}>
                           <div className={`${styles.id}`}>ID: {obj[table]}</div>
                           {Object.keys(obj)
                              .filter((str) => str !== table)
                              .map((str, key) => (
                                 <div key={key} className={`${styles.info} `}>
                                    <b>{str}</b>
                                    <span title={obj[str]}>: {obj[str]}</span>
                                 </div>
                              ))}
                        </li>
                     ))}
                     <MainButton
                        title="ADD"
                        onClick={() => handleAddRel(table)}
                        style={{ width: "4rem", height: "4rem" }}
                     />
                  </ul>
               </li>
            ))}
         </ul>
      </div>
   );
};

export default RelationEditor;
interface IRelationEditorProps {}
