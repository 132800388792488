import { useContext, useEffect, useRef, useState } from "react";
import styles from "./Superhuman.module.scss";
import MainInput from "../mainInput/MainInput";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/appContext/AppContext";
import { useHotkeys } from "react-hotkeys-hook";
import { useAuth0 } from "@auth0/auth0-react";
import { navs } from "../../children/nav/Nav";
import useAuthTabs from "../../../hooks/auth0/useAuthTabs";

const Item = ({ item: i, onHover, highlighted }: { item: TItem; onHover: () => void; highlighted: boolean }) => {
   const refItem = useRef<HTMLLIElement>(null);
   useEffect(() => {
      if (highlighted && refItem.current) refItem.current.scrollIntoView({ behavior: "smooth" });
   }, [highlighted]);

   return (
      <li onClick={i.callback} onMouseEnter={onHover} className={`${highlighted && styles.highlighted}`} ref={refItem}>
         {i.label}
      </li>
   );
};

const Superhuman = ({ onClose }: TSuperhumanProps) => {
   const { appDispatch } = useContext(AppContext);
   const nav = useNavigate();

   const { authTabs } = useAuthTabs();

   useHotkeys("esc", onClose);

   const refContainer = useRef<HTMLUListElement>(null);

   const [search, setSearch] = useState("");
   const [highlighted, setHighlighted] = useState(0);

   const callback = (cb: () => void) => () => {
      cb();
      onClose();
   };

   const { user } = useAuth0();

   const groups: TGroups[] = [
      {
         groupName: "Navigation",
         items: navs
            .filter((i) => !i.permision || authTabs.includes(i.permision))
            .map((i) => ({
               label: i.label,
               callback: () => nav(typeof i.path === "string" ? i.path : i.path({ email: user?.email })),
            })),
      },
      {
         groupName: "Notifications",
         items: [
            {
               label: "Show Notifications",
               callback: callback(() => appDispatch({ overwrite: { notifications_show: true } })),
            },
         ],
      },
      {
         groupName: "Quick Access",
         items: [
            {
               label: "Quick Calculator",
               callback: callback(() => appDispatch({ overwrite: { quickcalculator_show: true } })),
            },
         ],
      },
   ];
   const filteredGroups = groups
      .map((g) => ({
         ...g,
         items: g.items.filter((i) => i.label.toLowerCase().includes(search.toLowerCase())),
      }))
      .filter((g) => g.items.length > 0);
   const allItems = filteredGroups.map((g) => g.items).flat();
   const allItemsIndex = ({ gi, ii }: { gi: number; ii: number }) =>
      filteredGroups
         .slice(0, gi)
         .map((g) => g.items)
         .flat().length + ii;

   const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") onClose();
      if (e.key === "ArrowUp") setHighlighted(highlighted === 0 ? allItems.length - 1 : highlighted - 1);
      if (e.key === "ArrowDown") setHighlighted(highlighted === allItems.length - 1 ? 0 : highlighted + 1);
      if (e.key === "Enter") allItems[highlighted].callback();
   };
   const handleSearch = (str: string) => {
      setSearch(str);
      setHighlighted(0);
   };
   return (
      <article className={`${styles.contentC}`}>
         <MainInput
            disableCollapse
            value={search}
            handleValue={handleSearch}
            otherProps={{ autoFocus: true, onKeyDown: handleKeydown, placeholder: `"⌘ + /" Or "Ctrl + /"` }}
         />
         <div className={`${styles.scrollC}`}>
            <ul ref={refContainer}>
               {filteredGroups.map((g, gi) => (
                  <li key={gi}>
                     <div className={`cardHeader-s`}>{g.groupName}</div>
                     <ul>
                        {g.items.map((i, ii) => (
                           <Item
                              item={i}
                              key={ii}
                              onHover={() => setHighlighted(allItemsIndex({ gi, ii }))}
                              highlighted={highlighted === allItemsIndex({ gi, ii })}
                           />
                        ))}
                     </ul>
                  </li>
               ))}
            </ul>
         </div>
      </article>
   );
};

export default Superhuman;
type TSuperhumanProps = {
   onClose: () => void;
};
type TGroups = { groupName: string; items: TItem[] };
type TItem = { label: string; callback: () => void };
