import { useSearchParams } from "react-router-dom";
import { TUwData } from "../../../types/hsObjects";
import styles from "./UnderwritingBulk.module.scss";
import useQVersion, { TversionField } from "../../../hooks/querries/versions/useQVersion";
import { useUwContext } from "../Underwritings";
import useResolveUw from "../../../hooks/formulas/useResolveUw";
import UwField from "../../../components/children/underwritings/uwGroup/uwField/UwField";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import Table from "../../../components/common/table/Table";
import {  useState } from "react";
import MainInput from "../../../components/common/mainInput/MainInput";
import MainButton from "../../../components/common/mainButton/MainButton";
import parseFieldName from "../../../utils/fields/parseFieldName";


const UnderwritingBulk = () => {
   const [params] = useSearchParams();
   const ver = params.get("ver") || "";

   const { jsonFile } = useUwContext();

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [], bulk = [], groups = [] } = qVerData?.config || {};

   const commonFields = bulk
      .map(({ children }) => children.map((fieldId) => fields.find((field) => field.id === fieldId)))
      .flat()
      .filter(Boolean) as TversionField[];
   const defaultBulkVals = commonFields.reduce(
      (prev, { column = "", fallback }) => ({ ...prev, [column]: fallback }),
      {}
   );

   const allPropFields = groups
      .map(({ children }) => children.map((fieldId) => fields.find((field) => field.id === fieldId)))
      .flat()
      .filter(Boolean) as TversionField[];

   const {
      options: uwsOptions,
      output: uwsOutput,
      setOutput: uwsSetOutput,
      calculations,
      setCalculations,
   } = useResolveUw({
      uws: jsonFile.map((_) => ({ ...defaultBulkVals, $childUnits: [{ ...defaultBulkVals }] })),
      // uws: [],
      ver,
   });
   const [shownFields, setShownFields] = useState<string[]>([]);
   const [searchFields, setSearchFields] = useState("");
   const [showFieldsConfig, setShowFieldsConfig] = useState(false);
   const handleShownFields = (name: string) =>
      shownFields.includes(name)
         ? setShownFields(shownFields.filter((str) => str !== name))
         : setShownFields([...shownFields, name]);

   const handleCommonUwData = (newCommonUwData: TUwData) => {
      const commonData = commonFields.reduce(
         (prev, { column = "" }) => ({ ...prev, [column]: newCommonUwData[column] }),
         {}
      );
      const newUws = jsonFile.map((obj) => ({
         ...defaultBulkVals,
         ...commonData,
         property_address_one_line_: `${obj.Address}, ${obj.City}, ${obj.State} ${obj.Zip}, USA`,
         zip_code: obj.Zip,
         $childUnits: [{ ...defaultBulkVals, ...commonData, bedrooms: obj.Bedrooms }],
      }));
      uwsSetOutput(newUws);
   };
   const reloadUws = () => {
      const newUws = jsonFile.map((obj) => ({
         ...defaultBulkVals,
         property_address_one_line_: `${obj.Address}, ${obj.City}, ${obj.State} ${obj.Zip}, USA`,
         zip_code: obj.Zip,
         $childUnits: [{ ...defaultBulkVals, bedrooms: obj.Bedrooms }],
      }));
      setCalculations(0);
      uwsSetOutput(newUws);
   };

   if (qVerFetching) return <BodyFiller loading />;
   return (
      <section className={`${styles.contentC}`}>
         <article className={`${styles.bulkC}`}>
            <div className={`${styles.title} header-s`}>
               <span onClick={() => console.log({ uwsOutput })}>Base Common Fields</span>
               <MainButton title="Restart" onClick={reloadUws} />
            </div>
            <div className={`${styles.bulkGroups}`}>
               {bulk.map(({ children }, key) => (
                  <div key={key} className={`${styles.bulkGroup}`}>
                     {children.map((id, key) => (
                        <UwField
                           key={key}
                           uw={uwsOutput[0]}
                           setUw={handleCommonUwData}
                           fieldId={id}
                           ver={ver}
                           uwOptions={uwsOptions[0]}
                        />
                     ))}
                  </div>
               ))}
            </div>
         </article>
         <article className={`${styles.fieldsC}`}>
            <div className={`${styles.title} header-s`}>
               <span>Fields To Show</span>
               <MainInput value={searchFields} handleValue={setSearchFields} title="Search Fields" />
               <MainButton
                  title={showFieldsConfig ? "Hide" : "Show"}
                  onClick={() => setShowFieldsConfig(!showFieldsConfig)}
                  type="bland"
               />
               <MainButton title="Clear" type="bland" onClick={() => setShownFields([])} />
            </div>
            <div className={`${showFieldsConfig ? styles.fields : "hidden"} `}>
               {allPropFields
                  .filter(({ name }) => name.toLowerCase().includes(searchFields.toLowerCase()))
                  .map(({ name, id }, key) => (
                     <MainInput
                        key={key}
                        type="checkbox"
                        title={name}
                        value={shownFields.includes(id)}
                        handleValue={() => handleShownFields(id)}
                     />
                  ))}
            </div>
         </article>
         <article className={`${styles.tableC}`}>
            <div className={`${styles.title} header-s`}>Results ({calculations} Calculations)</div>
            <Table
               type="none"
               columnHeaders={[
                  "#",
                  "Address",
                  "Bedrooms",
                  "Cap Rate",
                  ...shownFields.map((id) => fields.find((field) => field.id === id)?.name || "No Name"),
               ]}
               data={uwsOutput.map((obj, key) => [
                  key + 1,
                  obj.property_address_one_line_,
                  obj.bedrooms,
                  new Intl.NumberFormat("en-US", {
                     style: "percent",
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2,
                  }).format(obj.cap_rate),
                  ...shownFields
                     .map((id) => {
                        const { name, column } = fields.find((field) => field.id === id) || {};
                        return column || parseFieldName(name);
                     })
                     .map((str) => (str != null ? obj[str] : "key?")),
               ])}
            />
         </article>
      </section>
   );
};

export default UnderwritingBulk;
