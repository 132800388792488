import { Node } from "excel-formula-ast";
import styles from "./FieldFormulaComponent.module.scss";
import { useVersionContext } from "../../../../../../../../pages/Versions/Versions";
import useQColumns from "../../../../../../../../hooks/querries/versions/useQColumns";
import FieldFormulaDrop from "../fieldFormulaNodeDrop/FieldFormulaDrop";
import { IformulaItemDnD, TformulaNode } from "../../../../../../../../types/DnD/formulaDnD";
import FieldFormulaDropNode from "../fieldFormulaNodeDrop/fieldFormulaDropNode/FieldFormulaDropNode";
import s3afBucketURI from "../../../../../../../../utils/s3/s3afBucketURI";
import MainInput from "../../../../../../../common/mainInput/MainInput";
const FieldFormulaComponent = ({ node, path = "", handleDrop }: IFieldFormulaComponentProps) => {
   const { version, setVersion } = useVersionContext();
   const { fields = [] } = version;

   const { data: columnsData } = useQColumns();
   const { columns = [] } = columnsData || {};
   const debug = () => {
      console.log({ node, path });
   };
   const handleDel = (addon: string = "", type: TformulaNode = "NodeDel") =>
      handleDrop({ item: { id: "", type }, path: path + addon });
   if (!node)
      return (
         <FieldFormulaDrop accept={"Node"} handleDrop={(item) => handleDrop({ item, path })} classname="green">
            <FieldFormulaDropNode />
         </FieldFormulaDrop>
      );
   const { type } = node;
   if (type === "cell") {
      const { key } = node;
      const cellName = key.startsWith("@")
         ? fields.find(({ id }) => id === key.replace("@", ""))?.name || "Not Found"
         : key;
      if (cellName === "PlaceHolder")
         return (
            <FieldFormulaDrop accept={"Node"} handleDrop={(item) => handleDrop({ item, path })}>
               <FieldFormulaDropNode />
            </FieldFormulaDrop>
         );

      return (
         <FieldFormulaDrop accept="Node" handleDrop={(item) => handleDrop({ item, path })}>
            <div className={`${styles.cellC}`}>
               <div className={`${styles.cell}`}>
                  <div>Var</div>
                  <div>{cellName}</div>
               </div>
               <img
                  className={`${styles.del}`}
                  src={s3afBucketURI("/icons/Close_round.svg")}
                  alt=""
                  onClick={() => handleDel()}
               />
            </div>
         </FieldFormulaDrop>
      );
   }
   if (type === "text" || type === "number") {
      const { value } = node;
      const handleVal = (str: string) =>
         handleDrop({ item: { id: str, type: type === "text" ? "InputText" : "InputNumb" }, path });
      return (
         <FieldFormulaDrop accept={"Node"} handleDrop={(item) => handleDrop({ item, path })}>
            <div className={`${styles.valueC}`}>
               <div className={`${styles.value}`}>
                  <div>{type}</div>
                  <MainInput value={value.toString()} handleValue={handleVal} useInternal />
               </div>
               <img
                  className={`${styles.del}`}
                  src={s3afBucketURI("/icons/Close_round.svg")}
                  alt=""
                  onClick={() => handleDel()}
               />
            </div>
         </FieldFormulaDrop>
      );
   }

   if (type === "binary-expression") {
      const { left, operator, right } = node;
      return (
         <div className={`${styles.binary}`}>
            <FieldFormulaComponent node={left} path={`${path}.left`} handleDrop={handleDrop} />
            <FieldFormulaDrop
               accept="Operator"
               handleDrop={(item) => handleDrop({ item: { ...item, type: "OpsOverwrite" }, path })}
               classname="orange"
            >
               <div className={`${styles.operatorC}`}>
                  <div className={`${styles.operator}`}>{operator}</div>
                  <img
                     className={`${styles.del}`}
                     src={s3afBucketURI("/icons/Close_round.svg")}
                     alt=""
                     onClick={() => handleDel()}
                  />
               </div>
            </FieldFormulaDrop>
            <FieldFormulaComponent node={right} path={`${path}.right`} handleDrop={handleDrop} />
         </div>
      );
   }
   if (type === "function") {
      const { name, arguments: args } = node;
      const currentPath = `${path}.arguments`;
      const targetDrop = `${path}.arguments.${args.length}`;
      // fxTargetRef.current = targetDrop;
      return (
         <div className={`${styles.function}`}>
            <FieldFormulaDrop accept="Node" handleDrop={(item) => handleDrop({ item, path })}>
               <div className={`${styles.nameC}`}>
                  <div className={`${styles.name}`} onClick={debug}>
                     <div>Fx</div>
                     <div>{name}</div>
                  </div>
                  <img
                     className={`${styles.del}`}
                     src={s3afBucketURI("/icons/Close_round.svg")}
                     alt=""
                     onClick={() => handleDel()}
                  />
               </div>
            </FieldFormulaDrop>
            <ul className={`${styles.args} `}>
               {args.map((node, key) => (
                  <li key={key} className={`${styles.arg}`}>
                     <div>
                        <img
                           className={`${styles.del}`}
                           src={s3afBucketURI("/icons/Close_round.svg")}
                           alt=""
                           onClick={() => handleDel(`.arguments.${key}`, "ArgDel")}
                        />
                        <div>
                           {key + 1}:
                        </div>
                     </div>
                     <FieldFormulaComponent
                        key={key}
                        node={node}
                        path={`${currentPath}.${key}`}
                        handleDrop={handleDrop}
                     />
                     <FieldFormulaDrop
                        accept="Operator"
                        handleDrop={(item) => handleDrop({ item, path: `${currentPath}.${key}` })}
                        classname="green"
                     >
                        <FieldFormulaDropNode title="Ops" />
                     </FieldFormulaDrop>
                  </li>
               ))}
               <li className={`${styles.arg}`}>
                  <div>{args.length + 1}:</div>
                  <FieldFormulaDrop
                     accept={"Node"}
                     handleDrop={(item) => handleDrop({ item, path: `${targetDrop}` })}
                     key={targetDrop}
                     classname="green"
                  >
                     <FieldFormulaDropNode />
                  </FieldFormulaDrop>
               </li>
            </ul>
         </div>
      );
   }
   console.log({ node });
   return <div className={`${styles.contentC}`}>{type}</div>;
};

export default FieldFormulaComponent;
interface IFieldFormulaComponentProps {
   node?: Node;
   path?: string;
   handleDrop: (payload: { item: IformulaItemDnD; path: string }) => void;
}
