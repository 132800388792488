import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import {
   TPg_Crm_Properties_Prefix_Helper,
   TPg_Crm_Properties_Schema,
   TPg_Crm_Renovations_Prefix_Helper,
   TPg_Crm_Renovations_Schema,
} from "@simplyhomes/utils";
import { useDebounce } from "@uidotdev/usehooks";

export const useQRenovations = ({ p }: TuseQRenovationsParams) => {
   const { aioAxios } = useAxios();
   const dbSearch = useDebounce(p?.search, 1000);
   return useQuery({
      queryKey: ["renovations", dbSearch, p?.sortBy, p?.sortDir],
      queryFn: () => aioAxios.get("/aio/renovations", { params: p }).then<TAxiosResp>(({ data }) => data),
   });
};

type TAxiosResp = {
   renovations: (TPg_Crm_Renovations_Prefix_Helper<
      Pick<TPg_Crm_Renovations_Schema, "hs_object_id" | "hs_pipeline_stage" | "address">
   > &
      TPg_Crm_Properties_Prefix_Helper<
         Pick<TPg_Crm_Properties_Schema, "hs_object_id" | "bedrooms" | "bathrooms" | "sqft">
      >)[];
   total: number;
};
type TuseQRenovationsParams = {
   p?: {
      search?: string;
      sortBy?: string;
      sortDir?: string;
   };
};
