import { Link, Outlet, useMatch } from "react-router-dom";
import TabButton from "../../components/common/tabButton/TabButton";
import styles from "./Settings.module.scss";
const Settings = () => {
   const setting = useMatch("/settings/:last")?.pathnameBase.split("/").at(-1);
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`header-m`}>Settings</h1>
         </div>
         <div className={`${styles.tabsC}`}>
            <Link to={"general"}>
               <TabButton title="General" isActive={setting === "general"} />
            </Link>
            <Link to={"account"}>
               <TabButton title="Account" isActive={setting === "account"} />
            </Link>
            <Link to={"subscriptions"}>
               <TabButton title="Subscriptions" isActive={setting === "subscriptions"} />
            </Link>
         </div>
         <div className={`${styles.bodyC}`}>
            <Outlet />
         </div>
      </section>
   );
};

export default Settings;
type TSettingsProps = {};
