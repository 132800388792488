import { SHrBodyFiller, SHrMainInput, TSHrMainInput_Option } from "@simplyhomes/react";
import { useAppContext } from "../../contexts/appContext/AppState";
import styles from "./AioMap.module.scss";
import { GoogleMap, OverlayViewF } from "@react-google-maps/api";
import { useQAioMap } from "../../hooks/querries/aio/map/useQAioMap";
import { useEffect, useMemo, useRef, useState } from "react";
import { useStateParams } from "../../hooks/react/useStateParams";

const defaultCoords = { lat: 39.9644666, lng: -83.4328782 };
const AioMap = () => {
   const { appState } = useAppContext();
   const { ggmap_isLoaded } = appState;

   const { getStateParams } = useStateParams();

   const [hlAddress, setHlAddress] = getStateParams("address");
   const [zoom, setZoom] = useState<number>(hlAddress ? 17 : 7);
   const mapRef = useRef<GoogleMap>(null);

   const qMap = useQAioMap({ options: { refetchOnMount: true } });
   const props = useMemo(
      () =>
         (qMap.data?.properties || [])
            .filter((prop) => !!prop.coords && !prop.coords.includes("undefined"))
            .map((prop) => {
               const coords = prop.coords.split(",");
               return { lat: Number(coords[0]), lng: Number(coords[1]), ...prop };
            }),
      [qMap.data?.properties]
   );
   const searchOptions: TSHrMainInput_Option[] = useMemo(
      () => props.map((p) => ({ label: p.address, value: p.address })),
      [props]
   );
   const hlProp = props.find((prop) => prop.address === hlAddress);
   const [center, setCenter] = useState(hlProp ? { lat: hlProp.lat, lng: hlProp.lng } : defaultCoords);
   const handleZoom = () => {
      const zoom = mapRef.current?.state.map?.getZoom();
      if (zoom) setZoom(zoom);
   };


   useEffect(() => {
      if (hlAddress) {
         const hlProp = props.find((prop) => prop.address === hlAddress);
         if (hlProp) {
            setCenter({ lat: hlProp.lat, lng: hlProp.lng });
            const curZoom = mapRef.current?.state.map?.getZoom();
            if (curZoom && curZoom < 17) setZoom(17);
         }
      }
   }, [hlAddress, props]);

   const loadingMsg = qMap.isLoading ? "Loading Properties" : !ggmap_isLoaded ? "Loading Google Maps API" : null;
   if (loadingMsg) return <SHrBodyFiller message={loadingMsg} />;
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.mapC}`}>
            <SHrMainInput
               className={`${styles.searchInput}`}
               value={""}
               onChange={setHlAddress}
               options={searchOptions}
               title="Search Property"
               type="search"
            />
            <GoogleMap
               mapContainerClassName={styles.map}
               center={center}
               zoom={zoom}
               onZoomChanged={handleZoom}
               ref={mapRef}
               options={{ disableDoubleClickZoom: true }}
            >
               {props.map(({ lat, lng, leadStatus, address }, key) => (
                  <OverlayViewF mapPaneName="overlayMouseTarget" key={key} position={{ lat, lng }}>
                     <div
                        className={`${styles.marker} 
                        ${
                           styles[
                              leadStatus === "Rejected" ? "rejected" : leadStatus === "Closed" ? "owned" : "contracted"
                           ]
                        } ${hlAddress === address && styles.highlighted}`}
                        onClick={() => setHlAddress(address)}
                     >
                        {zoom >= 15 && <b className={`${styles.markerAddress}`}>{address}</b>}
                     </div>
                  </OverlayViewF>
               ))}
            </GoogleMap>
         </div>
      </section>
   );
};

export default AioMap;
