import { useParams, useSearchParams } from "react-router-dom";
import styles from "./Underwriting.module.scss";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import { useUwContext } from "../Underwritings";
import useQUw from "../../../hooks/querries/underwritings/useQUw";
import jsTimeSince from "../../../utils/JS/jsTimeSince";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";
import MainButton from "../../../components/common/mainButton/MainButton";
import useAxios from "../../../hooks/axios/useAxios";
import { useEffect, useState } from "react";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import useQVersion from "../../../hooks/querries/versions/useQVersion";
import MainInput from "../../../components/common/mainInput/MainInput";
import UnderwritingGroup from "../../../components/children/underwritings/underwritingGroup/UnderwritingGroup";
import SmallSpinner from "../../../components/common/smallSpinner/SmallSpinner";
const Underwriting = () => {
   const { aioAxios } = useAxios();
   const { uwsid } = useParams();
   const [params] = useSearchParams();
   const ver = params.get("ver") || "";

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { data: qUwData, isFetching: qUwFetching, refetch: qUwRefetch } = useQUw({ uwsids: [uwsid as string] });
   const uwOG = qUwData?.underwritings[0];
   const { property_object_id, hs_object_id } = uwOG || {};

   const { uw, setUw, uwCache } = useUwContext();
   const { $childUnits = [] } = uw;
   const { groups = [], fields = [], units = [] } = qVerData?.config || {};
   const allPropGroups = groups.map(({ title }) => title);
   const allUnitGroups = units.map(({ title }) => title);
   const allUnits = $childUnits.map((_, i) => i + 1);
   const importantPropGroups = groups.filter(({ defaultShown }) => defaultShown).map(({ title }) => title);
   const importantUnitGroups = units.filter(({ defaultShown }) => defaultShown).map(({ title }) => title);
   const [submitting, setSubmitting] = useState(false);
   const [shownPropGroups, setShownPropGroups] = useState<string[]>(importantPropGroups);
   const [shownUnitGroups, setShownUnitGroups] = useState(importantUnitGroups);
   const [shownChildren, setShownChildren] = useState<number[]>(allUnits);
   const [now, setNow] = useState(Date.now());

   useEffect(() => {
      if (!uwsid || !ver) return;
      setShownPropGroups(importantPropGroups);
      setShownUnitGroups(importantUnitGroups);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [ver, uwsid]);

   if (!ver) return <BodyFiller fillerMsg="Select a Version on top right to start!" />;
   if (qUwFetching || qVerFetching) return <BodyFiller loading />;
   const snapDate = uwOG?.aio_original_date
      ? `${new Date(Number(uwOG?.aio_original_date || ""))?.toISOString().split("T")[0]}`
      : "-";
   const uwDate = uwOG?.aio_uw_date ? `${new Date(Number(uwOG?.aio_uw_date || ""))?.toISOString().split("T")[0]}` : "-";

   const handleAddUnit = () => {
      const dupData: TUnknownObject =
         $childUnits.length > 0
            ? $childUnits[0]
            : units
                 .map(({ children }) => children)
                 .flat()
                 .map((id) => fields.find((field) => field.id === id)?.column)
                 .reduce((prev, col = "") => ({ ...prev, [col]: uw[col] }), {});
      const newChildUnits = [dupData, ...$childUnits];
      setUw((prev) => ({ ...prev, $childUnits: newChildUnits }));
      setShownChildren([...new Set([...shownChildren, $childUnits.length + 1])]);
   };
   const handleRemoveUnit = (index: number) =>
      setUw((prev) => ({ ...prev, $childUnits: $childUnits.filter((_, cIndex) => index !== cIndex) }));

   const handleSave = async () => {
      setSubmitting(true);
      try {
         await aioAxios.patch(`/aio/underwritings/${uwsid}`, { aio_uw_data: uw, version: ver });
         qUwRefetch();
      } catch (error) {
         console.log({ error });
      }
      setNow(Date.now());
      setSubmitting(false);
   };
   if (submitting) return <BodyFiller loading />;
   const isQuerying = Object.keys(uwCache).some((key) => uwCache[key] === "pending");
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.header}`}>
            <div className={`${styles.infoC}`}>
               <div className={`${styles.infoRow}`}>
                  <div>
                     <p>Property</p>
                     <p>
                        <a
                           href={`https://app.hubspot.com/contacts/7832526/record/2-11273381/${property_object_id}`}
                           target="_blank"
                           rel="noreferrer"
                        >
                           <span>{property_object_id || "-"}</span>
                           <img src={s3afBucketURI("/icons/external_link.svg")} alt="" />
                        </a>
                     </p>
                  </div>
                  <div>
                     <p>Created By</p>
                     <p>{uwOG?.aio_original_created_by || "-"}</p>
                  </div>
                  <div>
                     <p>Snapshot Version</p>
                     <p>{uwOG?.aio_original_version || "-"}</p>
                  </div>
                  <div>
                     <p>Snapshot Date</p>
                     <p>{snapDate}</p>
                     <p>({jsTimeSince(Number(uwOG?.aio_original_date), now)})</p>
                  </div>
               </div>
               <div className={`${styles.infoRow}`}>
                  <div>
                     <p>Underwriting</p>
                     <p>
                        <a
                           href={`https://app.hubspot.com/contacts/7832526/record/2-15822979/${hs_object_id}`}
                           target="_blank"
                           rel="noreferrer"
                        >
                           <span>{hs_object_id || "-"}</span>
                           <img src={s3afBucketURI("/icons/external_link.svg")} alt="" />
                        </a>
                     </p>
                  </div>
                  <div>
                     <p>Saved By</p>
                     <p>{uwOG?.aio_uw_saved_by || "-"}</p>
                  </div>
                  <div>
                     <p>Underwriting Version</p>
                     <p>{uwOG?.aio_uw_version || "-"}</p>
                  </div>
                  <div>
                     <p>Underwriting Date</p>
                     <p>{uwDate}</p>
                     <p>({jsTimeSince(Number(uwOG?.aio_uw_date), now)})</p>
                  </div>
               </div>
               <div className={`${styles.infoRow}`}>
                  <div>
                     <p>Name</p>
                     <p>{uwOG?.name || "-"}</p>
                  </div>
                  <div>
                     <p>Address</p>
                     <div>{uwOG?.property_address_one_line_ || "-"}</div>
                  </div>
               </div>
            </div>
            <div className={`${styles.controlsC}`}>
               <MainButton title="Refresh" type="bland" onClick={qUwRefetch} />
               <MainButton
                  title="Save!"
                  type="warn"
                  confirmMsg="Overwrite Underwriting With Calculator Data?"
                  onClick={handleSave}
               />
            </div>
         </div>
         <div className={`${styles.pd}`}>
            <div className={`header-m ${styles.pdHeader}`}>
               <span onClick={() => console.log({ uw })}>Property Details</span>
               {isQuerying && <SmallSpinner style={{ width: "2rem" }} />}
            </div>
            <div className={`${styles.Groups}`}>
               <div className={`${styles.Group}`}>
                  <div className={`${styles.GroupTitle}`}>
                     <div>Property Groups</div>
                     <MainButton type="bland" title="Add All" onClick={() => setShownPropGroups(allPropGroups)} />
                     <MainButton type="bland" title="Hide All" onClick={() => setShownPropGroups([])} />
                  </div>
                  <div className={`${styles.titlesC}`}>
                     {allPropGroups.map((title, key) => (
                        <MainInput
                           key={key}
                           type="checkbox"
                           title={title}
                           value={shownPropGroups.includes(title)}
                           handleValue={(b) =>
                              setShownPropGroups(
                                 b
                                    ? [...shownPropGroups, title]
                                    : shownPropGroups.filter((groupTitle) => groupTitle !== title)
                              )
                           }
                        />
                     ))}
                  </div>
               </div>
               {groups.map((group, key) => (
                  <div className={`${!shownPropGroups.includes(group.title) ? "hidden" : styles.Group}`} key={key}>
                     <div className={`${styles.GroupTitle} `}>
                        <span>{group.title}</span>
                        <MainButton
                           title={"Hide"}
                           type="bland"
                           onClick={() =>
                              setShownPropGroups(shownPropGroups.filter((groupTitle) => groupTitle !== group.title))
                           }
                        />
                     </div>
                     <UnderwritingGroup group={group} />
                  </div>
               ))}
            </div>
         </div>
         <div className={`${styles.ud}`}>
            <div className={`header-m ${styles.udHeader}`}>
               <span>{$childUnits.length} Unit(s) Details</span>
               <MainButton title="Add Unit" confirmMsg="Add one new Unit?" onClick={handleAddUnit} />
               {isQuerying && <SmallSpinner style={{ width: "2rem" }} />}
            </div>
            {$childUnits.length > 0 && (
               <div className={`${styles.Groups}`}>
                  <div className={`${styles.Group}`}>
                     <div className={`${styles.GroupTitle}`}>
                        <span>Unit Groups</span>
                        <MainButton title="Add All" type="bland" onClick={() => setShownUnitGroups(allUnitGroups)} />
                        <MainButton title="Hide All" type="bland" onClick={() => setShownUnitGroups([])} />
                     </div>
                     <div className={`${styles.titlesC}`}>
                        {allUnitGroups.map((title, key) => (
                           <MainInput
                              key={key}
                              type="checkbox"
                              title={title}
                              value={shownUnitGroups.includes(title)}
                              handleValue={(b) =>
                                 setShownUnitGroups(
                                    b
                                       ? [...shownUnitGroups, title]
                                       : shownUnitGroups.filter((groupTitle) => groupTitle !== title)
                                 )
                              }
                           />
                        ))}
                     </div>
                  </div>
                  <div className={`${styles.Group}`}>
                     <div className={`${styles.GroupTitle}`}>
                        <span>Units</span>
                        <MainButton title="Add All" type="bland" onClick={() => setShownChildren(allUnits)} />
                        <MainButton title="Hide All" type="bland" onClick={() => setShownChildren([])} />
                     </div>
                     <div className={`${styles.titlesC}`}>
                        {allUnits.map((cIndex, key) => (
                           <MainInput
                              key={key}
                              title={`Unit #${cIndex}`}
                              type="checkbox"
                              value={shownChildren.includes(cIndex)}
                              handleValue={(b) =>
                                 setShownChildren(
                                    b ? [...shownChildren, cIndex] : shownChildren.filter((num) => num !== cIndex)
                                 )
                              }
                           />
                        ))}
                     </div>
                  </div>
               </div>
            )}
            <div className={`${styles.udChildren} `}>
               {$childUnits.map((_, childIndex) => (
                  <div
                     key={childIndex}
                     className={`${!shownChildren.includes(childIndex + 1) ? "hidden" : styles.udChild}`}
                  >
                     <div className={`${styles.udChildTitle}`}>
                        <span>Unit #{childIndex + 1}</span>
                        <MainButton
                           type="bland"
                           title={"Hide"}
                           onClick={() => setShownChildren(shownChildren.filter((cIndex) => cIndex !== childIndex + 1))}
                        />
                        <MainButton
                           type="warn"
                           title="Delete"
                           confirmMsg="Remove Unit?"
                           onClick={() => handleRemoveUnit(childIndex)}
                        />
                     </div>
                     <div className={`${styles.Groups} `}>
                        {units.map((group, key) => (
                           <div
                              className={`${!shownUnitGroups.includes(group.title) ? "hidden" : styles.Group} `}
                              key={key}
                           >
                              <div className={`${styles.GroupTitle}`}>
                                 <span>{group.title}</span>
                                 <MainButton
                                    title={!shownUnitGroups.includes(group.title) ? "Show" : "Hide"}
                                    type="bland"
                                    onClick={() =>
                                       !shownUnitGroups.includes(group.title)
                                          ? setShownUnitGroups([...shownUnitGroups, group.title])
                                          : setShownUnitGroups(
                                               shownUnitGroups.filter((groupTitle) => groupTitle !== group.title)
                                            )
                                    }
                                 />
                              </div>
                              <div>
                                 <UnderwritingGroup group={group} childIndex={childIndex} />
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </div>
   );
};

export default Underwriting;
