import { SHrMainButton, SHrMainInput } from "@simplyhomes/react";
import { useAioVersionContext } from "../AioVersion";
import styles from "./AioVersionTabViews.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useState } from "react";
import {
   SHu_const_AIO_Version2_Config_View_Group_Type,
   TAIO_Version2_Config_Field,
   TAIO_Version2_Config_View,
} from "@simplyhomes/utils";
import TabButton from "../../../common/tabButton/TabButton";
import { utils_AioFields_GenerateTags } from "../../../../utils/AioFields/utils";

const AioVersionTabViews = () => {
   const { aioVersionState: State, aioVersionDispatch: Dispatch } = useAioVersionContext();
   const { views = [] } = State.config || {};
   const { views_activeViewId: views_activeView = 0 } = State;
   const view: TAIO_Version2_Config_View | undefined = views.find((v) => v.id === views_activeView);

   const [viewingProp, setViewingProp] = useState(true);

   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.viewsC}`}>
            <DragDropContext onDragEnd={(payload) => Dispatch({ type: "config_views_moveView", payload })}>
               <Droppable droppableId="views" direction="horizontal">
                  {(dropCtx) => (
                     <div className={`${styles.viewTabsC}`} {...dropCtx.droppableProps} ref={dropCtx.innerRef}>
                        <SHrMainButton
                           title="Add View"
                           type="bland"
                           onClick={() => Dispatch({ type: "config_views_addView" })}
                           style={{ flexShrink: "0" }}
                        />
                        <div className={`${styles.viewTabs}`}>
                           {views.map((view, i) => (
                              <Draggable key={i} draggableId={`view-${i}`} index={i}>
                                 {(dragCtx) => (
                                    <div
                                       {...dragCtx.dragHandleProps}
                                       {...dragCtx.draggableProps}
                                       ref={dragCtx.innerRef}
                                    >
                                       <TabButton
                                          key={i}
                                          isActive={views_activeView === view.id}
                                          title={view.name}
                                          onClick={() => Dispatch({ overwrite: { views_activeViewId: view.id } })}
                                       />
                                    </div>
                                 )}
                              </Draggable>
                           ))}
                           {dropCtx.placeholder}
                        </div>
                     </div>
                  )}
               </Droppable>
            </DragDropContext>
            {view && (
               <div className={`${styles.viewConfig}`}>
                  <div className={`${styles.viewControlls}`}>
                     <SHrMainInput
                        title="View Name"
                        type="text"
                        className={`${styles.wFit}`}
                        value={view.name}
                        onChange={(name) => Dispatch({ type: "config_views_renameView", payload: { name } })}
                     />
                     <SHrMainButton
                        title="Delete"
                        msg={`Delete view ${view.name}?`}
                        type="bland"
                        onClick={() => Dispatch({ type: "config_views_removeView" })}
                     />
                  </div>
               </div>
            )}
            <div className={`${styles.view} `}>
               {view && (
                  <>
                     <div className={`${styles.viewTypeTabs}`}>
                        <TabButton
                           isActive={viewingProp}
                           title="Property Groups"
                           onClick={() => setViewingProp(true)}
                        />
                        <TabButton isActive={!viewingProp} title="Unit Groups" onClick={() => setViewingProp(false)} />
                     </div>
                     {viewingProp ? <Groups view={view} level="prop" /> : <Groups view={view} level="unit" />}
                  </>
               )}
            </div>
         </div>
      </article>
   );
};

export default AioVersionTabViews;
type TGroupsProps = {
   level: "prop" | "unit";
   view: TAIO_Version2_Config_View;
};
const Groups = ({ level, view }: TGroupsProps) => {
   const { aioVersionState: State, aioVersionDispatch: Dispatch } = useAioVersionContext();
   const groups = level === "prop" ? view.propGroups : view.unitGroups;
   const activeIdKey = level === "prop" ? "views_activePGroup" : "views_activeUGroup";
   const activeId = State[activeIdKey];
   const group = groups.find((g) => g.id === activeId);

   const fieldKey = level === "prop" ? "propFields" : "unitFields";
   const fields = State.config?.[fieldKey] || [];
   const fieldMap: { [x: string]: TAIO_Version2_Config_Field | undefined } = fields.reduce(
      (acc, f) => ({ ...acc, [f.id]: f }),
      {} as { [key: string]: any }
   );
   const fieldOptions = fields.map((f) => ({ label: f.label, value: f.id, tags: utils_AioFields_GenerateTags(f) }));

   return (
      <div className={`${styles.groupsC}`}>
         <DragDropContext
            onDragEnd={(result) => Dispatch({ type: "config_views_moveGroup", payload: { result, level } })}
         >
            <Droppable droppableId="groups" direction="horizontal">
               {(dropCtx) => (
                  <div className={`${styles.groupTabsC}`} {...dropCtx.droppableProps} ref={dropCtx.innerRef}>
                     <SHrMainButton
                        title="Add Group"
                        type="bland"
                        style={{ flexShrink: "0" }}
                        onClick={() => Dispatch({ type: "config_views_addGroup", payload: { level } })}
                     />
                     <div className={`${styles.groupTabs}`}>
                        {groups.map((g, i) => (
                           <Draggable draggableId={`group-${i}`} key={i} index={i}>
                              {(dragCtx) => (
                                 <div {...dragCtx.dragHandleProps} {...dragCtx.draggableProps} ref={dragCtx.innerRef}>
                                    <TabButton
                                       title={g.name}
                                       isActive={g.id === activeId}
                                       onClick={() => Dispatch({ overwrite: { [activeIdKey]: g.id } })}
                                    />
                                 </div>
                              )}
                           </Draggable>
                        ))}
                        {dropCtx.placeholder}
                     </div>
                  </div>
               )}
            </Droppable>
         </DragDropContext>
         {group && (
            <div className={`${styles.groupConfig}`}>
               <div className={`${styles.groupControlls}`}>
                  <SHrMainInput
                     title="Group Name"
                     type="text"
                     value={group.name}
                     onChange={(name) => Dispatch({ type: "config_views_renameGroup", payload: { name, level } })}
                     className={`${styles.wFit}`}
                  />
                  <SHrMainInput
                     type="dropdown"
                     title="Group Type"
                     value={group.type}
                     onChange={(type: string) =>
                        Dispatch({ type: "config_views_changeTypeGroup", payload: { level, type } })
                     }
                     options={[...SHu_const_AIO_Version2_Config_View_Group_Type]}
                     className={`${styles.wFit}`}
                  />
                  <SHrMainButton
                     title="Delete"
                     msg={`Delete group ${group.name}?`}
                     type="bland"
                     onClick={() => Dispatch({ type: "config_views_removeGroup", payload: { level } })}
                  />
               </div>
               {group.type === "1" && (
                  <DragDropContext
                     onDragEnd={(result) => Dispatch({ type: "config_views_f1_moveField", payload: { level, result } })}
                  >
                     <Droppable droppableId="field1" direction="vertical">
                        {(dropCtx) => (
                           <div className={`${styles.groupType1}`} {...dropCtx.droppableProps} ref={dropCtx.innerRef}>
                              <SHrMainButton
                                 title="Add Field"
                                 type="bland"
                                 onClick={() => Dispatch({ type: "config_views_f1_addField", payload: { level } })}
                              />
                              {group.fieldIds.map((fid, i) => (
                                 <Draggable draggableId={`field1-${i}`} key={i} index={i}>
                                    {(dragCtx) => (
                                       <div
                                       className={`${styles.groupType1Field}`}
                                          {...dragCtx.dragHandleProps}
                                          {...dragCtx.draggableProps}
                                          ref={dragCtx.innerRef}
                                       >
                                          <SHrMainInput
                                             type="search"
                                             key={i}
                                             title={fieldMap[fid]?.label || "Select a Field"}
                                             value={fid}
                                             containerProps={{
                                                style: { width: "100%", flexShrink: "0", height: "fit-content" },
                                             }}
                                             onChange={(fieldId) =>
                                                Dispatch({
                                                   type: "config_views_f1_renameField",
                                                   payload: { level, fieldId, index: i },
                                                })
                                             }
                                             buttons={[
                                                {
                                                   label: "Remove",
                                                   cb: () =>
                                                      Dispatch({
                                                         type: "config_views_f1_removeField",
                                                         payload: { level, i },
                                                      }),
                                                },
                                             ]}
                                             options={fieldOptions}
                                             tags={utils_AioFields_GenerateTags(fieldMap[fid])}
                                          />
                                       </div>
                                    )}
                                 </Draggable>
                              ))}
                              {dropCtx.placeholder}
                           </div>
                        )}
                     </Droppable>
                  </DragDropContext>
               )}
            </div>
         )}
      </div>
   );
};
