import { useEffect, useState } from "react";
import useQMap from "../../../../hooks/querries/useQMap";
import AFInput from "../../../common/AFInput/AFInput";
import styles from "./MapSettingsPopup.module.scss";
import { TMapRejectReason, TMapStage } from "../../../../contexts/appContext/AppState";
import MainButton from "../../../common/mainButton/MainButton";
import useAxios from "../../../../hooks/axios/useAxios";
const MapSettingsPopup = ({ handleClose }: TMapSettingsPopupProps) => {
   const { aioAxios } = useAxios();

   const { data: qMapData, refetch: qMapRefetch } = useQMap();
   const { mapData } = qMapData || {};
   const { stages = [], rejectReasons = [] } = mapData || {};
   const [newStages, setNewStages] = useState<TMapStage[]>([]);
   const [newReasons, setNewReasons] = useState<TMapRejectReason[]>([]);
   const [submitting, setSubmitting] = useState(false);

   useEffect(() => setNewStages(stages), [stages]);
   useEffect(() => setNewReasons(rejectReasons), [rejectReasons]);
   const handleStageColor = (index: number, color: string) => {
      const newStage = { ...stages[index], color };
      setNewStages(stages.map((stage, sIndex) => (sIndex === index ? newStage : stage)));
   };
   const handleReasonColor = (index: number, color: string) => {
      const newReason = { ...rejectReasons[index], color };
      setNewReasons(rejectReasons.map((stage, sIndex) => (sIndex === index ? newReason : stage)));
   };
   const handleReset = () => {
      setNewStages(stages);
      setNewReasons(rejectReasons);
   };
   const handleSave = async () => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/map/colors`, { stages: newStages, reasons: newReasons })
         .catch((er) => console.log({ er }));
      await qMapRefetch();
      setSubmitting(false);
      handleClose();
   };
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.controls}`}>
            <MainButton
               title="Reset!"
               onClick={handleReset}
               enable={!submitting}
               confirmMsg="You would lose unsaved colors"
            />
            <MainButton
               title="Submit!"
               onClick={handleSave}
               enable={!submitting}
               type="warn"
               confirmMsg="Overwrite existing data?"
            />
         </div>
         <div>
            <b>Stages</b>
            <ul className={`${styles.stages}`}>
               {newStages.map(({ color, label, value }, key) => (
                  <AFInput
                     key={key}
                     type="color"
                     value={color}
                     title={label}
                     handleChange={(v: string) => handleStageColor(key, v)}
                     style={{ width: "8rem" }}
                  />
               ))}
            </ul>
         </div>
         <div>
            <b>Reject Reasons</b>
            <ul className={`${styles.stages}`}>
               {newReasons.map(({ color, label, value }, key) => (
                  <AFInput
                     key={key}
                     type="color"
                     value={color}
                     title={label}
                     handleChange={(v: string) => handleReasonColor(key, v)}
                     style={{ width: "8rem" }}
                  />
               ))}
            </ul>
         </div>
      </div>
   );
};

export default MapSettingsPopup;
type TMapSettingsPopupProps = {
   handleClose: () => void;
};
