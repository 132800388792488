import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
const useQDb = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: "database",
      queryFn: async () => await aioAxios.get(`/aio/database/`).then<Iresp>(({ data }) => data),
   });
};

export default useQDb;

interface Iresp {
   tables: IuseQDbDataTable[];
   importData: {
      table: string;
      import_from: string;
      import_cols: string[];
   }[];
}
export interface IuseQDbDataTable {
   table: string;
   pk: string;
}
