import { Outlet, useSearchParams } from "react-router-dom";
import AFInput from "../../components/common/AFInput/AFInput";
import SmallSpinner from "../../components/common/smallSpinner/SmallSpinner";
import useQDb from "../../hooks/querries/database/useQDb";
import styles from "./Database.module.scss";
import { useState } from "react";
import MainButton from "../../components/common/mainButton/MainButton";
import Popup from "../../components/common/popup/Popup";
import LedgerSnapManager from "../../components/children/database/ledgerSnapManager/LedgerSnapManager";
const Database = () => {
   const [params, setParams] = useSearchParams();
   const table = params.get("table") || "";

   const { data: qDbData, isFetching: qDbIsFetching } = useQDb();
   const { tables = [] } = qDbData || {};

   const [showSnapPopup, setShowSnapPopup] = useState(false);

   if (qDbIsFetching) return <SmallSpinner style={{ height: "20rem", margin: "auto", marginTop: "10rem" }} />;
   return (
      <div className={`${styles.contentC}`}>
         {showSnapPopup && (
            <Popup onClose={() => setShowSnapPopup(false)}>
               <LedgerSnapManager />
            </Popup>
         )}
         <div className={`${styles.controls}`}>
            <AFInput
               type="dropdown"
               title="Select a Table"
               options={tables.map(({ table }) => table)}
               value={table}
               style={{ width: "14rem" }}
               handleChange={(v) => setParams({ table: v })}
            />
            {table === "crm_ledger" && <MainButton title="Snapshots" onClick={() => setShowSnapPopup(true)} />}
         </div>
         <Outlet />
      </div>
   );
};

export default Database;
