import { useState } from "react";
import styles from "./Dev.module.scss";
import DevInput, { TReact_MainInput_Option } from "./devInput/DevInput";
import MainInput from "../../components/common/mainInput/MainInput";
const Dev = () => {
   const [value, setValue] = useState("");
   const [boolVal, setBoolVal] = useState(false);
   const [stringVal, setStringVal] = useState("");
   const options: TReact_MainInput_Option[] = [
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4", value: "4" },
      { label: "abc", value: "abc" },
      { label: "DPS", value: "dps" },
   ];
   return (
      <div className={`${styles.contentC}`}>
         <div style={{ width: "200px", margin: "auto", display: "flex", flexDirection: "column", gap: "1rem" }}>
            <DevInput value={value} onChange={setValue} type="text" title="TEXT" />
            <MainInput title="TEXT" value={value} options={[1, 2, 3, 4]} />
            <DevInput value={value} onChange={setValue} type="text" title="TEXT" />
            <DevInput type="dropdown" title="DROPDOWN" value={value} onChange={setValue} options={options} />
            <DevInput type ='checkbox'title="CHECKBOX" value={boolVal} onChange={setBoolVal}  />
            <DevInput type ='toggle'  title="TOGGLE" value={boolVal} onChange={setBoolVal} />
            <DevInput
               type="search"
               title="SEARCH"
               value={value}
               onChange={setValue}
               options={options}
               tooltip={"This is a tooltip \n with multiple lines"}
            />
         </div>
      </div>
   );
};

export default Dev;
