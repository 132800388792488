import { SHrMainButton } from "@simplyhomes/react";
import { useAioUwViewerContext } from "../AioUwViewer";
import styles from "./AioUwViewerTracer.module.scss";
import { useMemo } from "react";
import { TAIO_Version2_Config_Field } from "@simplyhomes/utils";
import AioUwViewerInput from "../AioUwViewerInput/AioUwViewerInput";
const AioUwViewerTracer = () => {
   const { uwViewerState: State, uwViewerDispatch: Dispatch } = useAioUwViewerContext();
   const { tracer, config } = State;
   const { propFields = [], unitFields = [] } = config || {};

   const stack = tracer[tracer.length - 1];
   const fields = stack.unitIndex == null ? propFields : unitFields;
   const fieldsMap: { [x: string]: TAIO_Version2_Config_Field | undefined } = useMemo(
      () => fields.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
      [fields]
   );
   const findField = (s: typeof stack) => fieldsMap[s.fid];
   const field = findField(stack);
   const stacks = tracer.map((s) => findField(s));

   const { fieldDeps, fxMasked } = useMemo(() => {
      const fx = field?.type === "formula" ? field.config.formula : "";
      const fxFidsDep: string[] = [...new Set(fx.match(/\[((?!.*\|.*).*?)\]/g)?.map((str) => str.replace(/\[|\]/g, "")))] || [];
      const fieldDeps = fxFidsDep.map((fid) => fieldsMap[fid]).filter(Boolean) as TAIO_Version2_Config_Field[];
      const fxMasked = fxFidsDep?.reduce(
         (acc: string, cur: string) => acc.replaceAll(cur, `${fieldsMap[cur]?.label || "#ERROR:NOTFOUND"}`),
         fx
      );
      return { fieldDeps, fxMasked };
   }, [field, fieldsMap]);
   const isOneLeft = tracer.length === 1;
   const topLeftProps = {
      type: (isOneLeft ? "close" : "back") as any,
      onClick: () => Dispatch({ type: isOneLeft ? "tracer_flush" : "tracer_pop" }),
   };

   if (!stack || !field) return null;
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <SHrMainButton {...topLeftProps} key={stack.fid} />
            <div className={`${styles.header}`}>
               <h1 className={`header-s`}>Tracer</h1>
               <ul>
                  {stacks.map((s, i) => (
                     <li
                        key={i}
                        className={`${s?.id === field?.id && styles.currStack}`}
                        onClick={() => Dispatch({ type: "tracer_goTo", payload: i })}
                     >
                        {i < tracer.length && "/"}
                        {s?.label}
                     </li>
                  ))}
               </ul>
            </div>
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.infoC} ${styles.cardC}`}>
               <h1 className={`cardHeader-s`}>Information</h1>
               <div className={`${styles.info}`}>
                  <div className={`${styles.infoL}`}>
                     <p>
                        <b>Label:</b> {field?.label}
                     </p>
                     <p>
                        <b>ID:</b> {field?.id}
                     </p>
                     <p>
                        <b>Type:</b> {field?.type}
                     </p>
                  </div>
                  <div className={`${styles.infoR}`}>
                     <AioUwViewerInput field={field} unitIndex={stack.unitIndex} />
                  </div>
               </div>
            </div>
            {fxMasked && (
               <div className={`${styles.fxC} ${styles.cardC}`}>
                  <h1 className={`cardHeader-s`}>Formula</h1>
                  <pre>
                     <code>{fxMasked}</code>
                  </pre>
               </div>
            )}
            {fieldDeps.length > 0 && 
            <div className={`${styles.depsC} ${styles.cardC}`}>
               <h1 className={`cardHeader-s`}>Dependencies</h1>
               <ul>
                  {fieldDeps.map((f, i) => (
                     <li key={i + f.id}>
                        <AioUwViewerInput field={f} unitIndex={stack.unitIndex} />
                     </li>
                  ))}
               </ul>
            </div>
            }
         </div>
      </article>
   );
};

export default AioUwViewerTracer;
