import { createContext, Dispatch } from "react";
import { IappReducerAction } from "../../types/interfaces/reducer.interface";
import { TAppState } from "./AppState";
interface IappContext {
   appState: TAppState;
   appDispatch: Dispatch<IappReducerAction>;
}
const appState: TAppState = {};
const AppContext = createContext<IappContext>({ appState, appDispatch: () => {} });

export default AppContext;
