import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { useAuth0 } from "@auth0/auth0-react";
import {
   TAIO_notificationSubscription_event,
   TAIO_notification_subscription_object,
   TPg_Aio_AppNotificationSubscriptions_Schema,
} from "@simplyhomes/utils";

export const useQNotificationSubscriptions = () => {
   const { aioAxios } = useAxios();
   const { user } = useAuth0();
   return useQuery({
      queryKey: ["useQNotificationSubscriptions", user],
      enabled: !!user,
      queryFn: () => aioAxios.get("/aio/notification-subscriptions").then<TAxiosResp>(({ data }) => data),
   });
};
const cols = ["event", "object", "objectids"] as const;
type TAxiosResp = {
   subscriptions: (Pick<TPg_Aio_AppNotificationSubscriptions_Schema, (typeof cols)[number]> & {
      event: TAIO_notificationSubscription_event;
      object: TAIO_notification_subscription_object;
   })[];
};
