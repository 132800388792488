import { Outlet, NavLink, Link } from "react-router-dom";
import styles from "./Nav.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import useAuthTabs from "../../../hooks/auth0/useAuthTabs";
import useTheme from "../../../hooks/react/useTheme";
import { useContext, useState } from "react";
import { cfAIO } from "../../../utils/s3/cfAIO";
import { useQNotifications } from "../../../hooks/querries/notifications/useQNotifications";
import SmallSpinner from "../../common/smallSpinner/SmallSpinner";
import AppContext from "../../../contexts/appContext/AppContext";
import { TauthTab } from "../../../const/afRoles";
import useQUser from "../../../hooks/querries/users/useQUser";
const NavSubItem = ({ navSubItem }: { navSubItem: navSubItem }) => {
   const { label, to, icon } = navSubItem;
   return (
      <li className={`${styles.navSubItem} `}>
         <NavLink to={to} className={({ isActive }) => (isActive ? styles.active : "")}>
            <img src={cfAIO(icon || `/nav/simply-short.svg`)} alt="" />
            <span>{label}</span>
         </NavLink>
      </li>
   );
};
const NavItem = ({ navItem }: { navItem: nav }) => {
   const { icon, label, isActive, subItems, to, title, callback = () => {} } = navItem;
   const [show, setShow] = useState(false);
   return (
      <li className={`${styles.navItem} `}>
         {to ? (
            <NavLink to={to} title={label}>
               <img src={cfAIO(icon)} alt="" />
               <span>{label}</span>
            </NavLink>
         ) : (
            <div
               className={`${isActive && styles.active}`}
               title={title || label}
               onMouseEnter={() => setShow(true)}
               onClick={callback}
            >
               <img src={cfAIO(icon)} alt="" />
               <span>{label}</span>
            </div>
         )}
         {subItems && show && (
            <ul onMouseLeave={() => setShow(false)}>
               {subItems.map((item, key) => (
                  <NavSubItem navSubItem={item} key={key} />
               ))}
            </ul>
         )}
      </li>
   );
};
export const navs: {
   label: string;
   path: string | ((p: { email?: string }) => string);
   permision?: TauthTab;
   icon?: string;
}[] = [
   { label: "Homepage", path: `/` },
   { label: "Census Insights", path: `/census`, icon: "/nav/cencus.svg" },
   // { label: "Census Insights (external)", path: `/census-external`, icon: "/nav/cencus.svg" },
   // { label: "Census Insights (internal)", path: `/census-internal`, icon: "/nav/cencus.svg" },
   { label: "Underwriting", path: "/uwu/search", icon: "/nav/underwriting2.svg", permision: "uwu" },
   { label: "Bulk Underwriting(NEW)", path: "/uwubulk", icon: "/nav/bulk-underwriting2.svg", permision: "uwubulk" },
   {
      label: "Simpler Widget",
      path: ({ email }) => `/simpler?email=${email}`,
      permision: "simpler",
      icon: "/nav/widget2.svg",
   },
   { label: "Assets", path: "/assets/search", icon: "/nav/assets.svg", permision: "assets" },
   { label: "Renovations", path: "/renovations/search", icon: "/nav/renovation.svg", permision: "renovations" },
   { label: "Versions", path: "/versions", icon: "/nav/versions.svg", permision: "versions" },
   { label: "Database", path: "/database", icon: "/nav/database2.svg", permision: "database" },
   { label: "Map", path: "/map", icon: "/nav/map.svg", permision: "map" },
   {
      label: "Underwriting(Legalcy)",
      path: "/underwritings",
      icon: "/nav/underwriting2.svg",
      permision: "underwritings",
   },
   { label: "Bulk Underwriting(Legalcy)", path: "/uwub/bulk", icon: "/nav/bulk-underwriting2.svg", permision: "uwub" },
   { label: "API Table", path: "/apitable", icon: "/nav/widget2.svg", permision: "apitable" },
];
export const navItems = {
   Underwriting: {
      path: "/aio-underwriting",
      icon: "/nav/underwriting2.svg",
      permision: "underwritings",
   },
   "Bulk Underwriting": {
      path: "/aio-bulk-underwriting",
      icon: "/nav/bulk-underwriting2.svg",
      permision: "uwubulk",
   },
   Versions: {
      path: "/aio-versions",
      icon: "/nav/versions.svg",
      permision: "versions",
   },
   Map: {
      path: "/aio-map",
      icon: "/nav/map.svg",
      permision: "map",
   },
};
const Nav = () => {
   const { appDispatch } = useContext(AppContext);
   useQUser();

   const [expand, setExpand] = useState(false);
   const [hidden, setHidden] = useState(false);

   const { user, logout } = useAuth0();
   const { data: qNofData, isFetching: qNofFetching } = useQNotifications();
   const { notifications: nofs = [] } = qNofData || {};
   const unreadNofs = nofs.filter((n) => n["notifications.seen"] === false);
   const { authTabs } = useAuthTabs();
   const { theme, switchTheme } = useTheme();

   const toolItems: navSubItem[] = navs
      .filter((i) => !i.permision || authTabs.includes(i.permision))
      .map((i) => ({
         label: i.label,
         to: typeof i.path === "string" ? i.path : i.path({ email: user?.email }),
         icon: i.icon,
      }));
   const newTools: navSubItem[] = Object.entries(navItems).map(([key, value]) => ({
      label: key,
      to: value.path,
      icon: value.icon,
   }));

   const navData: nav[] = [
      {
         label: "2.0",
         icon: "/icons/nav/suitcase.svg",
         subItems: newTools,
      },
      {
         label: "Tools",
         icon: "/icons/nav/suitcase.svg",
         subItems: toolItems,
      },
      {
         label: "Superhuman",
         title: "(Ctrl or ⌘) + /",
         icon: "/nav/simply-short.svg",
         callback: () => appDispatch({ overwrite: { superhuman_show: true } }),
      },
   ];
   return (
      <div className={`${styles.bodyC}`}>
         {hidden && (
            <div className={`${styles.hoverShowBtn}`} onClick={() => setHidden(false)}>
               <img src={cfAIO("/icons/common/double_left.svg")} alt="" />
            </div>
         )}

         <nav className={`${expand && styles.expand} ${hidden && styles.hidden}`}>
            <div className={`${styles.top}`}>
               <div className={`${styles.toptop}`}>
                  <div className={`${styles.headerC}`}>
                     <Link to={"/"}>
                        <img className={`${styles.logo}`} src={cfAIO("/nav/logo_white.png")} alt="" />
                     </Link>
                     <img
                        className={`${styles.burger}`}
                        onClick={() => setExpand(!expand)}
                        src={cfAIO("/icons/common/burger_menu.svg")}
                        alt=""
                     />
                  </div>
                  <ul>
                     {navData.map((navItem, key) => (
                        <NavItem key={key} navItem={navItem} />
                     ))}
                  </ul>
               </div>
               <div className={`${styles.topbot}`}>
                  <div className={`${styles.hideBtn}`} onClick={() => setHidden(true)}>
                     <img src={cfAIO("/icons/common/double_left.svg")} alt="" />
                  </div>
                  <Link to={"/settings/general"}>
                     <img src={cfAIO(`/icons/nav/settings.svg`)} alt="" />
                  </Link>
                  <div className={`${styles.modeBtn}`} onClick={switchTheme}>
                     <img src={cfAIO(`/icons/common/${theme === "light" ? "lightmode" : "darkmode"}.svg`)} alt="" />
                  </div>
                  <div onClick={() => appDispatch({ overwrite: { notifications_show: true } })}>
                     <img src={cfAIO("/nav/bell.svg")} alt="" />
                     {qNofFetching ? (
                        <SmallSpinner style={{ width: "16px", height: "16px" }} />
                     ) : (
                        <p>{unreadNofs.length < 10 ? unreadNofs.length : "∞"}</p>
                     )}
                  </div>
               </div>
            </div>
            <div className={`${styles.bot}`}>
               <div className={`${styles.userC} ellipsis`}>
                  <img
                     src={user?.picture}
                     alt=""
                     className={`${styles.picture}`}
                     onClick={() => !expand && setExpand(true)}
                  />
                  <div className={`${styles.user}`}>
                     <p>{user?.nickname}</p>
                     <p>{user?.allRoles?.[0]}</p>
                  </div>
                  <img
                     className={`${styles.logout}`}
                     onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                     src={cfAIO("/icons/common/logout.svg")}
                     alt=""
                  />
               </div>
            </div>
         </nav>

         <Outlet />
      </div>
   );
};
type nav = {
   icon: string;
   label: string;
   isActive?: boolean;
   title?: string;
   to?: string;
   subItems?: navSubItem[];
   callback?: () => void;
};
type navSubItem = { label: string; to: string; icon?: string };
export default Nav;
