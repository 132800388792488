import { useContext, useEffect, useRef, useState } from "react";
import styles from "./UwUSingle.module.scss";
import { useParams } from "react-router-dom";
import useQUws from "../../../hooks/querries/underwritings/useQUws";
import { useUwUContext } from "../UwU";
import useQVersion, { TversionGroup } from "../../../hooks/querries/versions/useQVersion";
import useQUw from "../../../hooks/querries/underwritings/useQUw";
import MainInput from "../../../components/common/mainInput/MainInput";
import MainButton from "../../../components/common/mainButton/MainButton";
import useAxios from "../../../hooks/axios/useAxios";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import SmallSpinner from "../../../components/common/smallSpinner/SmallSpinner";
import UwUSingleField from "../../../components/children/uwu/uwuField/UwUSingleField";
import JSONtryParse from "../../../utils/JSON/JSONtryParse";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import Table from "../../../components/common/table/Table";
import sliceIntoChunks from "../../../utils/JS/sliceChunks";
import useQVersions from "../../../hooks/querries/versions/useQVersions";
import Popup from "../../../components/common/popup/Popup";
import UwUFieldInspector from "../../../components/children/uwu/uwuFieldInspector/UwUFieldInspector";
import TabButton from "../../../components/common/tabButton/TabButton";
import { cfAIO } from "../../../utils/s3/cfAIO";
import { SHu_JSONtryParse, SHu_js_percentageFormat } from "@simplyhomes/utils";
import { useStateParams } from "../../../hooks/react/useStateParams";
import { useQNotes } from "../../../hooks/querries/notes/useQNotes";
import AppContext from "../../../contexts/appContext/AppContext";
import { useQNotificationSubscriptions } from "../../../hooks/querries/notificationSubscriptions/useQNotificationSubscriptions";
import { useResolveUwU } from "../../../hooks/formulas/useResolveUwU";
import jsTimeSince from "../../../utils/JS/jsTimeSince";
import { useQFieldsOptions } from "../../../hooks/querries/fields/useQFieldsOptions";
import UwUCompare from "../../../components/common/uwUCompare/UwUCompare";

const ggSheetBaseUrl = "https://docs.google.com/spreadsheets/d/";

const UwUSingle = ({ bulkInspector }: TUwUSingleProps) => {
   const { aioAxios } = useAxios();

   const { appDispatch } = useContext(AppContext);

   const [submitting, setSubmitting] = useState(false);
   const { uwsid } = useParams();

   const { params } = useStateParams();
   const ver = params.get("ver") || "";

   const { recalcUwUFields } = useResolveUwU({ ver });

   const { data: qUwsData, isFetching: qUwsFetching } = useQUws();
   const { underwritings: uws = [] } = qUwsData || {};

   const { data: qVersData, isFetching: qVersFetching } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersData || {};

   const {
      data: qUwData,
      isFetching: qUwFetching,
      refetch: qUwRefetch,
      isError: qUwIsError,
   } = useQUw({ uwsids: [uwsid || ""], enabled: bulkInspector ? false : undefined });
   const uwOG = qUwData?.underwritings[0];

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { groups = [], units = [], fields = [], overview = [] } = qVerData?.config || {};

   const { data: qNotesData, isFetching: qNotesFetching } = useQNotes({
      object: "underwritings",
      objectid: uwOG?.hs_object_id || "",
   });
   const { notes = [] } = qNotesData || {};

   const { data: qNofSubData, isFetching: qNofSubFetching, refetch: qNofSubRefetch } = useQNotificationSubscriptions();
   const { subscriptions = [] } = qNofSubData || {};

   const { uwuState, uwuDispatch } = useUwUContext();
   const { uwFinal = {}, uwProcessing, uwCache = {}, uwOptions = {}, newUwName } = uwuState;
   const { $childUnits = [], $app_readonly } = uwFinal;

   const { data: qFOpsData } = useQFieldsOptions({
      names: ["hs_pipeline", "hs_pipeline_stage"],
      objects: ["underwritings"],
   });
   const { options: fieldsOptions = [] } = qFOpsData || {};

   const allPropGroups = groups.map(({ title }) => title);
   const allUnitGroups = units.map(({ title }) => title);
   const allUnits = $childUnits.map((_, i) => i + 1);
   const importantPropGroups = groups.filter(({ defaultShown }) => defaultShown).map(({ title }) => title);
   const importantUnitGroups = units.filter(({ defaultShown }) => defaultShown).map(({ title }) => title);

   const [view, setView] = useState<"details" | "overview">("overview");
   const [shownPropGroups, setShownPropGroups] = useState<string[]>(importantPropGroups);
   const [shownUnitGroups, setShownUnitGroups] = useState(importantUnitGroups);
   const [shownChildren, setShownChildren] = useState<number[]>(allUnits);
   const [showInfo, setShowInfo] = useState(false);
   const [showCompare, setShowCompare] = useState(false);
   const [sheetId, setSheetId] = useState("");
   const [inspectingIds, setInspectingIds] = useState<string[]>([]);
   const [overviewUnitIndex, setOverviewUnitIndex] = useState(0);
   const [expandBotBar, setExpandBotBar] = useState(false);

   const firstRenderRef = useRef(true);
   useEffect(() => {
      if (
         (firstRenderRef.current === false || process.env.REACT_APP_MODE !== "DEV") &&
         qUwData &&
         qVerData &&
         !qVerFetching &&
         !qUwFetching
      ) {
         const updateState = async () => {
            uwuDispatch({ overwrite: { uwProcessing: true } });
            const result = await recalcUwUFields({
               cache: uwCache,
               options: [uwOptions],
               uws: [
                  {
                     ...JSONtryParse<TUnknownObject>(qUwData?.underwritings[0]?.aio_original_data, {}),
                     ...JSONtryParse<TUnknownObject>(qUwData?.underwritings[0]?.aio_uw_data, {}),
                  },
               ],
            });
            uwuDispatch({ type: "recalced", payload: result });
         };
         updateState();
      }
      return () => {
         firstRenderRef.current = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [uwsid, ver, qUwFetching, qVerFetching]);

   const handleSave = async () => {
      setSubmitting(true);
      setShowCompare(false)
      await aioAxios
         .patch(`/aio/underwritings/${uwsid}`, { aio_uw_data: uwFinal, version: ver, name: newUwName || uwOG?.name })
         .then(() => qUwRefetch())
         .catch((err) => console.log({ err }));
      setSubmitting(false);
   };
   const handleAddUnit = async () => {
      const dupData: TUnknownObject =
         $childUnits.length > 0
            ? $childUnits[0]
            : units
                 .map(({ children }) => children)
                 .flat()
                 .map((id) => fields.find((field) => field.id === id))
                 .reduce(
                    (prev, curr) => ({ ...prev, [curr?.column || ""]: uwFinal[curr?.column || ""] ?? curr?.fallback }),
                    {}
                 );
      const newChildUnits = [dupData, ...$childUnits];
      const newUw = { ...uwFinal, $childUnits: newChildUnits };
      uwuDispatch({ overwrite: { uwProcessing: true } });
      const result = await recalcUwUFields({ uws: [newUw], cache: uwCache, options: [uwOptions] });
      uwuDispatch({ type: "recalced", payload: result });

      setShownChildren([...new Set([...shownChildren, $childUnits.length + 1])]);
   };
   const handleRemoveUnit = async (index: number) => {
      const newUw = { ...uwFinal, $childUnits: $childUnits.filter((_, cIndex) => index !== cIndex) };
      uwuDispatch({ overwrite: { uwProcessing: true } });
      const result = await recalcUwUFields({ uws: [newUw], cache: uwCache, options: [uwOptions] });
      uwuDispatch({ type: "recalced", payload: result });
   };
   const handleSheetExport = async () => {
      setSubmitting(true);
      try {
         const { sheetId } = await aioAxios
            .post(`/aio/underwritings/${uwsid}/export-sheet/${ver}`, { uw: uwFinal })
            .then(({ data }) => data || {});
         setSheetId(sheetId || "");
         if (!sheetId) return;
         const newWindow = window.open(ggSheetBaseUrl + sheetId, "_blank", "noopener,noreferrer");
         if (newWindow) newWindow.opener = null;
      } catch (error) {
         console.log({ error });
      }
      setSubmitting(false);
   };
   const handleTooltipClick = (id: string) => setInspectingIds([...inspectingIds, id]);
   const handleCrumbClick = (id: string) =>
      setInspectingIds(inspectingIds.slice(0, inspectingIds.findIndex((fId) => fId === id) + 1));

   const subscribed =
      uwOG?.hs_object_id &&
      subscriptions
         .find((s) => s.object === "underwritings" && s.event === "subscribed to notes")
         ?.objectids.includes(Number(uwOG.hs_object_id));
   const handleSubscribe = async () => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/notification-subscriptions`, {
            event: "subscribed to notes",
            object: "underwritings",
            objectid: uwOG?.hs_object_id,
            subscribe: !subscribed,
         })
         .catch((er) => console.log({ er }));
      qNofSubRefetch();
      setSubmitting(false);
   };

   const isValidUwId = !!uws.find((uw) => uw["underwritings.simply_object_id"] === uwsid);
   const isValidVer = !!versions.find((version) => version === ver);

   const inspectingId = inspectingIds.at(-1);
   if (qUwsFetching || qUwFetching || qVersFetching || qVerFetching || submitting || qNofSubFetching || qNotesFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   if (!isValidUwId && !bulkInspector)
      return <BodyFiller fillerMsg="No Such Underwriting! Please select another Underwriting." />;
   if (!isValidVer) return <BodyFiller fillerMsg="No Such Version! Please select another version." />;
   if (qUwIsError) return <BodyFiller fillerMsg="Oops! There is An Error For This Underwriting. CONTACT ADMIN!" />;
   const prepListGroup = (group: TversionGroup) =>
      (group.rows || "").split(",").reduce((prev: { children: string[]; group: string }[], curr, currIndex) => {
         const childrenCount = prev.map(({ children }) => children).flat().length;
         prev.push({
            children: group.children.slice(childrenCount, childrenCount + Number(curr)),
            group: group.columns?.split(",")[currIndex] || "",
         });
         return prev;
      }, []);
   const bottomGroup = overview.find((g) => g.zone === "bottom");
   const infoDate = (v: string | number | undefined | null) => {
      if (!v) return "-";
      return `${new Date(Number(v)).toISOString().split("T")[0]} (${jsTimeSince(Number(v))})`;
   };
   const pipelineName = fieldsOptions
      .find((f) => f.name === "hs_pipeline")
      ?.options.find((o) => o.value === uwOG?.hs_pipeline)?.label;
   const pipelineStage = fieldsOptions
      .find((f) => f.name === "hs_pipeline_stage")
      ?.options.find((o) => o.value === uwOG?.hs_pipeline_stage)?.label;
   return (
      <article className={`${styles.contentC}`}>
         {showInfo && (
            <Popup onClose={() => setShowInfo(false)}>
               <div className={`${styles.iPopupC}`}>
                  <div className={`${styles.iPopupHeader} header-s`}>
                     <h1 className={`cardHeader-s`}>Info</h1>
                     <MainButton type="close" onClick={() => setShowInfo(false)} />
                  </div>
                  <div className={`${styles.iPopupHeaderInfo}`}>
                     <MainInput
                        title="Underwriting Name"
                        value={newUwName || uwOG?.name || ""}
                        handleValue={(str) => uwuDispatch({ overwrite: { newUwName: str } })}
                     />
                     <MainInput title="Created by" value={uwOG?.aio_original_created_by || "-"} />
                     <MainInput title="Created at" value={infoDate(uwOG?.aio_original_date)} />
                     <MainInput title="Saved by" value={uwOG?.aio_uw_saved_by || "-"} />
                     <MainInput title="Saved at" value={infoDate(uwOG?.aio_uw_date)} />
                     <MainInput title="Pipeline" value={pipelineName || "-"} />
                     <MainInput title="Stage" value={pipelineStage || "-"} />
                  </div>
               </div>
            </Popup>
         )}
         {inspectingId && (
            <Popup onClose={() => setInspectingIds([])} pos="rightSlideIn">
               <UwUFieldInspector
                  fieldId={inspectingId}
                  fieldIds={inspectingIds}
                  onBackClick={() => setInspectingIds(inspectingIds.slice(0, inspectingIds.length - 1))}
                  onTooltipClick={handleTooltipClick}
                  onCrumbClick={handleCrumbClick}
               />
            </Popup>
         )}
         {showCompare && !qUwFetching && (
            <Popup onClose={() => setShowCompare(false)}>
               <UwUCompare
                  onClose={() => setShowCompare(false)}
                  onSave={handleSave}
                  ver={ver}
                  uws={[SHu_JSONtryParse(uwOG?.aio_uw_data || "", {}), uwFinal]}
               />
            </Popup>
         )}
         <div className={`${styles.infoC}`}>
            <div className={`${styles.infoL}`}>
               <div>
                  {$app_readonly ? (
                     <>
                        <p>Locked</p>
                        <img src={cfAIO(`/icons/common/lock.svg`)} alt="" />
                     </>
                  ) : (
                     <MainButton
                        type="warn"
                        title="Lock"
                        confirmMsg="Forever lock this underwriting from further changes?"
                        onClick={() => uwuDispatch({ overwrite: { uwFinal: { ...uwFinal, $app_readonly: true } } })}
                     />
                  )}
               </div>
               {sheetId && (
                  <a href={ggSheetBaseUrl + sheetId} target="_blank" rel="noreferrer">
                     <p>
                        <span>One Pager</span>
                        <img src={cfAIO("/icons/common/external_link.svg")} alt="" />
                     </p>
                     <b>Link Ready!</b>
                  </a>
               )}
               {uwOG?.hs_object_id && (
                  <a
                     href={`https://app.hubspot.com/contacts/7832526/record/2-15822979/${uwOG.hs_object_id}`}
                     target="_blank"
                     rel="noreferrer"
                  >
                     <p>
                        <span>Underwriting</span>
                        <img src={cfAIO("/icons/common/external_link.svg")} alt="" />
                     </p>
                     <b>{uwOG.hs_object_id}</b>
                  </a>
               )}
               {uwOG?.property_object_id && (
                  <a
                     href={`https://app.hubspot.com/contacts/7832526/record/2-11273381/${uwOG.property_object_id}`}
                     target="_blank"
                     rel="noreferrer"
                  >
                     <p>
                        <span>Property</span>
                        <img src={cfAIO("/icons/common/external_link.svg")} alt="" />
                     </p>
                     <b>{uwOG.property_object_id}</b>
                  </a>
               )}
               <div>
                  <p>Beds</p>
                  <b>{uwFinal.bedrooms || "-"}</b>
               </div>
               <div>
                  <p>Bath</p>
                  <b>{uwFinal.bathrooms || "-"}</b>
               </div>
               <div>
                  <p>BigML</p>
                  <b>{SHu_js_percentageFormat(uwFinal.bigml_score) || "-"}</b>
               </div>
               <div>
                  <p>Sqft</p>
                  <b>{uwFinal.sqft || "-"}</b>
               </div>
               <div>
                  <p>Year built</p>
                  <b>{uwFinal.year_built || "-"}</b>
               </div>
               <div>
                  <p>County</p>
                  <b>{uwFinal.$county || "-"}</b>
               </div>
               <div>
                  <p>Housing Authority</p>
                  <b>{uwFinal.$housing_authority || "-"}</b>
               </div>
               <div>
                  <p>Property Type</p>
                  <b>{uwFinal.$unit_type || "-"}</b>
               </div>
            </div>
            <div className={`${styles.infoR}`}>
               {!bulkInspector && (
                  <>
                     <MainInput title="Subscribed" type="toggle" value={subscribed} handleValue={handleSubscribe} />
                  </>
               )}
            </div>
         </div>
         <div className={`${styles.viewsC}`}>
            {bulkInspector && <span>{uwFinal.property_address_one_line_}</span>}
            <TabButton title="Overview" isActive={view === "overview"} onClick={() => setView("overview")} />
            <TabButton title="Details" isActive={view === "details"} onClick={() => setView("details")} />
         </div>

         {view === "details" && (
            <div className={`${styles.detailsC}`}>
               <div className={`${styles.pd}`}>
                  <div className={`header-m ${styles.pdHeader}`}>
                     <span onClick={() => console.log({ uwFinal, uwCache, uwOptions })}>Property Details</span>
                  </div>
                  <div className={`${styles.Groups}`}>
                     <div className={`${styles.Group}`}>
                        <div className={`${styles.GroupTitle}`}>
                           <div>Property Groups</div>
                           <MainButton type="bland" title="Add All" onClick={() => setShownPropGroups(allPropGroups)} />
                           <MainButton type="bland" title="Hide All" onClick={() => setShownPropGroups([])} />
                        </div>
                        <div className={`${styles.titlesC}`}>
                           {allPropGroups.map((title, key) => (
                              <MainInput
                                 key={key}
                                 type="checkbox"
                                 title={title}
                                 value={shownPropGroups.includes(title)}
                                 handleValue={(b) =>
                                    setShownPropGroups(
                                       b
                                          ? [...shownPropGroups, title]
                                          : shownPropGroups.filter((groupTitle) => groupTitle !== title)
                                    )
                                 }
                              />
                           ))}
                        </div>
                     </div>
                     {groups.map((group, key) => (
                        <div
                           className={`${!shownPropGroups.includes(group.title) ? "hidden" : styles.Group}`}
                           key={key}
                        >
                           <div className={`${styles.GroupTitle} `}>
                              <span>{group.title}</span>
                              <MainButton
                                 title={"Hide"}
                                 type="bland"
                                 onClick={() =>
                                    setShownPropGroups(
                                       shownPropGroups.filter((groupTitle) => groupTitle !== group.title)
                                    )
                                 }
                              />
                           </div>
                           {(!group?.type || group.type === "group") && (
                              <div className={`${styles.typeGroup}`}>
                                 {group.children.map((fieldId, key) => (
                                    <UwUSingleField fieldId={fieldId} key={key} onTooltipClick={handleTooltipClick} />
                                 ))}
                              </div>
                           )}
                           {group.type === "table" && (
                              <div className={`${styles.typeTable}`}>
                                 <Table
                                    type="UwUField"
                                    columnHeaders={group.columns?.split(",")}
                                    rowHeaders={group.rows?.split(",")}
                                    data={sliceIntoChunks(group.children, group.columns?.split(",").length || 0)}
                                    onUwUSingleFieldTooltipClick={handleTooltipClick}
                                 />
                              </div>
                           )}
                        </div>
                     ))}
                  </div>
               </div>
               <div className={`${styles.ud}`}>
                  <div className={`header-m ${styles.udHeader}`}>
                     <span>{$childUnits.length} Unit(s) Details</span>
                     <MainButton title="Add Unit" confirmMsg="Add one new Unit?" onClick={handleAddUnit} />
                  </div>
                  {$childUnits.length > 0 && (
                     <div className={`${styles.Groups}`}>
                        <div className={`${styles.Group}`}>
                           <div className={`${styles.GroupTitle}`}>
                              <span>Unit Groups</span>
                              <MainButton
                                 title="Add All"
                                 type="bland"
                                 onClick={() => setShownUnitGroups(allUnitGroups)}
                              />
                              <MainButton title="Hide All" type="bland" onClick={() => setShownUnitGroups([])} />
                           </div>
                           <div className={`${styles.titlesC}`}>
                              {allUnitGroups.map((title, key) => (
                                 <MainInput
                                    key={key}
                                    type="checkbox"
                                    title={title}
                                    value={shownUnitGroups.includes(title)}
                                    handleValue={(b) =>
                                       setShownUnitGroups(
                                          b
                                             ? [...shownUnitGroups, title]
                                             : shownUnitGroups.filter((groupTitle) => groupTitle !== title)
                                       )
                                    }
                                 />
                              ))}
                           </div>
                        </div>
                        <div className={`${styles.Group}`}>
                           <div className={`${styles.GroupTitle}`}>
                              <span>Units</span>
                              <MainButton title="Add All" type="bland" onClick={() => setShownChildren(allUnits)} />
                              <MainButton title="Hide All" type="bland" onClick={() => setShownChildren([])} />
                           </div>
                           <div className={`${styles.titlesC}`}>
                              {allUnits.map((cIndex, key) => (
                                 <MainInput
                                    key={key}
                                    title={`Unit #${cIndex}`}
                                    type="checkbox"
                                    value={shownChildren.includes(cIndex)}
                                    handleValue={(b) =>
                                       setShownChildren(
                                          b ? [...shownChildren, cIndex] : shownChildren.filter((num) => num !== cIndex)
                                       )
                                    }
                                 />
                              ))}
                           </div>
                        </div>
                     </div>
                  )}
                  <div className={`${styles.udChildren} `}>
                     {$childUnits.map((_, unitIndex) => (
                        <div
                           key={unitIndex}
                           className={`${!shownChildren.includes(unitIndex + 1) ? "hidden" : styles.udChild}`}
                        >
                           <div className={`${styles.udChildTitle}`}>
                              <span>Unit #{unitIndex + 1}</span>
                              <MainButton
                                 type="bland"
                                 title={"Hide"}
                                 onClick={() =>
                                    setShownChildren(shownChildren.filter((cIndex) => cIndex !== unitIndex + 1))
                                 }
                              />
                              <MainButton
                                 type="warn"
                                 title="Delete"
                                 confirmMsg="Remove Unit?"
                                 onClick={() => handleRemoveUnit(unitIndex)}
                              />
                           </div>
                           <div className={`${styles.Groups} `}>
                              {units.map((group, key) => (
                                 <div
                                    className={`${!shownUnitGroups.includes(group.title) ? "hidden" : styles.Group} `}
                                    key={key}
                                 >
                                    <div className={`${styles.GroupTitle}`}>
                                       <span>{group.title}</span>
                                       <MainButton
                                          title={!shownUnitGroups.includes(group.title) ? "Show" : "Hide"}
                                          type="bland"
                                          onClick={() =>
                                             !shownUnitGroups.includes(group.title)
                                                ? setShownUnitGroups([...shownUnitGroups, group.title])
                                                : setShownUnitGroups(
                                                     shownUnitGroups.filter((groupTitle) => groupTitle !== group.title)
                                                  )
                                          }
                                       />
                                    </div>
                                    <div className={`${styles.typeGroup}`}>
                                       {group.children.map((fieldId, key) => (
                                          <UwUSingleField
                                             fieldId={fieldId}
                                             key={key}
                                             unitIndex={unitIndex}
                                             onTooltipClick={handleTooltipClick}
                                          />
                                       ))}
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         )}
         {view === "overview" && (
            <div className={`${styles.overviewC}`}>
               <div className={`${styles.overviewBodyC}`}>
                  <div className={`${styles.overviewBodyL}`}>
                     {overview
                        .filter(({ zone, type }) => zone === "left" && type === "list")
                        .map(prepListGroup)
                        .map((lists, key) => (
                           <div key={key} className={`${styles.listGroups}`}>
                              {lists?.map(({ group, children }, key) => (
                                 <div className={`${styles.listGroup}`} key={key}>
                                    <b className={`${styles.listGroupTitle}`}>{group}</b>
                                    <div className={`${styles.listChildren}`}>
                                       {children.map((child, key) => (
                                          <div className={`${styles.listChild}`} key={key}>
                                             <span>{fields.find((f) => f.id === child)?.name}</span>
                                             <UwUSingleField
                                                style={{ width: "6.8rem" }}
                                                showValueOnly
                                                fieldId={child}
                                                onTooltipClick={handleTooltipClick}
                                             />
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              ))}
                           </div>
                        ))}
                  </div>
                  <div className={`${styles.overviewBodyR}`}>
                     <div className={`${styles.Groups}`}>
                        {overview
                           .filter(({ zone }) => zone === "right")
                           .map((group, key) => (
                              <div className={`${styles.Group}`} key={key}>
                                 <div className={`${styles.GroupTitle} `}>
                                    <span>{group.title}</span>
                                 </div>

                                 <div className={`${styles.typeTable}`}>
                                    <Table
                                       type="UwUField"
                                       columnHeaders={group.columns?.split(",")}
                                       rowHeaders={group.rows?.split(",")}
                                       data={sliceIntoChunks(group.children, group.columns?.split(",").length || 0)}
                                       onUwUSingleFieldTooltipClick={handleTooltipClick}
                                    />
                                 </div>
                              </div>
                           ))}
                     </div>
                  </div>
               </div>
               <div className={`${styles.overviewUnits}`}>
                  {$childUnits.length > 0 && (
                     <>
                        <div className={`${styles.ovUTitle}`}>
                           {$childUnits.map((_, i) => (
                              <TabButton
                                 key={i}
                                 isActive={i === overviewUnitIndex}
                                 title={`Unit#${i + 1}`}
                                 onClick={() => setOverviewUnitIndex(i)}
                              />
                           ))}
                        </div>
                        <div className={`${styles.Groups}`}>
                           {units.map((group, i) => (
                              <div key={i + group.id} className={`${styles.Group}`}>
                                 <div className={`${styles.GroupTitle} `}>
                                    <span>{group.title}</span>
                                 </div>
                                 <div className={`${styles.typeGroup}`}>
                                    {group.children.map((fieldId, key) => (
                                       <UwUSingleField
                                          unitIndex={overviewUnitIndex}
                                          fieldId={fieldId}
                                          key={key}
                                          onTooltipClick={handleTooltipClick}
                                       />
                                    ))}
                                 </div>
                              </div>
                           ))}
                        </div>
                     </>
                  )}
               </div>
            </div>
         )}
         <div className={`${styles.botBar} ${expandBotBar && styles.botBarExpand}`}>
            {!bulkInspector && (
               <div className={`${styles.toolboxC}`}>
                  <div className={`${styles.toolbox}`}>
                     <MainButton type="children">
                        <img
                           src={cfAIO(
                              notes.length > 0 ? "/icons/underwritings/notes.svg" : "/icons/common/toolbox.svg"
                           )}
                           alt=""
                        />
                     </MainButton>
                     <MainButton type="children" onClick={() => setShowCompare(true)}>
                        <img src={cfAIO("/icons/underwritings/save.svg")} alt="" />
                        <span>Compare & Save</span>
                     </MainButton>
                     <MainButton type="children" confirmMsg="Export One Pager?" onClick={handleSheetExport}>
                        <img src={cfAIO("/icons/underwritings/export.svg")} alt="" />
                        <span>Export</span>
                     </MainButton>
                     <MainButton type="children" confirmMsg="Unsaved data will be lost?" onClick={qUwRefetch}>
                        <img src={cfAIO("/icons/underwritings/refresh.svg")} alt="" />
                        <span>Refresh</span>
                     </MainButton>
                     <MainButton type="children" onClick={() => setShowInfo(true)}>
                        <img src={cfAIO("/icons/underwritings/info.svg")} alt="" />
                        <span>Info</span>
                     </MainButton>
                     <MainButton
                        type="children"
                        onClick={() =>
                           appDispatch({
                              overwrite: {
                                 notes_showNotes: true,
                                 notes_object: "underwritings",
                                 notes_objectid: uwOG?.hs_object_id,
                              },
                           })
                        }
                     >
                        <img src={cfAIO("/icons/underwritings/notes.svg")} alt="" />
                        <span>{notes.length} Notes</span>
                     </MainButton>
                  </div>
                  {notes.length > 0 && <div className={`${styles.noteCount}`}>{notes.length}</div>}
               </div>
            )}
            <div className={`${styles.botBarL}`}>
               {bottomGroup?.children.map((fieldId, key) => (
                  <UwUSingleField
                     key={key + fieldId}
                     fieldId={fieldId}
                     style={{ width: "10rem" }}
                     onTooltipClick={handleTooltipClick}
                  />
               ))}
            </div>
            <div className={`${styles.botBarR}`}>
               {uwProcessing ? (
                  <SmallSpinner style={{ width: "30px", height: "30px" }} />
               ) : (
                  <button onClick={() => setExpandBotBar(!expandBotBar)}>
                     <img src={cfAIO("/icons/common/double_left.svg")} alt="" />
                  </button>
               )}
            </div>
         </div>
      </article>
   );
};

export default UwUSingle;
type TUwUSingleProps = {
   bulkInspector?: boolean;
};
