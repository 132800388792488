import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import s3afBucketURI from "../../../../../../utils/s3/s3afBucketURI";
import AFInput from "../../../../../common/AFInput/AFInput";
import MainButton from "../../../../../common/mainButton/MainButton";
import styles from "./GroupsEditorPopup.module.scss";
import { useVersionContext } from "../../../../../../pages/Versions/Versions";
import { TVersionZone, TversionGroup } from "../../../../../../hooks/querries/versions/useQVersion";
import { TgroupType } from "../../../../../../const/UWcalc/groupTypes";
import MainInput from "../../../../../common/mainInput/MainInput";
import { TgroupEditorType } from "../GroupsEditor";
const GroupsEditorPopup = ({ index, handleClose, type: groupType }: IGroupsEditorPopupParamsEditting) => {
   const isNew = index === -1;
   const { ver = "" } = useParams();

   const { version, setVersion } = useVersionContext();

   const { fields = [] } = version;
   const source = version[groupType] || [];
   const item = source[index];
   const id = item?.id || Date.now().toString();
   const [title, setTitle] = useState<string>(item?.title || "");
   const [type, setType] = useState<TgroupType>(item?.type || "group");
   const [columns, setColumns] = useState(item?.columns || "");
   const [rows, setRows] = useState(item?.rows || "");
   const [zone, setZone] = useState<"top" | "left" | "right" | "bottom" | undefined>(item?.zone || undefined);
   const [children, setChildren] = useState<string[]>(item?.children || []);
   const [defaultShown, setDefaultShown] = useState(item?.defaultShown);
   const [search, setSearch] = useState("");
   const filteredFields = useMemo(
      () => fields.filter(({ name }) => name?.toLowerCase().includes(search.toLowerCase())),
      [fields, search]
   );
   if (!version) return <div>Not Found {ver}</div>;
   const target = groupType;
   const handleBtn = () => {
      const newGroup: TversionGroup = { id, title, children, type, columns, rows, defaultShown, zone };
      const newGroups: TversionGroup[] = [...source];
      if (isNew) newGroups.push(newGroup);
      else newGroups.splice(index, 1, newGroup);
      setVersion({ ...version, [target]: newGroups });
      handleClose();
   };
   const handleDel = () => {
      const newGroups = [...source];
      newGroups.splice(index, 1);
      setVersion({ ...version, [target]: newGroups });
      handleClose();
   };
   const addChild = (id: string) => setChildren([...children, id]);
   const removeChild = (index: number) => {
      const newChilren = [...children];
      newChilren.splice(index, 1);
      setChildren([...newChilren]);
   };
   const moveChild = (i: number, direction: "up" | "down") => {
      const newChildren = [...children];
      if (direction === "up" && !newChildren[i - 1]) return;
      if (direction === "down" && !newChildren[i + 1]) return;
      if (direction === "up") [newChildren[i - 1], newChildren[i]] = [newChildren[i], newChildren[i - 1]];
      if (direction === "down") [newChildren[i], newChildren[i + 1]] = [newChildren[i + 1], newChildren[i]];
      setChildren([...newChildren]);
   };
   const splitCols = columns.split(",").filter(Boolean);
   const getCol = (index: number) => splitCols[index % splitCols.length] || "ERROR";
   const splitRows = rows.split(",").filter(Boolean);
   const getRow = (index: number) => splitRows[Math.floor(index / splitCols.length)] || "ERROR";
   const getListHeader = (index: number) =>
      splitRows.map((num, ri) => [...Array(Number(num))].map(() => splitCols[ri])).flat()[index];
   const getRowCount = (index: number) =>
      splitRows
         .map((num, ri) => [...Array(Number(num))].map(() => splitCols[ri]))
         .flat()
         .splice(0, index)
         .filter((str) => str === getListHeader(index)).length + 1;
   const findField = (id: string) => fields.find((field) => field.id === id);
   return (
      <article className={`${styles.contentC} `}>
         <div className={`${styles.title}`}>{item ? `Editting ${item.title}` : "Creating a new group"}</div>
         <div className={`${styles.content}`}>
            <div className={`${styles.form}`}>
               <div className={`${styles.settings}`}>
                  <MainInput title="ID" value={id} readonly />
                  <AFInput value={title} title={"Group Title"} handleChange={(v) => setTitle(v as string)} />
                  <AFInput
                     value={type}
                     title={"Group Type"}
                     type={"dropdown"}
                     options={["table", "group", "list"]}
                     handleChange={(v) => setType(v as TgroupType)}
                  />
                  <MainInput
                     type="checkbox"
                     title="Shown By Default"
                     tooltip={{ type: "info", msg: "Will not be hidden on the default Calculator view" }}
                     value={defaultShown}
                     handleValue={setDefaultShown}
                  />
                  {["table", "list"].includes(type) && (
                     <>
                        <AFInput
                           value={columns}
                           title={"Columns"}
                           handleChange={setColumns}
                           tooltip={{ type: "info", msg: "separated by comma" }}
                        />
                        <AFInput
                           value={rows}
                           title={"Rows"}
                           handleChange={setRows}
                           tooltip={{ type: "info", msg: "separated by comma" }}
                        />
                     </>
                  )}
                  <AFInput
                     value={zone}
                     title="Zone"
                     type="dropdown"
                     handleChange={(v) => setZone(v as TVersionZone)}
                     options={["top", "left", "right", "bottom"] as TVersionZone[]}
                  />
               </div>
               <ul className={`${styles.fieldsC}`}>
                  {children.map((id, key) => (
                     <li key={key} className={`${styles.fieldC}`}>
                        {["table", "list"].includes(type) && (
                           <>
                              <div className={`${styles.tableInfoC}`}>
                                 <div className={`${styles.col}`}>
                                    <img src={s3afBucketURI("/icons/columns.svg")} alt="" />
                                    <span>{type === "table" ? getCol(key) : getListHeader(key)}</span>
                                 </div>
                                 <div className={`${styles.row}`}>
                                    <span>{type === "table" ? getRow(key) : getRowCount(key)}</span>
                                    <img src={s3afBucketURI("/icons/rows.svg")} alt="" />
                                 </div>
                              </div>
                           </>
                        )}
                        <div className={`${styles.field}`}>
                           <div className={`${styles.nameC}`}>
                              <span>{findField(id)?.name}</span>
                           </div>
                           <div className={`${styles.icons}`}>
                              <img
                                 src={s3afBucketURI("/icons/Arrow_top.svg")}
                                 alt="Up"
                                 onClick={() => moveChild(key, "up")}
                              />
                              <img
                                 src={s3afBucketURI("/icons/Arrow_down.svg")}
                                 alt="Down"
                                 onClick={() => moveChild(key, "down")}
                              />
                              <img
                                 src={s3afBucketURI("/icons/Close_round.svg")}
                                 alt="Delete"
                                 onClick={() => removeChild(key)}
                              />
                           </div>
                        </div>
                     </li>
                  ))}
               </ul>
            </div>
            <div className={`${styles.finderC}`}>
               <AFInput
                  value={search}
                  handleChange={(v) => setSearch(v as string)}
                  title={"Fields Finder"}
                  tooltip={{ type: "info", msg: "Click any to add to the group." }}
               />
               <ul className={`${styles.fieldsC}`}>
                  {filteredFields.map(({ name, id }, key) => (
                     <li key={key} className={`${styles.field}`} onClick={() => addChild(id)}>
                        <div className={`${styles.name}`}>{name}</div>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
         <div className={`${styles.controlsC}`}>
            <MainButton
               enable={!!title}
               title={isNew ? "Create" : "Update"}
               onClick={() => handleBtn()}
               confirmMsg="Confirm Save?"
            />
            {!isNew && (
               <MainButton title={"Delete"} onClick={() => handleDel()} confirmMsg="Confirm Delete?" type="warn" />
            )}
         </div>
      </article>
   );
};

export default GroupsEditorPopup;

interface IGroupsEditorPopupParamsEditting {
   handleClose: () => void;
   index: number;
   group?: TversionGroup;
   type: TgroupEditorType;
}
