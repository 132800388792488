import { useState } from "react";
import MainButton from "../mainButton/MainButton";
import styles from "./NotesPanel.module.scss";
import { useQNotes, useQNotesParams } from "../../../hooks/querries/notes/useQNotes";
import BodyFiller from "../bodyFiller/BodyFiller";
import useAxios from "../../../hooks/axios/useAxios";
import RichTextTinyMce from "../richTextTinyMce/RichTextTinyMce";
import Note from "../note/Note";
import { useQNotificationSubscriptions } from "../../../hooks/querries/notificationSubscriptions/useQNotificationSubscriptions";
import { useHotkeys } from "react-hotkeys-hook";
const NotesPanel = ({ object, objectid, onClose }: TNotesPanelProps) => {
   useHotkeys("esc", onClose);

   const { aioAxios } = useAxios();

   const { refetch: qNofSubRefetch } = useQNotificationSubscriptions();

   const {
      data: qNotesData,
      isFetching: qNotesFetching,
      refetch: qNotesRefetch,
   } = useQNotes({ object, objectid, refetchOnMount: true });
   const { notes = [] } = qNotesData || {};
   const [content, setContent] = useState("");
   const [submitting, setSubmitting] = useState(false);
   const [showTiny, setShowTiny] = useState(false);

   const handleSubmit: TNotesPanel_handleSubmit = async (body) => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/notes`, body)
         .then(() => setContent(""))
         .catch((er) => console.log({ er }));
      qNofSubRefetch();
      qNotesRefetch();
      setSubmitting(false);
   };
   if (qNotesFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading style={{ height: "50vh" }} />
         </div>
      );
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <MainButton type="close" onClick={onClose} />
            <h1 className={`header-s`}>Notes</h1>
         </div>
         <div className={`${styles.notesC}`}>
            <div className={`${styles.notesTop}`}>
               {notes
                  .filter((n) => !n.parentid)
                  .map((n, i) => (
                     <Note
                        key={i}
                        object={object}
                        objectid={objectid}
                        note={n}
                        submitting={submitting}
                        onSubmit={handleSubmit}
                        subnotes={notes.filter(({ parentid }) => parentid === n.nid)}
                     />
                  ))}
            </div>
            <div className={`${styles.notesBot}`}>
               {showTiny ? (
                  <RichTextTinyMce
                     value={content}
                     setValue={setContent}
                     submitting={submitting}
                     onSubmit={() => handleSubmit({ content, object, objectid })}
                     showCloseBtn
                     onClose={() => setShowTiny(false)}
                  />
               ) : (
                  <MainButton title="Write Note" onClick={() => setShowTiny(true)} />
               )}
            </div>
         </div>
      </section>
   );
};
export type TNotesPanel_handleSubmit = (p: {
   content: string;
   object: string;
   objectid: string | number;
   parentid?: number;
}) => void;
export default NotesPanel;
type TNotesPanelProps = {
   onClose: () => void;
} & useQNotesParams;
