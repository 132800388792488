import { Link } from "react-router-dom";
import styles from "./Tools.module.scss";
import MainButton from "../../components/common/mainButton/MainButton";
import useAuthTabs from "../../hooks/auth0/useAuthTabs";
import Helm from "../../components/common/helm/Helm";
import { useAuth0 } from "@auth0/auth0-react";
const Tools = () => {
   const { authTabs } = useAuthTabs();
   const { user } = useAuth0();
   return (
      <div className={`${styles.contentC}`}>
         <Helm titles={["All Our Tools"]} />
         {authTabs.includes("simpler") && (
            <Link to={`/simpler?email=${user?.email}`}>
               <MainButton title="Simpler Widget (Stable)" />
            </Link>
         )}
         {authTabs.includes("uwub") && (
            <Link to={"/uwub/bulk"}>
               <MainButton title="Bulk Underwriting Calc (Stable)" />
            </Link>
         )}
         {authTabs.includes("uwu") && (
            <Link to={"/uwu"}>
               <MainButton title="New Underwriting Calc (Stable)" />
            </Link>
         )}
         {authTabs.includes("assets") && (
            <Link to={"/assets"}>
               <MainButton title="Assets Calculator (WIP)" />
            </Link>
         )}
         {authTabs.includes("underwritings") && (
            <Link to={"/underwritings"}>
               <MainButton title="Underwriting Calculator (Legacy)" />
            </Link>
         )}
         {authTabs.includes("properties") && (
            <Link to={"/properties"}>
               <MainButton title="Properties Calculator (Legacy)" />
            </Link>
         )}
      </div>
   );
};

export default Tools;
