import { TPg_Crm_Renovations_Schema } from "@simplyhomes/utils";
import MainButton from "../../../common/mainButton/MainButton";
import MainInput from "../../../common/mainInput/MainInput";
import styles from "./RenovationPreview.module.scss";
import { useQFieldsOptions } from "../../../../hooks/querries/fields/useQFieldsOptions";
import { useQRenovation } from "../../../../hooks/querries/renovations/useQRenovation";
import { useStateParams } from "../../../../hooks/react/useStateParams";
import { useState, useEffect } from "react";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import useAxios from "../../../../hooks/axios/useAxios";
const RenovationPreview = () => {
   const { aioAxios } = useAxios();
   const { getStateParams } = useStateParams();
   const [rid, setRid] = getStateParams("rid");
   const [submitting, setSubmitting] = useState(false);

   const { data: qRevData, isFetching: qRevFetching, refetch: qRevRefetch } = useQRenovation({ rid });
   const { renovation } = qRevData || {};
   const { data: qFieldData, isFetching: qFieldFetching } = useQFieldsOptions({ objects: ["renovations"] });
   const { options = [] } = qFieldData || {};
   const getOptions = (col: string) => options.find((o) => o.name === col)?.options || [];

   const [rev, setRev] = useState<TPg_Crm_Renovations_Schema | null>();
   useEffect(() => {
      if (renovation) setRev(renovation);
   }, [renovation]);
   if (!rev || qRevFetching || qFieldFetching || submitting)
      return (
         <div className={`${styles.revPopup}`}>
            <BodyFiller loading style={{ height: "50vh" }} />
         </div>
      );

   const handleSubmit = async () => {
      setSubmitting(true);
      await aioAxios.put(`/aio/renovations/${rid}`, { renovation: rev }).catch((er) => console.log({ er }));
      qRevRefetch();
      setSubmitting(false);
   };

   return (
      <article className={`${styles.revPopup}`}>
         <div className={`${styles.revPopupHeader}`}>
            <MainButton type="close" onClick={() => setRid("")} />
            <div>
               <span className={`header-s`}>{rev.address}</span>
               <MainButton title="Save" type="warn" confirmMsg="Override with current data?" onClick={handleSubmit} />
            </div>
         </div>
         <div className={`${styles.revPopupForm}`}>
            <MainInput
               title="Stage"
               type="dropdown"
               value={getOptions("hs_pipeline_stage").find((o) => o.value === rev.hs_pipeline_stage)?.label || ""}
               handleValue={(v) => setRev({ ...rev, hs_pipeline_stage: v?.split("|").pop() || "" })}
               hideSeparators
               options={getOptions("hs_pipeline_stage").map(({ label, value }) => `${label}|${value}`)}
            />
            <MainInput
               title="Status"
               value={rev.status_update || ""}
               handleValue={(v) => setRev({ ...rev, status_update: v })}
               disableCollapse
            />
            <MainInput
               title="Initial Walkthrough"
               type="date"
               value={rev.initial_walkthrough || ""}
               handleValue={(v) => setRev({ ...rev, initial_walkthrough: v })}
               disableCollapse
            />
            <MainInput
               title="Access Details"
               value={rev.access_details || ""}
               handleValue={(v) => setRev({ ...rev, access_details: v })}
               disableCollapse
            />
            <MainInput
               title="Deadline for Walkthrough & Reno Report"
               type="date"
               value={rev.deadline_for_walkthrough___reno_report || ""}
               handleValue={(v) => setRev({ ...rev, deadline_for_walkthrough___reno_report: v })}
               disableCollapse
            />
            <MainInput
               title="Closing Date"
               type="date"
               value={rev.closing_date || ""}
               handleValue={(v) => setRev({ ...rev, closing_date: v })}
               disableCollapse
            />
            <MainInput
               title="Pre-Reno Matterport"
               type="dropdown"
               value={rev.pre_reno_matterport || ""}
               handleValue={(v) => setRev({ ...rev, pre_reno_matterport: v })}
               options={getOptions("pre_reno_matterport").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="Scope Updated"
               type="dropdown"
               value={rev.scope_updated || ""}
               handleValue={(v) => setRev({ ...rev, scope_updated: v })}
               options={getOptions("scope_updated").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="Reno Commence"
               type="date"
               value={rev.reno_commence || ""}
               handleValue={(v) => setRev({ ...rev, reno_commence: v })}
               disableCollapse
            />
            <MainInput
               title="Est Reno CompleteReno Commence"
               type="date"
               value={rev.est_reno_complete || ""}
               handleValue={(v) => setRev({ ...rev, est_reno_complete: v })}
               disableCollapse
            />
            <MainInput
               title="Reno Budget"
               value={rev.reno_budget || ""}
               format="currency"
               handleValue={(v) => setRev({ ...rev, reno_budget: v })}
               disableCollapse
            />
            <MainInput
               title="Form of Reno Budget"
               type="dropdown"
               value={rev.form_of_reno_budget || ""}
               handleValue={(v) => setRev({ ...rev, form_of_reno_budget: v })}
               options={getOptions("form_of_reno_budget").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="Actual Reno"
               value={rev.actual_reno || ""}
               format="currency"
               handleValue={(v) => setRev({ ...rev, actual_reno: v })}
               disableCollapse
            />
            <MainInput
               title="Difference"
               value={rev.difference || ""}
               format="currency"
               handleValue={(v) => setRev({ ...rev, difference: v })}
               disableCollapse
            />
            <MainInput
               title="Escrow Opened"
               type="date"
               value={rev.escrow_opened || ""}
               handleValue={(v) => setRev({ ...rev, escrow_opened: v })}
               disableCollapse
            />
            <MainInput
               title="Dye Test (PA units)"
               value={rev.dye_test__pa_units_ || ""}
               handleValue={(v) => setRev({ ...rev, dye_test__pa_units_: v })}
               disableCollapse
            />
            <MainInput
               title="Cert of Occupancy"
               value={rev.cert_of_occupancy || ""}
               handleValue={(v) => setRev({ ...rev, cert_of_occupancy: v })}
               disableCollapse
            />
            <MainInput
               title="All utilities Transfered"
               type="dropdown"
               value={rev.all_utilities_transfered || ""}
               handleValue={(v) => setRev({ ...rev, all_utilities_transfered: v })}
               options={getOptions("all_utilities_transfered").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="Post-Reno Matterport"
               type="dropdown"
               value={rev.post_reno_matterport || ""}
               handleValue={(v) => setRev({ ...rev, post_reno_matterport: v })}
               options={getOptions("post_reno_matterport").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="Uploaded to AH.com"
               type="date"
               value={rev.uploaded_to_ah_com || ""}
               handleValue={(v) => setRev({ ...rev, uploaded_to_ah_com: v })}
               disableCollapse
            />
            <MainInput
               title="Tenant Selected"
               type="dropdown"
               value={rev.tenant_selected || ""}
               handleValue={(v) => setRev({ ...rev, tenant_selected: v })}
               options={getOptions("tenant_selected").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="S8 Inspection"
               value={rev.s8_inspection || ""}
               handleValue={(v) => setRev({ ...rev, s8_inspection: v })}
               disableCollapse
            />
            <MainInput
               title="S8 Passed/Failed"
               type="dropdown"
               value={rev.s8_passed_failed || ""}
               handleValue={(v) => setRev({ ...rev, s8_passed_failed: v })}
               options={getOptions("s8_passed_failed").map(({ value }) => value)}
               disableCollapse
            />
            <MainInput
               title="S8 Reinspection Date"
               type="date"
               value={rev.s8_reinspection_date || ""}
               handleValue={(v) => setRev({ ...rev, s8_reinspection_date: v })}
               disableCollapse
            />
            <MainInput
               title="Lease Commence Date"
               type="date"
               value={rev.lease_commence_date || ""}
               handleValue={(v) => setRev({ ...rev, lease_commence_date: v })}
               disableCollapse
            />

            <MainInput title="Notes" value={rev.notes || ""} handleValue={(v) => setRev({ ...rev, notes: v })} />
         </div>
      </article>
   );
};

export default RenovationPreview;
