import { SHrBodyFiller, SHrMainButton, SHrMainInput, SHrPopup } from "@simplyhomes/react";
import MainButton from "../../common/mainButton/MainButton";
import styles from "./AioUwsCreatePopup.module.scss";
import { useQAioProps } from "../../../hooks/querries/aio/properties/useQAioProps";
import { useEffect, useMemo, useState } from "react";
import { useQAioVersions } from "../../../hooks/querries/aio/versions/useQAioVersions";
import AioUwsAddProperty from "../AioUwsAddProperty/AioUwsAddProperty";
import { useQAioUwGenerateAppData } from "../../../hooks/querries/aio/underwritings/useQAioUwCreateInfo";
import { SHu_const_airtable_engLocalities_fieldIds, TAIO_Underwritings2_AppData } from "@simplyhomes/utils";
import useAxios from "../../../hooks/axios/useAxios";
import { useNavigate } from "react-router-dom";

const fid_typeSingle = SHu_const_airtable_engLocalities_fieldIds["Default Single-Unit Type"];
const fid_typeMulti = SHu_const_airtable_engLocalities_fieldIds["Default Multi-Unit Type"];

const AioUwsCreatePopup = ({ onClose, onSubmit }: TAioUwsCreatePopupProps) => {
   const { aioAxios } = useAxios();
   const nav = useNavigate();
   const qProps = useQAioProps({ options: { refetchOnMount: true } });

   const qVers = useQAioVersions({ options: { refetchOnMount: true } });

   const [address, setAddress] = useState("");
   const [appdata, setAppdata] = useState<TAIO_Underwritings2_AppData>();
   const { propFields, unitFields = [] } = appdata || {};
   const qUwInfo = useQAioUwGenerateAppData({ address, options: { onSuccess: (r) => setAppdata(r.appdata) } });

   const [version, setVersion] = useState("");
   const [submitting, setSubmitting] = useState(false);
   const [showPropCreatePopup, setShowPropCreatePopup] = useState(false);

   const propOptions = useMemo(
      () => qProps.data?.properties.map((p) => ({ value: p.address, label: `${p.address}` })),
      [qProps.data]
   );
   const verOptions = useMemo(
      () =>
         qVers.data?.versions
            .sort((a) => (a.official ? -1 : 1))
            .map((v) => ({ value: v.version, label: `${v.version} ${v.official ? "(Official)" : ""}` })),
      [qVers.data]
   );
   useEffect(() => {
      if (!version && qVers.data && qVers.data.versions.length)
         setVersion(qVers.data.versions.find((v) => v.official)?.version || qVers.data.versions[0].version);
   }, [qVers.data, version]);

   const isValid = !!address && !!version;

   const handleUnits = (mode: "add" | "remove") => {
      if (!appdata) return;
      const newAppdata = { ...appdata };
      if (mode === "add") newAppdata.unitFields.push(appdata.unitFields?.[0] || {});
      else if (mode === "remove") newAppdata.unitFields.pop();
      setAppdata(newAppdata);
   };
   const handleProp = (p: { key: string; value: any }) => {
      if (!appdata) return;
      const newAppdata = { ...appdata };
      newAppdata.propFields[p.key] = { value: p.value };
      setAppdata(newAppdata);
   };
   const handleUnit = (p: { i: number; key: string; value: any }) => {
      if (!appdata) return;
      const newAppdata = { ...appdata };
      newAppdata.unitFields = newAppdata.unitFields.map((u, i) =>
         i === p.i ? { ...u, [p.key]: { value: p.value } } : u
      );
      setAppdata(newAppdata);
   };

   const handleSubmit = async () => {
      setSubmitting(true);
      if (!appdata) return;
      const uid = await aioAxios
         .post(`/aio/v2/underwritings/`, { appdata, address, version })
         .then((r) => r.data?.uid)
         .catch((e) => console.log({ e }));
      if (uid) nav(uid);
      onSubmit();
      setSubmitting(false);
   };
   const loadingMsg = qProps.isLoading
      ? "Loading properties..."
      : qVers.isLoading
      ? "Loading versions..."
      : qUwInfo.isFetching
      ? "Loading underwriting info..."
      : submitting
      ? "Calculating..."
      : null;
   if (loadingMsg)
      return (
         <div className={`${styles.contentC}`} id="loading">
            <SHrBodyFiller style={{ height: "10rem" }} message={loadingMsg} />
         </div>
      );
   return (
      <div className={`${styles.contentC}`}>
         <SHrPopup show={showPropCreatePopup} handleShow={setShowPropCreatePopup}>
            <AioUwsAddProperty onClose={() => setShowPropCreatePopup(false)} />
         </SHrPopup>

         <div className={`${styles.headerC}`}>
            <h1 className={`cardHeader-s`}>Create new underwriting</h1>
            <MainButton type="close" onClick={onClose} />
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.bodyRow}`}>
               <SHrMainInput
                  type="search"
                  tooltip="Not finding your address? Add it using 'More' button"
                  title="Choose Property*"
                  value={address}
                  onChange={setAddress}
                  options={propOptions}
                  loading={qProps.isFetching}
                  callbackOptions={[{ label: "Add new Property?", cb: () => setShowPropCreatePopup(true) }]}
                  bad={!address}
                  hideOptionValues
               />
               <SHrMainInput
                  value={version}
                  onChange={setVersion}
                  type="search"
                  options={verOptions}
                  title="Version*"
                  loading={qVers.isFetching}
                  containerProps={{ style: { width: "14rem" } }}
                  bad={!version}
               />
            </div>
            {appdata && (
               <div className={`${styles.infoC}`}>
                  <div className={`${styles.propC}`}>
                     <SHrMainInput
                        type="dropdown"
                        title="Property Type*"
                        value={
                           propFields?.$unit_type?.value ||
                           (unitFields?.length > 1
                              ? qUwInfo.data?.localityDefault[fid_typeMulti]
                              : qUwInfo.data?.localityDefault[fid_typeSingle])
                        }
                        onChange={(v: string) => handleProp({ key: "$unit_type", value: v })}
                        options={qUwInfo.data?.unitTypes.map((str) => ({ value: str, label: str }))}
                        hideOptionValues
                     />
                  </div>
                  <div className={`${styles.unitsC}`}>
                     <ul className={`${styles.units}`}>
                        {unitFields.map((u, i) => (
                           <li key={i} className={`${styles.unit}`}>
                              <div className={`${styles.unitH} cardHeader-s`}>Unit #{i + 1}</div>
                              <div className={`${styles.unitInputs}`}>
                                 <SHrMainInput
                                    title="Bedrooms"
                                    type="text"
                                    value={u.$bedrooms?.value ?? u.$at_bedrooms?.value ?? u.$aio_bedrooms?.value ?? 0}
                                    onChange={(n: string) => handleUnit({ i, key: "$bedrooms", value: Number(n) || 0 })}
                                 />
                                 <SHrMainInput
                                    title="Bathrooms"
                                    type="text"
                                    value={
                                       u.$bathrooms?.value ?? u.$at_bathrooms?.value ?? u.$aio_bathrooms?.value ?? 0
                                    }
                                    onChange={(n: string) =>
                                       handleUnit({ i, key: "$bathrooms", value: Number(n) || 0 })
                                    }
                                 />
                              </div>
                           </li>
                        ))}
                     </ul>
                  </div>
               </div>
            )}
         </div>
         <div className={`${styles.controlsC}`}>
            <SHrMainButton title="Submit" enable={isValid} onClick={handleSubmit} />
            {appdata && (
               <div className={`${styles.controlsL}`}>
                  <SHrMainButton title="Remove unit" onClick={() => handleUnits("remove")} type="secondary" />
                  <SHrMainButton title="Add unit" onClick={() => handleUnits("add")} type="secondary" />
               </div>
            )}
         </div>
      </div>
   );
};

export default AioUwsCreatePopup;
type TAioUwsCreatePopupProps = {
   onClose: () => void;
   onSubmit: () => void;
};
