import { useState } from "react";
import { TUwData } from "../../../../../types/hsObjects";
import useQVersion from "../../../../../hooks/querries/versions/useQVersion";
import parseFieldName from "../../../../../utils/fields/parseFieldName";
import useTranslateFormula from "../../../../../hooks/formulas/useTranslateFormula";
import { TUnknownObject } from "../../../../../types/interfaces/unknownObj";
import { parseDecimal } from "../../../../../utils/MATH/parseDecimal";
import MainInput from "../../../../common/mainInput/MainInput";
const UwField = ({ fieldId, showValueOnly, childIndex, uw = {}, setUw, ver, uwOptions = {} }: TUwFieldProps) => {
   const [isFocused, setIsFocused] = useState(false);

   const { data: versionData } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [], units = [] } = versionData?.config || {};

   const { $childUnits = [] } = uw;

   const currField = fields.find(({ id }) => id === fieldId);
   const {
      formula = "",
      name,
      column: baseCol,
      format,
      readonly,
      required,
      type,
      condFormat,
      unitBehavior = "NONE",
      id: fId = "",
   } = currField || {};
   const debug = name === "Cooking Costz";
   // console.log({ uw,name });
   const column = baseCol || parseFieldName(name);
   // const value: any = ''
   // const options: any = ''

   const { value: translatedFormula } = useTranslateFormula({ formula, ver, obj: "underwritings" });
   if (typeof uw === "function") return null;
   // if (childIndex != null) console.log({ fieldValue });
   if (!versionData?.config) return <div>could not find ver</div>;
   if (!currField) return <div>Field {fieldId} Does Not Exist</div>;

   const fieldsInUnits = units.map(({ children }) => children).flat();
   const amIaChild = childIndex != null;
   const amIaMother = !amIaChild && fieldsInUnits.includes(fId) && $childUnits.length > 0;

   const handleVal = (v: number | string | boolean) => {
      if (readonly) return;
      if (!column) return;
      const val =
         typeof v == "boolean"
            ? v
            : format === "Currency"
            ? Number(
                 v.toString().includes("e-") || v.toString().includes("e+")
                    ? Number(v).toFixed(0)
                    : v.toString().replace(/[^0-9.-]+/g, "")
              )
            : format === "Percentage"
            ? parseDecimal(v.toLocaleString())
            : v;
      if (childIndex != null) {
         const newUnit = { ...$childUnits[childIndex], [column]: val };
         const newChildUnits = $childUnits.map((unit, index) => (index === childIndex ? newUnit : unit));
         return setUw({ ...uw, $childUnits: newChildUnits });
      }
      return setUw({ ...uw, [column]: val });
   };
   const value = childIndex == null ? uw?.[column] : $childUnits?.[childIndex]?.[column];
   const options = uwOptions[column] || "";
   if (debug) console.log({ value, childIndex, uw });
   const formattedVal =
      format === "Currency"
         ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)
         : format === "Percentage"
         ? new Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
           }).format(value)
         : value;
   const formatConditions: TUnknownObject | undefined = condFormat
      ?.split(",")
      .reduce((prev, curr) => ({ ...prev, [curr.split("=")[0]]: curr.split("=")[1] }), {});
   const titleBehavior = amIaMother && unitBehavior !== "NONE" ? `[${unitBehavior}]` : "";
   const optionsSplit = options === "failed" ? ["[Not Found]"] : options.split("|").filter(Boolean);
   // if (name === "Which Uses Gas") console.log({ value });
   return (
      <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
         <MainInput
            style={{ backgroundColor: formatConditions?.[value] }}
            type={(!type || type === "text") && options ? "dropdown" : type}
            options={optionsSplit}
            title={showValueOnly ? undefined : `${name}${required ? "*" : ""}${titleBehavior}`}
            value={isFocused ? value || "" : formattedVal || ""}
            handleValue={(v) => handleVal(v)}
            readonly={(typeof readonly === "boolean" && readonly) || (amIaMother && unitBehavior !== "NONE")}
            bad={
               (required && (value === "" || value == null)) ||
               (value !== "" && !!options && !value?.split("|").every((str: string) => optionsSplit.includes(str)))
            }
            loading={options === "pending"}
            useInternal
            tooltip={
               amIaMother && unitBehavior !== "NONE"
                  ? { type: "info", msg: `This is the ${unitBehavior} of units' ${name} fields` }
                  : translatedFormula.includes("{")
                  ? { type: "info", msg: translatedFormula }
                  : undefined
            }
            key={name}
         />
      </div>
   );
};

export default UwField;
type TUwFieldProps = {
   uw: TUwData;
   setUw: React.Dispatch<React.SetStateAction<TUwData>>;
   uwOptions: TUnknownObject;
   ver: string;
   fieldId: string;
   childIndex?: number;
   showValueOnly?: boolean;
};
