import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Crm_Assets_Schema, TPg_Crm_Properties_Schema, TPg_Utils_FilterNode } from "@simplyhomes/utils";
import { useDebounce } from "@uidotdev/usehooks";

export const useQAssets = (p?: TUserAssets) => {
   const { aioAxios } = useAxios();
   const { params } = p || {};

   const debSearch = useDebounce(params?.search, 2000);
   return useQuery({
      queryKey: ["aio", "Assets", { ...params, search: debSearch }],
      queryFn: () =>
         aioAxios
            .get("/aio/assets", { params })
            .then<{ assets: TUseQAssets_asset[]; total: number }>(({ data }) => data),
   });
};
type PrefixedAsset<T> = {
   [K in keyof T as `assets.${string & K}`]: T[K];
};
type PrefixedProp<T> = {
   [K in keyof T as `properties.${string & K}`]: T[K];
};
export type TUseQAssets_asset = {
   option_label: string;
   property_object_id: string | null;
   "xero.sum_total": number;
   "xero.sum_due": number;
} & PrefixedAsset<Partial<TPg_Crm_Assets_Schema>> &
   PrefixedProp<Partial<TPg_Crm_Properties_Schema>>;

type TUserAssets = {
   params?: {
      search?: string;
      sortBy?: string;
      sortDirection?: 1 | -1;
      columns?: string[];
      pageLimit?: number;
      filters?: TPg_Utils_FilterNode[][];
   };
};
