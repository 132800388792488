import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import useQVersion from "../../../../hooks/querries/versions/useQVersion";
import parseFieldName from "../../../../utils/fields/parseFieldName";
import useTranslateFormula from "../../../../hooks/formulas/useTranslateFormula";
import { parseDecimal } from "../../../../utils/MATH/parseDecimal";
import MainInput from "../../../common/mainInput/MainInput";
import { TUnknownObject } from "../../../../types/interfaces/unknownObj";
import { TUwData } from "../../../../types/hsObjects";
const UwUbField = ({ version, fieldId, curOptions, curUw, handleUw, unitIndex, loading }: TUwubFieldProps) => {
   const [isFocused, setIsFocused] = useState(false);
   const [params] = useSearchParams();
   const ver = version || params.get("ver") || "";

   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [] } = qVerData?.config || {};

   const { $childUnits = [] } = curUw || {};
   const amIaChild = unitIndex != null;

   const currField = fields.find(({ id }) => id === fieldId);
   const {
      formula = "",
      name,
      column: baseCol,
      format,
      readonly,
      required,
      type,
      condFormat,
      options: optionsString,
   } = currField || {};
   const column = baseCol || parseFieldName(name);
   const value = amIaChild ? $childUnits[unitIndex]?.[column] : curUw[column];
   const options = optionsString ? curOptions[optionsString] : "";
   const { value: translatedFormula } = useTranslateFormula({ formula, ver, obj: "underwritings" });

   if (!qVerData?.config) return <div>could not find ver</div>;
   if (!currField) return <div>Field {fieldId} Does Not Exist</div>;

   const handleVal = async (v: number | string | boolean) => {
      if (readonly) return;
      if (!column) return;
      const val =
         typeof v == "boolean"
            ? v
            : format === "Currency"
            ? Number(
                 v.toString().includes("e-") || v.toString().includes("e+")
                    ? Number(v).toFixed(0)
                    : v.toString().replace(/[^0-9.-]+/g, "")
              )
            : format === "Percentage"
            ? parseDecimal(v.toLocaleString())
            : v;

      if (unitIndex != null) {
         const newUnit = { ...$childUnits[unitIndex], [column]: val };
         const newChildUnits = $childUnits.map((unit, index) => (index === unitIndex ? newUnit : unit));
         return handleUw({ ...curUw, $childUnits: newChildUnits });
      }

      const newUw = { ...curUw, [column]: val };
      return handleUw(newUw);
   };
   const formattedVal =
      format === "Currency"
         ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)
         : format === "Percentage"
         ? new Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
           }).format(value)
         : value;
   const formatConditions: TUnknownObject | undefined = condFormat
      ?.split(",")
      .reduce((prev, curr) => ({ ...prev, [curr.split("=")[0]]: curr.split("=")[1] }), {});
   const optionsSplit = !options ? [""] : options === "failed" ? ["[Not Found]"] : options.split("|").filter(Boolean);
   return (
      <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}>
         <MainInput
            style={{ backgroundColor: formatConditions?.[value] }}
            type={(!type || type === "text") && options ? "dropdown" : type}
            options={optionsSplit}
            title={`${name}${required ? "*" : ""}`}
            value={isFocused ? value || "" : formattedVal || ""}
            handleValue={(v) => handleVal(v)}
            readonly={typeof readonly === "boolean" && readonly}
            bad={
               (required && (value === "" || value == null)) ||
               (value !== "" && !!options && !value?.split("|").every((str: string) => optionsSplit.includes(str)))
            }
            loading={loading}
            useInternal
            tooltip={translatedFormula.includes("{") ? { type: "info", msg: translatedFormula } : undefined}
            key={name}
         />
      </div>
   );
};

export default UwUbField;
type TUwubFieldProps = {
   fieldId: string;
   curUw: TUwData;
   curOptions: TUnknownObject;
   handleUw: (uw: TUwData) => void;
   unitIndex?: number;
   version?: string;
   loading?: boolean;
};
