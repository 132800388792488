import { useState } from "react";
import { TUseQAssets_asset } from "../../../../hooks/querries/asssets/useQAssets";
import styles from "./AssetCard.module.scss";
import { cfAIO } from "../../../../utils/s3/cfAIO";
import { Link } from "react-router-dom";
import {
   SHu_aio_assets_baseFields,
   SHu_js_currencyFormat,
   TPg_Aio_AppViews_Schema,
   TPg_Crm_Assets_Schema,
} from "@simplyhomes/utils";
import { assetGetStageColor } from "../../../../utils/color/assetGetStageColor";
import { useQFields } from "../../../../hooks/querries/fields/useQFields";
const AssetCard = ({ asset, view }: TAssetProps) => {
   const { data: qFieldsData } = useQFields({ tables: ["assets", "properties"], custom: ["xero"] });
   const { fields = [] } = qFieldsData || {};

   const parsedPhotos = asset["assets.photos"] ? asset["assets.photos"].split("|") : null;
   const [imgIndex, setImgIndex] = useState(0);
   const reelTransform = `translateX(${-imgIndex * 100}%)`;
   const photosTbShown = parsedPhotos?.slice(0, imgIndex > 3 ? parsedPhotos.length : 6) || [];
   const baseCols = [...SHu_aio_assets_baseFields];

   const getFieldLabel = (name: string) => fields.find((f) => f.name === name.split(".").pop())?.label || name;
   const getFieldValue = ({ col, asset = {} }: { asset?: Record<string, any>; col: string }) => {
      if (!(col in asset)) return "!";
      const value = asset[col as keyof TPg_Crm_Assets_Schema];
      return value || "-";
   };
   return (
      <div className={`${styles.contentC}`}>
         <div
            className={`${styles.strip}`}
            style={{ backgroundColor: assetGetStageColor(asset["option_label"]) }}
         ></div>
         <div className={`${styles.imagesC}`}>
            <div className={`${styles.images}`} style={{ transform: reelTransform }}>
               <div className={`${styles.image} `}>
                  <img src={cfAIO(`/properties/street-view/${asset["property_object_id"]}`)} alt={"street-view"} />
               </div>
               {photosTbShown.map((photoId, key) => (
                  <div className={`${styles.image} `} key={key + photoId}>
                     <img src={cfAIO(`/assets/photos/${photoId}`)} alt={photoId} />
                  </div>
               ))}
            </div>
            {photosTbShown.length > 0 && (
               <>
                  <button
                     className={`${styles.btnL}`}
                     onClick={(e) => {
                        e.stopPropagation();
                        setImgIndex(imgIndex === 0 ? parsedPhotos?.length || 0 : imgIndex - 1);
                     }}
                  >
                     {"<"}
                  </button>
                  <button
                     className={`${styles.btnR}`}
                     onClick={(e) => {
                        e.stopPropagation();
                        setImgIndex(imgIndex === parsedPhotos?.length || 0 ? 0 : imgIndex + 1);
                     }}
                  >
                     {">"}
                  </button>
               </>
            )}
            {imgIndex > 0 && (
               <div className={`${styles.imgIndex}`}>
                  {imgIndex + 1}/{(parsedPhotos?.length || 0) + 1}
               </div>
            )}
            <div className={`${styles.mpC}`}>
               {asset["properties.matterport_scan_link"] ? (
                  <a
                     href={asset["properties.matterport_scan_link"]}
                     target="_blank"
                     rel="noreferrer"
                     title="Matterport"
                  >
                     <img src={cfAIO(`/icons/assets/card/matterport.svg`)} alt="" />
                  </a>
               ) : (
                  <div title="Matterport">
                     <img src={cfAIO(`/icons/assets/card/matterport.svg`)} alt="" />
                  </div>
               )}
               {asset["properties.matterport_scan_link__at_acquisition_"] ? (
                  <a
                     href={asset["properties.matterport_scan_link__at_acquisition_"]}
                     target="_blank"
                     rel="noreferrer"
                     title="Acquisition"
                  >
                     <img src={cfAIO(`/icons/assets/card/matterport-acq.svg`)} alt="" />
                  </a>
               ) : (
                  <div title="Acquisition">
                     <img src={cfAIO(`/icons/assets/card/matterport-acq.svg`)} alt="" />
                  </div>
               )}
               {asset["properties.matterport_scan_link__post_renovation_"] ? (
                  <a
                     href={asset["properties.matterport_scan_link__post_renovation_"]}
                     target="_blank"
                     rel="noreferrer"
                     title="Renovation"
                  >
                     <img src={cfAIO(`/icons/assets/card/matterport-ren.svg`)} alt="" />
                  </a>
               ) : (
                  <div title="Renovation">
                     <img src={cfAIO(`/icons/assets/card/matterport-ren.svg`)} alt="" />
                  </div>
               )}
            </div>
            <div className={`${styles.stage}`} style={{ backgroundColor: assetGetStageColor(asset.option_label) }}>
               {asset.option_label}
            </div>
         </div>
         <Link to={`/assets/${asset["assets.hs_object_id"]}`}>
            <div className={`${styles.cardPart1}`}>
               <p className={`${styles.price}`}>
                  {asset["properties.contract_price"] ? SHu_js_currencyFormat(asset["properties.contract_price"]) : "-"}
               </p>
               <p className={`${styles.address}`}>{asset["assets.address"]}</p>
            </div>
            <div className={`${styles.cardPart2}`}>
               <div>
                  <p>{asset["properties.bedrooms"] || "-"} Beds</p>
               </div>
               <div>
                  <p>{asset["properties.bathrooms"] || "-"} Baths</p>
               </div>
               <div>
                  <p>{asset["properties.sqft"] || "-"} sqft</p>
               </div>
            </div>
            <ul className={`${styles.cardPart3}`}>
               {view?.order
                  ?.filter((col) => !baseCols.includes(col as (typeof SHu_aio_assets_baseFields)[number]))
                  .map((col, key) => (
                     <li key={key + col}>
                        <span>{getFieldLabel(col)}: </span>
                        <b>{getFieldValue({ asset, col })}</b>
                     </li>
                  ))}
            </ul>
         </Link>
      </div>
   );
};

export default AssetCard;
type TAssetProps = {
   asset: TUseQAssets_asset;
   view?: Partial<TPg_Aio_AppViews_Schema>;
};
