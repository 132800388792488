import { useEffect, useState } from "react";
import useAxios from "../../../../hooks/axios/useAxios";
import MainButton from "../../../common/mainButton/MainButton";
import MainInput from "../../../common/mainInput/MainInput";
import styles from "./AccountSettings.module.scss";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import useQUser from "../../../../hooks/querries/users/useQUser";
const AccountSettings = () => {
   const { aioAxios } = useAxios();

   const { data: qUserData, isFetching: qUserFetching, refetch: qUserRefetch } = useQUser();
   const { first_name, last_name } = qUserData || {};

   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [submitting, setSubmitting] = useState(false);

   useEffect(() => {
      if (first_name) setFirstName(first_name);
      if (last_name) setLastName(last_name);
   }, [first_name, last_name]);

   const handleSubmit = async () => {
      setSubmitting(true);
      await aioAxios.post(`/aio/users/me`, { firstName, lastName }).catch((err) => console.log({ err }));
      qUserRefetch();
      setSubmitting(false);
   };
   if (qUserFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );

   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.contentpanelC}`}>
            <div className={`cardHeader-s ${styles.header}`}>PROFILE INFO</div>
            <div className={`${styles.userName}`}>
               <div className={`${styles.firstName}`}>
                  <MainInput
                     disableCollapse
                     title="Your First Name"
                     type="text"
                     value={firstName}
                     handleValue={setFirstName}
                  />
               </div>
               <div className={`${styles.lastName}`}>
                  <MainInput
                     disableCollapse
                     title="Your Last Name"
                     type="text"
                     value={lastName}
                     handleValue={setLastName}
                  />
               </div>
            </div>
            <div className={`${styles.submitButton}`}>
               {submitting ? (
                  <BodyFiller loading={true} style={{ height: "2rem", padding: "0.2rem" }} />
               ) : (
                  <MainButton enable={!!firstName && !!lastName} title="UPDATE PROFILE" onClick={handleSubmit} />
               )}
            </div>
         </div>
      </article>
   );
};

export default AccountSettings;
type TAccountSettingsProps = {};
