import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Crm_Underwritings_Schema } from "@simplyhomes/utils";

const useQUw = ({ enabled, uwsids, onSuccess }: TUseQUwParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: enabled != null ? enabled : uwsids.length > 0,
      queryKey: ["useQUw", uwsids],
      queryFn: () =>
         aioAxios.get(`/aio/underwritings/data`, { params: { uwsids } }).then<TAxiosReps>(({ data }) => data),
      onSuccess,
   });
};
export default useQUw;
type TUseQUwParams = {
   enabled?: boolean;
   uwsids: string[];
   onSuccess?: (data: TAxiosReps | undefined) => void;
};

const columns = [
   "property_address_one_line_",
   "name",
   "aio_original_data",
   "aio_uw_data",
   "aio_original_created_by",
   "aio_original_version",
   "aio_original_date",
   "aio_uw_saved_by",
   "aio_uw_version",
   "aio_uw_date",
   "simply_object_id",
   "hs_object_id",
   "hs_pipeline",
   "hs_pipeline_stage",
] as const;

type TAxiosReps = {
   underwritings: (Pick<TPg_Crm_Underwritings_Schema, (typeof columns)[number]> & {
      property_object_id: number | null;
   })[];
};
