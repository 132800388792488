import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppUnderwritingsBulk_Schema } from "@simplyhomes/utils";
import { TUwData } from "../../../types/hsObjects";

export const useQUwBulk = ({ bid, options }: TUseQUwBulkParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...options,
      queryKey: ["useQUwBulk"],
      enabled: !!bid,
      queryFn: () => aioAxios.get(`/aio/underwritings/bulk/${bid}`).then<TAxiosResp>(({ data }) => data),
   });
};

type TUseQUwBulkParams = {
   bid: string | number;
   options?: UseQueryOptions<TAxiosResp, unknown, TAxiosResp, string[]>;
};
const cols = [
   "bid",
   "addresses",
   "version",
   "progress",
   "created_at",
   "updated_at",
   "created_by",
   "updated_by",
   "name",
   "description",
] as const;
type TAxiosResp = {
   bulk: Pick<TPg_Aio_AppUnderwritingsBulk_Schema, (typeof cols)[number]> & {
      uws: { uw: TUwData; options: Record<string, any> }[];
      cache: Record<string, any>;
   };
};
