import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { useAuth0 } from "@auth0/auth0-react";
import {
   TAIO_notification_event,
   TPg_Aio_AppNotes_Prefix_Helper,
   TPg_Aio_AppNotes_Schema,
   TPg_Aio_AppNotifications_Prefix_Helper,
   TPg_Aio_AppNotifications_Schema,
} from "@simplyhomes/utils";

export const useQNotifications = (params?: TUseQNotifications) => {
   const { refetchOnMount = false, desc, seen } = params || {};
   const { aioAxios } = useAxios();
   const { user } = useAuth0();
   return useQuery({
      queryKey: ["useQNotifications", user, desc, seen],
      queryFn: () =>
         aioAxios.get(`/aio/notifications`, { params: { desc, seen } }).then<TAxiosResp>(({ data }) => data),
      // refetchInterval: 1000 * 60,
      refetchOnMount,
   });
};

const nofCols = ["event", "nid", "seen", "subjectid", "objectid", "time"] as const;
const noteCols = ["nid", "author", "content", "object", "objectid", "time"] as const;

export type TQNotifications_Notification = TPg_Aio_AppNotifications_Prefix_Helper<
   Pick<TPg_Aio_AppNotifications_Schema, (typeof nofCols)[number]> & { event: TAIO_notification_event }
> &
   Partial<TPg_Aio_AppNotes_Prefix_Helper<Pick<TPg_Aio_AppNotes_Schema, (typeof noteCols)[number]>>>;
type TAxiosResp = {
   notifications: TQNotifications_Notification[];
};
type TUseQNotifications = {
   refetchOnMount?: boolean;
   seen?: boolean | null;
   desc?: boolean;
};
