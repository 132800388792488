import { useQuery } from "react-query";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import useAxios from "../../axios/useAxios";

const useQProperties = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: "useProperties",
      queryFn: () => aioAxios.get("/aio/properties").then<IQueryPropObjs>(({ data }) => data),
      retryDelay: 5000,
   });
};

export default useQProperties;
interface IQueryPropObjs {
   properties?: Iproperty[];
}
export interface Iproperty extends TUnknownObject {
   hs_object_id: string;
   property_address_one_line_: string;
   coordinates?: string;
   hs_pipeline_stage?: string;
   bedrooms?: string;
   bathrooms?: string;
   closed_rejected_reason?: string;
   $last_saved: number;
   $saved_by: string;
   $version: string;
   $childUnits: TUnknownObject[];
}
