import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import SmallSpinner from "../smallSpinner/SmallSpinner";
import Tooltip, { ITooltip } from "../tooltip/Tooltip";
import { HexColorPicker } from "react-colorful";
import styles from "./AFInput.module.scss";
import Popup from "../popup/Popup";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";
const AFInput = forwardRef(
   (
      {
         type = "text",
         title,
         value = "",
         handleChange = () => {},
         boolVal,
         handleBool = () => {},
         arrVal = [],
         handleArrVal = () => {},
         tooltip,
         options = [],
         style,
         readonly,
         loading,
         bad,
         useInternal,
      }: TAFInputParams,
      ref: ForwardedRef<HTMLInputElement>
   ) => {
      const [focused, setFocused] = useState(false);
      const [showPopup, setShowPopup] = useState(false);

      if (Number.isNaN(value)) value = (value || "").toString();
      const [internalVal, setInternalVal] = useState("");
      useEffect(() => setInternalVal(value), [value]);

      const setDdVal = (str: string) => {
         if (readonly) return;
         if (type === "multi") {
            return arrVal.includes(str)
               ? handleArrVal(arrVal.filter((val) => val !== str))
               : handleArrVal([...arrVal, str]);
         }
         setFocused(false);
         handleChange(str);
      };
      const setStrVal = (v: string) => {
         if (readonly) return;
         handleChange(v);
      };
      const setBoolVal = (b: boolean) => {
         if (readonly) return;
         handleBool(b);
      };
      const setArrVal = (ar: string[]) => {
         if (readonly) return;
         handleArrVal(ar);
      };

      const filteredOptions = useMemo(
         () =>
            options.filter((str) => (type === "search" ? str.toLowerCase().includes(internalVal.toLowerCase()) : true)),
         [internalVal, options, type]
      );

      const hasOptions = options.filter(Boolean).length > 0;

      return (
         <label
            className={`${
               boolVal == null &&
               type !== "multi" &&
               type !== "checkbox" &&
               arrVal.length === 0 &&
               value === "" &&
               !focused &&
               styles.hideInput
            }
            ${type === "checkbox" && styles.checkbox}
            ${styles.contentC} ${bad && styles.bad}`}
            style={style}
            onMouseEnter={() => setFocused(true)}
            onMouseLeave={() => setFocused(false)}
            onFocus={() => setFocused(true)}
            onBlur={() => !hasOptions && setFocused(false)}
         >
            <div className={`${styles.left}`}>
               <div className={`${styles.titleC}`} title={title}>
                  <span className={`${styles.title} ${type === "checkbox" && boolVal && styles.checked}`}>{title}</span>
                  {loading && <SmallSpinner style={{ width: "1rem" }} />}
                  {readonly && (
                     <img
                        src={s3afBucketURI("/icons/lock.svg")}
                        alt=""
                        className={`${styles.lock} ${focused && styles.lockFocused}`}
                     />
                  )}
               </div>
               <div className={`${styles.inputC}`}>
                  {type === "dropdown" ? (
                     <div className={`${styles.dropdownVal}`} onClick={() => setStrVal("")}>
                        {value}
                     </div>
                  ) : type === "multi" ? (
                     <div className={`${styles.dropdownVal}`} onClick={() => setFocused(!focused)}>
                        {arrVal.length} selected
                     </div>
                  ) : type === "checkbox" ? (
                     <>
                        <input type="checkbox" checked={boolVal || false} onChange={() => setBoolVal(!boolVal)} />
                        <span className={`${styles.checkmark}`}></span>
                     </>
                  ) : type === "textarea" ? (
                     <textarea value={value} onChange={(v) => setStrVal(v.target.value)} />
                  ) : type === "date" ? (
                     <input
                        type="date"
                        value={value?.split("T")[0] || ""}
                        onChange={(v) => setStrVal(v.target.value)}
                        className={`${styles.input}`}
                        title={value}
                        ref={ref}
                     />
                  ) : type === "color" ? (
                     <div className={`${styles.colorC}`}>
                        {showPopup && (
                           <Popup onClose={() => setShowPopup(false)}>
                              <HexColorPicker color={value} onChange={setStrVal} />
                           </Popup>
                        )}
                        <div
                           onClick={() => setShowPopup(true)}
                           style={{ backgroundColor: value || "var(--text-1)" }}
                           className={`${styles.colorPreview}`}
                        ></div>
                        <input
                           type="text"
                           value={value}
                           onChange={(e) => setStrVal(e.target.value)}
                           className={`${styles.input}`}
                           title={value}
                        />
                     </div>
                  ) : type === "search" ? (
                     <input
                        type="text"
                        value={internalVal}
                        onChange={(e) => setInternalVal(e.target.value)}
                        className={`${styles.input}`}
                        title={value}
                        ref={ref}
                     />
                  ) : (
                     <input
                        type="text"
                        value={useInternal ? internalVal : value}
                        onChange={(e) => (useInternal ? setInternalVal(e.target.value) : setStrVal(e.target.value))}
                        className={`${styles.input}`}
                        onBlur={() => (useInternal ? setStrVal(internalVal) : {})}
                        title={value}
                        ref={ref}
                     />
                  )}
                  {focused && hasOptions && (
                     <div className={`${styles.dropdownOptions}`}>
                        {type === "multi" ? (
                           <>
                              <div onClick={() => setArrVal(options)}>Add All</div>
                              <div onClick={() => setArrVal([])}>Clear</div>
                           </>
                        ) : (
                           <div onClick={() => setDdVal("")}>Clear</div>
                        )}
                        {filteredOptions.map((str, key) => (
                           <div key={key} onClick={() => setDdVal(str)}>
                              <span className={`ellipsis`}>{str}</span>
                              {((type === "multi" && arrVal.includes(str)) ||
                                 ((type === "dropdown" || type === "search") && value === str)) && (
                                 <img
                                    className={`${styles.checkbox}`}
                                    src={s3afBucketURI("/icons/checkbox.svg")}
                                    alt=""
                                 />
                              )}
                           </div>
                        ))}
                     </div>
                  )}
               </div>
            </div>
            <div className={`${styles.right}`}>
               {tooltip && <Tooltip {...tooltip} />}
               {(type === "dropdown" || type === "multi" || type === "search") && (
                  <img
                     src={s3afBucketURI("/icons/dd_arrow.svg")}
                     alt=""
                     className={`${styles.ddArrow} ${focused && styles.ddArrowRotate}`}
                  />
               )}
               {type === "search" && <img src={s3afBucketURI("/icons/search.svg")} alt="" className={`${styles.lens}`} />}
            </div>
         </label>
      );
   }
);

export default AFInput;
type TAFInputParams = {
   title?: string;
   value?: string;
   handleChange?: (v: string) => void;
   boolVal?: boolean;
   handleBool?: (b: boolean) => void;
   arrVal?: string[];
   handleArrVal?: (v: string[]) => void;
   validator?: boolean;
   type?: TAFInputType;
   readonly?: boolean;
   bad?: boolean;
   options?: string[];
   tooltip?: ITooltip;
   children?: React.ReactNode;
   style?: React.CSSProperties;
   loading?: boolean;
   useInternal?: boolean;
};
export type TAFInputType =
   | "text"
   | "dropdown"
   | "checkbox"
   | "number"
   | "textarea"
   | "search"
   | "date"
   | "multi"
   | "color";
