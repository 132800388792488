import { useEffect, useState } from "react";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import useQUws from "../../../hooks/querries/underwritings/useQUws";
import styles from "./UwUBrowser.module.scss";
import MainInput from "../../../components/common/mainInput/MainInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainButton from "../../../components/common/mainButton/MainButton";
import Popup from "../../../components/common/popup/Popup";
import CreateUwPopup from "../../../components/children/underwritings/createUwPopup/CreateUwPopup";
import { useQFields } from "../../../hooks/querries/fields/useQFields";
import ViewConfig from "../../../components/common/viewConfig/ViewConfig";
import { useQViews } from "../../../hooks/querries/views/useQViews";
import { SHu_js_swapTwoElementsInArray, TPg_Aio_AppViews_Schema } from "@simplyhomes/utils";
import { useQView } from "../../../hooks/querries/views/useQView";
import FiltersConfig from "../../../components/children/underwritings/filtersConfig/FiltersConfig";
import { useAuth0 } from "@auth0/auth0-react";
import UwSCard from "../../../components/children/underwritings/uwsCard/UwSCard";
import TableV2, { TTableV2_OnHeaderDrop, TTableV2_onCellClickParams } from "../../../components/common/tableV2/TableV2";
import useQVersions from "../../../hooks/querries/versions/useQVersions";
import SmallSpinner from "../../../components/common/smallSpinner/SmallSpinner";
import useAxios from "../../../hooks/axios/useAxios";
import { cfAIO } from "../../../utils/s3/cfAIO";
import useDebounce from "../../../hooks/react/useDebounce";
import UwUCompare from "../../../components/common/uwUCompare/UwUCompare";
const ggSheetBaseUrl = "https://docs.google.com/spreadsheets/d/";
const defaultView: TPg_Aio_AppViews_Schema = {
   object: "underwritings",
   created_by_sub: "Admin",
   name: "Default View",
   filters: [[]],
   order: [],
   shared_to_subs: [],
   sort_by: "aio_original_date",
   sort_direction: 1,
   vid: 0,
};
const UwUBrowser = () => {
   const { aioAxios } = useAxios();
   const { user } = useAuth0();
   const nav = useNavigate();

   const [params, setSearchParams] = useSearchParams();
   const ver = params.get("ver") || "";
   const viewId = params.get("viewId") || "";

   const { data: qVersionsData } = useQVersions({ obj: "underwritings" });
   const { latest: latestVer } = qVersionsData || {};

   const { data: qFieldsData, isFetching: qFieldsFetching } = useQFields({ tables: ["underwritings"] });
   const { fields = [] } = qFieldsData || {};

   const { data: qViewsData, isFetching: qViewsFetching } = useQViews({ object: "underwritings" });
   const { views = [] } = qViewsData || {};

   const { data: qViewData, isFetching: qViewFetching } = useQView({ object: "underwritings", viewId });
   const { view: ogView, object: ogObject } = qViewData || {};

   const [search, setSearch] = useState("");
   const [showUwCreatePu, setShowUwCreatePu] = useState(false);
   const [showViewTab, setShowViewTab] = useState(false);
   const [showFiltersTab, setShowFiltersTab] = useState(false);
   const [showCompare, setShowCompare] = useState(false);
   const [selectedUws, setSelectedUws] = useState<string[]>([]);
   const [tableMode, setTableMode] = useState(false);
   const [selectMode, setSelectMode] = useState(false);
   const [view, setView] = useState(defaultView);
   const [exporting, setExporting] = useState(false);
   const [sheetId, setSheetId] = useState("");

   const { data: qUwsData, isFetching: qUwsFetching } = useQUws({
      params: {
         search,
         columns: [...view.order].sort(),
         sortBy: view.sort_by || "",
         sortDirection: view.sort_direction || 1,
         filters: view.filters,
      },
      options: { refetchOnMount: true },
   });
   const { underwritings: uws = [], total: uwsTotal = 0 } = qUwsData || {};
   const handleUwSelect = (uwsid: string) =>
      setSelectedUws(selectedUws.includes(uwsid) ? selectedUws.filter((id) => id !== uwsid) : [...selectedUws, uwsid]);

   useEffect(() => {
      if (!ver && latestVer) {
         params.set("ver", latestVer);
         setSearchParams(params);
      }
   }, [latestVer, params, setSearchParams, ver]);

   useEffect(() => {
      if (viewId === "") {
         params.set("viewId", "0");
         setSearchParams(params);
      }
   }, [params, setSearchParams, viewId]);

   useEffect(() => {
      if (ogView && ogObject === "underwritings") setView({ ...ogView, object: "underwritings" });
   }, [ogView, ogObject]);

   const getFieldLabel = (name: string) => fields.find((f) => f.name === name.split(".").pop())?.label || name;

   const isViewingOfficial = !!view?.filters?.[0]?.find(
      (f) => f.column === "underwritings.hs_pipeline" && f.value === "41400397"
   );
   const tableHeaders = [...view.order.map((key) => ({ key, label: getFieldLabel(key) }))];
   const tableColKeys = tableHeaders.map((h) => h.key);
   const isViewModified =
      JSON.stringify([ogView?.order, ogView?.sort_by, ogView?.sort_direction, ogView?.filters]) !==
      JSON.stringify([view.order, view.sort_by, view.sort_direction, view.filters]);

   const handleOfficialToggle = () =>
      setView({
         ...view,
         filters: isViewingOfficial
            ? [
                 [...(view.filters?.[0].filter((f) => f.column !== "underwritings.hs_pipeline") || [])],
                 ...view.filters.slice(1),
              ]
            : [
                 [
                    ...(view.filters?.[0] || []),
                    { column: "underwritings.hs_pipeline", operator: "=", value: "41400397" },
                 ],
                 ...view.filters.slice(1),
              ],
      });

   const handleViewSelect = (viewId: string) => {
      params.set("viewId", viewId);
      setSearchParams(params);
   };
   const handleCellClick = ({ c, r, v }: TTableV2_onCellClickParams) => {
      const col = view.order[c];
      if (r === -1) return setView({ ...view, sort_by: col, sort_direction: view.sort_direction === 1 ? -1 : 1 });
      if (selectMode && uws[r]["underwritings.hs_object_id"])
         return handleUwSelect(uws[r]["underwritings.simply_object_id"] as string);
      nav(`/uwu/${uws[r]["underwritings.simply_object_id"]}?ver=${ver || ""}`);
   };
   const handleHeaderDrop: TTableV2_OnHeaderDrop = ({ from, to }) => {
      const newOrder = SHu_js_swapTwoElementsInArray(tableColKeys, from, to);
      setView({ ...view, order: [...newOrder] });
   };
   const handleExport = async () => {
      setExporting(true);
      const newSheetId = await aioAxios
         .post(`/aio/underwritings/bulk-export`, { uwsids: selectedUws, ver })
         .then(({ data }) => data?.newSheetId)
         .catch((er) => console.log({ er }));
      setSheetId(newSheetId || "");
      setExporting(false);
   };
   if (qUwsFetching || qFieldsFetching || qViewsFetching || qViewFetching)
      return <BodyFiller loading style={{ height: "50vh" }} />;
   return (
      <div className={`${styles.contentC}`}>
         {showUwCreatePu && (
            <Popup onClose={() => setShowUwCreatePu(false)}>
               <CreateUwPopup handleClose={() => setShowUwCreatePu(false)} />
            </Popup>
         )}
         {showViewTab && (
            <Popup onClose={() => setShowViewTab(false)} pos="rightSlideIn">
               <ViewConfig currView={view} onClose={() => setShowViewTab(false)} object={"underwritings"} />
            </Popup>
         )}
         {showFiltersTab && (
            <Popup onClose={() => setShowFiltersTab(false)} pos="rightSlideIn">
               <FiltersConfig
                  externalFilters={view.filters}
                  onClose={() => setShowFiltersTab(false)}
                  onApply={(filters) => setView({ ...view, filters })}
                  objects={["underwritings"]}
               />
            </Popup>
         )}
         {showCompare && (
            <Popup onClose={() => setShowCompare(false)}>
               <UwUCompare ver={ver} uwids={selectedUws} onClose={() => setShowCompare(false)}  />
            </Popup>
         )}
         <article className={`${styles.uwsC}`}>
            <div className={`${styles.searchC}`}>
               <div className={`${styles.searchL}`}>
                  <SearchInput search={search} setSearch={setSearch} />
               </div>
               <div className={`${styles.searchR}`}>
                  <MainButton title="Create Underwriting" onClick={() => setShowUwCreatePu(true)} />
               </div>
            </div>
            <div className={`${styles.uwsHeader}`}>
               <div className={`${styles.uwsHeaderL}`}>
                  <span className={`header-m`}>
                     {uws.length < uwsTotal ? `${uws.length}/` : ""}
                     {uwsTotal} Underwriting
                  </span>
                  <div className={`${styles.uwsHeaderL1}`}>
                     <MainInput type="toggle" title={`Table Mode`} value={tableMode} handleValue={setTableMode} />
                     <MainInput
                        type="toggle"
                        title={`Select Mode ${selectedUws.length > 0 ? `(${selectedUws.length} selected)` : ""}`}
                        value={selectMode}
                        handleValue={setSelectMode}
                     />
                  </div>
                  <div className={`${styles.uwsHeaderL2}`}>
                     {selectMode && (
                        <>
                           <MainButton title="Clear" type="bland" onClick={() => setSelectedUws([])} />
                           <MainButton
                              title="Select All"
                              type="bland"
                              onClick={() =>
                                 setSelectedUws(uws.map((uw) => uw["underwritings.simply_object_id"] as string))
                              }
                           />
                           <MainButton
                              enable={!exporting && selectedUws.length > 0}
                              title="Export"
                              type="scnd"
                              onClick={handleExport}
                              confirmMsg="GoogleSheet API is very slow. Expect 5 min process time for 200 underwriting!"
                           />
                           <MainButton
                              enable={selectedUws.length > 1}
                              title="Compare"
                              type="scnd"
                              onClick={() => setShowCompare(true)}
                           />
                           {exporting ? (
                              <SmallSpinner style={{ height: "1rem" }} />
                           ) : sheetId ? (
                              <a href={ggSheetBaseUrl + sheetId} target="_blank" rel="noreferrer">
                                 <p>
                                    <span>One Pager Ready!</span>
                                    <img src={cfAIO("/icons/common/external_link.svg")} alt="" />
                                 </p>
                                 {/* <b>Link Ready!</b> */}
                              </a>
                           ) : null}
                        </>
                     )}
                  </div>
               </div>
               <div className={`${styles.uwsHeaderR}`}>
                  <div className={`${styles.uwsHeaderR1}`}>
                     <MainInput
                        type="search"
                        title={`View ${isViewModified ? "(modified)" : ""}`}
                        style={{ width: "14rem" }}
                        value={view ? `${view.name}` : ""}
                        handleValue={(str) => handleViewSelect(str.split("|").pop() || "")}
                        options={views.map((v) => `${v.name}|${v.vid}`)}
                        hideSeparators
                     />
                     <MainButton
                        title={`${view.created_by_sub === user?.sub ? "Edit" : "Create"} View`}
                        style={{ width: "6rem" }}
                        type="scnd"
                        onClick={() => setShowViewTab(true)}
                     />
                  </div>
                  <div className={`${styles.uwsHeaderR2}`}>
                     <MainInput
                        type="search"
                        title="Sort By"
                        style={{ width: "14rem" }}
                        value={getFieldLabel(view.sort_by || "")}
                        options={Object.keys(uws?.[0] || {}).map((col) => `${getFieldLabel(col)}|${col}`)}
                        handleValue={(str) => setView({ ...view, sort_by: str?.split("|").pop() || "" })}
                        hideSeparators
                     />
                     <MainInput
                        type="dropdown"
                        style={{ width: "6rem" }}
                        title="Sort"
                        value={view.sort_direction === 1 ? "ASC" : "DESC"}
                        options={["DESC", "ASC"]}
                        handleValue={(str) => setView({ ...view, sort_direction: str === "ASC" ? 1 : -1 })}
                     />
                  </div>
                  <div className={`${styles.uwsHeaderR3}`}>
                     <MainInput
                        type="toggle"
                        title="Official Only"
                        value={isViewingOfficial}
                        handleValue={handleOfficialToggle}
                     />
                     <MainButton
                        title={`Filters (${view.filters.flat().length})`}
                        type="bland"
                        onClick={() => setShowFiltersTab(true)}
                     />
                  </div>
               </div>
            </div>
            <div className={`${styles.uws}`}>
               {tableMode ? (
                  <div className={`${styles.tableC}`}>
                     <TableV2
                        headerData={tableHeaders}
                        tableData={uws}
                        sortColIndex={tableColKeys.findIndex((key) => key === view.sort_by)}
                        sortDirection={view.sort_direction === 1 ? "asc" : "desc"}
                        highlightRows={selectedUws.map((uwsid) =>
                           uws.findIndex((uw) => uw["underwritings.simply_object_id"] === uwsid)
                        )}
                        onCellClick={handleCellClick}
                        onHeaderDrop={handleHeaderDrop}
                     />
                  </div>
               ) : (
                  uws.map((uw, key) => (
                     <UwSCard
                        uw={uw}
                        view={view}
                        key={`${key}${uw["underwritings.simply_object_id"]}`}
                        onClick={() => selectMode && handleUwSelect(uw["underwritings.simply_object_id"] as string)}
                        ver={ver}
                        selectMode={selectMode}
                        checked={selectedUws.includes(uw["underwritings.simply_object_id"] as string)}
                        onChecked={() => handleUwSelect(uw["underwritings.simply_object_id"] as string)}
                     />
                  ))
               )}
            </div>
         </article>
      </div>
   );
};

const SearchInput = ({ search, setSearch }: TSearchInputProps) => {
   const [internal, setInternal] = useState(search);
   const debInternal = useDebounce(internal, 1000);
   useEffect(() => {
      if (debInternal !== search) setSearch(debInternal);
   }, [debInternal, search, setSearch]);
   return (
      <MainInput
         title="Search Underwriting"
         style={{ width: "20rem" }}
         value={internal}
         handleValue={setInternal}
         disableCollapse
      />
   );
};

type TSearchInputProps = {
   search: string;
   setSearch: (str: string) => void;
};

export default UwUBrowser;
