import { useState } from "react";
import styles from "./AioUwsAddProperty.module.scss";
import { SHrMainButton, SHrMainInput } from "@simplyhomes/react";
const AioUwsAddProperty = (p: TAioUwsAddPropertyProps) => {
   const [address, setAddress] = useState("");
   const [isValid, setIsValid] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const handleType = (str: string) => {
      setIsValid(false);
      setAddress(str);
   };

   const handleSubmit = async () => {
      setSubmitting(true);
      setSubmitting(false);
      p.onClose()
   };
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`cardHeader-s`}>Add property</h1>
         </div>
         <div className={`${styles.bodyC}`}>
            <SHrMainInput
               title="Address"
               type="text"
               value={address}
               onChange={handleType}
               tooltip="Please select from auto complete list"
               containerProps={{ style: { flexGrow: 1 } }}
               options={[{ label: "asd", value: "asad" }]}
               onOptionClick={() => setIsValid(true)}
            />
            <SHrMainButton title="Submit" enable={isValid} />
         </div>
      </article>
   );
};

export default AioUwsAddProperty;
type TAioUwsAddPropertyProps = {
   onSubmit?: (p: { address: string }) => void;
   onClose: () => void;
};
