import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Crm_Renovations_Schema } from "@simplyhomes/utils";

export const useQRenovation = ({ rid }: TuseQRenovationParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: !!rid,
      queryKey: ["useQRenovation", rid],
      queryFn: () => aioAxios.get(`/aio/renovations/${rid}`).then<TAxiosResp>(({ data }) => data),
   });
};

type TuseQRenovationParams = {
   rid: string;
};

type TAxiosResp = {
   renovation: TPg_Crm_Renovations_Schema;
};
