import { TUnknownObject } from "../../types/interfaces/unknownObj";

const jsManipulateNestedObj = ({ obj, path, value, remove, retrieve }: IjsManipulateNestedObj) => {
   if (!path) return value;
   const keys = path.split(".").filter(Boolean);
   const lastKey = keys.at(-1);
   if (lastKey == null) return obj;

   let currentObj = obj;
   keys
      .slice(0, -1)
      .forEach(
         (key, i) =>
            (currentObj = !(key in currentObj)
               ? /^\d+$/.test(keys[i + 1])
                  ? (currentObj[key] = [])
                  : (currentObj[key] = {})
               : currentObj[key])
      );
   if (retrieve) return currentObj[lastKey];
   if (!remove) currentObj[lastKey] = value;
   else if (Array.isArray(currentObj)) currentObj.splice(Number(lastKey), 1);
   else delete currentObj[lastKey];
   return obj;
};
export default jsManipulateNestedObj;
interface IjsManipulateNestedObj {
   obj: TUnknownObject;
   path: string;
   value?: any;
   retrieve?: boolean;
   remove?: boolean;
}
