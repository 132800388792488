import { TUseQAssets_asset } from "../../../../hooks/querries/asssets/useQAssets";
import { assetGetStageColor } from "../../../../utils/color/assetGetStageColor";
import AssetCard from "../../assets/AssetCard/AssetCard";
import styles from "./GgMapAssetMarker.module.scss";
const GgMapAssetMarker = ({ asset }: TGgMapAssetMarkerProps) => {
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.dot}`} style={{ backgroundColor: assetGetStageColor(asset.option_label) }}></div>
         <div className={`${styles.hoverC}`}>
            <AssetCard asset={asset} />
         </div>
      </div>
   );
};

export default GgMapAssetMarker;
type TGgMapAssetMarkerProps = {
   asset: TUseQAssets_asset;
};
