import { useSearchParams } from "react-router-dom";
import FieldsEditor from "./fieldsEditor/FieldsEditor";
import GroupsEditor from "./groupsEditor/GroupsEditor";
import styles from "./VerEditor.module.scss";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import { TaioVersionObj } from "../../../../types/aioVersion/types";
import MiscEditor from "./miscEditor/MiscEditor";
const VerEditor = () => {
   const [params] = useSearchParams();
   const obj = (params.get("obj") || "") as TaioVersionObj;

   if (!obj) return <BodyFiller fillerMsg="Select an Object to continue " />;
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.contentL}`}>
            <MiscEditor />
            <FieldsEditor obj={obj} />
         </div>
         <div className={`${styles.contentR}`}>
            <GroupsEditor type="bulk" />
            <GroupsEditor type="units" />
            <GroupsEditor type="overview" />
            <GroupsEditor type="groups" />
         </div>
      </section>
   );
};

export default VerEditor;
