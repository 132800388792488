import styles from "./ApiTable.module.scss";
const ApiTable = () => {
   return (
      <section className={`${styles.contentC}`}>
         <iframe
          width={"100%"} height={'100%'} src={"https://apitable.simplyhomes.dev/share/shrGYCDqLGL1VHyPz5tkG"} title="API-Table" />
      </section>
   );
};

export default ApiTable;
