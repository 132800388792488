import { parse } from "excel-formula-parser";

const parseFormula = (str: string) => {
   try {
      const tree = parse(str);
      return tree;
   } catch (error) {}
   return;
};

export default parseFormula;
