import { CSSProperties, useState } from "react";
import styles from "./DevInput.module.scss";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";

const DevInput = ({
   onChange,
   type,
   value,
   className,
   bad,
   hideTitle,
   highlight,
   loading,
   onTooltipClick,
   otherProps,
   readonly,
   title,
   tooltip,
   colors,
   options,
   hideOptionValues,
   onOptionClick,
}: TDevInputProps) => {
   const [isFocused, setIsFocused] = useState(false);
   const [internalVal, setInternalVal] = useState("");
   const OnChange = (v: string | string[] | boolean) => {
      if (!readonly && onChange)
         return type === "multi-select"
            ? onChange(v as string[])
            : type === "checkbox" || type === "toggle"
            ? onChange(v as boolean)
            : type === "search" || type === "dropdown" || type === "number" || type === "text" || type === "textarea"
            ? onChange(v as string)
            : null;
   };
   const handleOptionClick = (option: TReact_MainInput_Option) => {
      if (onOptionClick) return onOptionClick(option);
      if (type === "multi-select") {
      }
      if (type === "search" || type === "dropdown") OnChange(option.value);
   };
   const id = Math.random().toString(36).substring(7);
   const Options =
      type === "search"
         ? options?.sort((o) => ((o.label + o.value).toLowerCase().includes(internalVal.toLowerCase()) ? -1 : 1))
         : options;
   if (type === "search") console.log({ internalVal });
   const handleClearOption = () => {
      setInternalVal("");
      if (type === "multi-select") OnChange([]);
      if (type === "search" || type === "dropdown") OnChange("");
   };
   const handleSearchValue = (str: string) => {
      if (type === "search") {
         OnChange("");
         setInternalVal(str);
      }
   };

   return (
      <div
         onClick={() => (type === "checkbox" || type === "toggle" ? OnChange(!value) : setIsFocused(true))}
         className={`${className} ${styles.contentC} ${bad && styles.bad} ${highlight && styles.highlight}`}
         style={{ ...colors }}
      >
         <div className={`${styles.contentL}`}>
            <div className={`${styles.contentLT}`}>
               {!hideTitle && <label htmlFor={id}>{title}</label>}
               {tooltip && (
                  <div className={`${styles.tooltipIcon}`} onClick={onTooltipClick}>
                     <span>i</span>
                     <div className={`${styles.tooltip}`}>{tooltip}</div>
                  </div>
               )}
            </div>
            {type === "text" || type === "number" ? (
               <input {...otherProps} id={id} type="text" value={value} onChange={(e) => OnChange(e.target.value)} />
            ) : type === "textarea" ? (
               <textarea {...otherProps} id={id} value={value} onChange={(e) => OnChange(e.target.value)} />
            ) : type === "dropdown" ? (
               <div className={`${styles.input} `}>{options?.find((o) => o.value === value)?.label || value}</div>
            ) : type === "search" ? (
               <input
                  value={options?.find((o) => o.value === value)?.label || value || internalVal}
                  onChange={(e) => handleSearchValue(e.target.value)}
               />
            ) : null}
         </div>
         <div className={`${styles.contentR}`}>
            {loading && <div className={`${styles.loadingC}`}></div>}
            {type === "checkbox" && (
               <div className={`${styles.checkboxC}`}>
                  <input type="checkbox" checked={value} onChange={() => OnChange(!value)} />
                  <span className={`${styles.checkmark}`}></span>
               </div>
            )}
            {type === "toggle" && (
               <div className={`${styles.toggleC}`}>
                  <input type="checkbox" checked={value} onChange={() => OnChange(!value)} />
                  <div className={`${styles.toggleBall}`}></div>
               </div>
            )}
            {type === "search" && (
               <img
                  src={s3afBucketURI("/icons/search.svg")}
                  alt=""
                  className={`${styles.filterTitle} ${styles.lens}`}
               />
            )}
            {(type === "dropdown" || type === "multi-select") && (
               <img
                  src={s3afBucketURI("/icons/dd_arrow.svg")}
                  alt=""
                  className={`${styles.ddArrow} ${isFocused && styles.ddArrowRotate}`}
               />
            )}
         </div>
         {Options && isFocused && (
            <div className={`${styles.optionsC} `} onMouseLeave={() => setIsFocused(false)}>
               <li onClick={() => handleClearOption()}>
                  <div>
                     <b>Clear</b>
                  </div>
               </li>
               {Options.map((o, i) => (
                  <li key={i} onClick={() => handleOptionClick(o)}>
                     <div>
                        <b>{o.label}</b>
                        {!hideOptionValues && <p>{o.value}</p>}
                     </div>
                     <div>
                        {(type === "dropdown" || type === "search") && value === o.value && (
                           <img className={`${styles.filterText}`} src={s3afBucketURI("/icons/checkbox.svg")} alt="" />
                        )}
                     </div>
                  </li>
               ))}
            </div>
         )}
      </div>
   );
};

export default DevInput;
type TDevInputProps = {
   readonly?: boolean;
   title?: string;
   hideTitle?: boolean;

   tooltip?: string;
   onTooltipClick?: () => void;
   loading?: boolean;
   bad?: boolean;
   highlight?: boolean;

   colors?: MyCustomCSS;

   options?: TReact_MainInput_Option[];
   hideOptionValues?: boolean;
   onOptionClick?: (option: TReact_MainInput_Option) => void;

   className?: string;
   otherProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
} & (
   | { type?: "text" | "number"; value: string; onChange?: (value: string) => void }
   | {
        type?: "textarea";
        value: string;
        onChange?: (value: string) => void;
        otherProps: React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
     }
   | {
        type?: "dropdown" | "search";
        value: string;
        onChange?: (value: string) => void;
     }
   | {
        type?: "multi-select";
        value: string[];
        onChange?: (value: string[]) => void;
     }
   | {
        type?: "checkbox" | "toggle";
        value: boolean;
        onChange?: (value: boolean) => void;
     }
);
export type TReact_MainInput_Option = { label: string; value: string };
type MyCustomCSS = CSSProperties & {
   "--bad": string;
   "--title": string;
   "--text": string;
   "--accent": string;
   "--accent-o": string;
   "--background": string;
   "--text-filter": string;
};
