import { useNavigate } from "react-router-dom";
import s3afBucketURI from "../../../../utils/s3/s3afBucketURI";
import AFInput from "../../../common/AFInput/AFInput";
import MainButton from "../../../common/mainButton/MainButton";
import styles from "./PreviewPane.module.scss";
import { Iproperty } from "../../../../hooks/querries/properties/useQProperties";
const PreviewPane = ({ property, handleClose }: IPreviewPaneProps) => {
   const nav = useNavigate();
   if (!property)
      return (
         <div className={`${styles.contentC}`}>
            <div className={`${styles.title}`}>No Property Selected</div>
            <div className={`${styles.msg}`}>Select a Marker on the map to view one</div>
         </div>
      );
   const { hs_object_id, hs_pipeline_stage, coordinates, property_address_one_line_, bathrooms, bedrooms } = property;
   const allKeys = Object.keys(property);
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.titleC}`}>
            <div className={`${styles.title}`}>Property Preview</div>
            <div className={`${styles.close}`} onClick={() => handleClose()}>
               Close
            </div>
         </div>
         <div className={`${styles.headerInfoC}`}>
            <div className={`${styles.address}`}>{property_address_one_line_}</div>
            <div className={`${styles.bedrooms}`}>Bedroom: {bedrooms}</div>
            <div className={`${styles.bedrooms}`}>Bathroom: {bathrooms}</div>
         </div>
         <div className={`${styles.controlsC}`}>
            <MainButton title="Calculator" onClick={() => nav(`/properties/${hs_object_id}`)} />
         </div>
         <img src={s3afBucketURI(`/street-view/${hs_object_id}.png`)} alt="" className={`${styles.streetView}`} />
         <ul className={`${styles.infoC}`}>
            {allKeys.map((str, key) => (
               <li key={key}>
                  <AFInput title={str} value={property[str] || ""} handleChange={() => {}} readonly />
               </li>
            ))}
         </ul>
      </div>
   );
};

export default PreviewPane;
interface IPreviewPaneProps {
   property?: Iproperty;
   handleClose: () => void;
}
