import { useEffect } from "react";
import styles from "./SimplerWidget.module.scss";
import useTheme from "../../hooks/react/useTheme";
const SimplerWidget = () => {
   const { theme, switchTheme } = useTheme();
   useEffect(() => {
      const js = document.createElement("script");

      js.src = "https://dyaj6jwzih5dm.cloudfront.net/widget/simpler/main.js";
      js.defer = true;

      const css = document.createElement("link");
      css.rel = "stylesheet";
      css.type = "text/css";
      css.href = "https://dyaj6jwzih5dm.cloudfront.net/widget/simpler/main.css";
      css.onload = function () {
         document.getElementById("widgetApp")?.appendChild(js);
      };
      document.getElementsByTagName("head")[0].appendChild(css);
      // document.body.appendChild(script);

      return () => {
         if (document.getElementById("widgetApp")?.hasChildNodes())
            document.getElementById("widgetApp")?.removeChild(js);
         document.getElementsByTagName("head")[0].removeChild(css);
         // document.body.removeChild(script);
      };
   }, []);
   useEffect(() => {
      if (theme === "dark") switchTheme();
   }, [switchTheme, theme]);

   return (
      <div className={`${styles.contentC}`}>
         <div className={`header-m ${styles.title}`}>Welcome Simpler</div>
         <div id="widgetApp" className={`${styles.app}`}></div>
      </div>
   );
};

export default SimplerWidget;
