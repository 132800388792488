import { useEffect, useState } from "react";
import translateFormula from "../../utils/formula/translateFormula";
import useQVersion from "../querries/versions/useQVersion";
import { TaioVersionObj } from "../../types/aioVersion/types";

const useTranslateFormula = ({ ver, formula, name, obj }: IuseTranslateFormulaParams) => {
   const debug = name === "";
   const [value, setValue] = useState("");
   const { data: versionData } = useQVersion({ ver, obj });
   const { config } = versionData || {};
   const { fields = [] } = config || {};
   if (debug) console.log({ value });

   useEffect(() => {
      if (!formula) return;
      setValue(translateFormula(formula, fields).translated);
   }, [fields, formula]);
   return { value };
};
export default useTranslateFormula;

interface IuseTranslateFormulaParams {
   formula?: string;
   name?: string;
   ver: string;
   obj: TaioVersionObj;
}
