import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";

const useQDbLedgerSnapshot = ({ snap }: TuseQDbLedgerSnapshotParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: !!snap,
      queryKey: ["useQDbLedgerSnapshot", snap],
      queryFn: () =>
         aioAxios
            .get(`/aio/snapshots/ledger/${snap}`, { headers: { "Cache-Control": "no-cache" } })
            .then<TaxiosReps>(({ data }) => data),
   });
};

export default useQDbLedgerSnapshot;
type TuseQDbLedgerSnapshotParams = {
   snap: string;
};
type TaxiosReps = {
   differences: {
      table: string;
      curr: number;
      snap: number;
   }[];
};
