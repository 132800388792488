import { useSocket, useSocketEvent } from "socket.io-react-hook";
import { UseSocketEventOptions } from "socket.io-react-hook/dist/types";
import { useAppContext } from "../../contexts/appContext/AppState";

export const useSocketIo = (p?: useSocketIoParams) => {
   const { event, options } = p || {};
   const { appState } = useAppContext();
   const { socket } = appState;
   const { socket: blankSocket } = useSocket();
   return useSocketEvent(socket || blankSocket, event || "", options);
};

type useSocketIoParams = {
   event: string; //* use SHu_socketio_events to get the types
   options?: UseSocketEventOptions<any>;
};
