import { useState } from "react";
import useAxios from "../../../../hooks/axios/useAxios";
import styles from "./LedgerSnapManager.module.scss";
import useQDbLedgerSnapshots from "../../../../hooks/querries/database/useQDbSnapshots";
import AFInput from "../../../common/AFInput/AFInput";
import jsNumberToDate from "../../../../utils/JS/jsNumberToDate";
import useQDbLedgerSnapshot from "../../../../hooks/querries/database/useQDbLedgerSnapshot";
import MainButton from "../../../common/mainButton/MainButton";
import Table from "../../../common/table/Table";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import useQDbTable from "../../../../hooks/querries/database/useQDbTable";
const LedgerSnapManager = () => {
   const { aioAxios } = useAxios();

   const { data: ledgerSnapsData, refetch: ledgerSnapsRefetch } = useQDbLedgerSnapshots();
   const { snapshots = [] } = ledgerSnapsData || {};

   const { refetch: tableRefetch } = useQDbTable();

   const [submitting, setSubmitting] = useState(false);
   const [snap, setSnap] = useState("");

   const {
      data: ledgerSnapData,
      isFetching: ledgerIsFetching,
      refetch: ledgerRefetch,
   } = useQDbLedgerSnapshot({ snap });
   const { differences = [] } = ledgerSnapData || {};

   const handleCreateSnap = async () => {
      setSubmitting(true);
      setSnap("");
      await aioAxios
         .post(`/aio/snapshots/ledger`)
         .then(({ data }) => setSnap(data?.snap || ""))
         .catch((er) => console.log({ er }));
      await ledgerSnapsRefetch();
      setSubmitting(false);
   };
   const handleRevert = async () => {
      setSubmitting(true);
      await aioAxios.put(`/aio/snapshots/ledger`, { snap }).catch((er) => console.log({ er }));
      tableRefetch();
      ledgerRefetch();
      setSubmitting(false);
   };
   const snaps = snapshots.map((str) => ({ snap: str, date: jsNumberToDate(str) }));
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.controls}`}>
            <div>
               <AFInput
                  style={{ width: "14rem" }}
                  title="Snapshots"
                  type="dropdown"
                  value={jsNumberToDate(snap)}
                  handleChange={(date) => setSnap(snaps.find((snap) => snap.date === date)?.snap || "")}
                  options={snaps.sort((a, b) => Number(b.snap) - Number(a.snap)).map(({ date }) => date)}
               />
               {snap && (
                  <MainButton
                     title="Revert to this"
                     onClick={handleRevert}
                     type="warn"
                     confirmMsg="THIS WILL OVERWRITE LEDGER TABLES!"
                  />
               )}
            </div>
            <div>
               <MainButton title="Refresh" onClick={ledgerSnapsRefetch} />
               <MainButton
                  title="Create Snapshot"
                  onClick={handleCreateSnap}
                  confirmMsg="Create a snapshot of current ledger tables?"
               />
            </div>
         </div>
         <div className={`${styles.differences}`}>
            {ledgerIsFetching || submitting ? (
               <BodyFiller loading style={{ margin: "auto" }} />
            ) : !snap ? (
               <BodyFiller fillerMsg="Select a snapshot on top left to continue" style={{ margin: "auto" }} />
            ) : (
               <Table
                  type="none"
                  columnHeaders={["Current->", "->Snapshot", "Delta"]}
                  rowHeaders={differences.map(({ table }) => table)}
                  data={differences.map(({ curr, snap }) => [curr, snap, snap - curr])}
               />
            )}
         </div>
      </div>
   );
};

export default LedgerSnapManager;
