import { useAuth0 } from "@auth0/auth0-react";
import afRoles, { TauthTab } from "../../const/afRoles";

const useAuthTabs = () => {
   const { user } = useAuth0();
   const { allRoles = [] } = (user || {}) as { allRoles: string[] };
   const authTabs: TauthTab[] = [
      ...new Set(allRoles.map((str) => afRoles.find(({ role }) => role === str)?.tabs || []).flat()),
   ];
   return { authTabs };
};
export default useAuthTabs;
