import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPg_Aio_AppUnderwritingsVersions_Schema } from "@simplyhomes/utils";

export const useQAioVersions = (p?: TuseQAioVersions) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioVersions"],
      queryFn: () => aioAxios.get(`/aio/v2/versions`).then<axiosResp>(({ data }) => data),
   });
};
const cols = ["version", "official", "created_by", "created_at", "updated_by", "updated_at", "description"] as const;
type axiosResp = {
   versions: Pick<TPg_Aio_AppUnderwritingsVersions_Schema, (typeof cols)[number]>[];
};
type TuseQAioVersions = {
   options: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
