import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPG_AIO2_AppUnderwritings_Schema } from "@simplyhomes/utils";

export const useQAioUws = (p?: useQAioUwsParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioUws"],
      queryFn: () => aioAxios.get("/aio/v2/underwritings").then<axiosResp>((res) => res.data),
   });
};
type useQAioUwsParams = {
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
const cols = ["uid", "version", "created_at", "created_by", "updated_at", "updated_by", "address"] as const;
export type TQAioUws_UW = Pick<TPG_AIO2_AppUnderwritings_Schema, (typeof cols)[number]> & {
   arv: string;
   arv_uplift: string;
   cap_rate: string;
   target_purchase_price: string;
   units: number;
   offer_1: string;
   offer_2: string;
};
type axiosResp = {
   underwritings: TQAioUws_UW[];
};
