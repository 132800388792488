import { CSSProperties, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useQVersion from "../../../../hooks/querries/versions/useQVersion";
import { useUwUContext } from "../../../../pages/UwU/UwU";
import parseFieldName from "../../../../utils/fields/parseFieldName";
import useTranslateFormula from "../../../../hooks/formulas/useTranslateFormula";
import { parseDecimal } from "../../../../utils/MATH/parseDecimal";
import { TUnknownObject } from "../../../../types/interfaces/unknownObj";
import MainInput from "../../../common/mainInput/MainInput";
import styles from "./UwUSingleField.module.scss";
import { useResolveUwU } from "../../../../hooks/formulas/useResolveUwU";
const UwUSingleField = ({
   fieldId,
   unitIndex,
   showValueOnly,
   onTooltipClick = () => {},
   style,
   highlight,
}: TUwUFieldProps) => {
   const [isFocused, setIsFocused] = useState(false);
   const [params] = useSearchParams();
   const ver = params.get("ver") || "";

   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [], units = [] } = qVerData?.config || {};

   const { uwuState, uwuDispatch } = useUwUContext();
   const { uwFinal = {}, uwOptions = {}, uwCache = {}, uwProcessing } = uwuState;
   const { $childUnits = [] } = uwFinal;

   const { recalcUwUFields } = useResolveUwU({ ver });

   const currField = fields.find(({ id }) => id === fieldId);
   const {
      formula = "",
      name,
      column: baseCol,
      format,
      readonly: fieldReadonly,
      required,
      type,
      condFormat,
      unitBehavior = "NONE",
      options: optionsString,
      id: fId = "",
   } = currField || {};
   const readonly = process.env.REACT_APP_MODE === "DEV" ? false : fieldReadonly || uwFinal.$app_readonly;
   const debug = name === "Housing Authorityz";
   const column = baseCol || parseFieldName(name);
   const value = unitIndex != null ? $childUnits[unitIndex]?.[column] : uwFinal[column];
   const options = optionsString ? uwOptions[optionsString] : "";
   if (debug) console.log({ column, uwOptions, options });
   const { value: translatedFormula } = useTranslateFormula({ formula, ver, obj: "underwritings" });

   if (!qVerData?.config) return <div>could not find ver</div>;
   if (!currField) return <div>Field {fieldId} Does Not Exist</div>;

   const fieldsInUnits = units.map(({ children }) => children).flat();
   const amIaChild = unitIndex != null;
   const amIaMother = !amIaChild && fieldsInUnits.includes(fId) && $childUnits.length > 0;

   const handleVal = async (v: number | string | boolean) => {
      if (readonly) return;
      if (!column) return;
      const val =
         typeof v == "boolean"
            ? v
            : format === "Currency"
            ? Number(
                 v.toString().includes("e-") || v.toString().includes("e+")
                    ? Number(v).toFixed(0)
                    : v.toString().replace(/[^0-9.-]+/g, "")
              )
            : format === "Percentage"
            ? parseDecimal(v.toLocaleString())
            : v;

      const recalUwUProps = { fieldIds: [fieldId], unitIndex, cache: uwCache, options: [uwOptions] };
      if (unitIndex != null) {
         const newUnit = { ...$childUnits[unitIndex], [column]: val };
         const newChildUnits = $childUnits.map((unit, index) => (index === unitIndex ? newUnit : unit));
         const newUw = { ...uwFinal, $childUnits: newChildUnits };
         uwuDispatch({ overwrite: { uwProcessing: true } });
         const result = await recalcUwUFields({ uws: [newUw], ...recalUwUProps });
         uwuDispatch({ type: "recalced", payload: result });
         return;
      } else {
         const newUw = { ...uwFinal, [column]: val };
         uwuDispatch({ overwrite: { uwProcessing: true } });
         const result = await recalcUwUFields({ uws: [newUw], ...recalUwUProps });
         uwuDispatch({ type: "recalced", payload: result });
         return;
      }
   };
   const formattedVal =
      format === "Currency"
         ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)
         : format === "Percentage"
         ? new Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
           }).format(value)
         : value;
   const formatConditions: TUnknownObject | undefined = condFormat
      ?.split(",")
      .reduce((prev, curr) => ({ ...prev, [curr.split("=")[0]]: curr.split("=")[1] }), {});
   const titleBehavior = amIaMother && unitBehavior !== "NONE" ? `[${unitBehavior}]` : "";
   const optionsSplit = !options ? [""] : options === "failed" ? ["[Not Found]"] : options.split("|").filter(Boolean);
   // if (name === "Which Uses Gas") console.log({ value });

   return (
      <div
         onFocus={() => setIsFocused(true)}
         onBlur={() => setIsFocused(false)}
         className={`${uwProcessing && styles.blocked}`}
         style={style}
      >
         <MainInput
            showValueOnly={showValueOnly}
            style={{ backgroundColor: formatConditions?.[value] }}
            type={(!type || type === "text") && options ? "dropdown" : type}
            options={optionsSplit}
            title={`${name}${required ? "*" : ""}${titleBehavior}`}
            value={isFocused ? value || "" : formattedVal || ""}
            handleValue={(v) => handleVal(v)}
            readonly={(typeof readonly === "boolean" && readonly) || (amIaMother && unitBehavior !== "NONE")}
            highlight={highlight}
            bad={
               (required && (value === "" || value == null)) ||
               (value !== "" && !!options && !value?.split("|").every((str: string) => optionsSplit.includes(str)))
            }
            loading={uwProcessing}
            useInternal
            tooltip={
               amIaMother && unitBehavior !== "NONE"
                  ? { type: "info", msg: `This is the ${unitBehavior} of units' ${name} fields` }
                  : translatedFormula.includes("{")
                  ? { type: "info", msg: translatedFormula }
                  : undefined
            }
            onTooltipClick={() => onTooltipClick(fieldId)}
            key={name}
         />
      </div>
   );
};

export default UwUSingleField;
type TUwUFieldProps = {
   fieldId: string;
   showValueOnly?: boolean;
   unitIndex?: number;
   onTooltipClick?: (id: string) => void;
   style?: CSSProperties;
   highlight?: boolean;
};
