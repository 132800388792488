import { useSearchParams } from "react-router-dom";
import styles from "./DbTable.module.scss";
import useQDbTable from "../../../../hooks/querries/database/useQDbTable";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import Table from "../../../common/table/Table";
import { TUnknownObject } from "../../../../types/interfaces/unknownObj";
import AFInput from "../../../common/AFInput/AFInput";
import { useEffect, useState } from "react";
import EntryEditor from "./entryEditor/EntryEditor";
import useQDb from "../../../../hooks/querries/database/useQDb";
import MainButton from "../../../common/mainButton/MainButton";
import RelationEditor from "./relationEditor/RelationEditor";
import dbGetTablePks from "../../../../utils/database/dbGetTablePks";
const DbTable = () => {
   const { data: qDbData } = useQDb();
   const { tables = [] } = qDbData || {};

   const { data: qDbTableData, isFetching: qDbTableIsFetching, isRefetching: qDbTableIsRefetching } = useQDbTable();
   const { columns = [], rows = [], count, relations = [] } = qDbTableData || {};
   const colNames = columns.map(({ name }) => name);
   const rowData = rows.map((obj) => columns.map(({ name }) => obj[name]));
   const [entry, setEntry] = useState<TUnknownObject>({});

   const [params, setParams] = useSearchParams();
   const table = params.get("table");
   const limit = params.get("limit") || 50;
   const col = params.get("col");
   const offset = Number(params.get("offset")) || 0;
   const order = params.get("order") || "ASC";
   const searchCol = params.get("searchCol") || "";
   const searchVal = params.get("searchVal") || "";
   const id = params.get("id");

   const pageCount = Math.ceil(Number(count) / Number(limit));
   const pks = dbGetTablePks({ tables, table });

   useEffect(() => {
      setEntry({});
      params.delete("id");
      setParams(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [table]);

   if (!table) return <BodyFiller fillerMsg="Select A Table To Begin!" />;
   // if (!qDbTableData) return <BodyFiller fillerMsg="No DATA" />;
   const handleRowClick = (obj: TUnknownObject) => {
      if (pks.length === 1 && obj[pks[0]]) {
         params.set("id", obj[pks[0]]);
         setParams(params);
      }
      setEntry(obj);
   };
   const handleHeaderClick = (str: string) => {
      params.set("col", str);
      params.set("order", col === str && order === "DESC" ? "ASC" : "DESC");
      setParams(params);
   };

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.contentL}`}>
            <div className={`${styles.tableName} header-m`}>{table}</div>
            {pageCount > 1 && (
               <div className={`${styles.tableNav}`}>
                  <MainButton
                     title="Last Page"
                     enable={offset - 1 >= 0}
                     onClick={() => {
                        params.set("offset", Math.max(0, offset - 1).toString());
                        setParams(params);
                     }}
                  />
                  <div>
                     Page {offset + 1} / {pageCount}
                  </div>
                  <MainButton
                     title="Next Page"
                     enable={offset < pageCount - 1}
                     onClick={() => {
                        params.set("offset", Math.min(pageCount - 1, offset + 1).toString());
                        setParams(params);
                     }}
                  />
               </div>
            )}
            <div className={`${styles.tableC}`}>
               {qDbTableIsFetching || qDbTableIsRefetching ? (
                  <BodyFiller loading={true} style={{ height: "20rem"}} />
               ) : (
                  <Table
                     type="none"
                     hightlights={pks}
                     columnHeaders={colNames}
                     data={rowData}
                     onHeaderClick={handleHeaderClick}
                     onRowClick={handleRowClick}
                     internalScroll
                     tbodyHeight={"30rem"}
                  />
               )}
            </div>
         </div>
         <div className={`${styles.contentR}`}>
            <div className={`${styles.controls}`}>
               <div className={`header-s`}>Settings</div>
               <div className={`${styles.inputs}`}>
                  <AFInput
                     title="Items per page"
                     value={limit.toString()}
                     type="dropdown"
                     options={["50", "100", "1000"]}
                     handleChange={(v) => {
                        params.set("limit", v);
                        setParams(params);
                     }}
                  />
                  <AFInput
                     title="Search in Column"
                     value={searchCol}
                     type="dropdown"
                     options={colNames}
                     handleChange={(v) => {
                        params.set("searchCol", v);
                        setParams(params);
                     }}
                  />
                  <AFInput
                     title="Search Value"
                     value={searchVal}
                     handleChange={(v) => {
                        params.set("searchVal", v);
                        setParams(params);
                     }}
                  />
               </div>
            </div>
            <EntryEditor entry={entry} setEntry={setEntry} />
            {id && relations.length > 0 && <RelationEditor />}
         </div>
      </section>
   );
};

export default DbTable;
