import { useState } from "react";
import s3afBucketURI from "../../../../../utils/s3/s3afBucketURI";
import MainButton from "../../../../common/mainButton/MainButton";
import Popup from "../../../../common/popup/Popup";
import styles from "./GroupsEditor.module.scss";
import GroupsEditorPopup from "./groupsEditorPopup/GroupsEditorPopup";
import { useVersionContext } from "../../../../../pages/Versions/Versions";
const GroupsEditor = ({ type }: TgroupsEditor) => {
   const [showPopup, setShowPopup] = useState(false);
   const [groupIndex, setGroupIndex] = useState(-1);

   const { version, setVersion } = useVersionContext();

   const source = version[type] || [];
   const newBtn = () => {
      setGroupIndex(-1);
      setShowPopup(true);
   };
   const editBtn = (index: number) => {
      setGroupIndex(index);
      setShowPopup(true);
   };
   const moveGroup = (i: number, direction: "left" | "right") => {
      const newGroups = [...source];
      if (direction === "left" && !newGroups[i - 1]) return;
      if (direction === "right" && !newGroups[i + 1]) return;
      if (direction === "left") [newGroups[i - 1], newGroups[i]] = [newGroups[i], newGroups[i - 1]];
      if (direction === "right") [newGroups[i], newGroups[i + 1]] = [newGroups[i + 1], newGroups[i]];
      setVersion({ ...version, [type || "groups"]: newGroups });
   };
   return (
      <article className={`${styles.contentC}`}>
         {showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <GroupsEditorPopup index={groupIndex} handleClose={() => setShowPopup(false)} type={type} />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <div className={`header-s underline`}>
               {type === "bulk"
                  ? "Bulk Groups"
                  : type === "units"
                  ? "Unit Groups"
                  : type === "overview"
                  ? "Overview Groups"
                  : "Property Groups"}
            </div>
            <MainButton title="Create new Group" onClick={() => newBtn()} />
         </div>
         <ul className={`${styles.groupsC}`}>
            {source.map(({ children, title, type, zone }, key) => (
               <li className={`${styles.group}`} key={key}>
                  <div className={`${styles.groupHeader}`}>
                     <div className={`${styles.title}`}>{title}</div>
                     <div className={`${styles.icons}`}>
                        <img
                           src={s3afBucketURI("/icons/Arrow_left.svg")}
                           alt="left"
                           onClick={() => moveGroup(key, "left")}
                        />
                        <img
                           src={s3afBucketURI("/icons/Arrow_right.svg")}
                           alt="right"
                           onClick={() => moveGroup(key, "right")}
                        />
                        <img
                           src={s3afBucketURI("/icons/Setting_fill.svg")}
                           alt="settings_fill"
                           onClick={() => editBtn(key)}
                        />
                     </div>
                  </div>
                  <div className={`${styles.groupInfoC}`}>
                     <div className={`${styles.type}`}>Type: {type || "group"}</div>
                     <div className={`${styles.fieldCount}`}>Containing {children?.length || 0} field(s)</div>
                     {zone && <div>Zone: {zone}</div>}
                  </div>
               </li>
            ))}
         </ul>
      </article>
   );
};

export default GroupsEditor;
type TgroupsEditor = {
   type: TgroupEditorType;
};
export type TgroupEditorType = "units" | "bulk" | "groups" | "overview";
