import styles from "./AioUws.module.scss";
import { useState } from "react";
import Popup from "../common/popup/Popup";
import { SHrBodyFiller, SHrMainButton, SHrMainInput, SHrPopup } from "@simplyhomes/react";
import { useQAioUws } from "../../hooks/querries/aio/underwritings/useQAioUws";
import AioUwsCreatePopup from "./AioUwsCreatePopup/AioUwsCreatePopup";
import { Link } from "react-router-dom";
import AioUwCard from "./AioUwCard/AioUwCard";

const AioUws = () => {
   const qUws = useQAioUws({ options: { refetchOnMount: true } });
   const uws = qUws.data?.underwritings || [];
   const [showCreatePopup, setShowCreatePopup] = useState(false);
   // const [showSchemaPopup, setShowSchemaPopup] = useState(false);
   const [search, setSearch] = useState("");

   const loadingMsg = qUws.isLoading ? "Loading underwritings..." : "";
   if (loadingMsg)
      return (
         <section className={`${styles.contentC}`}>
            <SHrBodyFiller style={{ height: "100%" }} message={loadingMsg} />
         </section>
      );

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL} header-s`}>Underwriting</div>
            <div className={`${styles.headerM}`}>
               <SHrMainInput
                  title="Search"
                  type="text"
                  value={search}
                  onChange={setSearch}
                  // containerProps={{ style: { width: "20rem" } }}
               />
            </div>
            <div className={`${styles.headerR}`}>
               <SHrPopup
                  show={showCreatePopup}
                  handleShow={setShowCreatePopup}
                  triggerElement={
                     <SHrMainButton title="Create" type="primary" onClick={() => setShowCreatePopup(true)} />
                  }
               >
                  <AioUwsCreatePopup onSubmit={qUws.refetch} onClose={() => setShowCreatePopup(false)} />
               </SHrPopup>
            </div>
         </div>
         <div className={`${styles.uwsC}`}>
            <div className={`${styles.uwsHeaderC}`}>
               <div className={`${styles.uwsHeaderL}`}>
                  <h1 className={`cardHeader-s`}>{uws.length} Underwriting</h1>
               </div>
            </div>
            <ul className={`${styles.uws}`}>
               {uws.map((uw, i) => (
                  <li key={i} className={`${styles.uwC}`}>
                     <Link to={uw.uid.toString()}>
                        <AioUwCard underwriting={uw} />
                     </Link>
                  </li>
               ))}
            </ul>
         </div>
      </section>
   );
};

export default AioUws;
