import { useEffect, useState } from "react";
import Helm from "../../components/common/helm/Helm";
import useQUws from "../../hooks/querries/underwritings/useQUws";
import styles from "./Underwritings.module.scss";
import { TUnknownObject } from "../../types/interfaces/unknownObj";
import {
   Outlet,
   createSearchParams,
   useNavigate,
   useOutletContext,
   useParams,
   useSearchParams,
} from "react-router-dom";
import MainInput from "../../components/common/mainInput/MainInput";
import useQVersions from "../../hooks/querries/versions/useQVersions";
import MainButton from "../../components/common/mainButton/MainButton";
import Popup from "../../components/common/popup/Popup";
import CreateUwPopup from "../../components/children/underwritings/createUwPopup/CreateUwPopup";
import BodyFiller from "../../components/common/bodyFiller/BodyFiller";
import { TUwData } from "../../types/hsObjects";
import useQUwCache from "../../hooks/querries/underwritings/useQUwCache";
import useQUw from "../../hooks/querries/underwritings/useQUw";
import JSONtryParse from "../../utils/JSON/JSONtryParse";
import UploadCsvPopup from "../../components/children/underwritings/uploadCsvPopup/UploadCsvPopup";
import csvToJson from "csvtojson";
const Underwritings = () => {
   const nav = useNavigate();

   const { data: qVersionData } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersionData || {};
   const { data: qUwsData, isFetching: qUwsFetching } = useQUws();
   const { underwritings = [] } = qUwsData || {};

   const [showUwCreatePu, setShowUwCreatePu] = useState(false);
   const [showCsvUploadPu, setShowCsvUploadPu] = useState(false);
   const [uw, setUw] = useState<TUwData>({});
   const { uwCache, addToUwCache } = useQUwCache();
   const [search, setSearch] = useState("");
   const [bulkMode, setBulkMode] = useState(false);
   const [csvFile, setCsvFile] = useState<File>();
   const [jsonFile, setJsonFile] = useState<TUnknownObject[]>([]);

   const { uwsid } = useParams();
   const [searchParams, setSearchParams] = useSearchParams();
   const ver = searchParams.get("ver") || "";

   const { data: qUwData } = useQUw({ uwsids: [uwsid as string] });
   const { aio_original_data, aio_uw_data } = qUwData?.underwritings[0] || {};

   useEffect(() => {
      setUw({ ...JSONtryParse<TUwData>(aio_original_data, {}), ...JSONtryParse<TUwData>(aio_uw_data, {}) });
   }, [aio_original_data, aio_uw_data]);

   const handleBulk = (b: boolean) => {
      setBulkMode(b);
      if (b) return nav("bulk");
      return nav("");
   };

   const handleSearch = (str: string) => {
      setSearch(str);
      nav({ pathname: str.split("|").pop(), search: createSearchParams({ ver }).toString() });
   };

   const handleVer = (ver: string) => {
      searchParams.set("ver", ver);
      setSearchParams(searchParams);
   };
   if (qUwsFetching) return <BodyFiller loading />;
   const handleCsvUpload = async (file: File) => {
      if (!file || file.type !== "text/csv") return;
      setCsvFile(file);
      const parsed = await csvToJson().fromString(await file.text());
      setJsonFile(parsed);
      setShowCsvUploadPu(false);
   };

   const isValidJsFile = jsonFile.every((obj) => !!obj.Address && !!obj.Bedrooms);
   return (
      <section className={`${styles.contentC}`}>
         <Helm titles={["Underwritings"]} />
         {showUwCreatePu && (
            <Popup onClose={() => setShowUwCreatePu(false)}>
               <CreateUwPopup handleClose={() => setShowUwCreatePu(false)} />
            </Popup>
         )}
         {showCsvUploadPu && (
            <Popup onClose={() => setShowCsvUploadPu(false)}>
               <UploadCsvPopup handleFile={(files) => handleCsvUpload(files[0])} />
            </Popup>
         )}

         <div className={`${styles.controlsC}`}>
            <div className={`${styles.controlsLeft}`}>
               <MainInput type="checkbox" title="Bulk Mode" value={bulkMode} handleValue={handleBulk} />
               {bulkMode ? (
                  <>
                     <a
                        href="https://selltosimply-cdn.s3.amazonaws.com/auto-form/underwriting/template.csv"
                        download={"Template"}
                        target="_blank"
                        rel="noreferrer"
                     >
                        <MainButton title="Download Template" />
                     </a>
                     <MainButton title="Upload CSV" onClick={() => setShowCsvUploadPu(true)} />
                     {csvFile && (
                        <>
                           <div className={`${styles.csvUploadedC}`}>
                              <div>
                                 <b>Uploaded: </b>
                              </div>
                              <div>{csvFile.name}</div>
                              <div>({jsonFile.length} lines)</div>
                           </div>
                           <MainInput
                              value={ver || ""}
                              title={"Select a version"}
                              type="dropdown"
                              options={versions}
                              handleValue={handleVer}
                           />
                        </>
                     )}
                  </>
               ) : (
                  <>
                     <MainInput
                        type="search"
                        title="Search Underwritings"
                        // options={underwritings.map(
                        //    (obj) =>
                        //       `[${obj.name}]${obj.property_address_one_line_}|${obj.hs_object_id}|${obj.simply_object_id}`
                        // )}
                        value={search}
                        handleValue={handleSearch}
                        className={`${styles.uwSearchInput}`}
                     />
                     {uwsid && (
                        <MainInput
                           value={ver || ""}
                           title={"Select a version"}
                           type="dropdown"
                           options={versions}
                           handleValue={handleVer}
                        />
                     )}
                  </>
               )}
            </div>
            <div className={`${styles.controlsRight}`}>
               {!bulkMode && <MainButton title="Create new Underwriting" onClick={() => setShowUwCreatePu(true)} />}
            </div>
         </div>
         {bulkMode && !csvFile ? (
            <BodyFiller fillerMsg="Upload a proper CSV file to start" />
         ) : bulkMode && !isValidJsFile ? (
            <BodyFiller fillerMsg="Corrupted CSV" />
         ) : bulkMode && !ver ? (
            <BodyFiller fillerMsg="Select a version to Start!" />
         ) : !bulkMode && !uwsid ? (
            <BodyFiller fillerMsg="Select or create new an Underwriting Object to start!" />
         ) : (
            <Outlet context={{ uw, setUw, uwCache, addToUwCache, jsonFile }} />
         )}
      </section>
   );
};

export default Underwritings;
type TContext = {
   uw: TUwData;
   setUw: React.Dispatch<React.SetStateAction<TUwData>>;
   uwCache: TUnknownObject;
   addToUwCache: (str: string) => void;
   jsonFile: TUnknownObject[];
};
export const useUwContext = () => useOutletContext<TContext>();
