import { SHu_js_upperFirstLetter } from "@simplyhomes/utils";
import { useQNotificationSubscriptions } from "../../../../hooks/querries/notificationSubscriptions/useQNotificationSubscriptions";
import { useStateParams } from "../../../../hooks/react/useStateParams";
import TabButton from "../../../common/tabButton/TabButton";
import styles from "./SubscriptionsSettings.module.scss";
import useQUws from "../../../../hooks/querries/underwritings/useQUws";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import MainButton from "../../../common/mainButton/MainButton";
import { useState } from "react";
import MainInput from "../../../common/mainInput/MainInput";
import useAxios from "../../../../hooks/axios/useAxios";
const SubscriptionsSettings = () => {
   const { aioAxios } = useAxios();

   const { getStateParams } = useStateParams();
   const [noteSubsObject, setNoteSubsObject] = getStateParams("noteSubsObject", "underwritings");

   const { data: qUwsData, isFetching: qUwsFetching } = useQUws();
   const { underwritings: uws = [] } = qUwsData || {};

   const { data: qNofSubData, isFetching: qNofSubFetching, refetch: qNofSubRefetch } = useQNotificationSubscriptions();
   const { subscriptions = [] } = qNofSubData || {};

   const [showNoteSubAdd, setShowNoteSubAdd] = useState(false);
   const [noteSubAddId, setNoteSubAddId] = useState("");
   const [submitting, setSubmitting] = useState(false);

   const noteSubs = subscriptions.filter((sub) => sub.event === "subscribed to notes");
   const noteSubsObjects = [...new Set(noteSubs.map((ss) => ss.object))];
   const objectIds = noteSubs.find((sub) => sub.object === noteSubsObject)?.objectids || [];
   const objects: { label: string; id: string | number }[] =
      noteSubsObject === "underwritings"
         ? uws.map((uw) => ({
              id: uw["underwritings.hs_object_id"] || "",
              label: uw["underwritings.property_address_one_line_"] || "",
           }))
         : [];
   const findUw = (id: number) => uws.find((u) => u["underwritings.hs_object_id"]?.toString() === id.toString());
   const handleSubscribe = async ({ subscribe, objectid }: { objectid?: number; subscribe: boolean }) => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/notification-subscriptions`, {
            event: "subscribed to notes",
            object: "underwritings",
            objectid: objectid || noteSubAddId,
            subscribe,
         })
         .catch((er) => console.log({ er }));
      qNofSubRefetch();
      setSubmitting(false);
   };
   if (qUwsFetching || qNofSubFetching || submitting)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.noteSubsC}`}>
            <h1 className={`cardHeader-s`}>Subcriptions to Notes</h1>
            <div className={`${styles.noteSubsTabsC}`}>
               {noteSubsObjects.map((o, key) => (
                  <TabButton
                     title={`${SHu_js_upperFirstLetter(o)} (${objectIds.length})`}
                     isActive={noteSubsObject === o}
                     onClick={() => setNoteSubsObject(o)}
                     key={key}
                  />
               ))}
            </div>
            <div className={`${styles.noteSubs}`}>
               {showNoteSubAdd ? (
                  <div className={`${styles.noteSubAddC}`}>
                     <MainInput
                        value={objects.find((o) => o.id === noteSubAddId)?.label || ""}
                        useInternal
                        type="search"
                        options={uws.map(
                           (uw) =>
                              `${uw["underwritings.property_address_one_line_"]}|${uw["underwritings.hs_object_id"]}`
                        )}
                        handleValue={(str) => setNoteSubAddId(str.split("|").pop() || "")}
                     />
                     <MainButton title="Cancel" type="bland" onClick={() => setShowNoteSubAdd(false)} />
                     <MainButton
                        title="Subscribe"
                        onClick={() => handleSubscribe({ subscribe: true })}
                        confirmMsg={`Subscribe ${objects.find((o) => o.id === noteSubAddId)?.label}?`}
                     />
                  </div>
               ) : (
                  <MainButton title="Add new" type="bland" onClick={() => setShowNoteSubAdd(true)} />
               )}
               <ul>
                  {objectIds.map((oid, key) => (
                     <li key={key}>
                        <b className={`${styles.address} ellipsis`}>
                           {noteSubsObject === "underwritings"
                              ? findUw(oid)?.["underwritings.property_address_one_line_"]
                              : ""}
                        </b>
                        <MainButton
                           title="Unsubcribe"
                           type="bland"
                           onClick={() => handleSubscribe({ subscribe: false, objectid: oid })}
                           confirmMsg={`Unsubscribe ${findUw(oid)?.["underwritings.property_address_one_line_"]}?`}
                        />
                     </li>
                  ))}
               </ul>
            </div>
         </div>
      </article>
   );
};

export default SubscriptionsSettings;
