import { useNavigate, useParams } from "react-router-dom";
import { useQAioUw } from "../../../hooks/querries/aio/underwritings/useQAioUw";
import { SHrBodyFiller } from "@simplyhomes/react";
import AioUwViewer from "../AioUwViewer/AioUwViewer";
const AioUw = () => {
   const { uid = "" } = useParams();
   const nav = useNavigate();
   const qUw = useQAioUw({ uid, options: { refetchOnMount: true } });
   if (qUw.isLoading) return <SHrBodyFiller message="Loading App Data" />;
   if (!qUw.data) return <SHrBodyFiller message="No App Data" />;
   const { version, appdata, address } = qUw.data.underwriting;
   return (
      <AioUwViewer version={version} address={address} appdata={appdata} onClose={() => nav("/aio-underwriting")} />
   );
};

export default AioUw;
