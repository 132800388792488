import { TSHrMainInput_Tag } from "@simplyhomes/react";
import { TAIO_Version2_Config_Field } from "@simplyhomes/utils";

export const utils_AioFields_GenerateTags = (f?: TAIO_Version2_Config_Field) => {
   if (!f) return [];
   const tags: TSHrMainInput_Tag[] = [];
   if (f.type === "formula") tags.push({ label: "FX", style: "Primary" });
   if (f.type === "value") {
      tags.push({ label: "Import", style: "Accent" });
      if (f.config.source === "airtable_properties") tags.push({ label: "ATP", style: "Secondary" });
      if (f.config.source === "airtable_underwriting") tags.push({ label: "ATUw", style: "Secondary" });
   }
   return tags;
};
