import { SHu_JSONtryParse, SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import useQUw from "../../../hooks/querries/underwritings/useQUw";
import useQVersion, { TversionField } from "../../../hooks/querries/versions/useQVersion";
import styles from "./UwUCompare.module.scss";
import BodyFiller from "../bodyFiller/BodyFiller";
import parseFieldName from "../../../utils/fields/parseFieldName";
import MainInput from "../mainInput/MainInput";
import { useState } from "react";
import useQVersions from "../../../hooks/querries/versions/useQVersions";
import MainButton from "../mainButton/MainButton";
import TableV2, { TTableV2_headerData } from "../tableV2/TableV2";
const UwUCompare = (p: TUwUCompareProps) => {
   const [ver, setVer] = useState(p.ver);
   const [search, setSearch] = useState("");

   const { data: qVerData, isFetching: qVerF } = useQVersion({ ver, obj: "underwritings" });
   const { config } = qVerData || {};
   const { fields = [] } = config || {};

   const { data: qVersData, isFetching: qVersF } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersData || {};

   const { data: qUwData, isFetching: qUwF } = useQUw({ enabled: !!p.uwids, uwsids: p.uwids || [] });

   const uws = p.uwids
      ? qUwData?.underwritings.map((uw) =>
           SHu_JSONtryParse<Record<string, any>, Record<string, any>>(uw.aio_uw_data || "", {})
        ) || []
      : p.uws;

   const keyDifferences: { key: string; field?: TversionField }[] = Object.keys(uws[0] || {})
      .filter((key) => key !== "$childUnits" && !uws.every((uw) => uw[key] === uws[0][key]))
      .map((key) => ({
         key,
         field: fields.find((f) => f.column === key || parseFieldName(f.name) === key),
      }))
      .filter((o) => o.field?.name.toLowerCase().includes(search.toLowerCase()));
   const headerData: TTableV2_headerData = [
      { key: "label", label: "Differences", format: [{ type: "bold" }] },
      ...uws.map((_, i) => ({
         label: !p.uws
            ? qUwData?.underwritings[i].property_address_one_line_ || "Unknown"
            : i === 0
            ? "Before"
            : "After",
         key: i.toString(),
      })),
   ];
   const tableData = keyDifferences.map(({ key, field }) => ({
      label: field?.name || key,
      ...uws.reduce(
         (prev, curr, i) => ({
            ...prev,
            [i]:
               field?.format === "Currency"
                  ? SHu_js_currencyFormat(curr[key])
                  : field?.format === "Percentage"
                  ? SHu_js_percentageFormat(curr[key])
                  : JSON.stringify(curr[key]),
         }),
         {}
      ),
   }));
   if (qVerF || qUwF || qVersF)
      return (
         <article className={`${styles.contentC}`}>
            <BodyFiller loading />
         </article>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <h1 className={`header-s`}>Compare {uws.length} Underwriting</h1>
               <MainInput value={search} handleValue={setSearch} title="Search for fields" disableCollapse />
            </div>
            <div className={`${styles.headerR}`}>
               <MainInput value={ver} type="dropdown" handleValue={setVer} title="Version" options={versions} />
               {!!p.onSave && <MainButton title="Save" onClick={p.onSave} confirmMsg="Overwrite with new data?" />}
               <MainButton type="close" onClick={p.onClose} />
            </div>
         </div>
         <div className={`${styles.bodyC}`}>
               <TableV2
                  headerData={headerData}
                  tableData={tableData}
                  showFilterIcon={false}
                  stickyFirstColumn
                  stickyFirstRow
               />
         </div>
      </article>
   );
};

export default UwUCompare;
type TUwUCompareProps = {
   ver: string;
   onClose: () => void;
   onSave?: () => void;
} & (
   | {
        uws: Record<string, any>[];
        uwids?: never;
     }
   | {
        uws?: never;
        uwids: string[];
     }
);
