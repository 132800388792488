export const assetGetStageColor = (str: any) =>
   str === "Assigned"
      ? "#C77802"
      : str === "Due Diligence"
      ? "#577590"
      : str === "Funding/Closing"
      ? "#90be6d"
      : str === "Onboarding"
      ? "#43aa8b"
      : str === "Rehabilitation"
      ? "#8F96FF"
      : str === "Unit Leased" || str === "Leasing"
      ? "#221A68"
      : "grey";
