import { useSearchParams } from "react-router-dom";
import useQVersion, { TversionField } from "../../../../hooks/querries/versions/useQVersion";
import { useUwUbContext } from "../../../../pages/UwUb/UwUb";
import TabButton from "../../../common/tabButton/TabButton";
import styles from "./UwUbConfig.module.scss";
import { useMemo, useState } from "react";
import MainInput from "../../../common/mainInput/MainInput";
import UwUbField from "../uwubField/UwUbField";
import { TUwData } from "../../../../types/hsObjects";
import useResolveUwUBulk from "../../../../hooks/formulas/useResolveUwUBulk";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
const UwUbConfig = ({ tab, onTabChange }: TUwubConfigProps) => {
   const [searchParams] = useSearchParams();
   const ver = searchParams.get("ver") || "";

   const { uwubRecalcUw } = useResolveUwUBulk();
   const isOutputTab = tab === "opPropFields" || tab === "opUnitFields";

   const isPropTab = tab === "opPropFields" || tab === "orPropFields";
   const { uwubState, uwubDispatch } = useUwUbContext();
   const { uwCache, uwOptions, uwBase } = uwubState;

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { groups = [], fields = [], units = [] } = qVerData?.config || {};

   const [search, setSearch] = useState("");
   const fieldIds = uwubState[tab];
   const Groups = (isPropTab ? groups : units)
      .map((group) => ({
         ...group,
         children: group.children.filter((fId) => {
            const field = fields.find((f) => f.id === fId);
            if (
               field &&
               (tab.startsWith("op") || (!field.readonly && !field.formula)) &&
               (search === "" || field.name.toLowerCase().includes(search.toLowerCase()))
            )
               return true;
            return false;
         }),
      }))
      .filter((group) => group.children.length > 0);

   const Fields = useMemo(
      () => fieldIds.map((fId) => fields.find((f) => f.id === fId)).filter(Boolean) as TversionField[],
      [fieldIds, fields]
   );

   const unitIndex = tab === "orUnitFields" ? 0 : undefined;
   const handleSelect = (fId: string) =>
      uwubDispatch({ [tab]: fieldIds.includes(fId) ? fieldIds.filter((id) => id !== fId) : [...fieldIds, fId] });
   const handleUwBase = async ({ fieldIds, uw, unitIndex }: THandleUwBaseParams) => {
      uwubDispatch({ uwProcessing: true });
      uwubDispatch({ uwBase: uw });
      uwubDispatch({
         ...(await uwubRecalcUw({ curUw: uw, curCache: uwCache, curOptions: uwOptions, fieldIds, unitIndex })),
      });
   };
   type THandleUwBaseParams = { uw: TUwData; fieldIds: string[]; unitIndex?: number };
   if (qVerFetching) return <BodyFiller loading />;
   return (
      <div className={`${styles.contentC} noSelect`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.title} header-s`}>{isOutputTab ? "Output" : "Override"} Config</div>
            {isOutputTab ? (
               <>
                  <TabButton
                     isActive={tab === "opPropFields"}
                     title="Property"
                     onClick={() => onTabChange("opPropFields")}
                  />
                  <TabButton
                     isActive={tab === "opUnitFields"}
                     title="Unit"
                     onClick={() => onTabChange("opUnitFields")}
                  />
               </>
            ) : (
               <>
                  <TabButton
                     isActive={tab === "orPropFields"}
                     title="Property"
                     onClick={() => onTabChange("orPropFields")}
                  />
                  <TabButton
                     isActive={tab === "orUnitFields"}
                     title="Unit"
                     onClick={() => onTabChange("orUnitFields")}
                  />
               </>
            )}
         </div>
         <div className={`${styles.bodyC}`}>
            {fieldIds.length > 0 && (
               <div className={`${styles.selectedC}`}>
                  <b>Selected: </b>
                  {Fields.map(({ id, name }, key) => (
                     <button key={key + id} onClick={() => handleSelect(id)}>
                        <span>{name}</span>
                        <span>+</span>
                     </button>
                  ))}
               </div>
            )}
            <div className={`${styles.selectionsC}`}>
               <div className={`${styles.selectionsHeader}`}>
                  <b>Field Selection: </b>
                  <MainInput title="Search" value={search} handleValue={setSearch} style={{ height: 42 }} />
               </div>
               <div className={`${styles.Groups}`}>
                  {Groups.map((group, key) => (
                     <div className={`${styles.Group}`} key={key + group.id}>
                        <div className={`${styles.GroupTitle}`}>{group.title}</div>
                        <div className={`${styles.typeGroup}`}>
                           {group.children.map((fId, key) => (
                              <div className={`${styles.fieldC}`} key={key + fId}>
                                 <MainInput
                                    type="checkbox"
                                    value={fieldIds.includes(fId)}
                                    style={{ width: "2rem" }}
                                    handleValue={() => handleSelect(fId)}
                                 />
                                 {isOutputTab ? (
                                    <span>{fields.find((f) => f.id === fId)?.name}</span>
                                 ) : (
                                    <UwUbField
                                       key={fId + key}
                                       fieldId={fId}
                                       unitIndex={unitIndex}
                                       handleUw={(uw) => handleUwBase({ uw, fieldIds: [fId], unitIndex })}
                                       curOptions={uwOptions}
                                       curUw={uwBase}
                                    />
                                 )}
                              </div>
                           ))}
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default UwUbConfig;
type TUwubConfigProps = {
   tab: TUwUbConfigTab;
   onTabChange: (tab: TUwUbConfigTab) => void;
};
export type TUwUbConfigTab = "opPropFields" | "opUnitFields" | "orPropFields" | "orUnitFields";
