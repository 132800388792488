import { useState } from "react";
import useQUwSnapshots from "../../../../hooks/querries/properties/useQUwSnapshots";
import AFInput from "../../../common/AFInput/AFInput";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import styles from "./PropertySnapshotsPopup.module.scss";
import MainButton from "../../../common/mainButton/MainButton";
import useQUwSnapshot from "../../../../hooks/querries/properties/useQUwSnapshot";
import { usePropertiesContext } from "../../../../pages/Properties/Properties";
import useAxios from "../../../../hooks/axios/useAxios";
import { useSearchParams } from "react-router-dom";
import jsNumberToDate from "../../../../utils/JS/jsNumberToDate";
const PropertySnapshotsPopup = ({ pid, handleClose }: IPropertySnapshotsProps) => {
   const [params] = useSearchParams();
   const version = params.get("ver") || "";

   const [snapshot, setSnapshot] = useState("");
   const [note, setNote] = useState("");
   const [submitting, setSubmitting] = useState(false);
   const { aioAxios } = useAxios();
   const { data: uwSnaps, isFetching: uwSnapsIsFetching, refetch: uwSnapsRefetch } = useQUwSnapshots({ pid, version });
   const { snapshots = [] } = uwSnaps || {};
   const propSnapsDates = snapshots.map((snapshot) => ({
      snapshot,
      date: jsNumberToDate(snapshot),
   }));
   const { data: uwSnapData, isFetching: uwSnapIsFetching } = useQUwSnapshot({ pid, snapshot, version });
   const { property, setProperty } = usePropertiesContext();
   const handleOverWrite = () => {
      setProperty(uwSnapData?.data || {});
      handleClose();
   };
   const handleCreateSnap = async () => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/properties/snapshot/${pid}`, { property, note }, { params: { version } })
         .catch((err) => console.log({ err }));
      uwSnapsRefetch();
      setSubmitting(false);
   };
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.titleC}`}>
            <div className={`header-s`}>Snapshot Manager ({version})</div>
            <MainButton title="Refresh" onClick={uwSnapsRefetch} />
         </div>
         <div className={`${styles.creationC}`}>
            <AFInput title="Note" value={note} handleChange={setNote} />
            <MainButton title="Create Snap" onClick={handleCreateSnap} enable={!submitting} confirmMsg="Confirm?" />
         </div>
         {uwSnapsIsFetching || submitting ? (
            <BodyFiller loading />
         ) : snapshots.length === 0 ? (
            <BodyFiller fillerMsg="No Snapshot found for this property" />
         ) : (
            <div className={`${styles.controls}`}>
               <AFInput
                  type="dropdown"
                  title="Snapshot"
                  value={jsNumberToDate(snapshot)}
                  options={propSnapsDates
                     .sort((a, b) => Number(b.snapshot) - Number(a.snapshot))
                     .map(({ date }) => date)}
                  handleChange={(date) =>
                     setSnapshot(propSnapsDates.find((snap) => snap.date === date)?.snapshot || "")
                  }
                  style={{ width: "12rem" }}
               />
               <MainButton
                  title="Use This"
                  onClick={handleOverWrite}
                  confirmMsg="Unsaved data on the calculator will be overwritten!"
                  enable={!uwSnapIsFetching && !!snapshot}
               />
            </div>
         )}
         {snapshot && !uwSnapsIsFetching && (
            <div className={`${styles.infoC}`}>
               <div>
                  <b>Created at:</b>
                  <span>{new Date(Number(snapshot)).toLocaleString("en-US", { timeZone: "America/New_York" })}</span>
               </div>
               <div>
                  <b>Note: </b>
                  <span>{uwSnapData?.note || "-"}</span>
               </div>
               {uwSnapIsFetching ? (
                  <BodyFiller loading />
               ) : (
                  // <AFInput value={snapDisplay} type="textarea" />
                  <div className={`${styles.preview}`}>
                     <pre>
                        <code>{JSON.stringify(uwSnapData?.data || {}, null, 2)}</code>
                     </pre>
                  </div>
               )}
            </div>
         )}
      </article>
   );
};

export default PropertySnapshotsPopup;
interface IPropertySnapshotsProps {
   pid: string;
   handleClose: () => void;
}
