import { useMemo, useRef, useState } from "react";
import { useAioVersionContext } from "../../AioVersion";
import styles from "./AioVersionTabFieldEditor.module.scss";
import {
   SHu_aio_helper_parseFieldName,
   SHu_const_AIO_Version2_Field_Format,
   SHu_const_AIO_Version2_Field_Type,
   SHu_const_AIO_Version2_Field_Type_Input_Type,
   SHu_const_AIO_Version2_Field_Type_Value_Source,
   TAIO_Version2_Config_Field,
   TAIO_Version2_Config_Field_Types,
} from "@simplyhomes/utils";
import { useQAtUwsMeta } from "../../../../../hooks/querries/airtable/underwritings/useQAtUwsMeta";
import { useQAioUwsMeta } from "../../../../../hooks/querries/aio/underwritings/useQAioUwsMeta";
import { useQAioFormulas } from "../../../../../hooks/querries/aio/formulas/useQAioFormulas";
import parseFormula from "../../../../../utils/formula/parseFormula";
import { SHrMainButton, SHrMainInput } from "@simplyhomes/react";
import Popup from "../../../../common/popup/Popup";
import { useQAtMeta } from "../../../../../hooks/querries/airtable/metadata/useQAtMeta";
import AioUwsSchemaFieldEditor from "../../../../AioUws/AioUwsSchema/AioUwsSchemaFieldEditor/AioUwsSchemaFieldEditor";
import { useQAtPropsMeta } from "../../../../../hooks/querries/airtable/properties/useQAtPropsMeta";
const AioVersionTabFieldEditor = ({ fid, onClose, level }: TVersion2FieldEditorProps) => {
   const isEditing = fid != null;
   const { aioVersionState: ver2State, aioVersionDispatch: ver2Dispatch } = useAioVersionContext();
   const configKey = level === "property" ? "propFields" : "unitFields";
   const allFields = [...ver2State.config.propFields, ...ver2State.config.unitFields];
   const levelFields = ver2State.config?.[configKey] || [];
   const curField = levelFields.find((f) => f.id === fid);

   const qAtMeta = useQAtMeta({ options: { refetchInterval: 30000 } });

   const qAtPropsMeta = useQAtPropsMeta({ options: { refetchInterval: 30000 } });

   const qAtUwsMeta = useQAtUwsMeta({ options: { refetchInterval: 30000 } });

   const qAioUwsMeta = useQAioUwsMeta({ options: { refetchInterval: 30000 } });

   const qAioFxs = useQAioFormulas({ options: { refetchInterval: 30000 } });

   const textareaRef = useRef<HTMLTextAreaElement>(null);

   const id = curField?.id || `fid${Math.ceil(Date.now() / 1000)}`;
   const [label, setLabel] = useState(curField?.label || "");
   const [key, setKey] = useState(curField?.key || "");
   const [format, setFormat] = useState<(typeof SHu_const_AIO_Version2_Field_Format)[number] | undefined>(
      curField?.format || undefined
   );
   const [outputUwAT, setOutputUwAT] = useState(curField?.output_airtable_underwriting || "");
   const [outputUwAIO, setOutputUwAIO] = useState(curField?.output_aio_underwriting || "");

   const [type, setType] = useState<(typeof SHu_const_AIO_Version2_Field_Type)[number]>(curField?.type || "input");

   const [inputType, setInputType] = useState(
      curField?.type === "input" && curField.config.type ? curField?.config.type : "text"
   );
   const [inputReadonly, setInputReadonly] = useState(
      curField?.type === "input" && curField.config.readonly ? curField.config.readonly : false
   );
   const [inputDefault, setInputDefault] = useState(
      curField?.type === "input" && curField.config.default ? curField.config.default : ""
   );
   const [inputOptions, setInputOptions] = useState(
      curField?.type === "input" && curField.config.options ? curField.config.options : []
   );
   const [valueSource, subSetValueSource] = useState<(typeof SHu_const_AIO_Version2_Field_Type_Value_Source)[number]>(
      curField?.type === "value" && curField.config.source ? curField.config.source : "airtable_properties"
   );

   const [valueColumn, setValueColumn] = useState(
      curField?.type === "value" && curField.config.column ? curField.config.column : ""
   );
   const [formula, setFormula] = useState(
      curField?.type === "formula" && curField.config.formula ? curField.config.formula : ""
   );

   const [showFieldEditor, setShowFieldEditor] = useState(false);
   const [fxShowSuggs, setFxShowSuggs] = useState(true);
   const [fxSuggVerFields, setFxSuggVerFields] = useState(true);
   const [fxSuggFxs, setFxSuggFxs] = useState(true);
   const [fxSuggLengends, setFxSuggLengends] = useState(true);
   const [fxShowDeps, setFxShowDeps] = useState(true);

   const isDupe = !isEditing && levelFields.some((f) => f.key === key);
   const fxMaskedCaretPos = textareaRef.current?.selectionEnd || 0;
   const fxFidsDep: string[] = formula.match(/\[((?!.*\|.*).*?)\]/g)?.map((str) => str.replace(/\[|\]/g, "")) || [];
   const fxFieldsDep = allFields.filter((f) => fxFidsDep.includes(f.id));

   const fieldsIdLabelMap: Record<string, string> = allFields.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: cur.label }),
      {}
   );
   const fxMasked = fxFidsDep?.reduce(
      (acc: string, cur: string) => acc.replace(`[${cur}]`, `[${cur}|${fieldsIdLabelMap[cur]}]`),
      formula
   );
   const fxFxDeps = qAioFxs.data?.formulas.filter((f) => f.custom && formula.includes(f.formula)) || [];
   const fxHelperKeyword = fxMasked.slice(0, fxMaskedCaretPos).split(" ").pop()?.replaceAll("\n", "") || "";

   const isValidFx = !!parseFormula(formula);
   const dbLegends: { label: string; value: string; type: string }[] = useMemo(
      () => [
         { label: "Airtable Database", value: "AT", type: "Database" },
         { label: "AIO Database", value: "AIO", type: "Database" },
         ...(qAtMeta.data?.tableMetadata.map(({ name, tid }) => ({ label: name, value: tid, type: "AT-Table" })) || []),
         ...(qAtMeta.data?.fieldMetadata.map(({ fid, name, tid }) => ({
            label: `${name}(${qAtMeta.data?.tableMetadata.find((t) => t.tid === tid)?.name})`,
            value: fid,
            type: "AT-Column",
         })) || []),
      ],
      [qAtMeta.data?.fieldMetadata, qAtMeta.data?.tableMetadata]
   ).filter(
      (l) =>
         l.label.toLowerCase().includes(fxHelperKeyword.toLowerCase()) ||
         l.value.toLowerCase().includes(fxHelperKeyword.toLowerCase())
   );

   const error = isDupe
      ? "Field name already exists"
      : !label
      ? "Label is required"
      : !key
      ? "Name is required"
      : type === "input" && !inputType
      ? "Input type is required"
      : type === "value" && !valueSource
      ? "Source is required"
      : type === "value" && !valueColumn
      ? "Column is required"
      : type === "formula" && !formula
      ? "Formula is required"
      : type === "formula" && !isValidFx
      ? "Invalid formula"
      : null;
   const handleUnmaskFx = (str: string) => {
      const masked: string[] = str.match(/\[((?=.*\|.*).*?)\]/g) || [];
      setFormula(
         masked.reduce((acc: string, cur: string) => acc.replace(cur, `[${cur.slice(1, -1).split("|")[0]}]`), str)
      );
   };
   const findFields = (name: string, level: "all" | "prop" | "unit") =>
      (level === "all"
         ? allFields
         : level === "prop"
         ? ver2State.config.propFields
         : level === "unit"
         ? ver2State.config.unitFields
         : levelFields
      ).filter((f) => (f.key + f.label).toLowerCase().includes(name.toLowerCase()));
   const fxPropFieldSuggestions = findFields(fxHelperKeyword, "prop");
   const fxUnitFieldSuggestions = findFields(fxHelperKeyword, "unit");

   const fxFxSuggestions =
      qAioFxs.data?.formulas
         .filter((f) => f.formula.toLowerCase().includes(fxHelperKeyword.toLowerCase()))
         .sort((a) => (a.formula.toLowerCase().startsWith(fxHelperKeyword.toLowerCase()) ? -1 : 1)) || [];
   const prefixMap = {
      airtable_underwriting: "AT_Uw_",
      airtable_properties: "AT_P_",
      aio_properties: "AIO_P_",
   };
   const handleSave = () => {
      const typeConfig: TAIO_Version2_Config_Field_Types =
         type === "input"
            ? {
                 type,
                 config: { type: inputType, default: inputDefault, options: inputOptions, readonly: inputReadonly },
              }
            : type === "formula"
            ? { type, config: { formula } }
            : { type, config: { source: valueSource, column: valueColumn } };
      const newField: TAIO_Version2_Config_Field = {
         id,
         label,
         key,
         format,
         output_aio_underwriting: outputUwAIO,
         output_airtable_underwriting: outputUwAT,
         ...typeConfig,
      };
      const newFields = isEditing ? levelFields.map((f) => (f.id === id ? newField : f)) : [newField, ...levelFields];
      if (!ver2State.config) return;
      ver2Dispatch({ overwrite: { config: { ...ver2State.config, [configKey]: newFields } } });
      onClose();
   };
   const handleDelete = () => {
      const newFields = levelFields.filter((f) => f.key !== key);
      if (!ver2State.config) return;
      ver2Dispatch({ overwrite: { config: { ...ver2State.config, [configKey]: newFields } } });
      onClose();
   };
   const handleLabel = (str: string) => {
      setLabel(str);
      const prefix = type === "value" ? prefixMap[valueSource] : "";
      if (!key.startsWith("$")) setKey(SHu_aio_helper_parseFieldName(prefix + str));
   };
   const handleKey = (str: string) => {
      if (!str.startsWith("$")) return setKey(SHu_aio_helper_parseFieldName(str));
      setKey("$" + SHu_aio_helper_parseFieldName(str.replace("$", "")));
   };
   const handleType = (input: typeof type) => {
      setType(input);
      if (input === "value") {
         const prefix = prefixMap[valueSource];
         if (!key.startsWith(prefix.toLowerCase())) setKey(SHu_aio_helper_parseFieldName(prefix + key));
      } else {
         const prefixes = Object.values(prefixMap);
         const newKey = prefixes.reduce((acc, cur) => acc.replace(cur.toLowerCase(), ""), key);
         setKey(SHu_aio_helper_parseFieldName(newKey));
      }
   };
   const handleValueSource = (src: (typeof SHu_const_AIO_Version2_Field_Type_Value_Source)[number]) => {
      if (src !== valueSource) setValueColumn("");
      const prefix = prefixMap[src];
      setKey(SHu_aio_helper_parseFieldName(prefix + label));
      subSetValueSource(src);
   };
   const handleFxHelperSuggestion = (str: string) => {
      handleUnmaskFx(
         fxMasked.slice(0, fxMaskedCaretPos - fxHelperKeyword.length) + str + fxMasked.slice(fxMaskedCaretPos)
      );
      textareaRef.current?.focus();
   };
   const handleFieldEditorClose = (b: boolean, val?: string) => {
      setShowFieldEditor(b);
      if (val) {
         setOutputUwAIO(val);
         qAioUwsMeta.refetch();
      }
   };

   return (
      <div className={`${styles.contentC}`}>
         {showFieldEditor && (
            <Popup onClose={setShowFieldEditor}>
               <AioUwsSchemaFieldEditor onClose={handleFieldEditorClose} />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <p className={`header-s`}>
                  {isEditing ? "Editing" : "Creating"} {level} Field
               </p>
            </div>
            <div className={`${styles.headerR}`}>
               <SHrMainButton type="close" onClick={onClose} />
            </div>
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.inputsC}`}>
               <div className={`${styles.inputRow}`} style={{ display: "flex", justifyContent: "space-between" }}>
                  <SHrMainInput title="ID" type="text" value={id} readonly />
                  <SHrMainInput
                     title="Format (optional)"
                     type="dropdown"
                     options={SHu_const_AIO_Version2_Field_Format.map((str) => ({ label: str, value: str }))}
                     value={format || ""}
                     onChange={setFormat}
                     hideOptionValues
                  />
               </div>
               <div className={`${styles.inputRow}`}>
                  <SHrMainInput title="Field Label*" type="text" value={label} onChange={handleLabel} bad={!label} />
                  <SHrMainInput title="Field Key*" type="text" value={key} onChange={handleKey} bad={isDupe || !key} />
               </div>
            </div>
            <div className={`${styles.configC}`}>
               <div className={`${styles.configHeader}`}>
                  <p className={`cardHeader-s`}>Configurations</p>
                  <SHrMainInput
                     title="Type*"
                     type="dropdown"
                     options={
                        SHu_const_AIO_Version2_Field_Type.map((str) => ({ label: str.toUpperCase(), value: str })) || []
                     }
                     value={type}
                     onChange={handleType}
                     bad={!type}
                     hideOptionValues
                  />
               </div>
               <div className={`${styles.configBody}`}>
                  {type === "input" && (
                     <>
                        <SHrMainInput
                           title="Input Type*"
                           type="dropdown"
                           options={SHu_const_AIO_Version2_Field_Type_Input_Type.map((str) => ({
                              label: str.toUpperCase(),
                              value: str,
                           }))}
                           bad={!inputType}
                           value={inputType}
                           onChange={setInputType}
                           hideOptionValues
                        />
                        <SHrMainInput
                           title="Read Only"
                           type="checkbox"
                           value={inputReadonly}
                           onChange={setInputReadonly}
                        />
                        <SHrMainInput
                           title="Default Value"
                           type="text"
                           value={inputDefault}
                           onChange={setInputDefault}
                        />
                        <SHrMainInput
                           title="Options"
                           type="text"
                           value={inputOptions.join(",")}
                           onChange={(v: string) =>
                              setInputOptions(v.includes("|") ? ["=" + v.split("|")[0]] : v.split(","))
                           }
                           tooltip={"Separate options with ','\nexample:option1,option2"}
                           options={findFields(inputOptions.join("|"), "all").map((f) => ({
                              label: f.label,
                              value: f.id,
                           }))}
                        />
                     </>
                  )}
                  {type === "value" && (
                     <>
                        <SHrMainInput
                           title="Source*"
                           type="dropdown"
                           options={SHu_const_AIO_Version2_Field_Type_Value_Source.map((v) => ({ label: v, value: v }))}
                           bad={!valueSource}
                           value={valueSource}
                           onChange={handleValueSource}
                           loading={qAtPropsMeta.isFetching || qAtUwsMeta.isFetching}
                        />
                        <SHrMainInput
                           title="Column*"
                           type="search"
                           bad={!valueColumn}
                           value={valueColumn}
                           onChange={(str) => setValueColumn(str.split("|").pop() || "")}
                           options={
                              valueSource === "airtable_underwriting"
                                 ? qAtUwsMeta.data?.metadata.map(({ fid, name }) => ({ label: name, value: fid }))
                                 : valueSource === "airtable_properties"
                                 ? qAtPropsMeta.data?.metadata.map(({ fid, name }) => ({ label: name, value: fid }))
                                 : []
                           }
                        />
                     </>
                  )}
                  {type === "formula" && (
                     <>
                        <textarea
                           className={`${styles.fxTextarea} ${!isValidFx && styles.fxBad}`}
                           ref={textareaRef}
                           value={fxMasked}
                           onChange={(e) => handleUnmaskFx(e.target.value)}
                           spellCheck={false}
                        />
                        <div className={`${styles.fxHelper}`}>
                           <div className={`${styles.fxHelperHeader}`}>
                              <p className={`${styles.fxHelperHeaderL}`}>
                                 <b>Suggestions for: </b>
                                 <span>"{fxHelperKeyword}"</span>
                              </p>
                              <div className={`${styles.fxHelperHeaderR}`}>
                                 <SHrMainButton
                                    title={fxShowSuggs ? "Hide" : "Show"}
                                    onClick={() => setFxShowSuggs(!fxShowSuggs)}
                                    type="bland"
                                    style={{ fontSize: "10px", padding: "0" }}
                                 />
                              </div>
                           </div>
                           {fxShowSuggs && (
                              <div className={`${styles.fxHelperConfigs}`}>
                                 <SHrMainInput
                                    type="toggle"
                                    title="Version Fields"
                                    value={fxSuggVerFields}
                                    onChange={setFxSuggVerFields}
                                 />
                                 <SHrMainInput
                                    type="toggle"
                                    title="Formulas"
                                    value={fxSuggFxs}
                                    onChange={setFxSuggFxs}
                                 />
                                 <SHrMainInput
                                    type="toggle"
                                    title="Legends"
                                    value={fxSuggLengends}
                                    onChange={setFxSuggLengends}
                                 />
                              </div>
                           )}
                           {fxShowSuggs && !!fxHelperKeyword && (
                              <ul className={`${styles.fxSuggestions}`}>
                                 {fxSuggVerFields && (
                                    <>
                                       {fxPropFieldSuggestions.map((f, i) => (
                                          <li
                                             className={`${styles.fxSuggestion}`}
                                             key={i + "field"}
                                             onClick={() => handleFxHelperSuggestion(`[${f.id}]`)}
                                          >
                                             <div className={`${styles.fxSuggestionL}`}>
                                                <div className={`${styles.fxSuggestionLabel}`}>{f.label}</div>
                                                <div className={`${styles.fxSuggestionName}`}>{f.key}</div>
                                             </div>
                                             <div className={`${styles.fxSuggestionR}`}>
                                                <p className={`cardHeader-s`}>PROP-{f.type}</p>
                                             </div>
                                          </li>
                                       ))}
                                       {fxUnitFieldSuggestions.map((f, i) => (
                                          <li
                                             className={`${styles.fxSuggestion}`}
                                             key={i + "field"}
                                             onClick={() => handleFxHelperSuggestion(`[${f.id}]`)}
                                          >
                                             <div className={`${styles.fxSuggestionL}`}>
                                                <div className={`${styles.fxSuggestionLabel}`}>{f.label}</div>
                                                <div className={`${styles.fxSuggestionName}`}>{f.key}</div>
                                             </div>
                                             <div className={`${styles.fxSuggestionR}`}>
                                                <p className={`cardHeader-s`}>UNIT-{f.type}</p>
                                             </div>
                                          </li>
                                       ))}
                                    </>
                                 )}
                                 {fxSuggFxs &&
                                    fxFxSuggestions.map((f, i) => (
                                       <li
                                          key={i + "fx"}
                                          className={`${styles.fxSuggestion}`}
                                          onClick={() => handleFxHelperSuggestion(`${f.formula}(  )`)}
                                       >
                                          <div className={`${styles.fxSuggestionL}`}>
                                             <div className={`${styles.fxSuggestionLabel}`}>{f.formula}</div>
                                             <div className={`${styles.fxSuggestionDesc}`}>
                                                {f.custom ? f.description : "Native"}
                                             </div>
                                          </div>
                                          <div className={`${styles.fxSuggestionR}`}>
                                             <p className={`cardHeader-s`}>Fx</p>
                                          </div>
                                       </li>
                                    ))}
                                 {fxSuggLengends &&
                                    dbLegends.map((l, i) => (
                                       <li
                                          key={i + "db"}
                                          className={`${styles.fxSuggestion}`}
                                          onClick={() =>
                                             handleFxHelperSuggestion(`AIO_ALIAS("${l.value}","${l.label}")`)
                                          }
                                       >
                                          <div className={`${styles.fxSuggestionL}`}>
                                             <div className={`${styles.fxSuggestionLabel}`}>{l.label}</div>
                                             <div className={`${styles.fxSuggestionDesc}`}>{l.value}</div>
                                          </div>
                                          <div className={`${styles.fxSuggestionR}`}>
                                             <p className={`cardHeader-s`}>{l.type}</p>
                                          </div>
                                       </li>
                                    ))}
                              </ul>
                           )}
                           <div className={`${styles.fxHelperHeader}`}>
                              <p className={`${styles.fxHelperHeaderL}`}>
                                 <b>Dependencies: </b>
                                 <span>
                                    {fxFxDeps.length} Fx and {fxFidsDep.length} Fields
                                 </span>
                              </p>
                              <div className={`${styles.fxHelperHeaderR}`}>
                                 <SHrMainButton
                                    title={fxShowDeps ? "Hide" : "Show"}
                                    onClick={() => setFxShowDeps(!fxShowDeps)}
                                    type="bland"
                                    style={{ fontSize: "10px", padding: "0" }}
                                 />
                              </div>
                           </div>
                           {fxShowDeps && (
                              <ul className={`${styles.fxDeps}`}>
                                 {fxFxDeps.map((f, i) => (
                                    <li key={i + "fx"} className={`${styles.fxDep}`}>
                                       <b>{f.formula}:</b>
                                       <p>{f.description}</p>
                                    </li>
                                 ))}
                                 {fxFieldsDep.length > 0 && (
                                    <li className={`${styles.fxDep}`}>
                                       <b>Fields:</b>
                                       <p>{fxFieldsDep.map((f) => f.label).join(",")}</p>
                                    </li>
                                 )}
                              </ul>
                           )}
                        </div>
                     </>
                  )}
               </div>
               <div className={`${styles.configHeader}`}>
                  <p className={`cardHeader-s`}>Outputs</p>
               </div>
               <div className={`${styles.configBody}`}>
                  <div className={`${styles.configBodyRow}`}>
                     <SHrMainInput
                        title="AIO Underwriting"
                        type="search"
                        loading={qAioUwsMeta.isFetching}
                        options={qAioUwsMeta.data?.metadata.map(({ label, name }) => ({ label, value: name })) || []}
                        value={
                           qAioUwsMeta.data?.metadata.find(({ name }) => name === outputUwAIO)?.label || outputUwAIO
                        }
                        onChange={(str) => setOutputUwAIO(str.split("|").pop() || "")}
                        onTooltipClick={qAioUwsMeta.refetch}
                        tooltip={
                           "- Click '?' icon to refresh!\n- You can add new field in Underwriting 2.0\n- Field name is saved under the hood, so changing the name on Underwriting schema will not break anything"
                        }
                     />
                     <SHrMainButton title="Add" onClick={() => setShowFieldEditor(true)} />
                  </div>
                  <SHrMainInput
                     title="Airtable Underwriting"
                     type="search"
                     loading={qAtUwsMeta.isFetching}
                     options={qAtUwsMeta.data?.metadata.map(({ fid, name }) => ({ label: name, value: fid })) || []}
                     value={qAtUwsMeta.data?.metadata.find(({ fid }) => fid === outputUwAT)?.name || outputUwAT}
                     onChange={(str) => setOutputUwAT(str.split("|").pop() || "")}
                     onTooltipClick={qAtUwsMeta.refetch}
                     tooltip={
                        "Click '?' to refresh! Field ID is saved under the hood, so changing the name on AirTable will not break anything"
                     }
                  />
               </div>
            </div>
            <div className={`${styles.errorC}`}>{error && <p className={`${styles.error}`}>Error: {error}</p>}</div>
            <div className={`${styles.controlsC}`}>
               <SHrMainButton title="Cancel" type="secondary" onClick={onClose} />
               {isEditing && (
                  <SHrMainButton title="Delete" onClick={handleDelete} type="warn" />
                  // confirmMsg={`Delete field ${label}?`}
               )}
               <SHrMainButton
                  title={isEditing ? "Update" : "Save"}
                  type="primary"
                  enable={!error}
                  onClick={handleSave}
               />
               {/* confirmMsg={`${isEditing ? "Update" : "Save"} field ${label}?`} */}
            </div>
         </div>
      </div>
   );
};

export default AioVersionTabFieldEditor;
export type TVersion2FieldEditorProps_level = "property" | "unit";
type TVersion2FieldEditorProps = {
   level: TVersion2FieldEditorProps_level;
   fid: string | null;
   onClose: () => void;
};
