import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_AppNotes_Schema } from "@simplyhomes/utils";

export const useQNotes = ({ object, objectid, refetchOnMount = false }: useQNotesParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: !!objectid,
      queryKey: ["useQNotes", object, objectid],
      queryFn: () => aioAxios.get("/aio/notes/", { params: { object, objectid } }).then<TAxiosResp>(({ data }) => data),
      refetchOnMount,
   });
};
const cols = ["nid", "author", "content", "time", "parentid"] as const;
// export type TQNotes_subnote = Pick<TPg_Aio_AppNotes_Schema, (typeof cols)[number]>;
export type TQNotes_note = Pick<TPg_Aio_AppNotes_Schema, (typeof cols)[number]>;
type TAxiosResp = {
   notes: TQNotes_note[];
};
export type TQNotes_object = "underwritings" | "assets" | "renovations";
export type useQNotesParams = {
   object: TQNotes_object;
   objectid: number | string;
   refetchOnMount?: boolean;
};
