import { useDrag, useDrop } from "react-dnd";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import PropertyGroupChild from "../../children/property/propertyGroup/propertyGroupChild/PropertyGroupChild";
import UnderwritingField from "../../children/underwritings/underwritingGroup/underwritingField/UnderwritingField";
import UwUSingleField from "../../children/uwu/uwuField/UwUSingleField";
import styles from "./Table.module.scss";
import { useEffect, useState } from "react";
export type TTableDnDItem = {
   header: string;
};
export type TTableOnHeaderDrop = (p: { from: string; to: string }) => void;

const HeaderDrag = ({ header, children }: { header: string; children?: React.ReactNode }) => {
   const [, drag] = useDrag(() => ({
      type: "header",
      item: { header },
   }));
   return <div ref={drag}>{children}</div>;
};

const HeaderDrop = ({
   children,
   onHeaderDrop = () => {},
   onClick = () => {},
   header,
   className,
}: {
   children?: React.ReactNode;
   onHeaderDrop?: TTableOnHeaderDrop;
   onClick?: () => void;
   header: string;
   className?: string;
}) => {
   const [, drop] = useDrop(() => ({
      accept: "header",
      drop: (item: TTableDnDItem) => {
         const to = header;
         const from = item.header;
         console.log({ from, to });
         onHeaderDrop({ from, to });
      },
   }));

   return (
      <th ref={drop} className={`${className}`} onClick={onClick}>
         <HeaderDrag header={header}>{header}</HeaderDrag>
      </th>
   );
};

const Table = ({
   columnHeaders = [],
   data,
   rowHeaders = [],
   onHeaderClick = () => {},
   onRowClick = () => {},
   onCellClick = () => {},
   onHeaderDrop = () => {},
   onUwUSingleFieldTooltipClick = () => {},
   hightlights = [],
   internalScroll,
   tbodyHeight,
   debug,
   type,
}: ItableProps) => {
   const handleRowClick = (rIndex: number) =>
      onRowClick(columnHeaders.reduce((prev, curr, cIndex) => ({ ...prev, [curr]: data[rIndex]?.[cIndex] }), {}));
   if (debug) console.log({ columnHeaders, data });

   return (
      <table className={`${styles.contentC} ${internalScroll && styles.internalScroll}`}>
         {columnHeaders.length > 0 && (
            <thead>
               <tr>
                  {rowHeaders.length > 0 && <th></th>}
                  {columnHeaders.map((str, key) => (
                     <HeaderDrop
                        header={str}
                        onHeaderDrop={onHeaderDrop}
                        key={key}
                        className={`${hightlights.includes(str) && styles.hightlight}`}
                        onClick={() => onHeaderClick(str)}
                     ></HeaderDrop>
                  ))}
               </tr>
            </thead>
         )}
         <tbody style={{ height: tbodyHeight }}>
            {data.map((rowData, rIndex) => (
               <tr key={rIndex} onClick={() => handleRowClick(rIndex)}>
                  {rowHeaders.length > 0 && <th title={rowHeaders[rIndex]}>{rowHeaders[rIndex] || "blank"}</th>}
                  {rowData.map((value, cIndex) => (
                     <td key={cIndex} onClick={() => onCellClick({ r: rIndex, c: cIndex, v: value })}>
                        {type === "none" ? (
                           <span>
                              {Array.isArray(value)
                                 ? `{${value.join(",")}}`
                                 : value == null
                                 ? ""
                                 : isNaN(value)
                                 ? value.toString()
                                 : value}
                           </span>
                        ) : type === "PropertyField" ? (
                           <PropertyGroupChild showValueOnly id={value} />
                        ) : type === "UnderwritingField" ? (
                           <UnderwritingField showValueOnly id={value} />
                        ) : type === "UwUField" ? (
                           <UwUSingleField
                              showValueOnly
                              fieldId={value}
                              onTooltipClick={onUwUSingleFieldTooltipClick}
                              style={{ width: "7.6rem" }}
                           />
                        ) : (
                           <span>type?</span>
                        )}
                     </td>
                  ))}
               </tr>
            ))}
         </tbody>
      </table>
   );
};

export default Table;
interface ItableProps {
   columnHeaders?: string[];
   rowHeaders?: string[];
   data: any[][];
   hightlights?: string[];
   internalScroll?: boolean;
   tbodyHeight?: string;
   onHeaderClick?: (str: string) => void;
   onRowClick?: (obj: TUnknownObject) => void;
   onCellClick?: (p: { r: number; c: number; v: any }) => void;
   onHeaderDrop?: TTableOnHeaderDrop;
   onUwUSingleFieldTooltipClick?: (id: string) => void;
   debug?: boolean;
   type: "none" | "PropertyField" | "UnderwritingField" | "UwUField";
}
// export interface TtableDataNone {
//    value: string | string[];
// }
// export interface ItableDataGC {
//    type: "GroupChild";
//    value: string;
// }
