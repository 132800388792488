import styles from "./UnderwritingGroup.module.scss";
import { TversionGroup } from "../../../../hooks/querries/versions/useQVersion";
import sliceIntoChunks from "../../../../utils/JS/sliceChunks";
import Table from "../../../common/table/Table";
import UnderwritingField from "./underwritingField/UnderwritingField";
const UnderwritingGroup = ({ group, childIndex }: TUnderwritingGroupProps) => {
   const { children, type = "group", columns = "", rows = "" } = group;
   const cs = columns.split(",");
   const rs = rows.split(",");
   const rowsData = sliceIntoChunks(children, cs.length);
   return (
      <div className={`${styles.contentC} `}>
         {type === "group" && (
            <ul className={`${styles.children} `}>
               <div className={`${styles.childC}`}>
                  {children.map((id, key) => (
                     <UnderwritingField id={id} key={key} childIndex={childIndex} />
                  ))}
               </div>
            </ul>
         )}
         {type === "table" && (
            <div className={`${styles.tableC}`}>
               <Table columnHeaders={cs} rowHeaders={rs} data={rowsData} type="UnderwritingField" />
            </div>
         )}
      </div>
   );
};

export default UnderwritingGroup;
type TUnderwritingGroupProps = {
   group: TversionGroup;
   childIndex?: number;
};
