import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Crm_Fields_Schema } from "@simplyhomes/utils";

export const useQFields = ({ tables, custom = [] }: TUseQFieldsParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["fields", tables, custom],
      queryFn: () =>
         aioAxios.get(`/aio/fields/`, { params: { tables } }).then<{ fields: TUseQFields_Field[] }>(({ data }) => ({
            fields: [
               ...(custom.includes("xero")
                  ? [
                       { object: "xero", label: "Xero Total", name: "sum_total" },
                       { object: "xero", label: "Xero Due", name: "sum_due" },
                    ]
                  : []),
               ...data.fields,
            ],
         })),
   });
};
type TUseQFieldsParams = {
   tables: string[];
   custom?: "xero"[];
};
const columns = ["name", "label", "object"] as const; //(keyof TPg_Crm_Fields_Schema)[];
export type TUseQFields_Field = Pick<TPg_Crm_Fields_Schema, (typeof columns)[number]>;
