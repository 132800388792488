import { useHotkeys } from "react-hotkeys-hook";
import styles from "./QuickCalculator.module.scss";
import stringMath from "string-math";
import MainInput from "../mainInput/MainInput";
import { useState } from "react";
import MainButton from "../mainButton/MainButton";
const QuickCalculator = ({ onClose }: TQuickCalculatorProps) => {
   useHotkeys("esc", onClose);

   const [input, setInput] = useState("");
   //const [result, setResult] = useState("");

   const calculate = () => {
      try {
         return stringMath(input);
      } catch (error) {}
   };

   const result = calculate();
   //const test = stringMath(result);

   //console.log(result)

   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`header-s`}>QUICK CALCULATOR</h1>
            <MainButton type="close" onClick={onClose} />
         </div>
         <div className={`${styles.calBox}`}>
            <div className={`${styles.calInput}`}>
               <MainInput disableCollapse otherProps={{autoFocus: true}} type="text" value={input} handleValue={setInput} />
            </div>
            <div className={`${styles.equal}`}>
               <b>=</b>
               <span>{result}</span>
            </div>
         </div>
      </article>
   );
};

export default QuickCalculator;
type TQuickCalculatorProps = {
   onClose: () => void;
};
