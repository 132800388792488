import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { useAuth0 } from "@auth0/auth0-react";
import { TPg_Aio_AppUsers_Schema } from "@simplyhomes/utils";

const useQUser = () => {
   const { aioAxios } = useAxios();
   const { user } = useAuth0();
   const { sub } = user || {};
   return useQuery({
      enabled: !!sub,
      queryKey: ["useQUser", sub],
      queryFn: () => aioAxios.get("/aio/users/me").then<TaxiosResp>(({ data }) => data),
   });
};

export default useQUser;
type TaxiosResp = Pick<TPg_Aio_AppUsers_Schema, "first_name" | "last_name">;
