import { Helmet, HelmetProps } from "react-helmet-async";
const Helm = ({ titles, title, separator = " | ", ...props }: IHelmProps) => {
   const baseTitle = "Simply AIO";
   const finalTitle = titles?.filter(Boolean).join(separator) || title;
   return <Helmet {...props} title={[finalTitle, baseTitle].join(" - ")} />;
};

export default Helm;
interface IHelmProps extends HelmetProps {
   titles?: (string | undefined)[];
   separator?: " | " | " - ";
}
