import {
   Outlet,
   createSearchParams,
   useNavigate,
   useOutletContext,
   useParams,
   useSearchParams,
} from "react-router-dom";
import styles from "./UwU.module.scss";
import useQUws from "../../hooks/querries/underwritings/useQUws";
import useQVersions from "../../hooks/querries/versions/useQVersions";
import { useEffect, useReducer, useState } from "react";
import BodyFiller from "../../components/common/bodyFiller/BodyFiller";
import MainInput from "../../components/common/mainInput/MainInput";
import MainButton from "../../components/common/mainButton/MainButton";
import uwuReducer, { TUwUState } from "../../contexts/UwUContext/uwuReducer";
import { TUwUReducerAction } from "../../types/interfaces/reducer.interface";
import useQUw from "../../hooks/querries/underwritings/useQUw";
import useQVersion from "../../hooks/querries/versions/useQVersion";
import useAxios from "../../hooks/axios/useAxios";
import SmallSpinner from "../../components/common/smallSpinner/SmallSpinner";
import { SHu_js_sleep } from "@simplyhomes/utils";

const UwU = ({
   bulkInspector,
   uwCache,
   uwFinal,
   uwOptions,
   onBulkInspectorSave = () => {},
   onBulkInspectorClose = () => {},
}: UwUProps) => {
   const nav = useNavigate();
   const { aioAxios } = useAxios();
   const { uwsid } = useParams();
   const [params, setSearchParams] = useSearchParams();
   const ver = params.get("ver") || "";

   const [uwuState, uwuDispatch] = useReducer(uwuReducer, { uwFinal: {}, uwCache: {}, uwOptions: {} });
   const [submitting, setSubmitting] = useState(false);

   const {
      data: qUwsData,
      isFetching: qUwsFetching,
      refetch: qUwsRefetch,
   } = useQUws({ enabled: !bulkInspector, options: { refetchOnMount: true } });
   const { underwritings: uws = [] } = qUwsData || {};

   const { data: qUwData, isFetching: qUwFetching, refetch: qUwRefetch } = useQUw({ uwsids: [uwsid || ''], enabled: !bulkInspector });
   const { property_address_one_line_: address, name, hs_pipeline } = qUwData?.underwritings[0] || {};

   const { data: qVersData, isFetching: qVersFetching } = useQVersions({ obj: "underwritings" });
   const { versions = [], latest: latestVer } = qVersData || {};

   const { isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });

   const [bulkApplyStage, setBulkApplyStage] = useState<"button" | "loading" | "applied">("button");

   const isVerExist = versions.find((version) => version === ver);
   const isUwExist = uws.find((uw) => uw["underwritings.simply_object_id"] === uwsid);

   useEffect(() => {
      if (!isUwExist && !!uws && !bulkInspector) qUwsRefetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   useEffect(() => {
      if (uwFinal) uwuDispatch({ overwrite: { uwFinal, uwCache, uwOptions } });
   }, [uwCache, uwFinal, uwOptions]);
   useEffect(() => {
      if (!ver && latestVer) {
         params.set("ver", latestVer);
         setSearchParams(params);
      }
   }, [latestVer, params, setSearchParams, ver]);

   const handleVer = (str: string) => {
      params.set("ver", str);
      setSearchParams(params);
   };

   const handleBulkSave = async () => {
      onBulkInspectorSave({
         uwCache: uwuState.uwCache || {},
         uwFinal: uwuState.uwFinal || {},
         uwOptions: uwuState.uwOptions || {},
      });
      setBulkApplyStage("loading");
      await SHu_js_sleep(1000);
      setBulkApplyStage("applied");
      await SHu_js_sleep(1000);
      setBulkApplyStage("button");
   };
   const handleBackBtn = () => nav({ pathname: "/uwu/search", search: createSearchParams({ ver }).toString() });

   const uwsToBeDemoted = uws.filter(
      (uw) =>
         uw["underwritings.property_address_one_line_"] === address &&
         uw["underwritings.hs_pipeline"] === "41400397" &&
         uw["underwritings.simply_object_id"] !== "uwsid"
   );
   const handlePromote = async () => {
      setSubmitting(true);
      await aioAxios.get(`/aio/underwritings/${uwsid}/promote`).catch((er) => console.log({ er }));
      qUwRefetch();
      qUwsRefetch();
      setSubmitting(false);
   };
   if (qUwsFetching || qVersFetching || submitting)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />;
         </div>
      );
   return (
      <section className={`${styles.contentC} `}>
         {bulkInspector ? (
            <div className={`${styles.bulkInspectorC}`}>
               <MainButton type="close" style={{ height: "1.5rem", width: "1.5rem" }} onClick={onBulkInspectorClose} />
               <div className={`${styles.bulkTitle} header-s`}>Inspector</div>
               {bulkApplyStage === "button" ? (
                  <MainButton
                     title="Apply Changes"
                     onClick={handleBulkSave}
                     style={{ height: "1.5rem", padding: "0 0.5rem" }}
                  />
               ) : bulkApplyStage === "loading" ? (
                  <SmallSpinner style={{ height: "16px" }} />
               ) : (
                  <MainButton title="Changes applied!" type="bland" style={{ height: "1.5rem", padding: "0 0.5rem" }} />
               )}
            </div>
         ) : (
            <div className={`${styles.headerC}`}>
               <div className={`${styles.headerL}`}>
                  <MainButton type="back" onClick={handleBackBtn} />
                  <div className={`${styles.nameC}`}>
                     <p>
                        {hs_pipeline === "41400397" ? "⭐ " : ""}
                        {uwuState.newUwName || name}
                     </p>
                     <b>{address}</b>
                  </div>
                  {hs_pipeline !== "41400397" && isUwExist && (
                     <MainButton
                        title="Promote to Official"
                        type="warn"
                        confirmMsg={`Move this into official pipeline?
                        ${uwsToBeDemoted.length > 0 ? ` And demote ${uwsToBeDemoted.length} other underwritings?` : ""}
                        `}
                        onClick={handlePromote}
                     />
                  )}
               </div>
               <div className={`${styles.headerR}`}>
                  <MainInput
                     style={{ width: "8rem" }}
                     title="Version"
                     type="search"
                     value={ver}
                     handleValue={handleVer}
                     options={versions}
                  />
               </div>
            </div>
         )}
         <article className={`${styles.uwC}`}>
            {bulkInspector ? (
               <Outlet context={{ uwuState, uwuDispatch }} />
            ) : qUwFetching || qVerFetching ? (
               <BodyFiller loading />
            ) : !isUwExist ? (
               <BodyFiller fillerMsg="Seem like this underwriting does not exist!" />
            ) : !isVerExist ? (
               <BodyFiller fillerMsg="Select A Version To Start!" />
            ) : (
               <Outlet context={{ uwuState, uwuDispatch }} />
            )}
         </article>
      </section>
   );
};

export default UwU;
type UwUProps = {
   uwFinal?: Record<string, any>;
   uwCache?: Record<string, any>;
   uwOptions?: Record<string, any>;
   bulkInspector?: boolean;
   onBulkInspectorSave?: (p: {
      uwFinal: Record<string, any>;
      uwCache: Record<string, any>;
      uwOptions: Record<string, any>;
   }) => void;
   onBulkInspectorClose?: () => void;
};
type TContext = {
   uwuState: TUwUState;
   uwuDispatch: React.Dispatch<TUwUReducerAction>;
};
export const useUwUContext = () => useOutletContext<TContext>();
