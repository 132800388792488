import { useEffect, useState } from "react";
import { TUwData } from "../../types/hsObjects";
import useQVersion from "../querries/versions/useQVersion";
import { Node } from "excel-formula-ast";
import parseFormula from "../../utils/formula/parseFormula";
import isNumberic from "../../utils/valTypes/isNumberic";
import * as formulajs from "@formulajs/formulajs";
import JSONtryParse from "../../utils/JSON/JSONtryParse";
import parseFieldName from "../../utils/fields/parseFieldName";
import { useUwContext } from "../../pages/Underwritings/Underwritings";

// const errorCodes = ["ERROR#NOTFOUND", "ERROR#INTERNAL"] as const;
const useResolveUw = ({ uws, ver }: TuseResolveUw) => {
   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const [output, setOutput] = useState(uws);
   const { uwCache, addToUwCache } = useUwContext();
   const [calculations, setCalculations] = useState(0);
   const [options, setOptions] = useState<{ [x: string]: string | undefined }[]>(uws.map((_) => ({})));
   useEffect(() => {
      if (output.length !== options.length) setOptions(output.map((_) => ({})));
      //    setOutput(uws);
   }, [options.length, output]);

   useEffect(() => {
      const { fields = [], units = [], groups = [] } = qVerData?.config || {};
      const logs: any[] = [];

      const resolveParsed = ({
         unitIndex,
         pf,
         uwIndex,
         debug,
      }: {
         pf?: Node;
         uwIndex: number;
         unitIndex?: number;
         debug: boolean;
      }): any => {
         if (!pf) return;
         const data = output[uwIndex] || {};
         const { $childUnits = [] } = data;
         const { type } = pf;
         // if (debug) logs.push(pf);
         switch (type) {
            case "cell":
               const { key } = pf;
               if (key.includes("@")) {
                  const field = fields.find(({ id }) => id === key.replace("@", ""));
                  const { formula, column: baseKeyCol } = field || {};
                  const refVal = resolveParsed({
                     pf: parseFormula(baseKeyCol || formula || "") as Node,
                     unitIndex,
                     uwIndex,
                     debug,
                  });
                  if (debug) logs.push({ type: "cell", key, formula, refVal });
                  return refVal;
               }
               const keyVal =
                  unitIndex != null && $childUnits?.[unitIndex]?.[key] != null
                     ? $childUnits?.[unitIndex]?.[key]
                     : data[key];

               if (debug) logs.push({ type: "cell", key, keyVal, unitIndex });
               if (isNumberic(keyVal)) return Number(keyVal);
               return keyVal;
            case "number":
               return pf.value;
            case "text":
               return pf.value;
            case "function":
               const { arguments: args } = pf;
               const resolvedArgs: any[] = args.map((arg) => resolveParsed({ pf: arg, unitIndex, uwIndex, debug }));
               if (resolvedArgs.includes("pending")) return "pending";
               if (resolvedArgs.includes("ERROR")) return "error";
               // if (debug) console.log({ resolvedArgs, pf });
               const customName = pf.name as string;
               switch (customName.toUpperCase()) {
                  case "DBLIST":
                  case "DBLOOKUP":
                  case "DBLOOKUPSUM":
                  case "TRACT":
                  case "COUNTY":
                  case "CITY":
                     if (!resolvedArgs.every(Boolean)) return "missingArgs";
                     const cacheKey = customName + "|" + JSON.stringify(resolvedArgs);
                     const cacheVal = uwCache[cacheKey];
                     if (debug) logs.push({ type: customName, cacheVal, cacheKey, uwCache });
                     if (!cacheVal) {
                        // * only add new query if there is no pending
                        if (Object.keys(uwCache).every((key) => uwCache[key] !== "pending")) addToUwCache(cacheKey);
                        return "pending";
                     }
                     // if (errorCodes.includes(cacheVal)) {
                     //    if (fieldType === "number") return 0;
                     //    return "";
                     // }
                     return cacheVal;
                  case "PPAYDOWN": {
                     const [rate, term, principal, periods] = resolvedArgs;
                     // if (debug) console.log({ rate, term, principal, periods });
                     const mPayment = resolveParsed({
                        pf: parseFormula(`"-1" * PMT( ${rate} / 12 , ${term} * 12 , ${principal} )`) as Node,
                        unitIndex,
                        uwIndex,
                        debug,
                     });
                     let loanBalance = principal;
                     let pPaydown = 0;
                     for (let i = 1; i <= (periods || 12); i++) {
                        const iPayment = resolveParsed({
                           pf: parseFormula(`"-1" * IPMT( ${rate} / 12 , ${i} , ${term} * 12 , ${principal} )`) as Node,
                           unitIndex,
                           uwIndex,
                           debug,
                        });
                        const oldLoanBalance = loanBalance;
                        const newLoanBalance = loanBalance - (mPayment - iPayment);
                        loanBalance = newLoanBalance;
                        pPaydown = oldLoanBalance - newLoanBalance + pPaydown;
                        // if (debug) console.log({ mPayment, iPayment, loanBalance, pPaydown });
                     }
                     return pPaydown;
                  }
                  case "BOOLEAN": {
                     // const [val] = resolvedArgs;
                     return resolvedArgs.every(Boolean);
                  }
                  default:
                     break;
               }

               const routerNames = [{ external: "PARENTHESES", internal: "SUM" }];
               const name = (routerNames.find(({ external }) => external === pf.name)?.internal || pf.name) as "IFS";
               try {
                  const formulaJsVal = formulajs[name](...resolvedArgs);
                  if (debug) logs.push({ type: name, args: resolvedArgs, formulaJsVal });
                  return formulaJsVal;
               } catch (error) {
                  console.log({ error, name, resolvedArgs });
               }
               return;
            case "binary-expression":
               const { left, operator, right } = pf;
               const extendedOps = operator as any;
               const rL = resolveParsed({ pf: left, unitIndex, uwIndex, debug });
               const rR = resolveParsed({ pf: right, unitIndex, uwIndex, debug });
               if (debug) logs.push({ type: "binary-expression", rL, operator, rR });
               // if (debug) console.log({ rL, operator, rR });
               switch (extendedOps) {
                  case "&":
                     return `${rL}${rR}`;
                  case "=":
                     // eslint-disable-next-line eqeqeq
                     return typeof rL === "boolean"
                        ? rL === JSONtryParse(rR.toLowerCase())
                        : typeof rR === "boolean"
                        ? rR === JSONtryParse(rL.toLowerCase())
                        : rL === rR;
                  case "+":
                     return Number(rL) + Number(rR);
                  case "-":
                     return Number(rL) - Number(rR);
                  case "<":
                     return Number(rL) < Number(rR);
                  case "<=":
                     return Number(rL) <= Number(rR);
                  case ">":
                     return Number(rL) > Number(rR);
                  case ">=":
                     return Number(rL) >= Number(rR);
                  case "/":
                     return Number(rL) / Number(rR);
                  case "*":
                     return Number(rL) * Number(rR);
                  default:
                     break;
               }
               return;
            default:
               return "";
         }
      };
      const resolveOneField = ({
         fieldId,
         uwIndex,
         unitIndex,
      }: {
         fieldId: string;
         uwIndex: number;
         unitIndex?: number;
      }) => {
         const data = output[uwIndex] || {};
         const { $childUnits = [] } = data;
         const field = fields.find(({ id }) => id === fieldId);
         if (!field) return;
         const {
            name,
            column: baseCol,
            formula,
            type: fieldType,
            fallback,
            options: fieldOptions,
            unitBehavior,
         } = field;
         const amIaChild = unitIndex != null;
         const fieldsInUnits = units.map(({ children }) => children).flat();
         const amIaMother = !amIaChild && fieldsInUnits.includes(fieldId) && $childUnits.length > 0;
         const column = baseCol || parseFieldName(name);
         const curVal = amIaChild ? $childUnits[unitIndex]?.[column] : data[column];

         // childIndex ? $childUnits[childIndex][column] || data[column] : data[column];

         const debug = name === "Rent (Income - Month)";

         const setCurVal = (v: any) => {
            if (v === curVal || (curVal?.toString() === "NaN" && v?.toString() === "NaN")) return;
            // console.log({ uwIndex, name, v, curVal, unitBehavior });
            if (name === "beds") console.log({ v, curVal });
            if (!amIaChild) {
               // if (amIaMother)
               const defaultUw = uws[uwIndex];
               setCalculations((prev) => prev + 1);
               return setOutput((prev) =>
                  prev.map((uw, key) => (key === uwIndex ? { ...defaultUw, ...uw, [column]: v } : uw))
               );
            }
            const newUnit = { ...$childUnits[unitIndex], [column]: v };
            const newChildUnits = $childUnits.map((unit, key) => (key === unitIndex ? newUnit : unit));
            setCalculations((prev) => prev + 1);
            return setOutput((prev) =>
               prev.map((uw, key) => (key === uwIndex ? { ...uw, $childUnits: newChildUnits } : uw))
            );
         };

         if (amIaMother && unitBehavior !== "NONE") {
            const unitValues = $childUnits.map((unit) => unit[column]);
            const unitValSum = unitValues.reduce((a, b) => Number(a || 0) + Number(b || 0), 0) || 0;
            const motherValue =
               unitBehavior === "SUM"
                  ? unitValSum
                  : unitBehavior === "AVG"
                  ? unitValSum / unitValues.length
                  : unitBehavior === "MAX"
                  ? Math.max(...unitValues)
                  : unitBehavior === "MIN"
                  ? Math.min(...unitValues)
                  : "Behavior?";
            // console.log({name, column,motherValue})
            return setCurVal(motherValue);
         }

         if (formula) {
            const parsedFormula = parseFormula(formula);
            if (!parsedFormula) return;
            const formulaVal = resolveParsed({ pf: parsedFormula, uwIndex, debug, unitIndex });
            if (debug) console.log({ logs });
            if (formulaVal === "pending") return;
            return setCurVal(formulaVal);
         } else {
            if (!!fieldOptions) {
               const newOptions = fieldOptions.startsWith("=")
                  ? resolveParsed({ pf: parseFormula(fieldOptions), uwIndex, debug, unitIndex })
                  : fieldOptions;
               if (debug) console.log({ logs });
               // console.log({options})
               const curOptions = options?.[uwIndex]?.[column];
               if (newOptions !== curOptions) {
                  console.log({ options, newOptions, curOptions });
                  setOptions((prev) =>
                     prev.map((optionSet, oIndex) =>
                        oIndex === uwIndex ? { ...optionSet, [column]: newOptions } : optionSet
                     )
                  );
               }
            }
            const newVal =
               fieldType === "checkbox" &&
               (curVal === "true" || curVal === "Y" || curVal === true || fallback === "true") &&
               curVal !== "false" &&
               curVal !== false
                  ? true
                  : fieldType === "checkbox"
                  ? false
                  : column && curVal !== undefined && curVal !== null
                  ? curVal
                  : fallback ?? "";
            if (debug) console.log({ name, newVal, curVal });
            return setCurVal(newVal);
         }
      };
      output.forEach((uw, uwIndex) => {
         groups.forEach(({ children }) => children.forEach((fieldId) => resolveOneField({ fieldId, uwIndex })));
         uw?.$childUnits?.forEach((_, unitIndex) =>
            units.forEach(({ children }) =>
               children.forEach((fieldId) => resolveOneField({ fieldId, uwIndex, unitIndex }))
            )
         );
      });
   }, [addToUwCache, options, output, qVerData?.config, uwCache, uws]);

   return { output, setOutput, options, calculations, setCalculations };
};

export default useResolveUw;
type TuseResolveUw = {
   uws: TUwData[];
   // setData: React.Dispatch<React.SetStateAction<TUwData>>;
   ver: string;
};
