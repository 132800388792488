import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TaioVersionObj } from "../../../types/aioVersion/types";
const useQVersions = ({ obj }: TUseQVersionsParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: !!obj,
      queryKey: ["versions", obj],
      queryFn: async () =>
         await aioAxios
            .get("/aio/versions", { params: { obj }, headers: { "Cache-Control": "no-cache" } })
            .then<{ versions: string[]; latest: string }>(({ data }) => data),
   });
};

export default useQVersions;
type TUseQVersionsParams = {
   obj: TaioVersionObj;
};
