import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { THs_Object_Name } from "@simplyhomes/utils";

export const useQFieldsOptions = ({ names, objects }: TuseQFieldParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["useQFieldsOptions", objects, names],
      queryFn: () =>
         aioAxios.get("/aio/fields/options", { params: { objects, names } }).then<TAxiosReps>(({ data }) => data),
   });
};

type TuseQFieldParams = {
   names?: string[];
   objects?: THs_Object_Name[];
};
type TAxiosReps = {
   options: { name: string; object: THs_Object_Name;label: string; options: { label: string; value: string }[] }[];
};
