import * as formulajs from "@formulajs/formulajs";
import { Node } from "excel-formula-ast";
import { useSearchParams } from "react-router-dom";
import { useUwContext } from "../../pages/Underwritings/Underwritings";
import useQVersion from "../querries/versions/useQVersion";
import parseFieldName from "../../utils/fields/parseFieldName";
import { useEffect, useState } from "react";
import parseFormula from "../../utils/formula/parseFormula";
import isNumberic from "../../utils/valTypes/isNumberic";
import JSONtryParse from "../../utils/JSON/JSONtryParse";
import { TUwData } from "../../types/hsObjects";
const errorCodes = ["ERROR#NOTFOUND", "ERROR#INTERNAL"] as const;
const useResolveUwField = ({ fId, childIndex,  }: TUseResolveUwFieldParams) => {
   const { uw, setUw, uwCache, addToUwCache } = useUwContext();
   const [params] = useSearchParams();
   const ver = params.get("ver") || "";
   const { data: versionData } = useQVersion({ ver, obj: "underwritings" });
   const { config } = versionData || {};
   const { fields = [], units = [] } = config || {};
   const {
      formula,
      name,
      column: baseCol,
      fallback,
      type: fieldType,
      unitBehavior = "NONE",
      options: fieldOptions,
   } = fields.find(({ id }) => id === fId) || {};

   const column = baseCol || parseFieldName(name);
   const { $childUnits = [] } = uw;
   const amIaChild = childIndex != null;
   const fieldsInUnits = units.map(({ children }) => children).flat();
   const amIaMother = !amIaChild && fieldsInUnits.includes(fId) && $childUnits.length > 0;
   const curVal = amIaChild ? $childUnits?.[childIndex]?.[column] : uw[column];

   const [options, setOptions] = useState("");

   useEffect(() => {
      // if (!uw.hs_object_id) return;
      const logs: any[] = [];
      const debug = name === "Housing Authority";
      // if (debug) console.log({ name, column, curVal });

      const resolveParsed = (pf?: Node, keyIndex?: number): any => {
         if (!pf) return;
         const { type } = pf;
         // if (debug) logs.push(pf);
         switch (type) {
            case "cell":
               const { key } = pf;
               if (key.includes("@")) {
                  const field = fields.find(({ id }) => id === key.replace("@", ""));
                  const { formula, column: baseKeyCol } = field || {};
                  const refVal = resolveParsed(parseFormula(baseKeyCol || formula || "") as Node, keyIndex);
                  if (debug) logs.push({ type: "cell", key, formula, refVal });
                  return refVal;
               }
               const keyVal =
                  keyIndex != null && $childUnits?.[keyIndex]?.[key] != null ? $childUnits?.[keyIndex]?.[key] : uw[key];

               if (debug) logs.push({ type: "cell", key, keyVal, keyIndex });
               if (isNumberic(keyVal)) return Number(keyVal);
               return keyVal;
            case "number":
               return pf.value;
            case "text":
               return pf.value;
            case "function":
               const { arguments: args } = pf;
               const resolvedArgs: any[] = args.map((arg) => resolveParsed(arg, keyIndex));
               if (resolvedArgs.includes("pending")) return "pending";
               if (resolvedArgs.includes("ERROR")) return "error";
               // if (debug) console.log({ resolvedArgs, pf });
               const customName = pf.name as string;
               switch (customName.toUpperCase()) {
                  case "DBLIST":
                  case "DBLOOKUP":
                  case "DBLOOKUPSUM":
                  case "TRACT":
                  case "COUNTY":
                  case "CITY":
                     if (!resolvedArgs.every(Boolean)) return "missingArgs";
                     const cacheKey = customName + "|" + JSON.stringify(resolvedArgs);
                     const cacheVal = uwCache[cacheKey];
                     if (debug) logs.push({ type: customName, cacheVal, cacheKey, uwCache });
                     if (!cacheVal) {
                        // * only add new query if there is no pending
                        if (Object.keys(uwCache).every((key) => uwCache[key] !== "pending")) addToUwCache(cacheKey);
                        return "pending";
                     }
                     if (errorCodes.includes(cacheVal)) {
                        if (fieldType === "number") return 0;
                        return "";
                     }
                     return cacheVal;
                  case "PPAYDOWN": {
                     const [rate, term, principal, periods] = resolvedArgs;
                     if (debug) console.log({ rate, term, principal, periods });
                     const mPayment = resolveParsed(
                        parseFormula(`"-1" * PMT( ${rate} / 12 , ${term} * 12 , ${principal} )`) as Node,
                        keyIndex
                     );
                     let loanBalance = principal;
                     let pPaydown = 0;
                     for (let i = 1; i <= (periods || 12); i++) {
                        const iPayment = resolveParsed(
                           parseFormula(`"-1" * IPMT( ${rate} / 12 , ${i} , ${term} * 12 , ${principal} )`) as Node,
                           keyIndex
                        );
                        const oldLoanBalance = loanBalance;
                        const newLoanBalance = loanBalance - (mPayment - iPayment);
                        loanBalance = newLoanBalance;
                        pPaydown = oldLoanBalance - newLoanBalance + pPaydown;
                        if (debug) console.log({ mPayment, iPayment, loanBalance, pPaydown });
                     }
                     return pPaydown;
                  }
                  case "BOOLEAN": {
                     // const [val] = resolvedArgs;
                     return resolvedArgs.every(Boolean);
                  }
                  default:
                     break;
               }

               const routerNames = [{ external: "PARENTHESES", internal: "SUM" }];
               const name = (routerNames.find(({ external }) => external === pf.name)?.internal || pf.name) as "IFS";
               try {
                  const formulaJsVal = formulajs[name](...resolvedArgs);
                  if (debug) logs.push({ type: name, args: resolvedArgs, formulaJsVal });
                  return formulaJsVal;
               } catch (error) {
                  console.log({ error, name, resolvedArgs });
               }
               return;
            case "binary-expression":
               const { left, operator, right } = pf;
               const extendedOps = operator as any;
               const rL = resolveParsed(left, keyIndex);
               const rR = resolveParsed(right, keyIndex);
               if (debug) logs.push({ type: "binary-expression", rL, operator, rR });
               // if (debug) console.log({ rL, operator, rR });
               switch (extendedOps) {
                  case "&":
                     return `${rL}${rR}`;
                  case "=":
                     // eslint-disable-next-line eqeqeq
                     return typeof rL === "boolean"
                        ? rL === JSONtryParse(rR.toLowerCase())
                        : typeof rR === "boolean"
                        ? rR === JSONtryParse(rL.toLowerCase())
                        : rL === rR;
                  case "+":
                     return Number(rL) + Number(rR);
                  case "-":
                     return Number(rL) - Number(rR);
                  case "<":
                     return Number(rL) < Number(rR);
                  case "<=":
                     return Number(rL) <= Number(rR);
                  case ">":
                     return Number(rL) > Number(rR);
                  case ">=":
                     return Number(rL) >= Number(rR);
                  case "/":
                     return Number(rL) / Number(rR);
                  case "*":
                     return Number(rL) * Number(rR);
                  default:
                     break;
               }
               return;
            default:
               return "";
         }
      };
      const setCurVal = (v: any) => {
         // if (debug)
         if (v === curVal || (curVal?.toString() === "NaN" && v?.toString() === "NaN")) return;
         // console.log({ column, name, curVal, v });
         if (!amIaChild) return setUw((prev) => ({ ...prev, [column]: v }));
         // if (!amIaChild) return setUw({ ...uw, [column]: v });
         const newUnit = { ...$childUnits[childIndex], [column]: v };
         const newChildUnits = $childUnits.map((unit, key) => (key === childIndex ? newUnit : unit));
         return setUw((prev) => ({ ...prev, $childUnits: newChildUnits }));
         // return setUw({ ...uw, $childUnits: newChildUnits });
      };

      if (amIaMother && unitBehavior !== "NONE") {
         const unitValues = $childUnits.map((unit) => unit[column]);
         const unitValSum = unitValues.reduce((a, b) => Number(a || 0) + Number(b || 0), 0) || 0;
         const motherValue =
            unitBehavior === "SUM"
               ? unitValSum
               : unitBehavior === "AVG"
               ? unitValSum / unitValues.length
               : unitBehavior === "MAX"
               ? Math.max(...unitValues)
               : unitBehavior === "MIN"
               ? Math.min(...unitValues)
               : "Behavior?";
         return setCurVal(motherValue);
      }

      if (formula) {
         const parsedFormula = parseFormula(formula);
         if (!parsedFormula) return;
         const formulaVal = resolveParsed(parsedFormula, childIndex);
         if (debug) logs.push({ formulaVal, curVal });
         if (debug) console.log({ logs });
         if (formulaVal === "pending") return;
         return setCurVal(formulaVal);
      } else {
         if (!!fieldOptions && fieldOptions.startsWith("=")) {
            setOptions(resolveParsed(parseFormula(fieldOptions)));
         }
         const newVal =
            fieldType === "checkbox" &&
            (curVal === "true" || curVal === "Y" || curVal === true || fallback === "true") &&
            curVal !== "false" &&
            curVal !== false
               ? true
               : fieldType === "checkbox"
               ? false
               : column && curVal !== undefined && curVal !== null
               ? curVal
               : fallback ?? "";
         return setCurVal(newVal);
      }
   }, [
      $childUnits,
      addToUwCache,
      amIaChild,
      amIaMother,
      childIndex,
      column,
      curVal,
      fallback,
      fieldOptions,
      fieldType,
      fields,
      formula,
      name,
      setUw,
      unitBehavior,
      uw,
      uwCache,
   ]);

   return { value: curVal, options: options || fieldOptions };
};

export default useResolveUwField;
type TUseResolveUwFieldParams = {
   fId: string;
   childIndex?: number;
};
