import styles from "./UwUBulk.module.scss";
import {
   TUwUBulkReducerAction,
   TUwUBulkState,
   uwuBulkDefaultState,
   uwuBulkReducer,
} from "../../contexts/UwUBulk/uwuBulkReducer";
import { Link, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useReducer } from "react";
import MainButton from "../../components/common/mainButton/MainButton";
const UwUBulk = () => {
   const [uwuBulkState, uwuBulkDispatch] = useReducer(uwuBulkReducer, uwuBulkDefaultState);
   const location = useLocation();

   const isRoot = location.pathname === "/uwubulk";

   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               {!isRoot && (
                  <Link to={"/uwubulk"}>
                     <MainButton type="back" />
                  </Link>
               )}
               <h1 className={`header-m`}>Bulk Underwriting</h1>
            </div>
            <div className={`${styles.headerR}`}></div>
         </div>
         <Outlet context={{ uwuBulkState, uwuBulkDispatch }} />
      </section>
   );
};

export default UwUBulk;
type TContext = {
   uwuBulkState: TUwUBulkState;
   uwuBulkDispatch: React.Dispatch<TUwUBulkReducerAction>;
};
export const useUwuBulkContext = () => useOutletContext<TContext>();
