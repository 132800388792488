import { useEffect, useState } from "react";
import { TUwUBulkState } from "../../../../../contexts/UwUBulk/uwuBulkReducer";
import useQVersion, { TversionField } from "../../../../../hooks/querries/versions/useQVersion";
import MainInput from "../../../../common/mainInput/MainInput";
import TabButton from "../../../../common/tabButton/TabButton";
import UwUbField from "../../../uwub/uwubField/UwUbField";
import styles from "./UwUBulkViewConfig.module.scss";
import { useResolveUwU } from "../../../../../hooks/formulas/useResolveUwU";
import { TUwData } from "../../../../../types/hsObjects";
import { TTableV2_headerData } from "../../../../common/tableV2/TableV2";
import { useUwuBulkContext } from "../../../../../pages/UwUBulk/UwUBulk";
import { useStateParams } from "../../../../../hooks/react/useStateParams";
const UwUBulkViewConfig = ({ cache }: TUwUBulkStep2ConfigProps) => {
   const { uwuBulkState, uwuBulkDispatch } = useUwuBulkContext();
   const { activeTab, uwBase, columnHeaders } = uwuBulkState as TUwUBulkState & {
      activeTab: TUwUBulkStep2_ConfigTab;
   };

   const [processing, setProcessing] = useState(false);

   const { getStateParams } = useStateParams();
   const [ver] = getStateParams("ver");

   const { recalcUwUFields } = useResolveUwU({ ver });

   const isOutputTab = activeTab === "opPropFields" || activeTab === "opUnitFields";
   const isPropTab = activeTab === "opPropFields" || activeTab === "orPropFields";

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { groups = [], fields = [], units = [] } = qVerData?.config || {};

   const [search, setSearch] = useState("");
   const selectedFieldIds = uwuBulkState[activeTab];

   const Groups = (isPropTab ? groups : units)
      .map((group) => ({
         ...group,
         children: group.children.filter((fId) => {
            const field = fields.find((f) => f.id === fId);
            if (
               field &&
               (activeTab.startsWith("op") || (!field.readonly && !field.formula)) &&
               (search === "" || field.name.toLowerCase().includes(search.toLowerCase()))
            )
               return true;
            return false;
         }),
      }))
      .filter((group) => group.children.length > 0);

   const Fields = selectedFieldIds.map((fId) => fields.find((f) => f.id === fId)).filter(Boolean) as TversionField[];
   const unitIndex = activeTab === "orUnitFields" ? 0 : undefined;
   const handleSelect = (fId: string) => {
      const isNewField = !selectedFieldIds.includes(fId);
      const newSelectedFieldIds = isNewField ? [...selectedFieldIds, fId] : selectedFieldIds.filter((id) => id !== fId);
      const field = fields.find((f) => f.id === fId);
      if (!field) return;
      const affix = activeTab === "opPropFields" ? "P" : "U";
      const newColumnHeaders: TTableV2_headerData =
         activeTab === "opPropFields" || activeTab === "opUnitFields"
            ? isNewField
               ? [
                    ...columnHeaders,
                    {
                       label: `${field.name}|${affix}`,
                       key: `${field.id}|${affix}`,
                       format: field?.format ? [{ type: field.format === "Currency" ? "$" : "%" }] : [],
                    },
                 ]
               : columnHeaders.filter((h) => h.key !== `${field.id}|${affix}`)
            : columnHeaders;
      uwuBulkDispatch({ [activeTab]: newSelectedFieldIds, columnHeaders: newColumnHeaders });
   };
   const handleUwBase = async ({ fieldIds, uw, unitIndex }: THandleUwBaseParams) => {
      setProcessing(true);
      uwuBulkDispatch({ uwBase: { ...uwBase, ...uw } });
      const resp = await recalcUwUFields({ uws: [uw], cache, options: [uwBase.options], fieldIds, unitIndex });
      if (resp) uwuBulkDispatch({ uwBase: { uw: resp.uws[0].uw, options: resp.uws[0].options } });
      setProcessing(false);
   };
   type THandleUwBaseParams = { uw: TUwData; fieldIds: string[]; unitIndex?: number };

   useEffect(() => {
      const prepData = async () => {
         setProcessing(true);
         const data = await recalcUwUFields({ uws: [uwBase.uw], cache: {}, options: [{}] });
         if (data) uwuBulkDispatch({ uwBase: { uw: data.uws[0].uw, options: data.uws[0].options } });
         setProcessing(false);
      };
      if (Object.keys(uwBase.options).length === 0) prepData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [uwBase.options]);

   return (
      <div className={`${styles.contentC} noSelect`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.title} header-s`}>{isOutputTab ? "Output" : "Override"} Config</div>
            {isOutputTab ? (
               <>
                  <TabButton
                     isActive={activeTab === "opPropFields"}
                     title="Property"
                     onClick={() => uwuBulkDispatch({ activeTab: "opPropFields" })}
                  />
                  <TabButton
                     isActive={activeTab === "opUnitFields"}
                     title="Unit"
                     onClick={() => uwuBulkDispatch({ activeTab: "opUnitFields" })}
                  />
               </>
            ) : (
               <>
                  <TabButton
                     isActive={activeTab === "orPropFields"}
                     title="Property"
                     onClick={() => uwuBulkDispatch({ activeTab: "orPropFields" })}
                  />
                  <TabButton
                     isActive={activeTab === "orUnitFields"}
                     title="Unit"
                     onClick={() => uwuBulkDispatch({ activeTab: "orUnitFields" })}
                  />
               </>
            )}
         </div>
         <div className={`${styles.bodyC}`}>
            {selectedFieldIds.length > 0 && (
               <div className={`${styles.selectedC}`}>
                  <b>Selected: </b>
                  {Fields.map(({ id, name }, key) => (
                     <button key={key + id} onClick={() => handleSelect(id)}>
                        <span>{name}</span>
                        <span>+</span>
                     </button>
                  ))}
               </div>
            )}
            <div className={`${styles.selectionsC}`}>
               <div className={`${styles.selectionsHeader}`}>
                  <b>Field Selection: </b>
                  <MainInput title="Search" value={search} handleValue={setSearch} style={{ height: 42 }} />
               </div>
               <div className={`${styles.Groups}`}>
                  {Groups.map((group, key) => (
                     <div className={`${styles.Group}`} key={key + group.id}>
                        <div className={`${styles.GroupTitle}`}>{group.title}</div>
                        <div className={`${styles.typeGroup}`}>
                           {group.children.map((fId, key) => (
                              <div className={`${styles.fieldC}`} key={key + fId}>
                                 <MainInput
                                    type="checkbox"
                                    value={selectedFieldIds.includes(fId)}
                                    style={{ width: "2rem" }}
                                    handleValue={() => handleSelect(fId)}
                                 />
                                 {isOutputTab ? (
                                    <span>{fields.find((f) => f.id === fId)?.name}</span>
                                 ) : (
                                    <UwUbField
                                       key={fId + key}
                                       fieldId={fId}
                                       unitIndex={unitIndex}
                                       handleUw={(uw) => handleUwBase({ uw, fieldIds: [fId], unitIndex })}
                                       curOptions={uwBase.options}
                                       curUw={uwBase.uw}
                                       version={ver}
                                       loading={processing}
                                    />
                                 )}
                              </div>
                           ))}
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
};

export default UwUBulkViewConfig;
type TUwUBulkStep2ConfigProps = { cache: Record<string, any> };

export type TUwUBulkStep2_ConfigTab = "opPropFields" | "opUnitFields" | "orPropFields" | "orUnitFields";
