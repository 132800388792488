import ReactHtmlParser from "react-html-parser";
import { SHu_js_upperFirstLetter, TAIO_notification_event } from "@simplyhomes/utils";
import { TQNotifications_Notification } from "../../../../../hooks/querries/notifications/useQNotifications";
import styles from "./Notification.module.scss";
import MainButton from "../../../../common/mainButton/MainButton";
import { Link } from "react-router-dom";
import jsTimeSince from "../../../../../utils/JS/jsTimeSince";
import { useContext, useState } from "react";
import AppContext from "../../../../../contexts/appContext/AppContext";
import { TQNotes_object } from "../../../../../hooks/querries/notes/useQNotes";
const Notification = ({ notification: n, onRead }: TNotificationProps) => {
   const { appDispatch } = useContext(AppContext);

   const [expand, setExpand] = useState(false);

   const noteEvents: TAIO_notification_event[] = ["mentioned in note", "new note", "replied in note"];
   const isNoteRelated = noteEvents.includes(n["notifications.event"]);

   const handleNotes = () => {
      appDispatch({
         overwrite: {
            notes_showNotes: true,
            notes_object: n["notes.object"] as TQNotes_object,
            notes_objectid: n["notes.objectid"],
            notes_highlightNoteId: n["notes.nid"],
         },
      });
      onRead({ keepNofOpen: true });
   };
   const baseURL = process.env.REACT_APP_MODE === "DEV" ? "http://localhost:3000" : "https://aio.simplyhomes.dev";
   const LinkURL = isNoteRelated
      ? `${baseURL}/uwu/${
           n["notes.object"] === "underwritings" ? n["_underwritings.simply_object_id"] : n["notes.objectid"]
        }?showNotes=true`
      : "#";
   return (
      <div className={`${!n["notifications.seen"] && styles.unseen} ${styles.contentC}`}>
         <div className={`${styles.eventHeaderC}`}>
            <div className={`${styles.eventHeaderL}`}>
               <b>{n["notifications.event"]}</b>
               {isNoteRelated && (
                  <>
                     <MainButton title={`Notes`} onClick={handleNotes} type="bland" />
                     <Link to={LinkURL} onClick={() => onRead({ keepNofOpen: false })}>
                        <MainButton title={SHu_js_upperFirstLetter(n["notes.object"] || "")} />
                     </Link>
                  </>
               )}
               {n["notifications.event"] === "bulk underwriting finished parsing" && (
                  <Link to={`/uwubulk/${n["notifications.objectid"]}`} onClick={() => onRead({ keepNofOpen: false })}>
                     <MainButton title="Bulk Underwriting" />
                  </Link>
               )}
            </div>
            <div>
               {!n["notifications.seen"] && <div></div>}
               <p>{jsTimeSince(new Date(n["notifications.time"]).getTime())}</p>
            </div>
         </div>
         <div className={`${styles.msgC}`}>
            {n["notifications.event"] === "bulk underwriting finished parsing" && (
               <p>
                  Your <b>Bulk Underwriting</b> is Ready!
               </p>
            )}
            {n["notifications.event"] === "mentioned in note" && (
               <p>
                  <b>{n["_users.email"]?.split("@").shift()}</b> has mentioned you in (
                  {SHu_js_upperFirstLetter(n["notes.object"] || "")}){" "}
                  <b>{n["notes.objectid"] && n["_underwritings.property_address_one_line_"]}</b>
               </p>
            )}
            {n["notifications.event"] === "replied in note" && (
               <p>
                  <b>{n["_users.email"]?.split("@").shift()}</b> has replied in your note in (
                  {SHu_js_upperFirstLetter(n["notes.object"] || "")}){" "}
                  <b>{n["notes.objectid"] && n["_underwritings.property_address_one_line_"]}</b>
               </p>
            )}
            {n["notifications.event"] === "new note" && (
               <p>
                  <b>{n["_users.email"]?.split("@").shift()}</b> has made a new note in (
                  {SHu_js_upperFirstLetter(n["notes.object"] || "")}){" "}
                  <b>{n["notes.objectid"] && n["_underwritings.property_address_one_line_"]}</b>
               </p>
            )}
         </div>
         {isNoteRelated && (
            <div className={`${styles.previewC}  ${expand && styles.expanded}`}>
               <MainButton
                  title={expand ? "Collapse" : "Expand"}
                  type="bland"
                  className={`${styles.expandBtn}`}
                  onClick={() => setExpand(!expand)}
               />
               <div>{n["notes.content"] && ReactHtmlParser(n["notes.content"])}</div>
            </div>
         )}
      </div>
   );
};

export default Notification;
type TNotificationProps = {
   notification: T_FC_Notification;
   onRead: (p: { keepNofOpen?: boolean }) => void;
};
export type T_FC_Notification = TQNotifications_Notification & {
   "_underwritings.property_address_one_line_"?: string | null;
   "_underwritings.simply_object_id"?: string | null;
   "_users.email"?: string | null;
};
