import { Link } from "react-router-dom";
import styles from "./UwSCard.module.scss";
import {
   SHu_aio_underwritings_baseFields,
   SHu_js_currencyFormat,
   SHu_js_percentageFormat,
   TPg_Aio_AppViews_Schema,
   TPg_Crm_Underwritings_Schema,
} from "@simplyhomes/utils";
import { cfAIO } from "../../../../utils/s3/cfAIO";
import { useQFields } from "../../../../hooks/querries/fields/useQFields";
import MainInput from "../../../common/mainInput/MainInput";
import jsTimeSince from "../../../../utils/JS/jsTimeSince";
import { TqUws_Underwriting } from "../../../../hooks/querries/underwritings/useQUws";
const UwSCard = ({ view, ver, uw, onClick, selectMode, checked, onChecked, previewMode }: TUwSCardProps) => {
   const { data: qFieldsData } = useQFields({ tables: ["underwritings"] });
   const { fields = [] } = qFieldsData || {};
   const getFieldLabel = (name: string) => fields.find((f) => f.name === name.split(".").pop())?.label || name;
   const getFieldValue = ({ col, uw = {} }: { uw?: Record<string, any>; col: string }) => {
      if (!(col in uw)) return "!";
      const value = uw[col as keyof TPg_Crm_Underwritings_Schema];
      if (previewMode) return value;
      try {
         if (value && (col.includes("aio_original_date") || col.includes("aio_uw_date")))
            return new Date(Number(value)).toISOString().split("T").shift() + ` (${jsTimeSince(Number(value))})`;
      } catch (error) {}
      return value || "-";
   };
   const baseCols: string[] = [...SHu_aio_underwritings_baseFields];
   return (
      <div
         className={`${styles.uw} ${uw?.[`underwritings.hs_pipeline`] === "41400397" && styles.official} ${
            checked && styles.selected
         }`}
         onClick={onClick}
      >
         <Link
            to={`/uwu/${uw?.["underwritings.simply_object_id"]}?ver=${ver || ""}`}
            className={`${selectMode && styles.uwLinkDisable}`}
         >
            <div className={`${styles.uwImage}`}>
               <img
                  src={cfAIO(
                     `/properties/street-view/${
                        uw?.["associations.hs_object_id_a"] || uw?.["properties.hs_object_id"] || null
                     }`
                  )}
                  alt=""
               />
            </div>
            <div className={`${styles.uwCard1}`}>
               <b>
                  {uw?.["underwritings.hs_pipeline"] === "41400397" ? "⭐ " : ""}
                  {uw?.["underwritings.name"]}
               </b>
               <p>{uw?.["underwritings.property_address_one_line_"]}</p>
            </div>
            <ul className={`${styles.uwCard2}`}>
               <li>
                  <p>Units</p>
                  <b>{uw?.["underwritings.number_of_units"] || "-"}</b>
               </li>
               <li>
                  <p>Beds</p>
                  <b>{uw?.["underwritings.bedrooms"] || "-"}</b>
               </li>
               <li>
                  <p>Caprate</p>
                  <b>{SHu_js_percentageFormat(uw?.["underwritings.estimated_cap_rate_"] || "")}</b>
               </li>
               <li>
                  <p>ARV</p>
                  <b>
                     {SHu_js_currencyFormat(uw?.["underwritings.arv"] || "", {
                        notation: "compact",
                        minimumFractionDigits: 2,
                     })}
                  </b>
               </li>
            </ul>
            <ul className={`${styles.uwCard3}`}>
               {view?.order
                  ?.filter((col) => !baseCols.includes(col.split(".").pop() || ""))
                  .map((col, key) => (
                     <li key={key + col}>
                        <span>{getFieldLabel(col)}: </span>
                        <b>{getFieldValue({ uw, col })}</b>
                     </li>
                  ))}
            </ul>
         </Link>
         {selectMode && (
            <MainInput
               type="checkbox"
               value={checked}
               className={styles.uwCheckBox}
               showValueOnly
               handleValue={onChecked}
            />
         )}
         <ul className={`${styles.uwBadgesC}`}>
            {uw?.["underwritings.hs_pipeline"] === "41400397" && uw?.pipeline_stage_label && (
               <li className={`${styles.uwBadge2}`}>{uw.pipeline_stage_label}</li>
            )}
            {uw?.["underwritings.renovation_quote_accepted"] === "true" && (
               <li className={`${styles.uwBadge3}`}>Smart Est</li>
            )}
         </ul>
      </div>
   );
};

export default UwSCard;

export type TUwSCardProps = {
   uw?: TqUws_Underwriting;
   view?: Partial<TPg_Aio_AppViews_Schema>;
   onClick?: () => void;
   selectMode?: boolean;
   ver?: string;
   checked?: boolean;
   onChecked?: () => void;
   previewMode?: boolean;
};
