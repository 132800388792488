import { useQRenovations } from "../../../hooks/querries/renovations/useQRenovations";
import styles from "./RenovationsSearch.module.scss";
import MainInput from "../../../components/common/mainInput/MainInput";
import MainButton from "../../../components/common/mainButton/MainButton";
import { useStateParams } from "../../../hooks/react/useStateParams";
import { cfAIO } from "../../../utils/s3/cfAIO";
import { useQFieldsOptions } from "../../../hooks/querries/fields/useQFieldsOptions";
import Popup from "../../../components/common/popup/Popup";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import RenovationPreview from "../../../components/children/renovations/renovationPreview/RenovationPreview";
import { useState } from "react";
import RenovationCreatePopup from "../renovationCreatePopup/RenovationCreatePopup";
import TableV2, {
   TTableV2_header,
   TTableV2_onCellClickParams,
   TTableV2_sortDirection,
} from "../../../components/common/tableV2/TableV2";
const RenovationsSearch = () => {
   const { getStateParams } = useStateParams();
   const [search, setSearch] = getStateParams("search");
   const [tableMode, setTableMode] = getStateParams("tableMode");
   const [rid, setRid] = getStateParams("rid");
   const [showCreatePopup, setShowCreatePopup] = useState(false);
   const [sortBy, setSortBy] = getStateParams("sortBy");
   const [sortDir, setSortDir] = getStateParams("sortDir");
   // const [tableHeaders, setTableHeaders] = useState<TTableV2_header[]>([]);

   const { data: qRevsData, isFetching: qRevsFetching } = useQRenovations({ p: { search, sortBy, sortDir } });
   const { renovations = [], total } = qRevsData || {};

   const { data: qFieldData, isFetching: qFieldFetching } = useQFieldsOptions({
      objects: ["renovations", "properties"],
   });
   const { options = [] } = qFieldData || {};

   const getOptions = (col: string) => options.find((o) => o.name === col)?.options || [];
   const getLabel = (col: string, value: string) => getOptions(col).find((o) => o.value === value)?.label || "-";

   const tableHeaders: TTableV2_header[] = Object.keys(renovations[0] || {})
      .filter((key) => !key.includes("hs_object_id"))
      .map((key) => ({
         key,
         label: options.find((o) => o.name === key.split(".").pop())?.label || key,
      }));
   const handleCellClick = ({ c, r, v }: TTableV2_onCellClickParams) => {
      const { key } = tableHeaders[c];
      if (r === -1) {
         setSortBy(key);
         setSortDir(sortDir === "desc" ? "asc" : "desc");
         return;
      }
      setRid(renovations[r]["renovations.hs_object_id"]?.toString() || "");
   };

   if (qRevsFetching || qFieldFetching) return <BodyFiller loading style={{ height: "50vh" }} />;
   return (
      <section className={`${styles.contentC}`}>
         {showCreatePopup && (
            <Popup onClose={() => setShowCreatePopup(false)}>
               <RenovationCreatePopup onClose={() => setShowCreatePopup(false)} />
            </Popup>
         )}
         {!!rid && (
            <Popup onClose={() => setRid("")} pos="rightSlideIn">
               <RenovationPreview />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <MainInput
               title="Search Renovations"
               value={search}
               handleValue={setSearch}
               disableCollapse
               style={{ width: "14rem" }}
            />
            <MainButton title="Create Renovations" onClick={() => setShowCreatePopup(true)} />
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.bodyHeaderC}`}>
               <div className={`${styles.bodyHeaderL}`}>
                  <h1 className={`header-m`}>
                     {renovations.length}/{total} Renovations
                  </h1>
               </div>
               <div className={`${styles.bodyHeaderR}`}>
                  <MainInput
                     title="Sort By"
                     type="dropdown"
                     value={options.find((o) => o.name === sortBy.split(".").pop())?.label || ""}
                     handleValue={(v) => setSortBy(v.split("|").pop() || "")}
                     options={tableHeaders.map((h) => `${h.label}|${h.key}`)}
                     style={{ width: "14rem" }}
                     hideSeparators
                  />
                  <MainInput
                     title="Sort Direction"
                     type="dropdown"
                     value={sortDir === "asc" ? "asc" : "desc"}
                     handleValue={setSortDir}
                     options={["desc", "asc"]}
                     style={{ width: "10rem" }}
                  />
                  <MainInput
                     type="toggle"
                     title="Table Mode"
                     value={!!tableMode}
                     handleValue={(b) => setTableMode(b ? "true" : "")}
                  />
               </div>
            </div>
            <div className={`${styles.renovationsC}`}>
               {tableMode ? (
                  <div className={`${styles.tableC}`}>
                     <TableV2
                        headerData={tableHeaders}
                        tableData={renovations.map((o) => ({
                           ...o,
                           "renovations.hs_pipeline_stage": getLabel(
                              "hs_pipeline_stage",
                              o["renovations.hs_pipeline_stage"] || ""
                           ),
                        }))}
                        onCellClick={handleCellClick}
                        sortColIndex={tableHeaders.findIndex((h) => h.key === sortBy)}
                        sortDirection={(sortDir || "desc") as TTableV2_sortDirection}
                     />
                  </div>
               ) : (
                  <ul className={`${styles.cardsC}`}>
                     {renovations.map((rev, key) => (
                        <li
                           key={key + rev["renovations.hs_object_id"]}
                           onClick={() => setRid(rev["renovations.hs_object_id"].toString())}
                        >
                           <div className={`${styles.imgC}`}>
                              <img src={cfAIO(`/properties/street-view/${rev["properties.hs_object_id"]}`)} alt="" />
                           </div>
                           <div className={`${styles.cardHeader}`}>
                              <b>{getLabel("hs_pipeline_stage", rev["renovations.hs_pipeline_stage"] || "")}</b>
                              <p>{rev["renovations.address"]}</p>
                           </div>
                           <div className={`${styles.cardMid}`}>
                              <div>{rev["properties.bedrooms"] || "-"} Beds</div>
                              <div>{rev["properties.bathrooms"] || "-"} Baths</div>
                              <div>{rev["properties.sqft"] || "-"} sqft</div>
                           </div>
                        </li>
                     ))}
                  </ul>
               )}
            </div>
         </div>
      </section>
   );
};

export default RenovationsSearch;
