import { FC, ReactNode, useContext, useEffect, useReducer } from "react";
import { TUnknownObject } from "../../types/interfaces/unknownObj";
import AppContext from "./AppContext";
import appReducer from "./appReducer";
import { useAuth0 } from "@auth0/auth0-react";
import { TQNotes_object } from "../../hooks/querries/notes/useQNotes";
import { useSocket } from "socket.io-react-hook";
import { SocketLike } from "socket.io-react-hook/dist/types";
import { Socket } from "socket.io-client";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { useJsApiLoader } from "@react-google-maps/api";
import apiKeys from "../../const/apiKeys";
import googleLibs from "../../const/googleLibs";

/**
 * symbol $ is for form data in appState.auto_form_data
 * symbol @ is refering to custom field (user created) appState.versions[version].field[name]
 * symbol <blank> is refering to PG column in appState.property
 */

const AppState: FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
   const { REACT_APP_AUTH0_AUDIENCE } = process.env;
   const appStateDefaultState: TAppState = {};
   const [appState, appDispatch] = useReducer(appReducer, appStateDefaultState);

   const { user, getAccessTokenSilently } = useAuth0();

   const { socket } = useSocket(process.env.REACT_APP_API_URL);
   socket.on("connect", () => appDispatch({ overwrite: { socket } }));

   const { isLoaded , loadError} = useJsApiLoader({
      id: "google-map-script",
      googleMapsApiKey: apiKeys.googleAPIs,
      libraries: googleLibs,
   });
   useEffect(() => {
      if (isLoaded) appDispatch({ overwrite: { ggmap_isLoaded: true } });
   }, [isLoaded]);

   useEffect(() => {
      const getAccessToken = async () => {
         appDispatch({ overwrite: { fetchingAccessToken: true } });
         const newToken = await getAccessTokenSilently({ authorizationParams: { audience: REACT_APP_AUTH0_AUDIENCE } });
         appDispatch({ overwrite: { accessToken: newToken, fetchingAccessToken: false } });
      };

      if (user) getAccessToken();
   }, [REACT_APP_AUTH0_AUDIENCE, getAccessTokenSilently, user]);

   //*getting config
   return <AppContext.Provider value={{ appState, appDispatch }}>{children}</AppContext.Provider>;
};
export const useAppContext = () => useContext(AppContext);
export default AppState;

export type TAppState = {
   fetchingAccessToken?: boolean;
   accessToken?: string;
   test?: string;
   config?: any;
   formulaCache?: TUnknownObject;
   ggPins?: IggPin[];

   notes_showNotes?: boolean;
   notes_object?: TQNotes_object;
   notes_objectid?: number | string;
   notes_highlightNoteId?: number;

   socket?: SocketLike<Socket<DefaultEventsMap, DefaultEventsMap>>;

   notifications_show?: boolean;

   superhuman_show?: boolean;

   quickcalculator_show?: boolean;

   ggmap_isLoaded?: boolean;

   // columns?: string[];
   // versions?: {
   //    [x: string]: Iversion | undefined;
   // };
};
export type TMapData = {
   stages: TMapStage[];
   rejectReasons: TMapRejectReason[];
};
export type TMapStage = {
   label: string;
   value: string;
   color: string;
};
export type TMapRejectReason = {
   label: string;
   value: string;
   color: string;
};
export interface IggPin {
   lat: number;
   lng: number;
   address: string;
   place_id: string;
}
