import { useState } from "react";
import { useQAioUwsMeta } from "../../../../hooks/querries/aio/underwritings/useQAioUwsMeta";
import styles from "./AioUwsSchemaFieldEditor.module.scss";
import MainInput from "../../../common/mainInput/MainInput";
import { SHu_aio_helper_parseFieldName } from "@simplyhomes/utils";
import MainButton from "../../../common/mainButton/MainButton";
import useAxios from "../../../../hooks/axios/useAxios";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
const AioUwsSchemaFieldEditor = ({ fieldName, onClose }: TUwU2SchemaEditorProps) => {
   const { aioAxios } = useAxios();

   const qUwU2Meta = useQAioUwsMeta({ options: { refetchOnMount: true } });
   const { metadata: uwu2_metadata = [] } = qUwU2Meta.data || {};
   const Metadata = uwu2_metadata.find((m) => m.name === fieldName);

   const [label, setLabel] = useState(Metadata?.label || "");
   const [description, setDescription] = useState(Metadata?.description || "");
   const [format, setFormat] = useState(Metadata?.format || "");
   const [type, setType] = useState(Metadata?.type || "text");

   const [submitting, setSubmitting] = useState(false);

   const name = fieldName || SHu_aio_helper_parseFieldName(label || "");
   const isEditing = !!Metadata;
   const dupeField = uwu2_metadata.find((m) => m.name === name);
   const isValidSubmit = (!dupeField || isEditing) && !!name && !!label;

   const handleSubmit = async () => {
      setSubmitting(true);
      const body = { label, description, format, type };
      await aioAxios["post"](`/aio/v2/underwritings/metadata/${name}`, body).catch((er) => console.log({ er }));
      qUwU2Meta.refetch();
      setSubmitting(false);
      onClose(false, name);
   };
   const handleFieldDelete = async () => {
      setSubmitting(true);
      await aioAxios["delete"](`/aio/v2/underwritings/metadata/${name}`).catch((er) => console.log({ er }));
      qUwU2Meta.refetch();
      setSubmitting(false);
      onClose(false);
   };
   if (submitting)
      return (
         <article className={`${styles.contentC}`}>
            <BodyFiller loading />
         </article>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC} cardHeader-s`}>
            {isEditing ? `Editing ${Metadata.label || Metadata.name}` : "Creating new Column"}
         </div>
         <div className={`${styles.fieldsC}`}>
            <MainInput title="Field Label" value={label} handleValue={setLabel} />
            <MainInput title="Field Name" value={name} readonly />
            <MainInput title="Description" value={description} handleValue={setDescription} />
            <MainInput
               title="Type"
               type="dropdown"
               options={["text", "numeric"]}
               value={type}
               handleValue={setType}
               readonly={isEditing}
            />
            {type === "numeric" && (
               <MainInput
                  title="Format"
                  type="dropdown"
                  value={format}
                  handleValue={setFormat}
                  options={["currency", "percentage"]}
               />
            )}
         </div>
         <div className={`${styles.errorC}`}>
            {dupeField && !isEditing && (
               <p>
                  Duplicated with field "{dupeField.label}" ({dupeField.name})
               </p>
            )}
         </div>
         <div className={`${styles.controlsC}`}>
            <MainButton title="Cancel" onClick={() => onClose(false)} type="scnd" />
            {isEditing && (
               <MainButton
                  title="Delete"
                  type="warn"
                  confirmMsg={`Delete field "${label}" (${name})?`}
                  onClick={handleFieldDelete}
                  enable={Metadata.allow_delete}
               />
            )}
            <MainButton title={isEditing ? "Update" : "Create"} onClick={handleSubmit} enable={isValidSubmit} />
         </div>
      </article>
   );
};

export default AioUwsSchemaFieldEditor;
type TUwU2SchemaEditorProps = {
   fieldName?: string;
   onClose: (bool: boolean, fieldName?: string) => void;
};
