import { useState } from "react";
import MainButton from "../../../components/common/mainButton/MainButton";
import MainInput from "../../../components/common/mainInput/MainInput";
import { useQAssets } from "../../../hooks/querries/asssets/useQAssets";
import styles from "./RenovationCreatePopup.module.scss";
import useAxios from "../../../hooks/axios/useAxios";
import { useSearchParams } from "react-router-dom";
import SmallSpinner from "../../../components/common/smallSpinner/SmallSpinner";
import { useHotkeys } from "react-hotkeys-hook";
const RenovationCreatePopup = ({ onClose }: TRenovationCreatePopupProps) => {
   useHotkeys("esc", onClose);

   const [params, setParams] = useSearchParams();
   const { aioAxios } = useAxios();
   const { data: qAssetsData, isFetching: qAssetsFetching } = useQAssets();
   const { assets = [] } = qAssetsData || {};

   const [aid, setAid] = useState("");
   const [submitting, setSubmitting] = useState(false);

   const handleSubmit = async () => {
      setSubmitting(true);
      const rid = await aioAxios
         .post("/aio/renovations/", { aid })
         .then<number>(({ data }) => data.rid)
         .catch((er) => console.log({ er }));
      if (rid) {
         params.set("rid", rid.toString());
         setParams(params);
      }
      setSubmitting(false);
   };
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`cardHeader-s`}>Create new Renovation</h1>
            <MainButton type="close" onClick={onClose} />
         </div>
         <div className={`${styles.formC}`}>
            <MainInput
               title="Select Asset"
               type="dropdown"
               value={
                  (assets.find((a) => a["assets.hs_object_id"]?.toString() === aid)?.["assets.address"] || "") as string
               }
               handleValue={(v) => setAid(v.split("|").pop() || "")}
               options={assets.map((a) => `${a["assets.address"]}|${a["assets.hs_object_id"]}`)}
               hideSeparators
            />
            {submitting ? (
               <SmallSpinner style={{ height: "1rem" }} />
            ) : (
               <MainButton
                  title="Create"
                  type="warn"
                  confirmMsg="It would take few minutes to show up on Hubspot!"
                  enable={!!aid}
                  onClick={handleSubmit}
               />
            )}
         </div>
      </div>
   );
};

export default RenovationCreatePopup;
type TRenovationCreatePopupProps = {
   onClose: () => void;
};
