import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";
import { TPg_Aio_Changelogs_Schema } from "@simplyhomes/utils";

export const useQChangelogs = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["useQChangelogs"],
      queryFn: () =>
         aioAxios.get("/aio/changelogs/").then<{ changelogs: TUseQChangelogs_Changelog[] }>(({ data }) => data),
   });
};

const columns = ["cid", "sub", "content", "time"] as const;
export type TUseQChangelogs_Changelog = Pick<TPg_Aio_Changelogs_Schema, (typeof columns)[number]>;
