// import { useNavigate } from "react-router-dom";
import s3afBucketURI from "../../../../utils/s3/s3afBucketURI";
import styles from "./PropertyMarker.module.scss";
import { Iproperty } from "../../../../hooks/querries/properties/useQProperties";
import useQMap from "../../../../hooks/querries/useQMap";
import { cfAIO } from "../../../../utils/s3/cfAIO";
const PropertyMarker = ({ property }: IPropertyMarkerProps) => {
   const { data: qMapData } = useQMap();
   const { mapData } = qMapData || {};

   const { stages = [], rejectReasons = [] } = mapData || {};
   const { property_address_one_line_, hs_pipeline_stage, hs_object_id, closed_rejected_reason, bathrooms, bedrooms } =
      property;
   // const nav = useNavigate();
   const stage = stages.find(({ value }) => value === hs_pipeline_stage);
   const reason = rejectReasons.find(({ value }) => value === closed_rejected_reason);
   const color = reason?.color || stage?.color;
   return (
      <div
         className={`${styles.contentC}`}
         // onClick={() => nav(`/properties/${hs_object_id}`)}
         style={{ backgroundColor: color }}
      >
         <div className={`${styles.hoverC}`}>
            <img src={cfAIO(`/properties/street-view/${hs_object_id}`)} alt="" />
            <div className={`${styles.infoC}`}>
               <div className={`${styles.address}`}>{property_address_one_line_}</div>
               <div className={`${styles.bb}`}>
                  <span>Beds: {bedrooms ?? "-"}</span> | <span>Baths: {bathrooms ?? "-"}</span>
               </div>
               <div className={`${styles.stage}`}>{stage?.label}</div>
               <div className={`${styles.reason}`}>{reason?.label}</div>
            </div>
         </div>
      </div>
   );
};

export default PropertyMarker;
interface IPropertyMarkerProps {
   property: Iproperty;
}
