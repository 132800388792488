import useLocalStorage from "use-local-storage";

const useTheme = () => {
   const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
   const [theme, setTheme] = useLocalStorage<"light" | "dark">("theme", defaultDark ? "dark" : "light");
   const switchTheme = () => {
      const newTheme = theme === "light" ? "dark" : "light";
      setTheme(newTheme);
   };
   return { theme, switchTheme };
};
export default useTheme;
