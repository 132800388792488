import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";

export const useQAioMap = (p?: TuseQAioMap) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioMap"],
      queryFn: () => aioAxios.get(`/aio/v2/map/properties`).then<axiosResp>(({ data }) => data),
   });
};

type TProperty = { address: string; coords: string; transactionStatus: string | null; leadStatus: string | null };
type axiosResp = { properties: TProperty[] };

type TuseQAioMap = { options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]> };
