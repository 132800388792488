import { useNavigate, useParams } from "react-router-dom";
import styles from "./AioVersion.module.scss";
import TabButton from "../../common/tabButton/TabButton";
import { useStateParams } from "../../../hooks/react/useStateParams";
import { useQAioVersion } from "../../../hooks/querries/aio/versions/useQAioVersion";
import AioVersionTabFields from "./AioVersionTabFields/AioVersionTabFields";
import { useContext, useEffect, useReducer, useState } from "react";
import {
   AioVersionContext,
   aioVersionDefaultState,
   AioVersionReducer,
} from "../../../contexts/AioVersionContext/AioVersionContext";
import MainButton from "../../common/mainButton/MainButton";
import useAxios from "../../../hooks/axios/useAxios";
import { navItems } from "../../children/nav/Nav";
import Popup from "../../common/popup/Popup";
import { useQAioProps } from "../../../hooks/querries/aio/properties/useQAioProps";
import { TAIO_Underwritings2_AppData } from "@simplyhomes/utils";
import AioUwViewer from "../../AioUws/AioUwViewer/AioUwViewer";
import AioVersionTabViews from "./AioVersionTabViews/AioVersionTabViews";
import { SHrBodyFiller, SHrMainButton, SHrMainInput } from "@simplyhomes/react";
const AioVersion = () => {
   const { aioAxios } = useAxios();
   const { getStateParams } = useStateParams();
   const { vid = "" } = useParams();

   const nav = useNavigate();

   const qAioVer = useQAioVersion({ vid, options: { refetchOnMount: true } });

   const qProps = useQAioProps({ options: { refetchOnMount: true } });

   const [ver2State, ver2Dispatch] = useReducer(AioVersionReducer, aioVersionDefaultState);
   const { config } = ver2State;
   const [tab, setTab] = getStateParams("tab", "fields");
   const [saving, setSaving] = useState(false);
   const [showPreviewSettings, setShowPreviewSettings] = useState(false);
   const [address, setAddress] = useState("");
   const [showPreview, setShowPreview] = useState(false);
   const [processsingUw, setProcessingUw] = useState(false);
   const [previewError, setPreviewError] = useState("");
   const [uwAppData, setUwAppData] = useState<TAIO_Underwritings2_AppData | null>(null);

   const isOfficial = qAioVer.data?.version?.official;

   const tabProps = (name: string) => ({ isActive: tab === name, onClick: () => setTab(name) });

   useEffect(() => {
      if (qAioVer.data?.version?.config)
         ver2Dispatch({ type: "config_overwrite", payload: qAioVer.data?.version?.config });
   }, [qAioVer.data?.version?.config]);

   const handleSave = async (p?: { official?: boolean }) => {
      setSaving(true);
      await aioAxios
         .patch(`/aio/v2/versions/${vid}`, { config, official: p?.official })
         .catch((err) => console.log({ err }));
      qAioVer.refetch();
      setSaving(false);
   };
   const handlePreview = async () => {
      setProcessingUw(true);
      setPreviewError("");
      const resp = await aioAxios
         .post(`/aio/v2/underwritings/calculate`, { config, address })
         .then<{ appdata: TAIO_Underwritings2_AppData }>(({ data }) => data)
         .catch((er) => console.log({ er }));
      if (resp) {
         setUwAppData(resp.appdata);
         setShowPreview(true);
         setShowPreviewSettings(false);
      } else setPreviewError("Error processing underwriting");
      setProcessingUw(false);
   };
   const loadingMsg = saving
      ? "Saving..."
      : qAioVer.isLoading
      ? "Loading version..."
      : qProps.isLoading
      ? "Loading Properties..."
      : qAioVer.error
      ? "Error loading version"
      : null;
   if (loadingMsg)
      return (
         <section className={`${styles.contentC}`}>
            <SHrBodyFiller message={loadingMsg} style={{ height: "100%" }} />
         </section>
      );
   const previewMsg = processsingUw ? "Processing Underwriting..." : previewError ? previewError : null;
   return (
      <AioVersionContext.Provider value={{ aioVersionState: ver2State, aioVersionDispatch: ver2Dispatch }}>
         {showPreviewSettings && (
            <Popup onClose={() => setShowPreviewSettings(false)}>
               <div className={`${styles.previewSettingsC}`}>
                  <div className={`${styles.headerC}`}>
                     <div className={`${styles.headerL}`}>
                        <p className={`header-s`}>Preview Settings</p>
                     </div>
                     <div className={`${styles.headerR}`}>
                        <SHrMainButton type="close" onClick={() => setShowPreviewSettings(false)} />
                     </div>
                  </div>
                  <div className={`${styles.bodyC}`}>
                     <SHrMainInput
                        value={address}
                        onChange={setAddress}
                        title="Select an Address"
                        type="search"
                        options={qProps.data?.properties.map(({ address }) => ({ label: address, value: address }))}
                     />
                     <MainButton title="Preview" onClick={handlePreview} enable={!!address} />
                  </div>
                  <div className={`${styles.msgC}`}>{previewMsg && <SHrBodyFiller message={previewMsg} />}</div>
               </div>
            </Popup>
         )}
         {showPreview && uwAppData && config && (
            <Popup onClose={() => setShowPreview(false)} pos="rightSlideIn">
               <AioUwViewer
                  appdata={uwAppData}
                  config={config}
                  address={address}
                  onClose={() => setShowPreview(false)}
               />
            </Popup>
         )}
         <section className={`${styles.contentC}`}>
            <div className={`${styles.headerC}`}>
               <div className={`${styles.headerL}`}>
                  <SHrMainButton type="back" onClick={() => nav(navItems["Versions"].path)} />
                  <div>
                     <p className={`header-m`}>Version Editor</p>
                     <p>
                        <span className={`cardHeader-s`}>Editing: </span>
                        <b>{vid}</b>
                        {isOfficial && <span>(Official)</span>}
                     </p>
                  </div>
               </div>
               <div className={`${styles.headerR}`}>
                  <SHrMainButton title="Preview" type="secondary" onClick={() => setShowPreviewSettings(true)} />
                  <SHrMainButton title="Save" onClick={handleSave} msg={`Override version ${vid}?`} />
                  {!isOfficial && (
                     <SHrMainButton
                        title="Make Official"
                        type="warn"
                        msg={`Save and Make version ${vid} official effective now?\nThis cannot be undone!`}
                        onClick={() => handleSave({ official: true })}
                     />
                  )}
               </div>
            </div>
            <div className={`${styles.tabButtonsC}`}>
               <TabButton title="Fields" {...tabProps("fields")} />
               <TabButton title="Views" {...tabProps("views")} />
            </div>
            <div className={`${styles.tabContentC}`}>
               {tab === "fields" && <AioVersionTabFields />}
               {tab === "views" && <AioVersionTabViews />}
            </div>
         </section>
      </AioVersionContext.Provider>
   );
};
export const useAioVersionContext = () => useContext(AioVersionContext);
export default AioVersion;
