import { Link } from "react-router-dom";
import { useQUwBulks } from "../../../../hooks/querries/underwritingsBulk/useQUwBulks";
import { useQUsers } from "../../../../hooks/querries/users/useQUsers";
import styles from "./UwUBulkBrowser.module.scss";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import MainButton from "../../../common/mainButton/MainButton";
import jsTimeSince from "../../../../utils/JS/jsTimeSince";
import { SHu_js_upperFirstLetter, SHu_socketio_events, TSocketIO_uwuBulk_browsersPing } from "@simplyhomes/utils";
import { useState } from "react";
import { useSocketIo } from "../../../../hooks/socketio/useSocketIo";
import { useAuth0 } from "@auth0/auth0-react";
import useAxios from "../../../../hooks/axios/useAxios";
import SmallSpinner from "../../../common/smallSpinner/SmallSpinner";
import MainInput from "../../../common/mainInput/MainInput";
const UwUBulkBrowser = () => {
   const { aioAxios } = useAxios();
   const { user } = useAuth0();

   const [search, setSearch] = useState("");

   const { data: qUwBsData, isFetching: qUwBsF } = useQUwBulks({
      options: { refetchOnMount: true, onSuccess: (data) => setInternalBulks(data.bulks) },
      params: { search },
   });

   const [internalBulks, setInternalBulks] = useState<typeof bulks>([]);
   const [isDeleting, setIsDeleting] = useState(false);

   const { bulks = [] } = qUwBsData || {};

   const { data: qUsersData, isFetching: qUsersF } = useQUsers();
   const { users = [] } = qUsersData || {};

   useSocketIo({
      event: SHu_socketio_events.uwuBulk_browsersPing.event,
      options: {
         onMessage: (p: TSocketIO_uwuBulk_browsersPing) =>
            !!p &&
            !!p.bid &&
            setInternalBulks((prev) => prev.map((b) => (b.bid === p.bid ? { ...b, progress: p.progress } : b))),
      },
   });

   const Bulks = internalBulks.map((b) => ({
      ...b,
      creator: users.find((u) => u.sub === b.created_by),
      updater: users.find((u) => u.sub === b.updated_by),
   }));

   const isAllowedToDelete = (creator: string) => user?.sub === creator || user?.allRoles?.includes("admin");

   const handleDelete = async ({ bid }: { bid: number }) => {
      setIsDeleting(true);
      await aioAxios.delete(`/aio/underwritings/bulk/${bid}`).catch((er) => console.log({ er }));
      setInternalBulks(internalBulks.filter((b) => b.bid !== bid));
      setIsDeleting(false);
   };

   if (qUwBsF || qUsersF)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <p>Select previously created batches</p>
               <MainInput
                  value={search}
                  handleValue={setSearch}
                  title="Search by name or description"
                  disableCollapse
               />
            </div>
            <div className={`${styles.headerR}`}>
               <Link to={"create"}>
                  <MainButton title="Create" />
               </Link>
            </div>
         </div>
         <div className={`${styles.bulksC}`}>
            <div className={`${styles.bulksHeader}`}>
               <h1 className={`header-s`}>{Bulks.length} Bulk Underwriting</h1>
            </div>
            <ul className={`${styles.bulks}`}>
               {Bulks.map((b) => (
                  <li key={b.bid}>
                     <div className={`${styles.bulkControls}`}>
                        {isAllowedToDelete(b.created_by) &&
                           (isDeleting ? (
                              <SmallSpinner style={{ height: "24px" }} />
                           ) : (
                              <MainButton
                                 onClick={() => handleDelete({ bid: b.bid })}
                                 title="Delete"
                                 type="warn"
                                 confirmMsg={`Delete Bulk Underwriting #${b.bid}`}
                              />
                           ))}
                     </div>
                     <Link to={`${b.bid}`}>
                        <div
                           className={`${styles.statusC} ${
                              styles[b.progress === b.addresses.length ? "done" : "processing"]
                           }`}
                        >
                           {b.progress === b.addresses.length
                              ? `Finished`
                              : `Processing ${b.progress}/${b.addresses.length}`}
                        </div>
                        <div className={`${styles.cardTop}`}>
                           <b>{b.name || `#${b.bid}`}</b>
                           <p>{b.description || "-"}</p>
                        </div>
                        <div className={`${styles.cardMid}`}>
                           <p>
                              <span>Properties</span>
                              <b>{b.addresses.length}</b>
                           </p>
                           <p>
                              <span>Version</span>
                              <b>{b.version}</b>
                           </p>
                        </div>
                        <div className={`${styles.cardBot}`}>
                           <p>
                              <span>Created by: </span>{" "}
                              <b>{SHu_js_upperFirstLetter(b.creator?.email.split("@")[0] || "")}</b>
                           </p>
                           <p>
                              <span>Created at: </span>{" "}
                              <b>
                                 {b.created_at.split("T")[0]}({jsTimeSince(new Date(b.created_at).getTime())})
                              </b>
                           </p>
                           <p>
                              <span>Updated by: </span>{" "}
                              <b>{SHu_js_upperFirstLetter(b.updater?.email.split("@")[0] || "")}</b>
                           </p>
                           <p>
                              <span>Updated at: </span>{" "}
                              <b>
                                 {b.updated_at.split("T")[0]}({jsTimeSince(new Date(b.updated_at).getTime())})
                              </b>
                           </p>
                        </div>
                     </Link>
                  </li>
               ))}
            </ul>
         </div>
         {Bulks.length === 0 && <BodyFiller fillerMsg="No Bulk Underwriting found. Let's make some!" />}
      </article>
   );
};

export default UwUBulkBrowser;
