import {
   SHu_aio_view_validFilterOperator,
   SHu_aio_view_validateFilter,
   TPg_Utils_FilterNode,
} from "@simplyhomes/utils";
import MainButton from "../../../common/mainButton/MainButton";
import styles from "./FiltersConfig.module.scss";
import { useState } from "react";
import MainInput from "../../../common/mainInput/MainInput";
// import { useQFields } from "../../../../hooks/querries/fields/useQFields";
import { useQFieldsOptions } from "../../../../hooks/querries/fields/useQFieldsOptions";
const FiltersConfig = ({ externalFilters, onApply, onClose, objects }: TUwsFiltersProps) => {
   // const { data: qFieldsData } = useQFields({ tables: objects });
   // const { fields = [] } = qFieldsData || {};

   const { data: qFOpsData } = useQFieldsOptions({ objects });
   const { options = [] } = qFOpsData || {};

   const isEmpty = externalFilters.flat().length === 0;
   const [filters, setFilters] = useState(isEmpty ? [[]] : externalFilters);
   const operators = [...SHu_aio_view_validFilterOperator];

   const isValidFilters = filters.flat().every(SHu_aio_view_validateFilter);

   const handleOr = (targetIndex: number) =>
      setFilters([...filters.slice(0, targetIndex + 1), [], ...filters.slice(targetIndex + 1)]);
   const handleAnd = (targetIndex: number) =>
      setFilters(filters.map((fs, i) => (i === targetIndex ? [...fs, { column: "", operator: "IS NOT NULL" }] : fs)));
   const handleUpdateField = ({ filter, fI, fsI }: { filter: TPg_Utils_FilterNode; fsI: number; fI: number }) =>
      setFilters(
         filters.map((fs, fsIndex) => (fsIndex === fsI ? fs.map((f, fIndex) => (fIndex === fI ? filter : f)) : fs))
      );
   const handleRemoveField = ({ fsI, fI }: { fsI: number; fI?: number }) =>
      setFilters(
         fI == null
            ? filters.filter((_, fsIndex) => fsIndex !== fsI)
            : filters.map((fs, fsIndex) => (fsIndex === fsI ? fs.filter((_, fIndex) => fIndex !== fI) : fs))
      );
   const handleReplicateField = (fsI: number) =>
      setFilters([...filters.slice(0, fsI + 1), filters[fsI], ...filters.slice(fsI + 1)]);

   const getFieldLabel = (name: string) => options.find((f) => f.name === name)?.label || name;
   const getFieldOptions = (name: string) => options.find((f) => f.name === name)?.options || [];
   const getFieldValueLabel = (name: string, val: any) =>
      getFieldOptions(name).find((o) => o.value === val)?.label || val;
   const handleApply = () => {
      onClose();
      onApply(filters);
   };
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <MainButton type="back" onClick={onClose} />
            <p className={`header-s`}>Filters</p>
            <MainButton title="Apply" enable={isValidFilters} onClick={handleApply} />
         </div>
         <ul className={`${styles.filtersC}`}>
            {filters.map((fArr, fsI) => (
               <li key={fsI}>
                  <ul className={`${styles.filters}`}>
                     {fArr.map((f, fI) => (
                        <li key={fI} className={`${!SHu_aio_view_validateFilter(f) && styles.bad}`}>
                           <MainButton title="Remove" type="bland" onClick={() => handleRemoveField({ fsI, fI })} />
                           <MainInput
                              title="Field"
                              type="search"
                              style={{ width: "14rem" }}
                              options={options.map((f) => `${f.label}|${f.object}.${f.name}`)}
                              value={getFieldLabel(f.column.split(".").pop() || "")}
                              handleValue={(str) =>
                                 handleUpdateField({
                                    fsI,
                                    fI,
                                    filter: { ...f, column: str.split("|").pop() || "", value: "" },
                                 })
                              }
                              hideSeparators
                           />
                           <MainInput
                              title="Operator"
                              type="dropdown"
                              options={operators}
                              value={f.operator}
                              handleValue={(operator) =>
                                 handleUpdateField({ fsI, fI, filter: { ...f, operator, value: "" } })
                              }
                           />
                           {f.operator !== "IS NOT NULL" && f.operator !== "IS NULL" && (
                              <MainInput
                                 style={{ width: "12rem" }}
                                 title="Value"
                                 value={
                                    getFieldOptions(f.column).length > 0 &&
                                    f.operator !== "IS ANY OF" &&
                                    f.operator !== "IS NONE OF"
                                       ? getFieldValueLabel(f.column, f.value)
                                       : f.value || ""
                                 }
                                 type={
                                    f.operator === "IS ANY OF" || f.operator === "IS NONE OF"
                                       ? "filtersSelect"
                                       : getFieldOptions(f.column).length > 0
                                       ? "dropdown"
                                       : "text"
                                 }
                                 handleValue={(value) => handleUpdateField({ fsI, fI, filter: { ...f, value } })}
                                 options={getFieldOptions(f.column.split(".").pop() || "").map(
                                    (o) => `${o.label}|${o.value}`
                                 )}
                                 hideSeparators
                              />
                           )}
                        </li>
                     ))}
                     <li className={`${styles.filtersBtns}`}>
                        <MainButton className={`${styles.actionBtn}`} title="AND" onClick={() => handleAnd(fsI)} />
                        <MainButton title="Replicate" type="scnd" onClick={() => handleReplicateField(fsI)} />
                        <MainButton title="Remove" type="bland" onClick={() => handleRemoveField({ fsI })} />
                     </li>
                  </ul>
                  <MainButton className={`${styles.actionBtn}`} title="OR" onClick={() => handleOr(fsI)} />
               </li>
            ))}
         </ul>
      </article>
   );
};

export default FiltersConfig;
type TUwsFiltersProps = {
   externalFilters: TPg_Utils_FilterNode[][];
   onClose: () => void;
   onApply: (filters: TPg_Utils_FilterNode[][]) => void;
   objects: ("underwritings" | "assets" | "properties")[];
};
