import { IggPin } from "../../../../contexts/appContext/AppState";
import styles from "./GgMarker.module.scss";
const GgMarker = ({ item }: IGgMarkerProps) => {
   const { address } = item;
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.hoverC}`}>
            <div className={`${styles.infoC}`}>
               <div className={`${styles.address}`}>{address}</div>
            </div>
         </div>
      </div>
   );
};

export default GgMarker;
interface IGgMarkerProps {
   item: IggPin;
}
