import styles from "./EntryEditor.module.scss";
import { TUnknownObject } from "../../../../../types/interfaces/unknownObj";
import AFInput from "../../../../common/AFInput/AFInput";
import { useSearchParams } from "react-router-dom";
import useQDb from "../../../../../hooks/querries/database/useQDb";
import useQDbTable from "../../../../../hooks/querries/database/useQDbTable";
import { useState } from "react";
import MainButton from "../../../../common/mainButton/MainButton";
import BodyFiller from "../../../../common/bodyFiller/BodyFiller";
import dbGetTablePks from "../../../../../utils/database/dbGetTablePks";
import Popup from "../../../../common/popup/Popup";
import EntryImportPopup from "./entryImportPopup/EntryImportPopup";
import useAxios from "../../../../../hooks/axios/useAxios";
const EntryEditor = ({ entry, setEntry }: IEntryEditorProps) => {
   const { aioAxios } = useAxios();

   const [showPopup, setShowPopup] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState("");

   const [params, setParams] = useSearchParams();
   const table = params.get("table");

   const { data: qDbData } = useQDb();
   const { tables = [], importData = [] } = qDbData || {};
   const hasImportData = importData.find((obj) => obj.table === table);

   const { data: qDbTableData, refetch } = useQDbTable();
   const { columns = [] } = qDbTableData || {};
   const colNames = columns.map(({ name }) => name);

   const { data: colInfoData } = useQDbTable({
      table: "columns_info",
      limit: "1000",
      searchCol: "table",
      searchVal: table,
      col: "",
   });
   const { rows: colInfoRows = [] } = colInfoData || {};

   const pks = dbGetTablePks({ tables, table });

   const handleCreate = async () => {
      setIsLoading(true);
      const { error } = await aioAxios
         .post(`/aio/database/${table}`, { entry })
         .then(({ data }) => data)
         .catch((err) => err?.response?.data);
      if (error) setError(error);
      refetch();
      setIsLoading(false);
   };
   const handleDelete = async () => {
      setIsLoading(true);
      const axiosParams = pks.reduce((prev, curr) => ({ ...prev, [curr]: entry[curr] }), {});
      const { error } = await aioAxios
         .delete(`/aio/database/${table}`, { params: axiosParams })
         .then(({ data }) => data)
         .catch((err) => err?.response?.data);
      if (error) setError(error);
      params.delete("id");
      setParams(params);
      setEntry(Object.keys(entry).reduce((prev, curr) => ({ ...prev, [curr]: "" }), {}));
      refetch();
      setIsLoading(false);
   };
   const handleUpdate = async () => {
      setIsLoading(true);
      const { error } = await aioAxios
         .put(`/aio/database/${table}`, { entry, pks })
         .then(({ data }) => data)
         .catch((err) => err?.response?.data);
      if (error) setError(error);
      refetch();
      setIsLoading(false);
   };
   if (isLoading) return <BodyFiller loading={isLoading} />;
   const handleClear = () => setEntry(Object.keys(entry).reduce((prev, curr) => ({ ...prev, [curr]: "" }), {}));
   const getColInfo = (col: string) => colInfoRows.find((row) => row?.column === col) as any;

   return (
      <div className={`${styles.contentC} padding-s`}>
         {showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <EntryImportPopup setEntry={setEntry} close={() => setShowPopup(false)} />
            </Popup>
         )}
         <div className={`header-s`}>Entry Editor</div>
         <div className={`${styles.controls}`}>
            <MainButton
               title="Create"
               onClick={handleCreate}
               enable={
                  pks.every((str) => !entry[str]) &&
                  columns.filter(({ name }) => !pks.includes(name)).some(({ name }) => entry[name])
               }
            />
            {pks?.length > 0 && (
               <>
                  <MainButton title="Update" onClick={handleUpdate} enable={pks.every((str) => entry[str])} />
                  <MainButton title="Delete" onClick={handleDelete} enable={pks.every((str) => entry[str])} />
               </>
            )}
            {hasImportData && <MainButton title="Import" onClick={() => setShowPopup(true)} />}
         </div>
         <div className={`${styles.controls}`}>
            <MainButton title="Clear" onClick={handleClear} />
         </div>
         {error && typeof error === "string" && (
            <div className={`${styles.error}`} onClick={() => setError("")}>
               {error}
            </div>
         )}
         <div className={`${styles.inputs}`}>
            {colNames.map((col, key) => (
               <AFInput
                  readonly={col === "id"}
                  type={getColInfo(col)?.type || "text"}
                  options={getColInfo(col)?.enum || []}
                  style={{ width: "10rem" }}
                  key={key}
                  tooltip={pks?.includes(col) ? { type: "info", msg: "Primary Key" } : undefined}
                  title={col}
                  value={Array.isArray(entry[col]) ? `{${entry[col].join(",")}}` : entry[col] || ""}
                  handleChange={(v) => setEntry({ ...entry, [col]: v })}
               />
            ))}
         </div>
      </div>
   );
};

export default EntryEditor;
interface IEntryEditorProps {
   entry: TUnknownObject;
   setEntry: (obj: TUnknownObject) => void;
}
