import { TPG_AirTable_Meta_Tables_Schema, TPG_AirTable_Meta_Fields_Schema } from "@simplyhomes/utils";
import { useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPassThroughUseQueryOptions } from "../../../../types/useQuery/types";

export const useQAtMeta = (p?: TuseQAtMeta) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAtMeta"],
      queryFn: () => aioAxios.get(`/aio/v2/airtable/metadata`).then<axiosResp>((res) => res.data),
   });
};

const tableCols = ["tid", "name"] as const;
const fieldCols = ["fid", "tid", "name"] as const;

type axiosResp = {
   tableMetadata: Pick<TPG_AirTable_Meta_Tables_Schema, (typeof tableCols)[number]>[];
   fieldMetadata: Pick<TPG_AirTable_Meta_Fields_Schema, (typeof fieldCols)[number]>[];
};
type TuseQAtMeta = { options?: TPassThroughUseQueryOptions<axiosResp, string[]> };
