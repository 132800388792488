import { useSearchParams } from "react-router-dom";
import styles from "./UwUFieldInspector.module.scss";
import useQVersion, { TversionGroup } from "../../../../hooks/querries/versions/useQVersion";
import translateFormula from "../../../../utils/formula/translateFormula";
import { useUwUContext } from "../../../../pages/UwU/UwU";
import { useState } from "react";
import TabButton from "../../../common/tabButton/TabButton";
import UwUSingleField from "../uwuField/UwUSingleField";
import MainButton from "../../../common/mainButton/MainButton";
const UwUFieldInspector = ({
   fieldId,
   fieldIds = [],
   onBackClick,
   onTooltipClick,
   onCrumbClick,
}: TUwUFieldInspectorProps) => {
   const [params] = useSearchParams();
   const ver = params.get("ver") || "";

   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const { groups = [], units = [], fields = [] } = qVerData?.config || {};

   const { uwuState } = useUwUContext();
   const { uwFinal = {} } = uwuState;
   const { $childUnits = [] } = uwFinal;

   const [tab, setTab] = useState<number>(-1);

   const currField = fields.find(({ id }) => id === fieldId);
   const { formula = "", name = "NONAME", unitBehavior = "NONE" } = currField || {};
   const { relatedFieldIds, translated } = translateFormula(formula, fields, { traceRelated: true });

   const propFieldIds = relatedFieldIds.filter((id) => groups.find((g) => g.children.includes(id)));
   const unitFieldIds = relatedFieldIds.filter((id) => units.find((g) => g.children.includes(id)));
   const ghostFieldIds = relatedFieldIds.filter((id) => ![...propFieldIds, ...unitFieldIds].includes(id));
   if (unitBehavior !== "NONE") unitFieldIds.push(fieldId);

   const propGroups: TversionGroup[] = groups.filter((g) => propFieldIds.some((fId) => g.children.includes(fId)));
   console.log({ ghostFieldIds });
   if (ghostFieldIds.length > 0) propGroups.unshift({ id: "", title: "GHOST", children: ghostFieldIds });
   const unitGroups = units.filter((g) => unitFieldIds.some((fId) => g.children.includes(fId)));
   const handleTooltipClick = (fId: string) => {
      setTab(-1);
      onTooltipClick(fId);
   };
   const getField = (fId: string) => fields.find((f) => f.id === fId);
   return (
      <article className={`${styles.contentC} `}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.header1}`}>
               <MainButton type="back" onClick={onBackClick} style={{ width: "1.5rem", height: "1.5rem" }} />
            </div>
            <div className={`${styles.header2}`}>
               <div className={`${styles.title} header-s`}>Field Inspector</div>
               <ul className={`${styles.fieldInfoC}`}>
                  <li>
                     <b>Name:</b> <span>{name}</span>
                  </li>
                  <li>
                     <b>Id:</b> <span>{fieldId}</span>
                  </li>
               </ul>
               <ul className={`${styles.crumbs}`}>
                  {fieldIds.map((fId, key) => (
                     <li key={fId + key}>
                        <button className={`${fId === fieldId && styles.crumActive}`} onClick={() => onCrumbClick(fId)}>
                           /{getField(fId)?.name}
                        </button>
                     </li>
                  ))}
               </ul>
            </div>
         </div>
         {translated && (
            <div className={`${styles.translatedC}`}>
               <code>{translated}</code>
            </div>
         )}
         <div className={`${styles.valueC}`}>
            <b>Result</b>
            <UwUSingleField fieldId={fieldId} />
         </div>
         <div className={`${styles.tabsC}`}>
            {propFieldIds.length > 0 && <TabButton title="Property" isActive={tab === -1} onClick={() => setTab(-1)} />}
            {unitFieldIds.length > 0 &&
               $childUnits.map((_, i) => (
                  <TabButton key={i} title={`Unit #${i + 1}`} isActive={tab === i} onClick={() => setTab(i)} />
               ))}
         </div>
         <div className={`${styles.inputsC}`}>
            {(tab === -1 ? propGroups : unitGroups).map((group, key) => (
               <div className={`${styles.group}`} key={key + group.id}>
                  <div className={`${styles.groupTitle}`}>{group.title}</div>
                  <div className={`${styles.groupInputs}`}>
                     {group.children
                        .filter((cId) =>
                           (tab === -1 ? [...propFieldIds, ...ghostFieldIds] : unitFieldIds).includes(cId)
                        )
                        .map((fid, key) => (
                           <UwUSingleField
                              key={key + fid}
                              unitIndex={tab === -1 ? undefined : tab}
                              fieldId={fid}
                              onTooltipClick={handleTooltipClick}
                              style={{ width: "10rem" }}
                              highlight={fid === fieldId}
                           />
                        ))}
                  </div>
               </div>
            ))}
         </div>
      </article>
   );
};

export default UwUFieldInspector;
type TUwUFieldInspectorProps = {
   fieldId: string;
   fieldIds?: string[];
   onBackClick: () => void;
   onTooltipClick: (fieldId: string) => void;
   onCrumbClick: (fieldId: string) => void;
};
