import { useContext, useEffect } from "react";
import { useQueries } from "react-query";
import AppContext from "../../../contexts/appContext/AppContext";
import useAxios from "../../axios/useAxios";
import JSONtryParse from "../../../utils/JSON/JSONtryParse";
//
const useQPendingCache = () => {
   const { aioAxios } = useAxios();

   const { appDispatch, appState } = useContext(AppContext);
   const { formulaCache = {} } = appState;
   const allKeys = Object.keys(formulaCache);
   const allPendingCache = allKeys
      .filter((str) => formulaCache[str] === "pending")
      .map((str) => ({ key: str, val: formulaCache[str] }));
   const results = useQueries(
      allPendingCache.map(({ key }) => ({
         queryKey: key,
         queryFn: () => {
            const fx = key.split("|")[0];
            const args = JSONtryParse(key.replace(`${fx}|`, ""));
            return key.includes("pending")
               ? { [key]: "isPending" }
               : aioAxios
                    .post(`/aio/custom-formula/${key.split("|")[0].toLowerCase()}`, { args })
                    .then(({ data }) => ({ [key]: data?.value || "failed" }));
         },
      }))
   );
   useEffect(() => {
      if (allPendingCache.length > 0 && results.every(({ isFetching }) => !Boolean(isFetching))) {
         const payload = results.map(({ data }) => data).reduce((prev, curr) => ({ ...prev, ...curr }), {});
         appDispatch({ type: "updateCache", payload });
      }
   }, [allPendingCache.length, appDispatch, results]);
};

export default useQPendingCache;
