import { useQNotifications } from "../../../../hooks/querries/notifications/useQNotifications";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import MainButton from "../../../common/mainButton/MainButton";
import styles from "./NotificationPanel.module.scss";
import useAxios from "../../../../hooks/axios/useAxios";
import Notification, { T_FC_Notification } from "./notification/Notification";
import { useState } from "react";
import MainInput from "../../../common/mainInput/MainInput";
import useQUws from "../../../../hooks/querries/underwritings/useQUws";
import { useQUsers } from "../../../../hooks/querries/users/useQUsers";
import { useHotkeys } from "react-hotkeys-hook";

const defaultSearchByOptions = ["Address", "Email", "Content"];
const NotificationPanel = ({ onClose }: TNotificationPanelProps) => {
   useHotkeys("esc", onClose);
   
   const { aioAxios } = useAxios();

   const [seen, setSeen] = useState<boolean | null>();
   const [desc, setDesc] = useState(true);

   const {
      data: qNofData,
      isFetching: qNofFetching,
      refetch: qNofRefetch,
   } = useQNotifications({ refetchOnMount: true, seen, desc });
   const { notifications = [] } = qNofData || {};

   const { data: qUwsData, isFetching: qUwsFetching } = useQUws();
   const { underwritings: uws = [] } = qUwsData || {};

   const { data: qUsersData, isFetching: qUsersFetching } = useQUsers();
   const { users = [] } = qUsersData || {};

   const [search, setSearch] = useState("");
   const [searchBy, setSearchBy] = useState<string[]>(defaultSearchByOptions);
   const lcSearch = search.toLowerCase();

   const nofs: T_FC_Notification[] = notifications
      .map((n) => {
         const uw = uws.find((uw) => uw["underwritings.hs_object_id"] === n["notes.objectid"]);
         const user = users.find((u) => u.sub === n["notifications.subjectid"]);
         return {
            ...n,
            "_underwritings.property_address_one_line_": uw?.["underwritings.property_address_one_line_"],
            "_underwritings.simply_object_id": uw?.["underwritings.simply_object_id"],
            "_users.email": user?.email,
         };
      })
      .filter(
         (n) =>
            !search ||
            (searchBy.includes("Address") &&
               n["_underwritings.property_address_one_line_"]?.toLowerCase().includes(lcSearch)) ||
            (searchBy.includes("Email") && n["_users.email"]?.toLowerCase().includes(lcSearch)) ||
            (searchBy.includes("Content") && n["notes.content"]?.toLowerCase().includes(lcSearch))
      );

   const handleRead = async ({ nid, seen, keepNofOpen }: { nid: number; seen: boolean; keepNofOpen?: boolean }) => {
      if (seen && !keepNofOpen) return onClose();
      await aioAxios.get(`/aio/notifications/read/${nid}`).catch((er) => console.log({ er }));
      if (!keepNofOpen) onClose();
      qNofRefetch();
   };

   const handleNofMarkAllRead = async () => {
      await aioAxios.get(`/aio/notifications/read`).catch((er) => console.log({ er }));
      qNofRefetch();
   };

   if (qNofFetching || qUwsFetching || qUsersFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <MainButton type="close" onClick={onClose}  />
            <h1 className={`header-s`}>Notifications</h1>
            <MainButton
               title="Mark All Read"
               type="bland"
               confirmMsg="Mark all Notifications as read?"
               onClick={handleNofMarkAllRead}
            />
         </div>
         <div className={`${styles.filtersC}`}>
            <MainInput title="Search" value={search} handleValue={setSearch} disableCollapse />
            <MainInput
               title="Search by"
               type="multi"
               options={defaultSearchByOptions}
               value={searchBy}
               handleValue={setSearchBy}
            />
            <MainInput
               title="Status"
               type="dropdown"
               options={["All", "Read", "Unread"]}
               value={seen === true ? "Read" : seen === false ? "Unread" : "All"}
               handleValue={(v) => setSeen(v === "Read" ? true : v === "Unread" ? false : null)}
            />
            <MainInput
               title={`Direction`}
               type="dropdown"
               options={["Descending", "Ascending"]}
               value={desc ? "Descending" : "Ascending"}
               handleValue={(v) => setDesc(v === "Descending" ? true : false)}
            />
         </div>
         <div className={`${styles.nofsC}`}>
            {nofs.map((n, key) => (
               <Notification
                  notification={n}
                  key={key}
                  onRead={({ keepNofOpen }) =>
                     handleRead({ nid: n["notifications.nid"], seen: n["notifications.seen"], keepNofOpen })
                  }
               />
            ))}
         </div>
      </section>
   );
};

export default NotificationPanel;
type TNotificationPanelProps = {
   onClose: () => void;
};
