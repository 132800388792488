import { TPg_Aio_AppUnderwritingsFormulas_Schema } from "@simplyhomes/utils";
import { TPassThroughUseQueryOptions } from "../../../../types/useQuery/types";
import useAxios from "../../../axios/useAxios";
import { useQuery } from "react-query";

export const useQAioFormulas = (p?: TuseQAioFormulas) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioFormulas"],
      queryFn: () => aioAxios.get(`/aio/v2/formulas`).then<axiosResp>(({ data }) => data),
   });
};

const cols = ["formula", "custom", "description"] as const;
type axiosResp = {
   formulas: Pick<TPg_Aio_AppUnderwritingsFormulas_Schema, (typeof cols)[number]>[];
};

type TuseQAioFormulas = {
   options: TPassThroughUseQueryOptions<axiosResp, string[]>;
};
