import { useSearchParams } from "react-router-dom";
import useQDbTable from "../../../../../../hooks/querries/database/useQDbTable";
import styles from "./RelationEditorPopup.module.scss";
import { useState } from "react";
import Table from "../../../../../common/table/Table";
import AFInput from "../../../../../common/AFInput/AFInput";
import dbGetTablePks from "../../../../../../utils/database/dbGetTablePks";
import useQDb from "../../../../../../hooks/querries/database/useQDb";
import { TUnknownObject } from "../../../../../../types/interfaces/unknownObj";
import MainButton from "../../../../../common/mainButton/MainButton";
import useQDbRelation from "../../../../../../hooks/querries/database/useQDbRelation";
import BodyFiller from "../../../../../common/bodyFiller/BodyFiller";
import useAxios from "../../../../../../hooks/axios/useAxios";
const RelationEditorPopup = ({ handleClose }: IRelationEditorPopupProps) => {
   const { aioAxios } = useAxios();

   const [label, setLabel] = useState("");
   const [searchCol, setSearchCol] = useState("");
   const [searchVal, setSearchVal] = useState("");
   const [relId, setRelId] = useState("");

   const [submitting, setSubmitting] = useState(false);
   const [params] = useSearchParams();
   const relTable = params.get("relTable");
   const table = params.get("table");
   const id = params.get("id");

   const { data: qDbData } = useQDb();
   const { tables = [] } = qDbData || {};
   const pks = dbGetTablePks({ tables, table: relTable });
   const pk = pks?.[0];

   const { data: qDbRelData, refetch: qDbRelRefetch } = useQDbRelation();
   const { related = [] } = qDbRelData || {};
   const { labels = [] } = related.find(({ table }) => table === relTable) || {};

   const {
      data: qDbTableData,
      isFetching: qDbTableIsFetching,
      refetch: qDbTableRefetch,
   } = useQDbTable({
      table: relTable,
      searchCol,
      searchVal,
      dontUseParams: true,
   });
   const { columns = [], rows = [] } = qDbTableData || {};
   const colNames = columns.map(({ name }) => name);
   const rowData = rows.map((obj) => columns.map(({ name }) => obj[name]));

   const handleRowClick = (obj: TUnknownObject) => setRelId(obj[pk]);
   const handleAdd = async () => {
      setSubmitting(true);
      try {
         const body = { table1: table, id1: id, table2: relTable, id2: relId, label };
         await aioAxios.post(`/aio/relations/`, body);
         qDbRelRefetch();
         handleClose();
      } catch (error) {
         console.log({ error });
      }
      setSubmitting(false);
   };
   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.title} header-m`}>Add a new Relation</div>
         <div className={`${styles.inputsC}`}>
            <AFInput title="Column" type="dropdown" options={colNames} value={searchCol} handleChange={setSearchCol} />
            <AFInput title="Search" value={searchVal} handleChange={setSearchVal} />
            <MainButton title="Refresh" onClick={qDbTableRefetch} />
         </div>
         {relId && (
            <div className={`${styles.preview}`}>
               <div className={`${styles.msg} header-s`}>Add this Relation?</div>
               <div className={`${styles.id}`}>
                  <div>
                     <b>Table:</b>
                     {table} | <b>ID:</b> {id}
                  </div>
                  <div>
                     <b>Table:</b>
                     {relTable} | <b>ID:</b> {relId}
                  </div>
               </div>
               <AFInput title="Label" value={label} type="dropdown" options={labels} handleChange={setLabel} />
               {submitting ? (
                  <BodyFiller loading={true} style={{ height: "2.8rem", padding: "0.2rem" }} />
               ) : (
                  <div className={`${styles.buttons}`}>
                     <MainButton title="Add" onClick={handleAdd} enable={!!label} />
                     <MainButton title="Cancel" onClick={() => setRelId("")} />
                  </div>
               )}
            </div>
         )}
         <div className={`${styles.tableC}`}>
            {qDbTableIsFetching ? (
               <BodyFiller loading={true} style={{ height: "16rem" }} />
            ) : (
               <Table
                  type="none"
                  onRowClick={handleRowClick}
                  hightlights={pks}
                  columnHeaders={colNames}
                  data={rowData}
                  internalScroll
                  tbodyHeight="20rem"
               />
            )}
         </div>
      </div>
   );
};

export default RelationEditorPopup;
interface IRelationEditorPopupProps {
   handleClose: () => void;
}
