import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./AioBulkUw.module.scss";
import { TuseQAioBUw_underwriting, useQAioBUw } from "../../../hooks/querries/aio/bulkUnderwritings/useQAioBUw";
import { SHrBodyFiller, SHrMainButton, SHrPopup } from "@simplyhomes/react";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
import { useState } from "react";
import AioUwViewer from "../../AioUws/AioUwViewer/AioUwViewer";
const AioBulkUw = () => {
   const { bid } = useParams();
   const nav = useNavigate();
   const qBUw = useQAioBUw({
      bid: Number(bid),
      options: { refetchOnMount: true, refetchInterval: 10000, onError: () => nav(`/aio-bulk-underwriting`) },
   });
   if (!qBUw.data || !qBUw.data.bulkUnderwriting) return <SHrBodyFiller message="Loading Underwriting" />;
   const { name, percentage, status, version, ...buw } = qBUw.data.bulkUnderwriting;
   const uws = qBUw.data.underwritings || [];
   const loadingMsg = status === "processing" ? `Processing ${SHu_js_percentageFormat(percentage)}` : null;
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <Link to={"/aio-bulk-underwriting"}>
                  <SHrMainButton type="back" />
               </Link>
               <div className={`${styles.headerL1}`}>
                  <h1 className={`header-m`}>Bulk Underwriting</h1>
                  <p>
                     <span>Portfolio: </span>
                     <b>{name}</b>
                  </p>
               </div>
            </div>
            <div className={`${styles.headerR}`}></div>
         </div>
         {loadingMsg ? (
            <SHrBodyFiller message={loadingMsg} style={{ marginTop: "200px" }} />
         ) : (
            <div className={`${styles.bodyC}`}>
               <div className={`${styles.detailsC}`}>
                  <h1 className={`cardHeader-s`}>Details</h1>
                  <ul className={`${styles.details}`}>
                     <li>
                        <span>Total Purchase Price: </span>
                        <b>{SHu_js_currencyFormat(buw.total_target_purchase_price)}</b>
                     </li>
                  </ul>
               </div>
               <div className={`${styles.propsC}`}>
                  <h1 className={`cardHeader-s`}>
                     {uws.length}/{buw.addresses.length} Properties
                  </h1>
                  <ul className={`${styles.props}`}>
                     {uws.map((uw) => (
                        <UW uw={uw} key={uw.uid} version={version} />
                     ))}
                  </ul>
               </div>
            </div>
         )}
      </section>
   );
};

export default AioBulkUw;
type TUwProps = {
   uw: TuseQAioBUw_underwriting;
   version: string;
};
type TOfferDebug = {
   i: number;
   arvU: number;
   cap: number;
   price: number;
};
const UW = ({ uw, version }: TUwProps) => {
   const { address, appdata } = uw;
   const [showViewer, setShowViewer] = useState(false);
   const off1: TOfferDebug = appdata.propFields.$debug_offer_1?.value || {};
   const off2: TOfferDebug = appdata.propFields.$debug_offer_2?.value || {};
   return (
      <li className={`${styles.uw}`}>
         <div className={`${styles.uw1}`}>
            <div>
               <b>{SHu_js_currencyFormat(appdata.propFields.$target_purchase_price?.value)}</b>
               <SHrPopup
                  show={showViewer}
                  handleShow={setShowViewer}
                  type="resize-right"
                  triggerElement={<SHrMainButton title="View" type="secondary" />}
               >
                  <AioUwViewer
                     version={version}
                     address={address}
                     appdata={appdata}
                     onClose={() => setShowViewer(false)}
                  />
               </SHrPopup>
            </div>
            <p>{address}</p>
         </div>
         <div className={`${styles.uw2}`}>
            <div>
               <p>Unit Count</p>
               <b>{appdata.unitFields.length}</b>
            </div>
            <div>
               <p>Bed Count</p>
               <b>{appdata.propFields.$bedrooms_total?.value || "N/A"}</b>
            </div>
            <div>
               <p>Bath Count</p>
               <b>{appdata.propFields.$bathrooms_total?.value || "N/A"}</b>
            </div>
         </div>
         <div className={`${styles.uw3}`}>
            <div>
               <p>Offer (based on Caprate): </p>
               <b>
                  {SHu_js_currencyFormat(off1.price)} (Cap:{SHu_js_percentageFormat(off1.cap)}|ARVu:
                  {SHu_js_percentageFormat(off1.arvU)})
               </b>
            </div>
            <div>
               <p>Offer (based on ARV Uplift): </p>
               <b>
                  {SHu_js_currencyFormat(off2.price)} (Cap:{SHu_js_percentageFormat(off2.cap)}|ARVu:
                  {SHu_js_percentageFormat(off2.arvU)})
               </b>
            </div>
         </div>
      </li>
   );
};
