import { useDrag } from "react-dnd";
import styles from "./FieldFormulaDrag.module.scss";
import { IformulaItemDnD, TformulaDnDType } from "../../../../../../../../types/DnD/formulaDnD";

const FieldFormulaDrag = ({ item,  type, style, children }: IFieldFormulaNodeDragProps) => {
   const [collected, drag, dragPreview] = useDrag(() => ({ type, item }));
   return (
      <div className={`${styles.contentC}`} ref={drag} style={style}>
         {children}
      </div>
   );
};

export default FieldFormulaDrag;
interface IFieldFormulaNodeDragProps {
   type: TformulaDnDType;
   item: IformulaItemDnD;
   style?: React.CSSProperties;
   children: JSX.Element;
}
