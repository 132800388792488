import { useMemo, useState } from "react";
import { useAioVersionContext } from "../AioVersion";
import MainButton from "../../../common/mainButton/MainButton";
import styles from "./AioVersionTabFields.module.scss";
import AioVersionTabFieldEditor, {
   TVersion2FieldEditorProps_level,
} from "./AioVersionTabFieldEditor/AioVersionTabFieldEditor";
import Popup from "../../../common/popup/Popup";
import { SHrMainInput, SHrTooltip } from "@simplyhomes/react";
import { useQAtUwsMeta } from "../../../../hooks/querries/airtable/underwritings/useQAtUwsMeta";
import { useQAioUwsMeta } from "../../../../hooks/querries/aio/underwritings/useQAioUwsMeta";
import { TAIO_Version2_Config_Field } from "@simplyhomes/utils";
import { useQAtPropsMeta } from "../../../../hooks/querries/airtable/properties/useQAtPropsMeta";
const AioVersionTabFields = () => {
   const { aioVersionState } = useAioVersionContext();
   const { propFields = [], unitFields = [] } = aioVersionState.config || {};

   const [showFieldEditor, setShowFieldEditor] = useState(false);
   const [fieldEditorFid, setFieldEditorFid] = useState<string | null>(null);
   const [fieldEditorLevel, setFieldEditorLevel] = useState<TVersion2FieldEditorProps_level>("property");
   const [search, setSearch] = useState("");

   const maskAndFilterFields = (fields: TAIO_Version2_Config_Field[], search: string) => {
      return fields
         .map((f) => ({
            ...f,
            dependencies: f.type !== "formula" ? [] : fields.filter(({ id }) => f.config.formula.includes(id)),
            usedIn: fields.filter(({ type, config }) => type === "formula" && config.formula.includes(f.id)),
         }))
         .filter(
            (f) =>
               f.label.toLowerCase().includes(search.toLowerCase()) ||
               f.key.toLowerCase().includes(search.toLowerCase()) ||
               f.dependencies.join("|").toLowerCase().includes(search.toLowerCase()) ||
               f.usedIn.join("|").toLowerCase().includes(search.toLowerCase())||
               f.id.toLowerCase().includes(search.toLowerCase())
         );
   };

   const maskedPropFields = useMemo(() => maskAndFilterFields(propFields, search), [propFields, search]);

   const maskedUnitFields = useMemo(() => maskAndFilterFields(unitFields, search), [search, unitFields]);

   const handleShowFieldEditor = ({ fid, level }: { fid: string | null; level: typeof fieldEditorLevel }) => {
      setShowFieldEditor(true);
      setFieldEditorFid(fid);
      setFieldEditorLevel(level || "property");
   };
   return (
      <article className={`${styles.contentC}`}>
         {showFieldEditor && (
            <Popup onClose={() => setShowFieldEditor(false)}>
               <AioVersionTabFieldEditor
                  level={fieldEditorLevel}
                  fid={fieldEditorFid}
                  onClose={() => setShowFieldEditor(false)}
               />
            </Popup>
         )}
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <SHrMainInput
                  type="text"
                  title={`Search by label or dependencies`}
                  value={search}
                  onChange={setSearch}
               />
            </div>
            <div className={`${styles.headerR}`}></div>
         </div>
         <div className={`${styles.fieldLists}`}>
            <div className={`${styles.fieldList}`}>
               <div className={`${styles.fieldsCount}`}>
                  <p>
                     <span className={`cardHeader-s`}>Property Fields:</span> <b>{propFields.length}</b>
                  </p>
                  <MainButton
                     title="Add Property Field"
                     onClick={() => handleShowFieldEditor({ fid: null, level: "property" })}
                  />
               </div>
               <Fields
                  fields={maskedPropFields}
                  onFieldClick={(fid) => handleShowFieldEditor({ fid, level: "property" })}
               />
            </div>

            <div className={`${styles.fieldList}`}>
               <div className={`${styles.fieldsCount}`}>
                  <p>
                     <span className={`cardHeader-s`}>Unit Fields:</span> <b>{unitFields.length}</b>
                  </p>
                  <MainButton
                     title="Add Unit Field"
                     onClick={() => handleShowFieldEditor({ fid: null, level: "unit" })}
                  />
               </div>
               <Fields
                  fields={maskedUnitFields}
                  onFieldClick={(fid) => handleShowFieldEditor({ fid, level: "unit" })}
               />
            </div>
         </div>
      </article>
   );
};

export default AioVersionTabFields;
type FieldsParms = {
   fields: (TAIO_Version2_Config_Field & {
      usedIn: TAIO_Version2_Config_Field[];
      dependencies: TAIO_Version2_Config_Field[];
   })[];
   onFieldClick: (fid: string) => void;
};
const Fields = ({ fields, onFieldClick }: FieldsParms) => {
   const qAtUwsMeta = useQAtUwsMeta({ options: { refetchOnMount: true } });
   const qAtPropsMeta = useQAtPropsMeta({ options: { refetchOnMount: true } });
   const qAioUwsMeta = useQAioUwsMeta({ options: { refetchOnMount: true } });

   return (
      <ul className={`${styles.fieldsC} `}>
         {fields.map((f, i) => (
            <li key={i} onClick={() => onFieldClick(f.id)}>
               <p className={`${styles.fieldLabel}`}>{f.label}</p>
               <p className={`${styles.fieldName}`}>{f.key}</p>
               <div className={`${styles.fieldRow}`}>
                  <p className={`${styles.fieldId}`}>{f.id}</p>
                  <p className={`cardHeader-s ${styles.fieldType}`}>{f.type === "formula" ? "FX" : f.type}</p>
               </div>
               <div className={`${styles.fieldInfo}`}>
                  {f.format && (
                     <div className={`${styles.fieldDesc}`}>
                        <span>Format:</span>
                        <b> {f.format}</b>
                     </div>
                  )}
                  {f.type === "value" && f.config.source === "airtable_properties" && (
                     <div className={`${styles.fieldDesc}`}>
                        <span>Read:</span>
                        <b>
                           {" "}
                           {qAtPropsMeta.data?.metadata.find(({ fid }) => fid === f.config.column)?.name ||
                              f.config.column}
                        </b>{" "}
                        (Airtable-Properties)
                     </div>
                  )}
                  {f.type === "value" && f.config.source === "airtable_underwriting" && (
                     <div className={`${styles.fieldDesc}`}>
                        <span>Read:</span>
                        <b>
                           {" "}
                           {qAtUwsMeta.data?.metadata.find(({ fid }) => fid === f.config.column)?.name ||
                              f.config.column}
                        </b>{" "}
                        (Airtable-Underwriting)
                     </div>
                  )}
                  {f.output_aio_underwriting && (
                     <div className={`${styles.fieldDesc}`}>
                        <span>Output:</span>
                        <b>
                           {" "}
                           {qAioUwsMeta.data?.metadata.find(({ name }) => name === f.output_aio_underwriting)?.label ||
                              f.output_aio_underwriting}
                        </b>
                        (AIO-Underwriting)
                     </div>
                  )}
                  {f.output_airtable_underwriting && f.output_airtable_underwriting !== f.output_aio_underwriting && (
                     <div className={`${styles.fieldDesc}`}>
                        <span>Output:</span>
                        <b>
                           {" "}
                           {qAtUwsMeta.data?.metadata.find(({ fid }) => fid === f.output_airtable_underwriting)?.name ||
                              f.output_airtable_underwriting}
                        </b>
                        (Airtable-Underwriting)
                     </div>
                  )}
                  {f.dependencies.length > 0 && (
                     <div className={`${styles.fieldDesc} ${styles.fieldDescFlex}`}>
                        <span>Depends on:</span>
                        <b>{f.dependencies.length}</b>
                        <SHrTooltip tooltip={f.dependencies.map((f) => f.label).join("\n")} />
                     </div>
                  )}
                  {f.usedIn?.length > 0 && (
                     <div className={`${styles.fieldDesc} ${styles.fieldDescFlex}`}>
                        <span>Used in:</span>
                        <b>{f.usedIn.length}</b>
                        <SHrTooltip tooltip={f.usedIn.map((f) => f.label).join("\n")} />
                     </div>
                  )}
               </div>
            </li>
         ))}
      </ul>
   );
};
