import { useState, useEffect } from "react";
import {
   Outlet,
   createSearchParams,
   useNavigate,
   useOutletContext,
   useParams,
   useSearchParams,
} from "react-router-dom";
import useQPendingCache from "../../hooks/querries/properties/useQPendingCache";
import useQProperties, { Iproperty } from "../../hooks/querries/properties/useQProperties";
import styles from "./Properties.module.scss";
import useQVersions from "../../hooks/querries/versions/useQVersions";
import useQVersion from "../../hooks/querries/versions/useQVersion";
import BodyFiller from "../../components/common/bodyFiller/BodyFiller";
import useQProperty from "../../hooks/querries/properties/useQProperty";
import { TUnknownObject } from "../../types/interfaces/unknownObj";
import Helm from "../../components/common/helm/Helm";
import MainInput from "../../components/common/mainInput/MainInput";

const Properties = () => {
   const { data: propertiesData, isFetching: propertiesFetching } = useQProperties();
   const { properties = [] } = propertiesData || {};
   const { data: versionsData } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = versionsData || {};

   useQPendingCache();

   const { pid } = useParams();
   const [searchParams, setSearchParams] = useSearchParams();
   const ver = searchParams.get("ver") || "";
   const { property_address_one_line_ } = properties.find((obj) => obj.hs_object_id === pid) || {};
   const nav = useNavigate();
   const [search, setSearch] = useState("");
   const [property, setProperty] = useState<TUnknownObject>({});

   // const [property, propertyDispatch] = useReducer(propertiesReducer, {});

   const { isFetching: versionFetching } = useQVersion({ ver, obj: "underwritings" });
   const { data: propertyData } = useQProperty({ pid, ver });
   const { property: original } = propertyData || {};

   useEffect(() => {
      if (original) setProperty(original);
   }, [original]);

   if (propertiesFetching) return <div className={`${styles.loading}`}>Loading...</div>;
   const handleSearch = (v: string) => {
      setSearch(v);
      nav({ pathname: v.split("|").pop(), search: createSearchParams({ ver }).toString() });
   };
   const handleVer = (ver: string) => {
      searchParams.set("ver", ver);
      setSearchParams(searchParams);
   };
   return (
      <section className={`${styles.contentC}`}>
         <Helm titles={["Properties", property_address_one_line_]} />

         <div className={`${styles.controlsC}`}>
            <MainInput
               type="search"
               title="Search Address"
               options={properties.map(
                  ({ property_address_one_line_, hs_object_id }) => `${property_address_one_line_}|${hs_object_id}`
               )}
               style={{ width: "30rem" }}
               value={search}
               handleValue={handleSearch}
            />
            <MainInput
               value={ver || ""}
               title={"Select a version"}
               type="dropdown"
               options={versions.sort((a, b) =>
                  b
                     .replace(/\d+/g, (n) => (+Number(n) + 100000).toString())
                     .localeCompare(a.replace(/\d+/g, (n) => (+Number(n) + 100000).toString()))
               )}
               handleValue={handleVer}
            />
            <div className={`${styles.controls}`}></div>
         </div>
         {versionFetching ? <BodyFiller loading /> : <Outlet context={{ property, setProperty }} />}
      </section>
   );
};

export default Properties;
type ContextType = { property: Iproperty; setProperty: React.Dispatch<React.SetStateAction<TUnknownObject>> };
export const usePropertiesContext = () => useOutletContext<ContextType>();
