import { TAIO_Underwritings2_AppData, TAIO_Version2_Config_Field } from "@simplyhomes/utils";
import { useAioUwViewerContext } from "../AioUwViewer";
import { SHrMainInput } from "@simplyhomes/react";
import useAxios from "../../../../hooks/axios/useAxios";
import { utils_AioFields_GenerateTags } from "../../../../utils/AioFields/utils";
const AioUwViewerInput = ({ field, unitIndex, className }: TAioUwViewerInputProps) => {
   const { aioAxios } = useAxios();
   const { uwViewerDispatch: Dispatch, uwViewerState: State } = useAioUwViewerContext();
   const { appdata, config, version, address, processing } = State;
   const isUnit = unitIndex != null;
   if (!field) return <div>NOT FOUND</div>;
   const rawValue = isUnit ? appdata?.unitFields[unitIndex]?.[field.key]?.value : appdata?.propFields[field.key]?.value;
   const value =
      rawValue == null
         ? ""
         : Array.isArray(rawValue) && field.type === "input" && field.config.type === "multi"
         ? rawValue
         : typeof rawValue === "object"
         ? JSON.stringify(rawValue)
         : typeof rawValue === "number"
         ? rawValue.toString()
         : rawValue == null
         ? ""
         : rawValue;
   const readonly = field.type !== "input" || (field.type === "input" && field.config.readonly);
   const handleInputChange = async (val: any) => {
      if (!appdata || readonly) return;
      Dispatch({ overwrite: { processing: true } });
      const newData = !isUnit
         ? { ...appdata, propFields: { ...appdata.propFields, [field.key]: { value: val } } }
         : {
              ...appdata,
              unitFields: appdata.unitFields.map((u, i) =>
                 i === unitIndex ? { ...appdata.unitFields[unitIndex], [field.key]: { value: val } } : u
              ),
           };

      const body = { config, version, address, appdata: newData, fieldId: field.id, unitIndex };
      const resp = await aioAxios
         .post(`/aio/v2/underwritings/calculate`, body)
         .then<{ appdata: TAIO_Underwritings2_AppData }>(({ data }) => data)
         .catch((er) => console.log({ er }));
      Dispatch({ overwrite: { processing: false, appdata: resp?.appdata ? resp.appdata : appdata } });
   };
   const tooltips = [`Type: ${field.type.toUpperCase()}`, "Click to view details"];

   const commonProps = {
      loading: processing,
      title: field.label,
      readonly,
      containerProps: { style: { padding: "4px 8px" } },
      onChange: handleInputChange,
      format: field.format,
      tooltip: tooltips.join("\n"),
      onTooltipClick: () => Dispatch({ type: "tracer_push", payload: { fid: field.id, unitIndex } }),
      useInternal: true,
      tags: utils_AioFields_GenerateTags(field),
      hideOptionValues: true,
      className,
      // buttons: field.type === "formula" ? [{ label: "?", cb: () => {} }] : undefined,
   };
   const getOptions = (fid: string) => {
      const optField = config?.[isUnit ? "unitFields" : "propFields"]?.find((f) => f.id === fid);
      if (!optField) return [];
      const optValue = isUnit
         ? appdata?.unitFields[unitIndex]?.[optField.key]?.value
         : appdata?.propFields[optField.key]?.value;
      const options = (
         typeof optValue === "string"
            ? optValue.split(",")
            : Array.isArray(optValue)
            ? optValue
            : [optValue?.toString()]
      ).map((o) => ({ label: o || "-", value: o || "-" }));
      return options;
   };
   if (field.type === "input" && field.config.type === "dropdown" && typeof value === "string")
      return (
         <SHrMainInput
            {...commonProps}
            type={"dropdown"}
            value={value}
            options={
               field.config.options?.[0]?.startsWith("fid")
                  ? getOptions(field.config.options?.[0])
                  : field.config.options?.map((o) => ({ label: o, value: o }))
            }
         />
      );
   if (typeof value === "boolean") return <SHrMainInput {...commonProps} type={"checkbox"} value={value} />;
   if (field.type === "input" && field.config.type === "multi")
      return (
         <SHrMainInput
            {...commonProps}
            type={"multi-select"}
            value={Array.isArray(value) ? value : []}
            options={
               field.config.options?.[0]?.startsWith("fid")
                  ? getOptions(field.config.options?.[0])
                  : field.config.options?.map((o) => ({ label: o, value: o }))
            }
         />
      );
   if (Array.isArray(value)) return <SHrMainInput {...commonProps} type={"text"} value={value.join(",")} />;
   return <SHrMainInput {...commonProps} type={"text"} value={value} />;
};

export default AioUwViewerInput;
type TAioUwViewerInputProps = {
   field: TAIO_Version2_Config_Field;
   unitIndex?: number;
   className?: string;
};
