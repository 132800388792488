import { IappReducerAction } from "../../types/interfaces/reducer.interface";
import { TAppState } from "./AppState";

const appReducer = (state: TAppState, action: IappReducerAction): TAppState => {
   // console.log({ action });
   if (action.overwrite) return { ...state, ...action.overwrite };

   switch (action.type) {
      case "updateGgPins":
         return { ...state, ggPins: [...(state.ggPins || []), action.payload] };
      case "updateCache":
         return { ...state, formulaCache: { ...state.formulaCache, ...action.payload } };
      case "clearCache":
         return { ...state, formulaCache: {} };
      default:
         break;
   }
   console.log(`action is not supported`);

   return { ...state };
};

export default appReducer;
