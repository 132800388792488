import { TversionGroup } from "../../../../hooks/querries/versions/useQVersion";
import sliceIntoChunks from "../../../../utils/JS/sliceChunks";
import Table from "../../../common/table/Table";
import styles from "./PropertyGroup.module.scss";
import PropertyGroupChild from "./propertyGroupChild/PropertyGroupChild";
const PropertyGroup = ({ group, childIndex }: TPropertyGroupParams) => {
   const { children, type = "group", columns = "", rows = "" } = group;
   const cs = columns.split(",");
   const rs = rows.split(",");
   const rowsData = sliceIntoChunks(children, cs.length);
   return (
      <div className={`${styles.contentC}`}>
         {type === "group" && (
            <ul className={`${styles.children} `}>
               <div className={`${styles.childC}`}>
                  {children.map((id, key) => (
                     <PropertyGroupChild id={id} key={key} childIndex={childIndex} />
                  ))}
               </div>
            </ul>
         )}
         {type === "table" && (
            <div className={`${styles.tableC}`}>
               <Table columnHeaders={cs} rowHeaders={rs} data={rowsData} type="PropertyField" />
            </div>
         )}
      </div>
   );
};

export default PropertyGroup;

type TPropertyGroupParams = {
   group: TversionGroup;
   childIndex?: number;
};
