import { Node } from "excel-formula-ast";
const stringifyFormula = (node?: Node): string => {
   if (!node) return "Empty Formula";
   const { type } = node;
   switch (type) {
      case "cell": {
         const { key } = node;
         return key;
      }
      case "binary-expression": {
         const { left, operator, right } = node;
         return `${stringifyFormula(left)} ${operator} ${stringifyFormula(right)}`;
      }
      case "text": {
         const { value } = node;
         return `"${value.toString()}"`;
      }
      case "number": {
         const { value } = node;
         return `${value.toString()}`;
      }
      case "function": {
         const { name, arguments: args } = node;
         return `${name}( ${args?.map(stringifyFormula).join(" , ") || ""} )`;
      }
      default:
         return "";
   }
};

export default stringifyFormula;
