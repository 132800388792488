import { TTableV2_headerData } from "../../components/common/tableV2/TableV2";
import { TUwUBulkStep2_ConfigTab } from "../../components/children/uwuBulk/UwUBulkView/UwUBulkViewConfig/UwUBulkViewConfig";
import { TUwData } from "../../types/hsObjects";
import { SHu_js_swapTwoElementsInArray } from "@simplyhomes/utils";

export const uwuBulkDefaultState: TUwUBulkState = {
   uwBase: { uw: { $childUnits: [{ bedrooms: 1 }] }, options: {} },
   columnHeaders: [
      { key: "✅", label: "✅" },
      { key: "1680002446461|P", label: "Address|P" },
      { key: "Unit#", label: "Unit#" },
      { key: "1680002446469|U", label: "Beds|U" },
      { key: "1680002446468|U", label: "Baths|U" },
      { key: "1680002446401|P", label: "Cap Rate|P", format: [{ type: "%" }] },
      { key: "1680002446459|P", label: "Price (Offer Amount)|P", format: [{ type: "$" }] },
      { key: "1680002446447|P", label: "After Repair Value|P", format: [{ type: "$" }] },
      { key: "1680002446419|U", label: "Base Monthly Rent|U", format: [{ type: "$" }] },
      { key: "1680002446424|U", label: "Rental Source|U" },
   ],
   markedProperties: [],
   activeTab: null,

   orPropFields: [],
   orUnitFields: [],
   opPropFields: ["1680002446461", "1680002446401", "1680002446459", "1680002446447"],
   opUnitFields: ["1680002446469", "1680002446468", "1680002446419", "1680002446424"],
};
export type TUwUBulkState = {
   uwBase: { uw: TUwData; options: Record<string, any> };
   columnHeaders: TTableV2_headerData;
   markedProperties: string[];
   activeTab: TUwUBulkStep2_ConfigTab | null;
   activeUwIndex?: number;

   orPropFields: string[];
   orUnitFields: string[];
   opPropFields: string[];
   opUnitFields: string[];
};

export const uwuBulkReducer = (state: TUwUBulkState, action: TUwUBulkReducerAction): TUwUBulkState => {
   // console.log({ action });
   if (!action.type) return { ...state, ...action };
   switch (action.type) {
      case "swapHeaders":
         return {
            ...state,
            columnHeaders: SHu_js_swapTwoElementsInArray(
               [...state.columnHeaders],
               action.payload.from,
               action.payload.to
            ),
         };
      default:
         return { ...state };
   }
};

export type TUwUBulkReducerAction = (
   | { type: "swapHeaders"; payload: { from: number; to: number } }
   | { type?: ""; payload?: any }
) &
   Partial<TUwUBulkState>;
// export const UwUBulkContext = createContext<{
//    uwuBulkState: TUwUBulkState;
//    uwuBulkDispatch: React.Dispatch<TUwUBulkReducerAction>;
// }>({ uwuBulkState: uwuBulkDefaultState, uwuBulkDispatch: () => {} });

// export const UwUBulkProvider = ({ children }: { children: React.ReactNode }) => {
//    const [uwuBulkState, uwuBulkDispatch] = useReducer(uwuBulkReducer, uwuBulkDefaultState);
//    return <UwUBulkContext.Provider value={{ uwuBulkState, uwuBulkDispatch }}>{children}</UwUBulkContext.Provider>;
// };
