import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styles from "./Property.module.scss";
import PropertyGroup from "../../../components/children/property/propertyGroup/PropertyGroup";
import useQProperty from "../../../hooks/querries/properties/useQProperty";
import MainButton from "../../../components/common/mainButton/MainButton";
import Popup from "../../../components/common/popup/Popup";
import useQVersion from "../../../hooks/querries/versions/useQVersion";
import { usePropertiesContext } from "../Properties";
import useAxios from "../../../hooks/axios/useAxios";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import { useAuth0 } from "@auth0/auth0-react";
import PropertySnapshotsPopup from "../../../components/children/property/propertySnapshotsPopup/PropertySnapshotsPopup";
import Table from "../../../components/common/table/Table";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";
import PropertyErrors from "../../../components/children/property/propertyErrors/PropertyErrors";
import MainInput from "../../../components/common/mainInput/MainInput";

const Property = () => {
   const { aioAxios } = useAxios();

   const { property, setProperty } = usePropertiesContext();

   const { pid } = useParams();
   const [params] = useSearchParams();
   const { user } = useAuth0();

   const ver = params.get("ver") || "";

   const { data: versionData } = useQVersion({ ver, obj: "underwritings" });
   const { data: propertyData, isFetching: propertyFetching, refetch: propertyRefetch } = useQProperty({ pid, ver });
   const { property: original = {} } = propertyData || {};

   const { property_address_one_line_, $last_saved, $saved_by, $version, $childUnits = [] } = property;
   const { groups = [], fields = [], units = [] } = versionData?.config || {};
   const allGroups = groups.map(({ title }) => title);
   const allChildGroups = units.map(({ title }) => title);
   const allChildren = $childUnits.map((_, i) => i + 1);

   const [showDiffPopup, setShowDiffPopup] = useState(false);
   const [showSnapPopup, setShowSnapPopup] = useState(false);
   const [showErrorPopup, setShowErrorPopup] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const [shownGroups, setShownGroups] = useState<string[]>(allGroups);
   const [shownChildren, setShownChildren] = useState<number[]>(allChildren);
   const [shownChildGroups, setShownChildGroups] = useState(allChildGroups);

   if (propertyFetching || submitting) return <BodyFiller loading />;
   if (!ver) return <BodyFiller fillerMsg="Select A Version on top right to continue" />;

   const differences: string[] = [];
   Object.keys(original).forEach(
      (oKey) => original[oKey]?.toString() !== property[oKey]?.toString() && differences.push(oKey)
   );
   const allRequiredFilled = fields
      .filter(({ required, column }) => required && column)
      .every(({ column }) => property[column || ""]);
   const handleSave = async () => {
      setSubmitting(true);
      await aioAxios
         .patch(`/aio/properties/${pid}`, {
            property: { ...property, $last_saved: Date.now(), $saved_by: user?.email, $version: ver },
            original,
            version: ver,
         })
         .catch((err) => console.log({ err }));
      propertyRefetch();
      setSubmitting(false);
   };

   const handleAddUnit = () => {
      const dupData: TUnknownObject =
         $childUnits.length > 0
            ? $childUnits[0]
            : units
                 .map(({ children }) => children)
                 .flat()
                 .map((id) => fields.find((field) => field.id === id)?.column)
                 .reduce((prev, col = "") => ({ ...prev, [col]: property[col] }), {});
      const newChildUnits = [dupData, ...$childUnits];
      setProperty((prev) => ({ ...prev, $childUnits: newChildUnits }));
      setShownChildren([...new Set([...shownChildren, $childUnits.length + 1])]);
   };
   const handleRemoveUnit = (index: number) => {
      $childUnits.splice(index, 1);
      setProperty((prev) => ({ ...prev, $childUnits }));
   };

   const test = () => console.log({ property, original });
   return (
      <section className={`${styles.contentC}`}>
         {showDiffPopup && (
            <Popup onClose={() => setShowDiffPopup(false)}>
               <article className={`${styles.differences}`}>
                  <Table
                     columnHeaders={["Orginal", "New Values"]}
                     rowHeaders={differences}
                     data={differences.map((dKey) => [original?.[dKey]?.toString(), property?.[dKey]?.toString()])}
                     type="none"
                  />
               </article>
            </Popup>
         )}
         {showSnapPopup && pid && (
            <Popup onClose={() => setShowSnapPopup(false)}>
               <PropertySnapshotsPopup pid={pid} handleClose={() => setShowSnapPopup(false)} />
            </Popup>
         )}
         {showErrorPopup && (
            <Popup onClose={() => setShowErrorPopup(false)}>
               <PropertyErrors />
            </Popup>
         )}

         <div className={`${styles.header}`}>
            <div className={`${styles.infoC}`}>
               <div>
                  <p>Go to Hubspot: </p>
                  <p>
                     <a
                        href={`https://app.hubspot.com/contacts/7832526/record/2-11273381/${pid}`}
                        target="_blank"
                        rel="noreferrer"
                     >
                        <span>{pid}</span>
                        <img src={s3afBucketURI("/icons/external_link.svg")} alt="" />
                     </a>
                  </p>
               </div>
               <div onClick={test}>
                  <p>Address:</p>
                  <p>{property_address_one_line_}</p>
               </div>
               <div>
                  <p>Last Saved: </p>
                  <p>
                     {$last_saved
                        ? new Date($last_saved).toLocaleString("en-US", { timeZone: "America/New_York" })
                        : "Unknown"}
                  </p>
               </div>
               <div>
                  <p>Saved By: </p>
                  <p>{$saved_by || "Unknown"}</p>
               </div>
               <div>
                  <p>Version Used: </p>
                  <p>{$version || "Unknown"}</p>
               </div>
            </div>
            <div className={`${styles.controlsC}`}>
               <div>
                  <MainButton title="Refresh" onClick={propertyRefetch} type="bland" />
                  <MainButton title="Snapshots" onClick={() => setShowSnapPopup(true)} />
                  <MainButton title="Compare" onClick={() => setShowDiffPopup(true)} />
                  <MainButton
                     title="Save!"
                     type="warn"
                     onClick={handleSave}
                     enable={allRequiredFilled}
                     confirmMsg="Overwrite with new Data?"
                  />
               </div>
            </div>
         </div>

         <div className={`${styles.ud}`}>
            <div className={`header-m ${styles.udHeader}`}>
               <span>{$childUnits.length} Unit(s) Details</span>
               <MainButton title="Add Unit" confirmMsg="Add one new Unit?" onClick={handleAddUnit} />
            </div>
            {$childUnits.length > 0 && (
               <div className={`${styles.Groups}`}>
                  <div className={`${styles.Group}`}>
                     <div className={`${styles.GroupTitle}`}>
                        <span>Units</span>
                        <MainButton title="Add All" type="bland" onClick={() => setShownChildren(allChildren)} />
                        <MainButton title="Clear" type="bland" onClick={() => setShownChildren([])} />
                     </div>
                     <div className={`${styles.titlesC}`}>
                        {allChildren.map((cIndex, key) => (
                           <MainInput
                              key={key}
                              title={`Unit #${cIndex}`}
                              type="checkbox"
                              value={shownChildren.includes(cIndex)}
                              handleValue={(b) =>
                                 setShownChildren(
                                    b ? [...shownChildren, cIndex] : shownChildren.filter((num) => num !== cIndex)
                                 )
                              }
                           />
                        ))}
                     </div>
                  </div>
                  <div className={`${styles.Group}`}>
                     <div className={`${styles.GroupTitle}`}>
                        <span>Unit Groups</span>
                        <MainButton
                           title="Add All"
                           type="bland"
                           onClick={() => setShownChildGroups(allChildGroups)}
                        />
                        <MainButton title="Clear" type="bland" onClick={() => setShownChildGroups([])} />
                     </div>
                     <div className={`${styles.titlesC}`}>
                        {allChildGroups.map((title, key) => (
                           <MainInput
                              key={key}
                              type="checkbox"
                              title={title}
                              value={shownChildGroups.includes(title)}
                              handleValue={(b) =>
                                 setShownChildGroups(
                                    b
                                       ? [...shownChildGroups, title]
                                       : shownChildGroups.filter((groupTitle) => groupTitle !== title)
                                 )
                              }
                           />
                        ))}
                     </div>
                  </div>
               </div>
            )}
            <div className={`${styles.udChildren}`}>
               {$childUnits.map((_, childIndex) => (
                  <div
                     key={childIndex}
                     className={`${!shownChildren.includes(childIndex + 1) ? "hidden" : styles.udChild}`}
                  >
                     <div className={`${styles.udChildTitle}`}>
                        <span>Unit #{childIndex + 1}</span>
                        <MainButton
                           type="bland"
                           title={"Hide"}
                           onClick={() =>
                              setShownChildren(shownChildren.filter((cIndex) => cIndex !== childIndex + 1))
                           }
                        />
                        <MainButton
                           type="warn"
                           title="Delete"
                           confirmMsg="Remove Unit?"
                           onClick={() => handleRemoveUnit(childIndex)}
                        />
                     </div>
                     <div className={`${styles.Groups}`}>
                        {units.map((group, key) => (
                           <div
                              className={`${!shownChildGroups.includes(group.title) ? "hidden" : styles.Group}`}
                              key={key}
                           >
                              <div className={`${styles.GroupTitle}`}>
                                 <span>{group.title}</span>
                                 <MainButton
                                    title={!shownChildGroups.includes(group.title) ? "Show" : "Hide"}
                                    type="bland"
                                    onClick={() =>
                                       !shownChildGroups.includes(group.title)
                                          ? setShownChildGroups([...shownChildGroups, group.title])
                                          : setShownChildGroups(
                                               shownChildGroups.filter((groupTitle) => groupTitle !== group.title)
                                            )
                                    }
                                 />
                              </div>
                              <div>
                                 <PropertyGroup group={group} childIndex={childIndex} />
                              </div>
                           </div>
                        ))}
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <div className={`${styles.pd}`}>
            <div className={`header-m ${styles.pdHeader}`}>
               <span>Property Details</span>
            </div>
            <div className={`${styles.Groups}`}>
               <div className={`${styles.Group}`}>
                  <div className={`${styles.GroupTitle}`}>
                     <div>Property Groups</div>
                     <MainButton type="bland" title="Add All" onClick={() => setShownGroups(allGroups)} />
                     <MainButton type="bland" title="Clear" onClick={() => setShownGroups([])} />
                  </div>
                  <div className={`${styles.titlesC}`}>
                     {allGroups.map((title, key) => (
                        <MainInput
                           key={key}
                           type="checkbox"
                           title={title}
                           value={shownGroups.includes(title)}
                           handleValue={(b) =>
                              setShownGroups(
                                 b ? [...shownGroups, title] : shownGroups.filter((groupTitle) => groupTitle !== title)
                              )
                           }
                        />
                     ))}
                  </div>
               </div>
               {groups
                  // .filter(({ title }) => shownGroups.includes(title))
                  .map((group, key) => (
                     <div className={`${!shownGroups.includes(group.title) ? "hidden" : styles.Group}`} key={key}>
                        <div className={`${styles.GroupTitle} `}>
                           <span>{group.title}</span>
                           <MainButton
                              title={"Hide"}
                              type="bland"
                              onClick={() =>
                                 setShownGroups(shownGroups.filter((groupTitle) => groupTitle !== group.title))
                              }
                           />
                        </div>
                        <PropertyGroup group={group} />
                     </div>
                  ))}
            </div>
         </div>
      </section>
   );
};

export default Property;
