import { useState } from "react";
import useQVersion from "../../../../hooks/querries/versions/useQVersion";
import { TUwData } from "../../../../types/hsObjects";
import parseFieldName from "../../../../utils/fields/parseFieldName";
import MainButton from "../../../common/mainButton/MainButton";
import TableV2, { TTableV2_OnHeaderDrop } from "../../../common/tableV2/TableV2";
import styles from "./UwUBulkView.module.scss";
import Popup from "../../../common/popup/Popup";
import { useResolveUwU } from "../../../../hooks/formulas/useResolveUwU";
import {
   SHu_socketio_events,
   TPg_Crm_Properties_Schema,
   TPg_Crm_Underwritings_Schema,
   TSocketIO_uwuBulk_creatorPing,
} from "@simplyhomes/utils";
import useAxios from "../../../../hooks/axios/useAxios";
import MainInput from "../../../common/mainInput/MainInput";
import UwU from "../../../../pages/UwU/UwU";
import { useNavigate, useParams } from "react-router-dom";
import UwUBulkViewConfig from "./UwUBulkViewConfig/UwUBulkViewConfig";
import { useUwuBulkContext } from "../../../../pages/UwUBulk/UwUBulk";
import { useQUwBulk } from "../../../../hooks/querries/underwritingsBulk/useQUwBulk";
import { useStateParams } from "../../../../hooks/react/useStateParams";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import useQVersions from "../../../../hooks/querries/versions/useQVersions";
import SmallSpinner from "../../../common/smallSpinner/SmallSpinner";
import { useSocketIo } from "../../../../hooks/socketio/useSocketIo";
const UwUBulkView = () => {
   const { aioAxios } = useAxios();
   const nav = useNavigate();

   const { bid = "" } = useParams();
   const {
      data: qUwBData,
      isFetching: qUwBF,
      refetch: qUwBRefetch,
   } = useQUwBulk({
      bid,
      options: {
         refetchOnMount: true,
         onSuccess: ({ bulk }) => {
            setUws(bulk.uws);
            setCache(bulk.cache);
            setVer(bulk.version);
            setPortfolio(bulk.name || "UNNAMED");
         },
      },
   });
   const { bulk } = qUwBData || {};

   const { getStateParams } = useStateParams();
   const [ver, setVer] = getStateParams("ver");

   const { uwuBulkDispatch, uwuBulkState } = useUwuBulkContext();
   const { columnHeaders, markedProperties, activeTab, uwBase } = uwuBulkState;
   const { orPropFields, orUnitFields, opPropFields, opUnitFields, activeUwIndex } = uwuBulkState;

   const [uws, setUws] = useState<{ uw: TUwData; options: Record<string, any> }[]>([]);
   const [cache, setCache] = useState<Record<string, any>>({});

   const { data: qVersData } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersData || {};

   const { data: qVerData } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [] } = qVerData?.config || {};

   const { recalcUwUFields } = useResolveUwU({ ver });

   type TPostData = {
      contact: string | number | undefined;
      properties: Pick<TPg_Crm_Properties_Schema, "hs_object_id" | "simply_object_id" | "property_address_one_line_">[];
      underwritings: Pick<TPg_Crm_Underwritings_Schema, "simply_object_id">[];
   };
   const [postData, setPostData] = useState<TPostData>();
   const { contact: postContact, properties = [], underwritings = [] } = postData || {};
   const dupProperties = properties.filter((p) => Number(p.hs_object_id) > 0);
   const [showPostData, setShowPostData] = useState(false);

   const [showImportPopup, setShowImportPopup] = useState(false);
   const [contactName, setContactName] = useState("");
   const [contactPhone, setContactPhone] = useState("");
   const [contactEmail, setContactEmail] = useState("");
   const [portfolio, setPortfolio] = useState("");
   const [recalculating, setRecalculating] = useState(false);
   const [importSubmitting, setImportSubmitting] = useState(false);
   const [progress, setProgress] = useState(0);
   const [saving, setSaving] = useState(false);

   useSocketIo({
      event: SHu_socketio_events.uwuBulk_creatorPing.event,
      options: { onMessage: (p: TSocketIO_uwuBulk_creatorPing) => setProgress(p.progress) },
   });

   const getTableData = (uw: TUwData, unitIndex?: number): Record<string, any> => {
      const { $childUnits = [] } = uw;
      const outputData = columnHeaders.reduce((prev, PUfId) => {
         const fieldId = PUfId.key.split("|")[0];
         const level = PUfId.key.split("|")[1];
         const field = fields.find((f) => f.id === fieldId);
         if (!field) return { ...prev };
         if (level === "P")
            return {
               ...prev,
               [`${PUfId.key}`]:
                  unitIndex != null ? "" : field.column ? uw[field.column] : uw[parseFieldName(field.name)],
            };
         return {
            ...prev,
            [`${PUfId.key}`]:
               unitIndex == null && field.unitBehavior !== "NONE"
                  ? field.column
                     ? uw[field.column]
                     : uw[parseFieldName(field.name)]
                  : unitIndex == null
                  ? ""
                  : field.column
                  ? $childUnits[unitIndex][field.column]
                  : $childUnits[unitIndex][parseFieldName(field.name)],
         };
      }, {});

      const baseData = {
         "✅": unitIndex != null ? "" : markedProperties.includes(uw.property_address_one_line_) ? "✅" : "🔲",
         Address: uw.property_address_one_line_,
         "Unit#": unitIndex == null ? 0 : unitIndex + 1,
      };

      return { ...baseData, ...outputData };
   };
   const TableData = uws
      .map(({ uw }) => [
         getTableData(uw),
         ...(uw.$childUnits || []).map((_: any, uIndex: number) => getTableData(uw, uIndex)),
      ])
      .flat();
   const handleRecalc = async () => {
      const orPropData = fields
         .filter(({ id }) => orPropFields.includes(id))
         .reduce(
            (prev, { column, name }) => ({
               ...prev,
               [column || parseFieldName(name)]: uwBase.uw[column || parseFieldName(name)] || undefined,
            }),
            {}
         );
      const orUnitData = fields
         .filter(({ id }) => orUnitFields.includes(id))
         .reduce(
            (prev, { column, name }) => ({
               ...prev,
               [column || parseFieldName(name)]:
                  uwBase.uw.$childUnits?.[0][column || parseFieldName(name)] || undefined,
            }),
            {}
         );
      setRecalculating(true);
      const resp = await recalcUwUFields({
         uws: uws.map(({ uw }) => ({
            ...uw,
            ...orPropData,
            $childUnits: uw?.$childUnits?.map((unit) => ({ ...unit, ...orUnitData })),
         })),
         cache,
         options: uws.map(({ options }) => options),
         perfectCaprate: true,
      });
      if (resp) {
         setUws(resp.uws);
         setCache(resp.cache);
      }
      setRecalculating(false);
      setProgress(0);
   };
   const handleHsImport = async () => {
      const contact = {
         name: contactName,
         phone: contactPhone,
         email: contactEmail,
      };
      setImportSubmitting(true);
      const mappedUws = [...new Set(markedProperties)]
         .map((address) => uws.find(({ uw }) => uw.property_address_one_line_ === address)?.uw)
         .filter(Boolean);
      const resp = await aioAxios
         .post(`/aio/underwritings/bulk/import`, { uws: mappedUws, portfolio, contact })
         .then<TPostData>(({ data }) => data)
         .catch((er) => console.log({ er }));

      if (resp) {
         setPostData(resp);
         setShowPostData(true);
      }
      setImportSubmitting(false);
      setShowImportPopup(false);
   };

   const handleDownloadCsv = () => {
      const keysToBeRemoved = ["✅"];
      const csvOutputKeys = columnHeaders.filter(({ key }) => !keysToBeRemoved.includes(key));
      const csv = TableData.map((obj) => csvOutputKeys.map(({ key }) => JSON.stringify(obj[key])).join(","));
      csv.unshift(csvOutputKeys.map(({ label }) => label).join(","));
      const csvString = csv.join("\n");
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvString));
      element.setAttribute("download", "BulkCalcResult");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
   };
   const handleTableHeaderDrop: TTableV2_OnHeaderDrop = ({ from, to }) =>
      uwuBulkDispatch({ type: "swapHeaders", payload: { from, to } });
   const handleInspectorSave = (p: {
      uwFinal: Record<string, any>;
      uwCache: Record<string, any>;
      uwOptions: Record<string, any>;
   }) => {
      const newUw = { uw: p.uwFinal, options: p.uwOptions };
      setUws(uws.map((uw, i) => (i === activeUwIndex ? newUw : uw)));
      setCache({ ...cache, ...p.uwCache });
   };
   const handleCellClick = ({ r, c }: { r: number; c: number; v: any }) => {
      const colLabel = columnHeaders[c]?.label;
      if (r === -1) return;
      if (colLabel === "✅") {
         const address = TableData[r]["Address"];
         if (!address) return;
         return uwuBulkDispatch({
            markedProperties: markedProperties.includes(address)
               ? markedProperties.filter((str) => str !== address)
               : [...markedProperties, address],
         });
      }
      if (colLabel === "Address|P") {
         const address = TableData[r]["Address"];
         if (!address) return;
         const uwIndex = uws.findIndex(({ uw }, i) => uw.property_address_one_line_ === address);
         if (uwIndex > -1) nav({ pathname: uwIndex.toString() });
         uwuBulkDispatch({ activeUwIndex: uwIndex });
      }
   };

   const handleRevert = () => {
      if (!bulk) return;
      setUws(bulk.uws);
      setCache(bulk.cache);
      setVer(bulk.version);
   };

   const handleBulkSave = async () => {
      setSaving(true);
      await aioAxios
         .patch(`/aio/underwritings/bulk/${bid}`, { data: uws, cache, version: ver })
         .catch((er) => console.log({ er }));
      const { data: refetchData } = await qUwBRefetch();
      const { bulk } = refetchData || {};
      if (bulk) {
         setUws(bulk.uws);
         setCache(bulk.cache);
      }
      setSaving(false);
   };
   if (qUwBF)
      return (
         <article className={`${styles.contentC}`}>
            <BodyFiller loading />
         </article>
      );
   return (
      <article className={`${styles.contentC}`}>
         {activeTab && (
            <Popup pos="rightSlideIn" onClose={() => uwuBulkDispatch({ activeTab: null })}>
               <UwUBulkViewConfig cache={cache} />
            </Popup>
         )}
         {activeUwIndex != null && (
            <Popup pos="rightSlideIn" onClose={() => uwuBulkDispatch({ activeUwIndex: undefined })}>
               <UwU
                  bulkInspector
                  uwCache={cache}
                  uwFinal={uws[activeUwIndex].uw}
                  uwOptions={uws[activeUwIndex].options}
                  onBulkInspectorSave={handleInspectorSave}
                  onBulkInspectorClose={() => uwuBulkDispatch({ activeUwIndex: undefined })}
               />
            </Popup>
         )}
         {showPostData && (
            <Popup onClose={() => setShowPostData(false)}>
               <div className={`${styles.postDataC}`}>
                  <div className={`${styles.postDataTitle} header-s`}>
                     <p>Imported</p>
                     <MainButton type="close" onClick={() => setShowPostData(false)} />
                  </div>
                  <div className={`${styles.postDataInfoC}`}>
                     {postContact && (
                        <div>
                           {!isNaN(Number(postContact))
                              ? "Associated with existing contact that match broker's information"
                              : "Created new associated contact with broker's information"}
                        </div>
                     )}
                     <div>
                        <b>Total Properties: </b>
                        <span>{properties.length}</span>
                     </div>
                     <div>
                        <b>Total Underwrtings: </b>
                        <span>{underwritings.length}</span>
                     </div>
                     {dupProperties.length > 0 && (
                        <div>
                           <b>Duplicated Properties found: </b>
                           <ul>
                              {dupProperties.map((p, i) => (
                                 <li key={i}>
                                    <b>
                                       {1 + i}. {p.hs_object_id}
                                    </b>
                                    <span>({p.property_address_one_line_})</span>
                                 </li>
                              ))}
                           </ul>
                        </div>
                     )}
                  </div>
               </div>
            </Popup>
         )}
         {showImportPopup && (
            <Popup onClose={() => setShowImportPopup(false)}>
               <div className={`${styles.contactPopup}`}>
                  <div className={`${styles.contactPopupHeaderC}`}>
                     <h1 className={`cardHeader-s`}>Associations</h1>
                     <MainButton
                        type="close"
                        onClick={() => setShowImportPopup(false)}
                        style={{ height: "16px", width: "16px" }}
                     />
                  </div>
                  <div className={`${styles.contactPopupInputs}`}>
                     <MainInput
                        disableCollapse
                        title="Portfolio(*required)"
                        value={portfolio}
                        handleValue={setPortfolio}
                     />
                     <MainInput disableCollapse title="Contact Name" value={contactName} handleValue={setContactName} />
                     <MainInput
                        disableCollapse
                        title="Contact Phone"
                        value={contactPhone}
                        handleValue={setContactPhone}
                     />
                     <MainInput
                        disableCollapse
                        title="Contact Email"
                        value={contactEmail}
                        handleValue={setContactEmail}
                     />
                  </div>
                  <div className={`${styles.contactPopupControlls}`}>
                     {importSubmitting ? (
                        <SmallSpinner style={{ height: "16px", margin: "auto" }} />
                     ) : (
                        <MainButton title="Submit" onClick={handleHsImport} enable={!!portfolio} />
                     )}
                  </div>
               </div>
            </Popup>
         )}
         <div className={`${styles.controllsC}`}>
            {recalculating ? (
               <div className={`${styles.submitting}`}>
                  <SmallSpinner style={{ height: "16px" }} />
                  <span>
                     Recalculating {progress}/{uws.length}
                  </span>
               </div>
            ) : saving ? (
               <div className={`${styles.submitting}`}>
                  <SmallSpinner style={{ height: "16px" }} />
                  <span>Saving</span>
               </div>
            ) : (
               <>
                  <div className={`${styles.controllsL}`}>
                     <div className={`${styles.controllsInfo}`}>
                        <h1 className={`header-s`}>{bulk?.name || "-"}</h1>
                        <p>{bulk?.description || "-"}</p>
                     </div>
                     <div className={`${styles.controllsLU}`}>
                        <MainButton
                           title="Select All"
                           type="bland"
                           onClick={() =>
                              uwuBulkDispatch({
                                 markedProperties: uws.map(({ uw }) => uw.property_address_one_line_),
                              })
                           }
                        />
                        <MainButton
                           title="Deselect All"
                           type="bland"
                           onClick={() => uwuBulkDispatch({ markedProperties: [] })}
                        />
                        {markedProperties.length > 0 && (
                           <p>
                              <span>Selected: </span>
                              <b>{markedProperties.length}</b>
                              <span> properties</span>
                           </p>
                        )}
                     </div>
                     <div className={`${styles.controllsLD}`}>
                        <MainButton
                           title="Download CSV"
                           enable={markedProperties.length > 0}
                           onClick={handleDownloadCsv}
                        />
                        <MainButton
                           title="Import to Database"
                           onClick={() => setShowImportPopup(true)}
                           enable={markedProperties.length > 0}
                        />
                     </div>
                  </div>
                  <div className={`${styles.controllsR}`}>
                     <div className={`${styles.controllsRU}`}>
                        <MainInput
                           title="Version"
                           value={ver}
                           handleValue={setVer}
                           type="dropdown"
                           options={versions}
                        />
                        <MainButton
                           title="Refresh"
                           type="scnd"
                           confirmMsg="All unsaved changes will be reverted"
                           onClick={handleRevert}
                        />
                        <MainButton
                           title="Save"
                           confirmMsg="Overwrite bulk underwriting with current data?"
                           onClick={handleBulkSave}
                        />
                     </div>
                     <div className={`${styles.controllsRD}`}>
                        <MainButton
                           type="scnd"
                           title={`Override (${orPropFields.length + orUnitFields.length})`}
                           onClick={() => uwuBulkDispatch({ activeTab: "orPropFields" })}
                        />
                        <MainButton
                           type="scnd"
                           title={`Outputs (+${opPropFields.length + opUnitFields.length})`}
                           onClick={() => uwuBulkDispatch({ activeTab: "opPropFields" })}
                        />
                        <MainButton title={`Recalculate`} onClick={handleRecalc} />
                     </div>
                  </div>
               </>
            )}
         </div>
         <ul className={`${styles.notesC}`}>
            <li>
               <b>Hint: </b>
               <span>
                  Use "Outputs" button to select more fields you would like to display. All fields normally displayed in
                  single underwriting calculator can be found here. You can rearrange the order of the columns by
                  dragging the headers around.
               </span>
            </li>
            <li>
               <b>Hint: </b>
               <span>
                  If you want to override certain fields across <b>ALL</b> properties, use the "Override" button. Make
                  sure to tick the checkbox for fields to override and then click "Recalculate" button.
               </span>
            </li>
            <li>
               <b>Hint: </b>
               <span>
                  You can edit <b>INDIVIDUAL</b> property by clicking on the address to open a single underwriting
                  calculator. Make sure to click "Apply Changes" button to save your edits, clicking "Recalculating"
                  button after would not be neccessary
               </span>
            </li>
            <li>
               <b>Note: </b>
               <span>If AVM fetching is selected, the final offer amount is capped at 80% of AVM</span>
            </li>
         </ul>
         <div className={`${styles.tableC}`}>
            <TableV2
               headerData={columnHeaders}
               tableData={TableData}
               showFilterIcon={false}
               onHeaderDrop={handleTableHeaderDrop}
               onCellClick={handleCellClick}
            />
         </div>
      </article>
   );
};

export default UwUBulkView;
