import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPg_Aio_AppUnderwritingsVersions_Schema } from "@simplyhomes/utils";

export const useQAioVersion = ({ vid, options }: TuseQAioVersion) => {
   const { aioAxios } = useAxios();
   return useQuery({
      enabled: !!vid,
      ...options,
      queryKey: ["useQAioVersion", vid || ""],
      queryFn: (data: Record<string, any>) =>
         aioAxios.get(`/aio/v2/versions/${vid}`, { data }).then<axiosResp>(({ data }) => data),
   });
};
type TuseQAioVersion = {
   vid: string | undefined;
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
const cols = ["version", "config", "official", "created_by", "created_at", "updated_by", "updated_at"] as const;
type axiosResp = {
   version: Pick<TPg_Aio_AppUnderwritingsVersions_Schema, (typeof cols)[number]>;
};
