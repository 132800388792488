import { Node } from "excel-formula-ast";
import parseFormula from "./parseFormula";
import { TversionField } from "../../hooks/querries/versions/useQVersion";

const translateFormula = (formula: string, fields: TversionField[], config?: TTranslateFormulaConfig) => {
   const relatedFieldIds: string[] = [];
   const translate = (pf: Node | undefined, layer: number): string => {
      if (!pf) return "";
      const nextLayer = layer + 1;
      const { type } = pf;
      switch (type) {
         case "cell":
            const { key } = pf;
            const keyCleansed = key.replace("@", "");
            if (config?.traceRelated) {
               const field = fields.find(({ id, column }) => (key.includes("@") ? id === keyCleansed : column === key));
               const { formula, id } = field || {};
               if (id) {
                  const existingIndex = relatedFieldIds.findIndex((str) => str === id);
                  if (existingIndex > -1) relatedFieldIds.splice(existingIndex, 1);
                  relatedFieldIds.push(id);
               }
               if (formula) translate(parseFormula(formula), nextLayer);
            }
            return "{" + (fields.find(({ id }) => id === keyCleansed)?.name.replaceAll(" ", "_") || key) + "}";
         case "number":
            return pf.value.toString();
         case "text":
            return `"${pf.value}"`;
         case "function":
            const { name, arguments: args } = pf;
            const resolvedArgs = args.map((arg) => translate(arg, nextLayer));
            return `${name}( ${resolvedArgs.join(" , ")} )`;
         case "binary-expression":
            const { left, operator, right } = pf;
            return `${translate(left, nextLayer)} ${operator} ${translate(right, nextLayer)}`;
         default:
            return "";
      }
   };
   const translated = translate(parseFormula(formula), 0);
   return { translated, relatedFieldIds };
};
export default translateFormula;
type TTranslateFormulaConfig = {
   traceRelated?: boolean;
};
