import { useEffect, useState } from "react";
import {
   Outlet,
   createSearchParams,
   useNavigate,
   useOutletContext,
   useParams,
   useSearchParams,
} from "react-router-dom";
import AFInput from "../../components/common/AFInput/AFInput";
import MainButton from "../../components/common/mainButton/MainButton";
import styles from "./Versions.module.scss";
import useQVersion, { TversionConfig } from "../../hooks/querries/versions/useQVersion";
import useQVersions from "../../hooks/querries/versions/useQVersions";
import BodyFiller from "../../components/common/bodyFiller/BodyFiller";
import useAxios from "../../hooks/axios/useAxios";
import { TauthRole } from "../../const/afRoles";
import { useAuth0 } from "@auth0/auth0-react";
import MainInput from "../../components/common/mainInput/MainInput";
import { TaioVersionObj } from "../../types/aioVersion/types";
const Versions = () => {
   const nav = useNavigate();
   const { aioAxios } = useAxios();

   const { ver = "" } = useParams();
   const [params, setParams] = useSearchParams();
   const obj = (params.get("obj") || "") as TaioVersionObj;
   const handleObj = (str: string) => {
      params.set("obj", str);
      setParams(params);
   };
   const { user } = useAuth0();

   const { data: versionsData, refetch: versionsRefetch } = useQVersions({ obj });
   const { versions = [] } = versionsData || {};
   const { data: versionData, refetch: versionRefetch, isFetching: versionFetching } = useQVersion({ ver, obj });

   const [version, setVersion] = useState<TversionConfig>({});
   const [verName, setVerName] = useState("");
   const [roles, setRoles] = useState<TauthRole[]>([]);
   const [submitting, setSubmitting] = useState(false);

   const handleVersion = async (method: "post" | "delete") => {
      if (!ver && !verName) return;
      const curVer = ver;
      setSubmitting(true);
      try {
         const { ver } = await aioAxios[method]("/aio/versions/" + (method === "post" ? verName : curVer), {
            config: version,
            roles,
         }).then((res) => res.data);
         nav({ pathname: method === "post" ? ver : "", search: createSearchParams({ obj }).toString() });
         versionsRefetch();
         versionRefetch();
      } catch (error) {
         console.log({ error });
      }
      setSubmitting(false);
   };
   useEffect(() => {
      if (versionData?.config) setVersion(versionData.config);
      if (versionData?.roles) setRoles(versionData.roles);
   }, [versionData]);
   return (
      <section className={`${styles.contentC}`}>
         <article className={`${styles.content}`}>
            <div className={`${styles.verSelect} `}>
               <MainInput
                  type="dropdown"
                  title="Object"
                  value={obj}
                  handleValue={handleObj}
                  options={["underwritings", "assets"]}
                  style={{ minWidth: "10rem" }}
               />
               {obj && (
                  <>
                     <AFInput
                        type="dropdown"
                        title="Versions"
                        value={ver}
                        options={versions.sort((a, b) =>
                           b
                              .replace(/\d+/g, (n) => (+Number(n) + 100000).toString())
                              .localeCompare(a.replace(/\d+/g, (n) => (+Number(n) + 100000).toString()))
                        )}
                        handleChange={(ver) => nav({ pathname: ver as string, search: `${params}` })}
                        style={{ width: "10rem" }}
                     />
                     {ver && user?.allRoles?.includes("admin") && (
                        <MainButton
                           title="Delete"
                           confirmMsg={`Delete this Version(${ver})?`}
                           type="warn"
                           onClick={() => handleVersion("delete")}
                        />
                     )}
                  </>
               )}
            </div>
            <div className={`${styles.verControls} `}>
               {ver && obj && (
                  <div className={`${styles.controlsC} `}>
                     <AFInput
                        title={"Roles"}
                        arrVal={roles}
                        handleArrVal={setRoles}
                        options={["basic", "admin", "ops"]}
                        type="multi"
                        style={{ width: "8rem" }}
                     />
                     <AFInput
                        value={verName}
                        title={"Save as"}
                        handleChange={(v) => setVerName(v as string)}
                        style={{ width: "6rem" }}
                     />
                     <div className={`${styles.controls} `}>
                        {submitting ? (
                           <div className={`${styles.submitting}`}>Submitting</div>
                        ) : (
                           <MainButton
                              title="Save!"
                              onClick={() => handleVersion("post")}
                              enable={!!verName && roles.length > 0}
                              type="warn"
                              confirmMsg={`Save As ${verName}`}
                           />
                        )}
                     </div>
                  </div>
               )}
            </div>
         </article>
         {!obj ? (
            <BodyFiller fillerMsg="Select an Object to continue" />
         ) : versionFetching ? (
            <BodyFiller loading />
         ) : !ver ? (
            <BodyFiller fillerMsg="Select a Version to continue" />
         ) : (
            <Outlet context={{ version, setVersion }} />
         )}
      </section>
   );
};

export default Versions;
type ContextType = { version: TversionConfig; setVersion: (x: TversionConfig) => void };
export const useVersionContext = () => useOutletContext<ContextType>();
