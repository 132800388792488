import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPG_AirTable_Meta_Fields_Schema } from "@simplyhomes/utils";

export const useQAtUwsMeta = (p?: TuseQAtUwsMetaParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAtUwsMeta"],
      queryFn: () => aioAxios.get(`/aio/v2/airtable/underwritings/metadata`).then<axiosResp>((res) => res.data),
   });
};

const cols = ["fid", "description", "is_computed", "name", "type"] as const;
type axiosResp = { metadata: Pick<TPG_AirTable_Meta_Fields_Schema, (typeof cols)[number]>[] };

type TuseQAtUwsMetaParams = {
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
};
