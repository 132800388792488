import { useState } from "react";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";
import styles from "./Tooltip.module.scss";
const Tooltip = ({ msg, onTooltipClick = () => {} }: ITooltip) => {
   const [showTooltip, setShowTooltip] = useState(false);
   return (
      <div
         className={`${styles.contentC}`}
         onClick={() => onTooltipClick()}
         onMouseEnter={() => setShowTooltip(true)}
         onMouseLeave={() => setShowTooltip(false)}
      >
         {showTooltip && msg && <div className={`${styles.tooltip}`}>{msg}</div>}
         <img src={s3afBucketURI("/icons/Question_fill.svg")} alt="?" className={`${styles.img}`} />
      </div>
   );
};

export default Tooltip;

export interface ITooltip {
   msg?: string;
   type: "warning" | "info";
   onTooltipClick?: () => void;
}
