import { useContext } from "react";
import Nav from "../../children/nav/Nav";
import styles from "./Body.module.scss";
import Popup from "../popup/Popup";
import Superhuman from "../superhuman/Superhuman";
import { useHotkeys } from "react-hotkeys-hook";
import AppContext from "../../../contexts/appContext/AppContext";
import NotificationPanel from "../../children/nav/notificationPanel/NotificationPanel";
import NotesPanel from "../notesPanel/NotesPanel";
import QuickCalculator from "../quickCalculator/QuickCalculator";
const Body = () => {
   const { appDispatch, appState } = useContext(AppContext);
   const { superhuman_show, notes_object, notes_objectid, notes_showNotes, notifications_show, quickcalculator_show } =
      appState;

   useHotkeys("ctrl+/", () => appDispatch({ overwrite: { superhuman_show: true } }));
   return (
      <section className={`${styles.contentC}`}>
         <Nav />
         {notifications_show && (
            <Popup onClose={() => appDispatch({ overwrite: { notifications_show: false } })} pos="rightSlideIn">
               <NotificationPanel onClose={() => appDispatch({ overwrite: { notifications_show: false } })} />
            </Popup>
         )}
         {notes_showNotes && notes_object && notes_objectid && (
            <Popup pos="rightSlideIn" onClose={() => appDispatch({ overwrite: { notes_showNotes: false } })}>
               <NotesPanel
                  object={notes_object}
                  objectid={notes_objectid}
                  onClose={() => appDispatch({ overwrite: { notes_showNotes: false } })}
                  refetchOnMount
               />
            </Popup>
         )}
         {quickcalculator_show && (
            <Popup onClose={() => appDispatch({ overwrite: { quickcalculator_show: false } })} pos="center">
               <QuickCalculator onClose={() => appDispatch({ overwrite: { quickcalculator_show: false } })} />
            </Popup>
         )}
         {superhuman_show && (
            <Popup
               screenStyles={{ opacity: 0.8 }}
               onClose={() => appDispatch({ overwrite: { superhuman_show: false } })}
            >
               <Superhuman onClose={() => appDispatch({ overwrite: { superhuman_show: false } })} />
            </Popup>
         )}
      </section>
   );
};

export default Body;
