import {
   TAIO_Underwritings2_AppData,
   TAIO_Version2_Config,
   TAIO_Version2_Config_Field,
   TAIO_Version2_Config_View_Group_Type_1,
} from "@simplyhomes/utils";
import styles from "./AioUwViewer.module.scss";
import { useQAioVersion } from "../../../hooks/querries/aio/versions/useQAioVersion";
import { SHrBodyFiller, SHrMainButton, SHrPopup } from "@simplyhomes/react";
import TabButton from "../../common/tabButton/TabButton";
import { useContext, useEffect, useReducer, useState } from "react";
import {
   AioUwViewerContext,
   AioUwViewerReducer,
   aioUwViewerDefaultState,
} from "../../../contexts/AioUwViewerContext/AioUwViewerContext";
import AioUwViewerTabAll from "./AioUwViewerTabAll/AioUwViewerTabAll";
import AioUwViewerTabViews from "./AioUwViewerTabViews/AioUwViewerTabViews";
import AioUwViewerTracer from "./AioUwViewerTracer/AioUwViewerTracer";
import AioUwViewerInput from "./AioUwViewerInput/AioUwViewerInput";
import useAxios from "../../../hooks/axios/useAxios";
const AioUwViewer = (p: TAioUwViewerProps) => {
   const { aioAxios } = useAxios();
   const { address, onClose } = p;
   const [State, Dispatch] = useReducer(AioUwViewerReducer, { ...aioUwViewerDefaultState, address });
   const { processing, tracer, config } = State;
   const { propFields = [], unitFields = [], views } = config || {};
   const miscView = views?.find((v) => v.name === "$misc");
   const bottomBarGroup = miscView?.propGroups.find((g) => g.name === "$bottom_bar") as
      | TAIO_Version2_Config_View_Group_Type_1
      | undefined;
   const fieldsMap: { [x: string]: TAIO_Version2_Config_Field | undefined } = [...propFields, ...unitFields].reduce(
      (acc, f) => ({ ...acc, [f.id]: f }),
      {}
   );

   const [tab, setTab] = useState<"all" | "views">("views");

   const qVer = useQAioVersion({ vid: "version" in p ? p.version : undefined, options: { refetchOnMount: true } });
   const verConfig = "config" in p ? p.config : qVer.data?.version.config;

   useEffect(() => {
      if (verConfig && !State.config)
         Dispatch({
            overwrite: {
               config: verConfig,
               tabView_activeViewId: verConfig.views[0].id,
               tabView_activeGroupId: verConfig.views[0].propGroups[0].id,
            },
         });
   }, [verConfig, State.config]);

   useEffect(() => {
      if ("version" in p && !State.version) Dispatch({ overwrite: { version: p.version } });
   }, [p, State.version]);

   useEffect(() => {
      if (p.appdata && !State.appdata) Dispatch({ overwrite: { appdata: p.appdata } });
   }, [p.appdata, State.appdata]);

   const loadingMsg = processing ? "Processing..." : qVer.isLoading ? "Loading version..." : "";
   if (loadingMsg)
      return (
         <div className={`${styles.contentC}`}>
            <SHrBodyFiller style={{ height: "100%" }} message={loadingMsg} />
         </div>
      );
   const debug = () => {
      console.log({ State });
   };
   const handleRecalcMaxPrice = async () => {
      if (!State.appdata || processing) return;
      Dispatch({ overwrite: { processing: true } });
      const body = { version: State.version, address, appdata: State.appdata };
      const resp = await aioAxios
         .post(`/aio/v2/underwritings/calculate`, body)
         .then<{ appdata: TAIO_Underwritings2_AppData }>(({ data }) => data)
         .catch((er) => console.log({ er }));
      Dispatch({ overwrite: { processing: false, appdata: resp?.appdata || State.appdata } });
   };
   return (
      <AioUwViewerContext.Provider value={{ uwViewerState: State, uwViewerDispatch: Dispatch }}>
         {tracer.length > 0 && (
            <SHrPopup
               type="resize-right"
               options={{ widthMultiplier: 0.6 }}
               handleShow={(b) => !b && Dispatch({ type: "tracer_flush" })}
            >
               <AioUwViewerTracer />
            </SHrPopup>
         )}
         <div className={`${styles.contentC}`}>
            <div className={`${styles.headerC}`}>
               {onClose && <SHrMainButton type="close" onClick={onClose} />}
               <div className={`${styles.header}`}>
                  <b onClick={debug}>Underwriting</b>
                  <p className={`cardHeader-s`}>{address}</p>
               </div>
            </div>
            <div className={`${styles.tabsC}`}>
               <TabButton title="Views" isActive={tab === "views"} onClick={() => setTab("views")} />
               <TabButton title="All Fields" isActive={tab === "all"} onClick={() => setTab("all")} />
               <SHrMainButton title="Recalculate Max Price" onClick={handleRecalcMaxPrice} type="bland" />
            </div>
            <div className={`${styles.bodyC}`}>
               {tab === "views" && <AioUwViewerTabViews />}
               {tab === "all" && <AioUwViewerTabAll />}
            </div>
            {bottomBarGroup && (
               <div className={`${styles.bottomBarC} `}>
                  {bottomBarGroup.fieldIds
                     .map((fid) => fieldsMap[fid])
                     .filter(Boolean)
                     .map((field, i) => (
                        <AioUwViewerInput
                           className={styles.bottomBarInput}
                           field={field as TAIO_Version2_Config_Field}
                           key={i + (field?.id || "")}
                        />
                     ))}
               </div>
            )}
         </div>
      </AioUwViewerContext.Provider>
   );
};

export default AioUwViewer;
type TAioUwViewerProps = { appdata: TAIO_Underwritings2_AppData; address: string; onClose?: () => void } & (
   | { config: TAIO_Version2_Config }
   | { version: string }
);

export const useAioUwViewerContext = () => useContext(AioUwViewerContext);
