import { useContext, useMemo, useState } from "react";
import AppContext from "../../contexts/appContext/AppContext";
import styles from "./Map.module.scss";
import AFInput from "../../components/common/AFInput/AFInput";
import useQMap from "../../hooks/querries/useQMap";
import GGM, { IpinGg, IpinProperty } from "../../components/common/GGM/GGM";
import { Icoordinates } from "../../types/googleMap";
import PreviewPane from "../../components/children/map/previewPane/PreviewPane";
import apiKeys from "../../const/apiKeys";
import { usePlacesWidget } from "react-google-autocomplete";
import isNumberic from "../../utils/valTypes/isNumberic";
import GgPreviewPane from "../../components/children/map/ggPreviewPane/GgPreviewPane";
import useQProperties from "../../hooks/querries/properties/useQProperties";
import useQUser from "../../hooks/querries/users/useQUser";
import MainButton from "../../components/common/mainButton/MainButton";
import Popup from "../../components/common/popup/Popup";
import MapSettingsPopup from "../../components/children/map/mapSettingsPopup/MapSettingsPopup";
const defaultCoord = { lat: 39.0164745, lng: -94.7263118 };
const Map = () => {
   const { data: propertiesData } = useQProperties();
   const { properties = [] } = propertiesData || {};

   const { data: qMapData } = useQMap();
   const { mapData } = qMapData || {};
   const { stages = [], rejectReasons = [] } = mapData || {};

   const { appDispatch, appState } = useContext(AppContext);
   const { ggPins = [] } = appState;

   const [zoom, setZoom] = useState(4);
   const [internalSearch, setInternalSearch] = useState("");
   const [ggSearch, setGgSearch] = useState("");
   const [filterStages, setFilterStages] = useState<string[]>([]);
   const [filterReasons, setFilterReasons] = useState<string[]>([]);
   const [previewId, setPreviewId] = useState("");

   const [center, setCenter] = useState<Icoordinates>(defaultCoord);
   const [showPopup, setShowPopup] = useState(false);

   const internalSearchedProps = useMemo(
      () =>
         properties.filter(
            ({ coordinates, property_address_one_line_, hs_pipeline_stage = "", closed_rejected_reason = "" }) =>
               coordinates?.split(",").length === 2 &&
               property_address_one_line_.toLowerCase().includes(internalSearch.toLowerCase()) &&
               (filterStages.length === 0 || filterStages.includes(hs_pipeline_stage)) &&
               (filterReasons.length === 0 || filterReasons.includes(closed_rejected_reason))
         ),
      [filterReasons, filterStages, internalSearch, properties]
   );
   const handleMarkerClose = () => {
      setPreviewId("");
      setCenter(defaultCoord);
   };
   const { ref } = usePlacesWidget<HTMLInputElement>({
      apiKey: apiKeys["googleAPIs"],
      options: { types: ["address"], componentRestrictions: { country: "us" } },
      onPlaceSelected: (place) => {
         const lat = place.geometry?.location?.lat();
         const lng = place.geometry?.location?.lng();
         const place_id = place.place_id;
         const address = place.formatted_address;
         if (!lat || !lng || !address || !place_id) return;
         setGgSearch(address || "");
         setZoom(17);
         setCenter({ lat, lng });
         setPreviewId(address);
         appDispatch({ type: "updateGgPins", payload: { lat, lng, address, place_id } });
      },
   });

   return (
      <div className={`${styles.contentC}`}>
         {showPopup && (
            <Popup onClose={() => setShowPopup(false)}>
               <MapSettingsPopup handleClose={() => setShowPopup(false)} />
            </Popup>
         )}
         <div className={`${styles.mapC}`}>
            <div className={`${styles.map}`}>
               <GGM
                  center={center}
                  zoom={zoom}
                  pins={[
                     ...internalSearchedProps.map<IpinProperty>((item) => ({ item, type: "property" })),
                     ...ggPins.map<IpinGg>((item) => ({ item, type: "ggPin" })),
                  ]}
                  onOverlayClick={(objId: string) => setPreviewId(objId)}
               />
            </div>
            {!isNumberic(previewId) ? (
               <GgPreviewPane item={ggPins.find(({ address }) => address === previewId)} />
            ) : (
               <PreviewPane
                  property={properties.find(({ hs_object_id: id }) => id === previewId)}
                  handleClose={() => handleMarkerClose()}
               />
            )}
         </div>
         <div className={`${styles.infoC}`}>
            <div className={`${styles.count}`}>Properties Matched: {internalSearchedProps.length}</div>
            <MainButton title="Legends" onClick={() => setShowPopup(true)} />
         </div>
         <div className={`${styles.externalC}`}>
            <AFInput title="Search Google Address" value={ggSearch} ref={ref} handleChange={(v) => setGgSearch(v)} />

            <div className={`${styles.externalControls}`}></div>
         </div>
         <div className={`${styles.filtersC}`}>
            <AFInput
               title="Hubspot Filter"
               type="search"
               value={internalSearch}
               handleChange={(v) => setInternalSearch(v)}
               options={internalSearchedProps.slice(0, 5).map((o) => o.property_address_one_line_)}
            />
            <div className={`${styles.filters}`}>
               <AFInput
                  title="Pipeline Stages"
                  type="multi"
                  options={stages.map(({ label }) => label)}
                  arrVal={filterStages
                     .map((value) => stages.find(({ value: sValue }) => sValue === value)?.label)
                     .filter((str): str is string => !!str)}
                  handleArrVal={(arr) =>
                     setFilterStages(
                        arr
                           .map((label) => stages.find(({ label: sLabel }) => sLabel === label)?.value)
                           .filter((value): value is string => !!value)
                     )
                  }
               />
               <AFInput
                  title="Rejected Reasons"
                  type="multi"
                  options={rejectReasons.map(({ label }) => label)}
                  arrVal={filterReasons
                     .map((value) => rejectReasons.find(({ value: sValue }) => sValue === value)?.label)
                     .filter((str): str is string => !!str)}
                  handleArrVal={(arr) =>
                     setFilterReasons(
                        arr
                           .map((label) => rejectReasons.find(({ label: sLabel }) => sLabel === label)?.value)
                           .filter((value): value is string => !!value)
                     )
                  }
               />
            </div>
            <div className={`${styles.colorsC}`}>
               <div className={`${styles.colorControls}`}></div>
            </div>
         </div>
      </div>
   );
};

export default Map;
