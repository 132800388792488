import { useState } from "react";
import styles from "./CreateUwPopup.module.scss";
import useQProperties from "../../../../hooks/querries/properties/useQProperties";
import MainButton from "../../../common/mainButton/MainButton";
import MainInput from "../../../common/mainInput/MainInput";
import useQVersions from "../../../../hooks/querries/versions/useQVersions";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
import useAxios from "../../../../hooks/axios/useAxios";
import { useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import useQUws from "../../../../hooks/querries/underwritings/useQUws";
const CreateUwPopup = ({ handleClose }: TCreateUwPopupProps) => {
   const { aioAxios } = useAxios();

   const { refetch: qUwRefetch } = useQUws();
   const { data: qPropsData, isFetching: qPropsFetching } = useQProperties();
   const { properties = [] } = qPropsData || {};
   const { data: qVersData, isFetching: qVersFetching } = useQVersions({ obj: "underwritings" });
   const { versions = [] } = qVersData || {};

   const nav = useNavigate();

   const [name, setName] = useState("");
   const [address, setAddress] = useState("");
   const [pid, setPid] = useState("");
   const [ver, setVer] = useState("");
   const [submitting, setSubmitting] = useState(false);
   const handleSearch = (v: string) => {
      const [add, id] = v.split("|");
      setAddress(add);
      setPid(id);
   };
   const handleCreateUw = async () => {
      setSubmitting(true);
      try {
         const uwsid = await aioAxios
            .post(`/aio/underwritings/`, { name, address, pid, version: ver })
            .then(({ data }) => data?.uwsid);
         nav({ pathname: `/uwu/${uwsid}`, search: createSearchParams({ ver }).toString() });
         handleClose();
         qUwRefetch();
      } catch (error) {
         console.log({ error });
      }
      setSubmitting(false);
   };
   if (qPropsFetching || qVersFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.header}`}>
            <span className={`header-m`}>Create An Underwriting</span>
            <MainButton title="Close" type="bland" onClick={handleClose} />
         </div>
         <article className={`${styles.note}`}>
            <div>
               <b>Note: </b>
               <span>
                  This will create a internal replicate of data from the selected Property Object. Meaning any update to
                  the Property Object after this point will not reflect upon the created Underwriting Object. (Don't
                  worry, you will have the ability to refresh the internal data)
               </span>
            </div>
            <div>
               <b>Note: </b>
               <span>
                  Selecting a version for an Underwriting creation is neccessary as the version will help with
                  determining which fields/data to be replicated. In other words, copying everything from the Property
                  Object will bloat the database without meaningful purposes.
               </span>
            </div>
         </article>
         <form className={`${styles.form}`}>
            <MainInput
               type="search"
               title="Property*"
               value={address}
               handleValue={handleSearch}
               options={properties.map(
                  ({ property_address_one_line_: address, hs_object_id: pid }) => `${address}|${pid}`
               )}
            />
            <div className={`${styles.row}`}>
               <MainInput
                  title="Name*"
                  type="dropdown"
                  value={name}
                  handleValue={setName}
                  options={["Preliminary Review", "Ancillary Review", "Engineer Test"]}
               />
               <MainInput title="Version*" type="dropdown" value={ver} handleValue={setVer} options={versions} />
            </div>
         </form>
         {submitting ? (
            <BodyFiller loading />
         ) : (
            <div className={`${styles.controlsC} `}>
               <MainButton title="Cancel" type="bland" onClick={handleClose} />
               <MainButton
                  title="Create"
                  type="warn"
                  enable={!!address && !!name && !!ver && !!pid}
                  confirmMsg="Create A New Underwriting Object?"
                  onClick={handleCreateUw}
               />
            </div>
         )}
      </article>
   );
};

export default CreateUwPopup;
type TCreateUwPopupProps = {
   handleClose: () => void;
};
