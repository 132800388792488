import { useQUsers } from "../../../hooks/querries/users/useQUsers";
import MainButton from "../mainButton/MainButton";
import SmallSpinner from "../smallSpinner/SmallSpinner";
import styles from "./RichTextTinyMce.module.scss";
import { Editor } from "@tinymce/tinymce-react";

const RichTextTinyMce = ({ setValue, submitting, value, onSubmit, onClose, showCloseBtn }: TRichTextTinyMceProps) => {
   const { data: qUsersData, isFetching: qUsersFetching } = useQUsers();
   const { users = [] } = qUsersData || {};
   if (qUsersFetching)
      return (
         <div style={{margin:"auto"}}>
            <SmallSpinner style={{ height: "16px" }} />
         </div>
      );
   return (
      <div className={`${styles.tiny}`}>
         <Editor
            apiKey="jzvx5mj9b5lb0mfetjqdb6jnozpgfar5q8dxr5uazhcwzw22"
            value={value}
            onEditorChange={setValue}
            
            init={{
               // max_height: 300,
               height: 200,
               plugins: ["mentions"],
               // resize: false,
               mentions_item_type: "profile",
               mentions_selector: "span.mymention",

               mentions_menu_complete: function (editor: any, userInfo: any) {
                  var span = editor.getDoc().createElement("span");
                  span.className = "mymention";
                  span.setAttribute("data-mention-id", userInfo.id);
                  span.appendChild(editor.getDoc().createTextNode("@" + userInfo.name));
                  return span;
               },
               mentions_fetch: function (query: any, success: any) {
                  //Fetch your full user list from somewhere
                  var urs: any = users.map((u) => ({ id: u.sub, name: u.email }));
                  //query.term is the text the user typed after the '@'
                  urs = urs.filter(function (user: any) {
                     return user.name.indexOf(query.term.toLowerCase()) === 0;
                  });
                  urs = urs.slice(0, 10);
                  window.setTimeout(function () {
                     success(urs);
                  }, 0);
               },
            }}
         />
         <div className={`${styles.controls}`}>
            {showCloseBtn && <MainButton title="Close" type="scnd" onClick={onClose} />}
            {submitting ? (
               <SmallSpinner style={{ height: "16px" }} />
            ) : (
               <MainButton enable={!!value} onClick={onSubmit} title="Submit" />
            )}
         </div>
      </div>
   );
};

export default RichTextTinyMce;
type TRichTextTinyMceProps = {
   value: string;
   setValue: (v: string) => void;
   submitting: boolean;
   onSubmit: () => void;
   showCloseBtn?: boolean;
   onClose?: () => void;
};
