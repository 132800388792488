import { useState } from "react";
import { useAioUwViewerContext } from "../AioUwViewer";
import AioUwViewerInput from "../AioUwViewerInput/AioUwViewerInput";
import styles from "./AioUwViewerTabAll.module.scss";
import { SHrMainInput } from "@simplyhomes/react";
const AioUwViewerTabAll = () => {
   const { uwViewerState } = useAioUwViewerContext();
   const { appdata, config, address } = uwViewerState;
   const { propFields = [], unitFields = [] } = config || {};

   const [search, setSearch] = useState("");

   const filteredPropFields = propFields.filter((f) => f.label.toLowerCase().includes(search.toLowerCase()));
   const filteredUnitFields = unitFields.filter((f) => f.label.toLowerCase().includes(search.toLowerCase()));

   const errorMsg = !appdata ? "No appdata" : !config ? "No config" : null;
   if (errorMsg) return <div className={`${styles.contentC}`}>{errorMsg}</div>;

   return (
      <div className={`${styles.contentC}`}>
         <div className={`${styles.controlsC}`}>
            <SHrMainInput type="text" title="Search Fields" value={search} onChange={setSearch} />
         </div>
         <div className={`${styles.propsC}`}>
            <div className={`${styles.headerC} cardHeader-s`}>{propFields.length} Property Fields</div>
            <div className={`${styles.props}`}>
               {filteredPropFields.map((field, i) => (
                  <AioUwViewerInput field={field} key={i} />
               ))}
            </div>
         </div>
         {appdata?.unitFields.map((_, ui) => (
            <div className={`${styles.unitsC}`} key={ui}>
               <div className={`${styles.headerC} cardHeader-s`}>
                  {unitFields.length} Unit {ui + 1} Fields
               </div>
               <div className={`${styles.units}`}>
                  {filteredUnitFields.map((field, i) => (
                     <AioUwViewerInput field={field} key={i} unitIndex={ui}  />
                  ))}
               </div>
            </div>
         ))}
      </div>
   );
};

export default AioUwViewerTabAll;
type TAioUwViewerTabAllProps = {};
