import { SHrBodyFiller } from "@simplyhomes/react";
import { useAioUwViewerContext } from "../AioUwViewer";
import styles from "./AioUwViewerTabViews.module.scss";
import { useEffect, useState } from "react";
import TabButton from "../../../common/tabButton/TabButton";
import AioUwViewerInput from "../AioUwViewerInput/AioUwViewerInput";
import { TAIO_Version2_Config_Field, TAIO_Version2_Config_View_Group_Type_1 } from "@simplyhomes/utils";
const AioUwViewerTabViews = () => {
   const { uwViewerState: State, uwViewerDispatch: Dispatch } = useAioUwViewerContext();
   const { appdata, config } = State;
   const { unitFields: unitData = [] } = appdata || {};
   const { propFields = [], unitFields = [], views = [] } = config || {};
   const { tabView_activeViewId, tabView_activeGroupId, tabView_unitIndex } = State;
   const setActiveViewId = (id: typeof tabView_activeViewId) => Dispatch({ overwrite: { tabView_activeViewId: id } });
   const setActiveGroupId = (id: typeof tabView_activeGroupId) =>
      Dispatch({ overwrite: { tabView_activeGroupId: id } });
   // const [tabView_activeViewId, setActiveViewId] = useState(views[0]?.id);
   // const [tabView_activeGroupId, setActiveGroupId] = useState(views[0]?.propGroups[0]?.id);
   // const [tabView_unitIndex, setUnitIndex] = useState<number | undefined>();

   const isProp = tabView_unitIndex == null;
   const view = views.find((v) => v.id === tabView_activeViewId);
   const { propGroups = [], unitGroups = [] } = view || {};
   const groups = isProp ? propGroups : unitGroups;
   const group = groups.find((g) => g.id === tabView_activeGroupId);

   // const fields = isProp ? propFields : unitFields;
   const fieldsMap: { [x: string]: TAIO_Version2_Config_Field | undefined } = [...propFields, ...unitFields].reduce(
      (acc, f) => ({ ...acc, [f.id]: f }),
      {}
   );

   const handleLevel = (index: typeof tabView_unitIndex) =>
      Dispatch({
         overwrite: {
            tabView_unitIndex: index,
            tabView_activeGroupId: index == null ? propGroups[0].id : unitGroups[0].id,
         },
      });
   // useEffect(() => {
   //    if (!view && tabView_activeViewId !== views[0]?.id) setActiveViewId(views[0].id);
   // }, [tabView_activeViewId, view, views]);
   // useEffect(() => {
   //    if (!group && activeGroupId !== groups[0]?.id) setActiveGroupId(groups[0]?.id);
   // }, [activeGroupId, group, groups]);

   if (!config) return <SHrBodyFiller message="Config not found!" />;
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.viewsC}`}>
            <div className={`${styles.viewTabs}`}>
               <span className={`cardHeader-s`}>Views: </span>
               {views
                  .filter((v) => !v.name.startsWith("$"))
                  .map((view, i) => (
                     <TabButton
                        title={view.name}
                        key={i}
                        isActive={view.id === tabView_activeViewId}
                        onClick={() => setActiveViewId(view.id)}
                     />
                  ))}
            </div>
            <div className={`${styles.levelsC}`}>
               <div className={`${styles.levels}`}>
                  <div className={`${styles.levelTabs}`}>
                     <span className={`cardHeader-s`}>Levels: </span>
                     <TabButton title="Property" isActive={isProp} onClick={() => handleLevel(undefined)} />
                     {unitData.map((_, i) => (
                        <TabButton
                           key={i}
                           title={`Unit #${i + 1}`}
                           isActive={tabView_unitIndex === i}
                           onClick={() => handleLevel(i)}
                        />
                     ))}
                  </div>
                  <div className={`${styles.groupsC}`}>
                     <div className={`${styles.groups}`}>
                        <div className={`${styles.groupTabs}`}>
                           <span className={`cardHeader-s`}>Groups: </span>
                           {groups.map((group, i) => (
                              <TabButton
                                 title={group.name}
                                 key={i}
                                 isActive={group.id === tabView_activeGroupId}
                                 onClick={() => setActiveGroupId(group.id)}
                              />
                           ))}
                        </div>
                        <div className={`${styles.group}`}>
                           {group?.type === "1" && (
                              <div className={`${styles.group1}`}>
                                 {group.fieldIds
                                    .map((fid) => fieldsMap[fid])
                                    .filter(Boolean)
                                    .map((field, i) => (
                                       <AioUwViewerInput
                                          field={field as TAIO_Version2_Config_Field}
                                          key={i + (field?.id || "")}
                                          unitIndex={tabView_unitIndex}
                                       />
                                    ))}
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </article>
   );
};

export default AioUwViewerTabViews;
