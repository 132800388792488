import { IuseQDbDataTable } from "../../hooks/querries/database/useQDb";

const dbGetTablePks = ({ tables, table = "" }: IdbGetTablePksParams) =>
   tables
      .filter((obj) => obj.table === table && obj.pk)
      .map(({ pk }) => pk)?.[0]
      ?.replaceAll(" ", "")
      ?.split(",") || [];
export default dbGetTablePks;
interface IdbGetTablePksParams {
   tables: IuseQDbDataTable[];
   table: string | null;
}
