import useTheme from "../../hooks/react/useTheme";
import styles from "./ThemeProvider.module.scss";
const ThemeProvider = ({ children }: TypeThemeProvider) => {
   const { theme } = useTheme();
   // console.log({ theme });
   return (
      <section data-theme={theme} className={`${styles.contentC}`}>
         {children}
      </section>
   );
};
export default ThemeProvider;
type TypeThemeProvider = {
   children: React.ReactNode;
};
