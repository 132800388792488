import { TUwData } from "../../types/hsObjects";
import { TUwUReducerAction } from "../../types/interfaces/reducer.interface";
import { TUnknownObject } from "../../types/interfaces/unknownObj";

const uwuReducer = (state: TUwUState, action: TUwUReducerAction): TUwUState => {
   // console.log({ action });
   if (action.overwrite) return { ...state, ...action.overwrite };
   switch (action.type) {
      case "addOptions":
         return { ...state, uwOptions: { ...state.uwOptions, ...action.payload } };
      case "addCache":
         return { ...state, uwCache: { ...state.uwCache, ...action.payload } };
      case "addFinal":
         return { ...state, uwFinal: { ...state.uwFinal, ...action.payload } };
      case "recalced":
         return {
            ...state,
            uwFinal: action.payload?.uws[0].uw || {},
            uwCache: action.payload?.cache || {},
            uwOptions: action.payload?.uws[0].options || {},
            uwProcessing: false,
         };
      case "setProgress":
         return {
            ...state,
            progress: {
               count: (state.progress?.count || 0) + (action.payload.addCount || 0),
               total: (state.progress?.total || 0) + (action.payload.addTotal || 0),
            },
         };
      default:
         return { ...state };
   }
};

export default uwuReducer;

export type TUwUState = {
   uwFinal?: TUwData;
   uwCache?: TUnknownObject;
   uwOptions?: TUnknownObject;
   uwProcessing?: boolean;
   progress?: { count: number; total: number };
   newUwName?: string;
};
