import { useQuery } from "react-query";
import { TMapData } from "../../contexts/appContext/AppState";
import useAxios from "../axios/useAxios";

const useQMap = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: "useQMap",
      queryFn: () => aioAxios.get("/aio/map").then<IuseQMapRes>(({ data }) => data),
   });
};
export default useQMap;
interface IuseQMapRes {
   mapData?: TMapData;
}
