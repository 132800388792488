import styles from "./PropertyErrors.module.scss";
const PropertyErrors = () => {
   // const { formulaResults = {} } = appState;
   // const warnings = Object.keys(formulaResults).filter((key) => formulaResults[key]?.status !== "ok");

   return (
      <div className={`${styles.contentC}`}>
         <ul>
            <b>Fields that could not be calculated:</b>
            {/* {warnings.map((field, key) => {
               const { column, status, cacheKey } = formulaResults[field] || {};
               return (
                  <li key={key}>
                     <b>
                        {field}({column}):
                     </b>
                     <p>
                        {status === "ERROR#INTERNAL"
                           ? "System Error"
                           : status === "ERROR#NOTFOUND"
                           ? "Could Not Find Using Provided Data"
                           : status}
                     </p>
                     <p>{cacheKey}</p>
                  </li>
               );
            })} */}
         </ul>
      </div>
   );
};

export default PropertyErrors;
type TPropertyErrorsProps = {};
