import { useRef, useState } from "react";
import styles from "./FileUploader.module.scss";
const FileUploader = ({ children, handleFiles = () => {}, maxCount = 10, maxSizeMB = 5 }: TFileUploaderProps) => {
   const [dragging, setDragging] = useState(false);
   const extractFiles = (filesObj: FileList | null) => {
      if (!filesObj) return;
      return Object.keys(filesObj)
         ?.map((id) => filesObj[Number(id)])
         .slice(0, maxCount)
         .filter((file) => 0 < file.size && file.size <= maxSizeMB * 1000000);
   };
   const handleDrag = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === "dragenter" || e.type === "dragover") setDragging(true);
      if (e.type === "dragleave") setDragging(false);
   };
   const handleDrop = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(false);
      const files = extractFiles(e.dataTransfer.files);
      if (files) handleFiles(files);
   };
   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const files = extractFiles(e.target.files);
      if (files) handleFiles(files);
   };
   const inputRef = useRef<HTMLInputElement>(null);
   const handleClick = () => inputRef.current && inputRef.current.click();

   return (
      <label className={`${styles.contentC}`} onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
         <input
            ref={inputRef}
            type="file"
            onChange={handleChange}
            multiple={maxCount > 0}
            className={`${styles.hidden}`}
         />
         <div onClick={handleClick} className={`${styles.children}`}>
            {children}
         </div>
         {dragging && (
            <div
               className={`${styles.dragging}`}
               onDragEnter={handleDrag}
               onDragLeave={handleDrag}
               onDragOver={handleDrag}
               onDrop={handleDrop}
            ></div>
         )}
      </label>
   );
};
export default FileUploader;
type TFileUploaderProps = {
   children?: React.ReactNode;
   handleFiles: (files: File[]) => void;
   maxCount?: number;
   maxSizeMB?: number;
};
