import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";

const useQFormulas = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["app", "formula"],
      queryFn: () => aioAxios.get(`/aio/versions/formulas`).then<Iresp>(({ data }) => data),
   });
};
export default useQFormulas;
interface Iresp {
   formulas: { formula: string; type: string | null }[];
}
