import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import MainButton from "../../../components/common/mainButton/MainButton";
import { TUwData } from "../../../types/hsObjects";
import { useUwUbContext } from "../UwUb";
import styles from "./UwUBulkOld.module.scss";
import useQVersion, { Tformat, TtypeDropDown } from "../../../hooks/querries/versions/useQVersion";
import SmallSpinner from "../../../components/common/smallSpinner/SmallSpinner";
import parseFieldName from "../../../utils/fields/parseFieldName";
import BodyFiller from "../../../components/common/bodyFiller/BodyFiller";
import { TUnknownObject } from "../../../types/interfaces/unknownObj";
import { useEffect, useState } from "react";
import useAxios from "../../../hooks/axios/useAxios";
import MainInput from "../../../components/common/mainInput/MainInput";
import Popup from "../../../components/common/popup/Popup";
import UwUbConfig, { TUwUbConfigTab } from "../../../components/children/uwub/uwubConfig/UwUbConfig";
import TableV2, { TTableV2_OnHeaderDrop, TTableV2_header } from "../../../components/common/tableV2/TableV2";
import UwU from "../../UwU/UwU";
import { TPg_Crm_Properties_Schema, TPg_Crm_Underwritings_Schema } from "@simplyhomes/utils";
import { useResolveUwU } from "../../../hooks/formulas/useResolveUwU";
const UwUBulkOld = () => {
   const nav = useNavigate();

   const { aioAxios } = useAxios();

   const [searchParams] = useSearchParams();
   const ver = searchParams.get("ver") || "";

   const { uwubDispatch, uwubState } = useUwUbContext();
   const { uwCache, uwBase, uwProcessing, uwsFinal, uwsOptions, jsFile = [] } = uwubState;
   const { orPropFields, orUnitFields, opPropFields, opUnitFields, outputOrder } = uwubState;

   const { data: qVerData, isFetching: qVerFetching } = useQVersion({ ver, obj: "underwritings" });
   const { fields = [] } = qVerData?.config || {};

   // const { uwubRecalcUw } = useResolveUwUBulk();
   const { recalcUwUFields } = useResolveUwU({ ver });

   const [markedProperty, setMarkedProperty] = useState<string[]>([]);
   const [portfolio, setPortfolio] = useState("");
   const [submitting, setSubmitting] = useState(false);
   type TPostData = {
      contact: string | number | undefined;
      properties: Pick<TPg_Crm_Properties_Schema, "hs_object_id" | "simply_object_id" | "property_address_one_line_">[];
      underwritings: Pick<TPg_Crm_Underwritings_Schema, "simply_object_id">[];
   };
   const [postData, setPostData] = useState<TPostData>();
   const { contact: postContact, properties = [], underwritings = [] } = postData || {};
   const dupProperties = properties.filter((p) => Number(p.hs_object_id) > 0);
   const [showPostData, setShowPostData] = useState(false);

   const [slideTab, setSlideTab] = useState<TUwUbConfigTab | null>(null);
   const [uwIndexInspect, setUwIndexInspect] = useState(-1);

   useEffect(() => {
      const baseKeys = ["✅", "Address", "Unit#", "Total Beds(P)", "Total Baths(P)"];
      const baseOutputOrder: TTableV2_header[] = [
         ...baseKeys.map((str) => ({ label: str, key: str })),
         ...opPropFields
            .map((fId) => fields.find((f) => f.id === fId))
            .map((f) => ({
               label: `${f?.name}|P`,
               key: `${f?.id}|P`,
               format: f?.format ? [{ type: f.format === "Currency" ? "$" : "%" }] : [],
            })),
         ...opUnitFields
            .map((fId) => fields.find((f) => f.id === fId))
            .map((f) => ({
               label: `${f?.name}|U`,
               key: `${f?.id}|U`,
               format: f?.format ? [{ type: f.format === "Currency" ? "$" : "%" }] : [],
            })),
      ];
      const selectedOutputs = outputOrder.filter(({ key }) => baseOutputOrder.map(({ key }) => key).includes(key));
      const newOutputs = baseOutputOrder.filter(({ key }) => !selectedOutputs.map(({ key }) => key).includes(key));
      if (selectedOutputs.length !== outputOrder.length || newOutputs.length > 0)
         uwubDispatch({ outputOrder: [...selectedOutputs, ...newOutputs] });
   }, [fields, opPropFields, opUnitFields, outputOrder, uwubDispatch]);

   const recalcSimUws = async () => {
      const dataLevel = jsFile[0];
      const propKeys = Object.keys(dataLevel).filter((key) => dataLevel[key] === "p");
      const unitKeys = Object.keys(dataLevel).filter((key) => dataLevel[key] === "u");

      const orPropData = fields
         .filter(({ id }) => orPropFields.includes(id))
         .reduce(
            (prev, { column, name }) => ({
               ...prev,
               [column || parseFieldName(name)]: uwBase[column || parseFieldName(name)] || undefined,
            }),
            {}
         );
      const orUnitData = fields
         .filter(({ id }) => orUnitFields.includes(id))
         .reduce(
            (prev, { column, name }) => ({
               ...prev,
               [column || parseFieldName(name)]: uwBase.$childUnits?.[0][column || parseFieldName(name)] || undefined,
            }),
            {}
         );

      const data = jsFile.slice(3);
      const propsData: TUwData[] = data
         .filter(({ unit }) => unit === "0")
         .map((r1) => ({
            ...propKeys.reduce((prev, curr) => ({ ...prev, [curr]: r1[curr] }), {}),
            ...orPropData,
            $childUnits: data
               .filter((r2) => r2.unit !== "0" && r2.property_address_one_line_ === r1.property_address_one_line_)
               .map((r2) => ({
                  ...unitKeys.reduce((prev, curr) => ({ ...prev, [curr]: r2[curr] }), {}),
                  ...orUnitData,
               })),
         }));

      let tempCache = { ...uwCache };
      uwubDispatch({ uwProcessing: true });
      const result = await recalcUwUFields({ perfectCaprate: true, uws: propsData, cache: tempCache, options: [] });
      tempCache = { ...tempCache, ...result?.cache };
      const uwsFinal: TUwData[] = result?.uws.map(({ uw }) => uw) || [];
      const uwsOptions: TUnknownObject[] = result?.uws.map(({ options }) => options) || [];
      uwubDispatch({ uwProcessing: false, uwCache: tempCache, uwsFinal, uwsOptions });
   };

   const getTableData = (uw: TUwData, unitIndex?: number): Record<string, any> => {
      const { $childUnits = [] } = uw;
      const outputData = outputOrder.reduce((prev, PUfId) => {
         const fieldId = PUfId.key.split("|")[0];
         const level = PUfId.key.split("|")[1];
         const field = fields.find((f) => f.id === fieldId);
         if (!field) return { ...prev };
         if (level === "P")
            return {
               ...prev,
               [`${PUfId.key}`]:
                  unitIndex != null ? "" : field.column ? uw[field.column] : uw[parseFieldName(field.name)],
            };
         return {
            ...prev,
            [`${PUfId.key}`]:
               unitIndex == null && field.unitBehavior !== "NONE"
                  ? field.column
                     ? uw[field.column]
                     : uw[parseFieldName(field.name)]
                  : unitIndex == null
                  ? ""
                  : field.column
                  ? $childUnits[unitIndex][field.column]
                  : $childUnits[unitIndex][parseFieldName(field.name)],
         };
      }, {});

      const baseData = {
         "✅": unitIndex != null ? "" : markedProperty.includes(uw.property_address_one_line_) ? "✅" : "🔲",
         Address: uw.property_address_one_line_,
         "Unit#": unitIndex == null ? 0 : unitIndex + 1,
         "Total Beds(P)": unitIndex == null ? uw.bedrooms : $childUnits[unitIndex]?.bedrooms,
         "Total Baths(P)": unitIndex == null ? uw.bathrooms : $childUnits[unitIndex]?.bathrooms,
      };

      return { ...baseData, ...outputData };
   };
   const TableData = uwsFinal
      .map((uw) => [getTableData(uw), ...(uw.$childUnits || []).map((_, uIndex) => getTableData(uw, uIndex))])
      .flat();

   const contact = {
      name: jsFile?.[0]?.["Broker Name"],
      phone: jsFile?.[0]?.["Broker Phone"],
      email: jsFile?.[0]?.["Broker Email"],
   };

   const handleDownloadCsv = () => {
      const keysToBeRemoved = ["✅"];
      const csvOutputKeys = outputOrder.filter(({ key }) => !keysToBeRemoved.includes(key));
      const csv = TableData.map((obj) => csvOutputKeys.map(({ key }) => JSON.stringify(obj[key])).join(","));
      csv.unshift(csvOutputKeys.map(({ label }) => label).join(","));
      const csvString = csv.join("\n");
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(csvString));
      element.setAttribute("download", "BulkCalcResult");
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
   };

   const handleCellClick = ({ r, c }: { r: number; c: number; v: any }) => {
      const colLabel = outputOrder[c]?.label;
      if (r === -1) return;
      if (colLabel === "✅") {
         const address = TableData[r]["Address"];
         if (!address) return;
         setMarkedProperty(
            markedProperty.includes(address)
               ? markedProperty.filter((str) => str !== address)
               : [...markedProperty, address]
         );
      }
      if (colLabel === "Address") {
         const address = TableData[r]["Address"];
         if (!address) return;
         const uwIndex = uwsFinal.findIndex((uw) => uw.property_address_one_line_ === address);
         if (uwIndex > -1) nav({ pathname: uwIndex.toString(), search: createSearchParams({ ver }).toString() });
         setUwIndexInspect(uwIndex);
      }
   };

   const handleHsImport = async () => {
      const uws = [...new Set(markedProperty)]
         .map((address) => uwsFinal.find((uw) => uw.property_address_one_line_ === address))
         .filter(Boolean);
      setSubmitting(true);

      const resp = await aioAxios
         .post(`/aio/underwritings/bulk`, { uws, portfolio, contact })
         .then<TPostData>(({ data }) => data)
         .catch((er) => console.log({ er }));

      if (resp) {
         setPostData(resp);
         setShowPostData(true);
      }

      setSubmitting(false);
   };

   const handleTableHeaderDnD: TTableV2_OnHeaderDrop = ({ from, to }) => {
      uwubDispatch({ type: "swapHeaders", payload: { from, to } });
      // setOutputOrder([...newOutputOrder]);
   };

   const handleInspectorSave = (p: {
      uwFinal: Record<string, any>;
      uwCache: Record<string, any>;
      uwOptions: Record<string, any>;
   }) => {
      uwubDispatch({
         uwCache: { ...uwCache, ...p.uwCache },
         uwsOptions: uwsOptions.map((o, i) => (i === uwIndexInspect ? p.uwOptions : o)),
         uwsFinal: uwsFinal.map((o, i) => (i === uwIndexInspect ? p.uwFinal : o)),
      });
   };

   if (qVerFetching || submitting) return <BodyFiller loading />;
   return (
      <section className={`${styles.contentC}`}>
         {slideTab && (
            <Popup pos="rightSlideIn" onClose={() => setSlideTab(null)}>
               <UwUbConfig tab={slideTab} onTabChange={setSlideTab} />
            </Popup>
         )}
         {uwIndexInspect > -1 && (
            <Popup pos="rightSlideIn" onClose={() => setUwIndexInspect(-1)}>
               <UwU
                  bulkInspector
                  uwCache={uwCache}
                  uwFinal={uwsFinal[uwIndexInspect]}
                  uwOptions={uwsOptions[uwIndexInspect]}
                  onBulkInspectorSave={handleInspectorSave}
                  onBulkInspectorClose={() => setUwIndexInspect(-1)}
               />
            </Popup>
         )}
         {showPostData && (
            <Popup onClose={() => setShowPostData(false)}>
               <div className={`${styles.postDataC}`}>
                  <div className={`${styles.postDataTitle} header-s`}>
                     <p>Imported</p>
                     <MainButton type="close" onClick={() => setShowPostData(false)} />
                  </div>
                  <div className={`${styles.postDataInfoC}`}>
                     {postContact && (
                        <div>
                           {!isNaN(Number(postContact))
                              ? "Associated with existing contact that match broker's information"
                              : "Created new associated contact with broker's information"}
                        </div>
                     )}
                     <div>
                        <b>Total Properties: </b>
                        <span>{properties.length}</span>
                     </div>
                     <div>
                        <b>Total Underwrtings: </b>
                        <span>{underwritings.length}</span>
                     </div>
                     {dupProperties.length > 0 && (
                        <div>
                           <b>Duplicated Properties found: </b>
                           <ul>
                              {dupProperties.map((p, i) => (
                                 <li key={i}>
                                    <b>
                                       {1 + i}. {p.hs_object_id}
                                    </b>
                                    <span>({p.property_address_one_line_})</span>
                                 </li>
                              ))}
                           </ul>
                        </div>
                     )}
                  </div>
               </div>
            </Popup>
         )}
         <div className={`${styles.sd}`}>
            <div className={`${styles.dHeader} header-m`}>
               <span onClick={() => console.log({ uwsFinal })}>Bulk Underwriting</span>
               <MainButton
                  title={`Override (${orPropFields.length + orUnitFields.length})`}
                  type="scnd"
                  onClick={() => setSlideTab("orPropFields")}
               />
               <MainButton
                  title={`Outputs (+${opPropFields.length + opUnitFields.length})`}
                  type="scnd"
                  onClick={() => setSlideTab("opPropFields")}
               />
               {uwProcessing ? (
                  <SmallSpinner style={{ width: 16, height: 16 }} />
               ) : (
                  <MainButton title="Calculate!" onClick={recalcSimUws} />
               )}
            </div>
            {TableData.length > 0 && (
               <div className={`${styles.tableControls}`}>
                  <MainButton
                     title="Select All"
                     type="bland"
                     onClick={() => setMarkedProperty(uwsFinal.map((uw) => uw.property_address_one_line_))}
                  />
                  <MainButton title="Deselect All" type="bland" onClick={() => setMarkedProperty([])} />
                  <MainButton title="Download CSV" onClick={handleDownloadCsv} />
                  <MainInput title="Portfolio Name" value={portfolio} handleValue={setPortfolio} />
                  <MainButton
                     title="Import Into Database?"
                     enable={!!portfolio && markedProperty.length > 0}
                     onClick={handleHsImport}
                     confirmMsg="Confirm? Note: Might take up to 1min to show up on HubSpot"
                     type="warn"
                  />
               </div>
            )}
            {contact.name && (
               <div className={`${styles.brokerInfoC}`}>
                  <div className={`${styles.brokerName}`}>
                     <b>Broker Name: </b>
                     <span>{contact.name || "-"}</span>
                  </div>
                  <div className={`${styles.brokerPhone}`}>
                     <b>Broker Phone: </b>
                     <span>{contact.phone || "-"}</span>
                  </div>
                  <div className={`${styles.brokerEmail}`}>
                     <b>Broker Email: </b>
                     <span>{contact.email || "-"}</span>
                  </div>
               </div>
            )}
            <div className={`${styles.tableC}`}>
               {TableData.length > 0 && (
                  <TableV2
                     tableData={TableData}
                     headerData={outputOrder}
                     onCellClick={handleCellClick}
                     onHeaderDrop={handleTableHeaderDnD}
                     showFilterIcon={false}
                  />
               )}
            </div>
         </div>
      </section>
   );
};

export default UwUBulkOld;
type TcellData = { name: string; value: any; format?: Tformat; type?: TtypeDropDown };
