import { useDrop } from "react-dnd";
import styles from "./FieldFormulaDrop.module.scss";
import { IformulaItemDnD } from "../../../../../../../../types/DnD/formulaDnD";
const FieldFormulaDrop = ({ handleDrop, accept, children, classname }: TFieldFormulaNodeDropProps) => {
   const [collectedProps, drop] = useDrop(() => ({
      accept,
      drop: handleDrop,
   }));
   return (
      <div className={`${styles.contentC} ${styles[classname || ""]}`} ref={drop}>
         {children}
      </div>
   );
};

export default FieldFormulaDrop;
type TFieldFormulaNodeDropProps = {
   accept: "Node" | "Operator";
   classname?: "green" | "orange";
   handleDrop: (item: IformulaItemDnD) => void;
   children: JSX.Element;
};
