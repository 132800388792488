import { Link, useNavigate } from "react-router-dom";
import styles from "./AioBulkUwCreate.module.scss";
import { SHrBodyFiller, SHrMainButton, SHrMainInput } from "@simplyhomes/react";
import Spreadsheet, { CellBase, Matrix } from "react-spreadsheet";
import { useEffect, useState } from "react";
import { useQAioVersions } from "../../../hooks/querries/aio/versions/useQAioVersions";
import useAxios from "../../../hooks/axios/useAxios";
import { useQAioBUw } from "../../../hooks/querries/aio/bulkUnderwritings/useQAioBUw";
import { SHu_js_percentageFormat, TAIO_Underwritings2_AppData_PropertyFields } from "@simplyhomes/utils";
type TMatrix = Matrix<CellBase<string>>;
type libAIOv2_uws_generateAppData_unitData =
   | { beds: number; baths: number }[]
   | { totalBeds: number; totalBaths: number; unitCount: number };
type TProperty = {
   address: string;
   unitData: libAIOv2_uws_generateAppData_unitData;
   propData?: Partial<TAIO_Underwritings2_AppData_PropertyFields>;
};
type TsubmitBody = {
   name: string;
   version: string;
   properties: TProperty[];
};
type TMode = "total" | "each";
const genHeaders = (m: TMode) => [
   "Address*",
   "Total Reno",
   ...(m === "total"
      ? ["Unit Count*", "Total Beds*", "Total Baths"]
      : [
           "Unit 1 Beds",
           "Unit 1 Baths",
           "Unit 2 Beds",
           "Unit 2 Baths",
           "Unit 3 Beds",
           "Unit 3 Baths",
           "Unit 4 Beds",
           "Unit 4 Baths",
           "Unit 5 Beds",
           "Unit 5 Baths",
           "Unit 6 Beds",
           "Unit 6 Baths",
        ]),
];
const AioBulkUwCreate = () => {
   const { aioAxios } = useAxios();
   const nav = useNavigate();

   const qVers = useQAioVersions({
      options: {
         refetchOnMount: true,
         onSuccess: (d) => setVersion(d.versions.find((v) => !!v.official)?.version || d.versions[0]?.version || ""),
      },
   });

   const [submitting, setSubmitting] = useState(false);
   const [mode, setMode] = useState<TMode>("each");
   const [version, setVersion] = useState("");
   const [name, setName] = useState("");

   const [tableData, setTableData] = useState<TMatrix>([
      [...genHeaders(mode).map((str) => ({ value: str, readOnly: true }))],
      ...new Array(9).fill([]),
   ]);

   const handleMode = (m: typeof mode) => {
      setMode(m);
      const newHeaders = genHeaders(m);
      setTableData([[...newHeaders.map((str) => ({ value: str, readOnly: true }))], ...tableData.slice(1)]);
   };
   const handleTableData = (tdata: typeof tableData) => {
      if (JSON.stringify(tableData) !== JSON.stringify(tdata)) setTableData(tdata);
   };
   const handleSubmit = async () => {
      setSubmitting(true);
      const properties: TProperty[] = tableData
         .slice(1)
         .filter((r) => !!r[0]?.value)
         .map((r) => {
            const address = r[0]?.value || "";
            const reno = Number(r[1]?.value) || 0;
            const unitData: libAIOv2_uws_generateAppData_unitData =
               mode === "total"
                  ? { totalBeds: Number(r[3]?.value), totalBaths: Number(r[4]?.value), unitCount: Number(r[2]?.value) }
                  : r.slice(2, 15).reduce((acc, cur, i, arr) => {
                       if (i % 2 === 0 && !!cur?.value)
                          acc.push({ beds: Number(cur.value), baths: Number(arr?.[i + 1]?.value || 0) });
                       return acc;
                    }, [] as { beds: number; baths: number }[]);
            return { address, unitData, propData: { $rough_reno: { value: reno } } };
         })
         .filter((p) =>
            Array.isArray(p.unitData) ? p.unitData.length > 0 : p.unitData.unitCount > 0 && p.unitData.totalBeds > 0
         );
      if (properties.length === 0) {
         setSubmitting(false);
         return alert("No properties to submit");
      }
      if (!name) {
         setSubmitting(false);
         return alert("Portfolio Name is required");
      }
      const body: TsubmitBody = { name, properties, version };
      await aioAxios
         .post("/aio/v2/bulk-underwritings", body)
         .then((r) => nav(`/aio-bulk-underwriting/${r.data.bid}`))
         .catch((er) => console.log({ er }));
      setSubmitting(false);
   };
   const loadingMsg = qVers.isLoading ? "Loading Versions" : submitting ? "Submitting" : null;
   if (loadingMsg) return <SHrBodyFiller message={loadingMsg} />;
   return (
      <section className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <div className={`${styles.headerL}`}>
               <Link to={"/aio-bulk-underwriting"}>
                  <SHrMainButton type="back" />
               </Link>
               <h1 className={`header-m`}>Create New Bulk Underwriting</h1>
            </div>
            <div className={`${styles.headerR}`}>
               <SHrMainButton title="Submit" onClick={handleSubmit} />
            </div>
         </div>
         <div className={`${styles.bodyC}`}>
            <div className={`${styles.SSC}`}>
               <div className={`${styles.SSConfig}`}>
                  <SHrMainInput
                     title="Versions"
                     type="search"
                     value={version}
                     onChange={setVersion}
                     options={qVers.data?.versions.map((v) => ({
                        value: v.version,
                        label: v.version + (v.official ? "⭐" : ""),
                     }))}
                     // hideOptionValues
                  />
                  <SHrMainInput
                     type="checkbox"
                     title="Individual Unit Mode"
                     value={mode === "each"}
                     onChange={() => handleMode(mode === "each" ? "total" : "each")}
                  />
                  <SHrMainInput title="Portfolio Name*" value={name} onChange={setName} type="text" bad={!name} />
               </div>
               <div className={`${styles.SS}`}>
                  <Spreadsheet data={tableData} onChange={handleTableData} />
               </div>
            </div>
         </div>
      </section>
   );
};

export default AioBulkUwCreate;
