import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslateFormula from "../../../../../../hooks/formulas/useTranslateFormula";
import parseFormula from "../../../../../../utils/formula/parseFormula";
// import DisplayFormula from "../../../../../common/displayFormula/DisplayFormula";
import MainButton from "../../../../../common/mainButton/MainButton";
import styles from "./FieldsEditorPopup.module.scss";
import useQColumns from "../../../../../../hooks/querries/versions/useQColumns";
import { useVersionContext } from "../../../../../../pages/Versions/Versions";
import { TversionField, Tformat, TtypeDropDown } from "../../../../../../hooks/querries/versions/useQVersion";
import FieldFormulaBuilder from "./fieldFormulaBuilder/FieldFormulaBuilder";
import Popup from "../../../../../common/popup/Popup";
import unitBehaviors, { TversionUnitBehavior } from "../../../../../../const/UWcalc/unitBehaviors";
import parseFieldName from "../../../../../../utils/fields/parseFieldName";
import MainInput from "../../../../../common/mainInput/MainInput";
import { TaioVersionObj } from "../../../../../../types/aioVersion/types";

const FieldsEditorPopup = ({ id = "", handleClose, obj }: IFieldsEditorPopupParams) => {
   const isNew = !id;
   const { ver = "" } = useParams();

   const { version, setVersion } = useVersionContext();
   const { fields = [], groups = [], units = [] } = version;

   const { data: columnsData } = useQColumns();
   const { columns = [] } = columnsData || {};

   const fieldIndex = fields.findIndex(({ id: fId }) => fId === id);
   const field = fields[fieldIndex];
   const [showBuilderPopUp, setShowBuilderPopUp] = useState(false);
   const [name, setName] = useState(field?.name || "");
   const [column, setColumn] = useState(field?.column || "");
   const [uwColumn, setUwColumn] = useState(field?.uwColumn || "");
   const [formula, setFormula] = useState(field?.formula || "");
   const [format, setFormat] = useState<Tformat>(field?.format || "");
   const [readonly, setReadonly] = useState<boolean>(field?.readonly ?? true);
   const [showInBulk, setShowInBulk] = useState(field?.showInBulk ?? false);
   const [required, setRequired] = useState<boolean>(field?.required ?? false);
   const [type, setType] = useState(field?.type || "text");
   const [options, setOptions] = useState(field?.options || "");
   const [fallback, setFallback] = useState(field?.fallback || "");
   const [condFormat, setCondFormat] = useState(field?.condFormat || "");
   const [unitBehavior, setUnitBehavior] = useState(field?.unitBehavior || "NONE");

   const { value } = useTranslateFormula({ formula, ver, obj });
   const [fieldSearch, setFieldSearch] = useState("");
   const filteredFields = useMemo(
      () => [
         ...fields
            .filter(
               ({ name, column, id }) =>
                  name?.toLowerCase()?.includes(fieldSearch.toLowerCase()) ||
                  column?.toLowerCase().includes(fieldSearch.toLowerCase()) ||
                  id?.toLowerCase().includes(fieldSearch.toLowerCase())
            )
            .map<IfilteredField>(({ name, id }) => ({ name, id })),
         ...columns
            .filter((name) => name?.toLowerCase()?.includes(fieldSearch.toLowerCase()))
            .map<IfilteredField>((name) => ({ name })),
      ],
      [columns, fieldSearch, fields]
   );

   if (!version) return <div>Not Found Ver</div>;
   const newId = field?.id || Date.now().toString();
   const usedInPropGroups = groups.filter(({ children }) => children.includes(newId));
   const usedInFields = fields.filter(({ formula }) => formula?.includes(newId));
   const usedInUnitGroups = units.filter(({ children }) => children.includes(newId));
   const handleSubmit = () => {
      const newField: TversionField = {
         id: newId,
         name,
         formula,
         format,
         column,
         uwColumn,
         options,
         fallback,
         readonly,
         required,
         type,
         condFormat,
         unitBehavior,
         showInBulk,
      };
      const newFields = [...fields];
      if (isNew) newFields.unshift(newField);
      else newFields.splice(fieldIndex, 1, newField);
      setVersion({ ...version, fields: newFields });
      handleClose();
   };
   const handleDelete = () => {
      const newFields = [...fields];
      newFields.splice(fieldIndex, 1);
      setVersion({ ...version, fields: newFields });
      handleClose();
   };
   const handleFieldClick = ({ name, id }: { name: string; id?: string }) => {
      if (!id) return navigator.clipboard.writeText(name);
      return navigator.clipboard.writeText(`@${id}`);
   };
   const parsedFormula = parseFormula(formula);
   const handleDisplayName = (v: string) => {
      setName(v);
      if (v.includes("#lambda")) return setColumn("");
      if (column.includes("$") || !column) setColumn(parseFieldName(v));
   };
   return (
      <div className={`${styles.contentC}`}>
         {showBuilderPopUp && (
            <Popup onClose={() => setShowBuilderPopUp(false)}>
               <FieldFormulaBuilder formula={formula} setFormula={setFormula} key={formula} />
            </Popup>
         )}
         <div className={`${styles.title}`}>{isNew ? "Create a new field" : "Edit a field"}</div>
         <div className={`${styles.content}`}>
            <div className={`${styles.form}`}>
               <MainInput value={newId} title={"ID"} readonly />
               <MainInput value={name} title={"Display Name"} handleValue={handleDisplayName} />
               <MainInput
                  value={column}
                  title={"Link to Property Column"}
                  handleValue={(v) => setColumn(v)}
                  tooltip={{ type: "info", msg: "Postgres Property column name" }}
               />
               <MainInput
                  value={uwColumn}
                  title={"Link to Underwriting Column"}
                  handleValue={(v) => setUwColumn(v)}
                  tooltip={{ type: "info", msg: "Postgres Underwriting column name" }}
               />
               <div className={`${styles.formulaC}`}>
                  <MainInput
                     value={formula}
                     type={"textarea"}
                     title={"Raw Formula"}
                     handleValue={(v) => setFormula(v)}
                     tooltip={!parsedFormula ? { type: "warning", msg: "unable to parse!" } : undefined}
                  />
                  <MainButton title="Use Builder" onClick={() => setShowBuilderPopUp(true)} />
               </div>
               <div>{value}</div>
               {/* {parsedFormula && (
                  <div className={`${styles.displayFormula}`}>
                     <div className={`${styles.subTitle}`}>Formula Review</div>
                     <DisplayFormula pf={parsedFormula} ver={ver} />
                  </div>
               )} */}
               <MainInput
                  title={"Format"}
                  value={format}
                  type={"dropdown"}
                  handleValue={(v) => setFormat(v as Tformat)}
                  options={["Currency", "Percentage"]}
               />
               <MainInput title={"Conditional Formatting"} value={condFormat} handleValue={setCondFormat} />
               <MainInput
                  type="dropdown"
                  title={"Unit Behavior"}
                  options={unitBehaviors}
                  value={unitBehavior}
                  handleValue={(v) => setUnitBehavior(v as TversionUnitBehavior)}
               />
               <MainInput title={"Read Only?"} value={readonly} type={"checkbox"} handleValue={setReadonly} />
               {!readonly && (
                  <>
                     <MainInput
                        title={"Show In UW Bulk View?"}
                        value={showInBulk}
                        type={"checkbox"}
                        handleValue={setShowInBulk}
                     />
                     <MainInput title={"Required?"} value={required} type={"checkbox"} handleValue={setRequired} />
                     <MainInput
                        value={type}
                        title={"Type"}
                        handleValue={(v) => setType(v as TtypeDropDown)}
                        type={"dropdown"}
                        options={["text", "number", "checkbox", "dropdown", "search", "multi"]}
                     />
                     {["dropdown", "search", "multi"].includes(type) && (
                        <MainInput
                           value={options}
                           title={"Options"}
                           handleValue={(v) => setOptions(v)}
                           tooltip={{ type: "info", msg: "Separated by comma" }}
                        />
                     )}
                  </>
               )}
               {column && (
                  <MainInput
                     value={fallback}
                     title={"Default"}
                     handleValue={(v) => setFallback(v)}
                     tooltip={{ type: "info", msg: "Default value if blank" }}
                  />
               )}
            </div>
            <div className={`${styles.finderC}`}>
               <MainInput
                  value={fieldSearch}
                  title={"Fields Finder"}
                  handleValue={(v) => setFieldSearch(v)}
                  tooltip={{ type: "info", msg: "Search and click on any field below to copy to clipboard" }}
                  // style={{ width: "6rem" }}
               />
               <ul className={`${styles.fields}`}>
                  {filteredFields.map(({ name, id }, key) => (
                     <li key={key} className={`${styles.field}`} onClick={() => handleFieldClick({ name, id })}>
                        <span className={`${styles.type} ${styles[id ? "Custom" : "Database"]}`}>
                           {id ? "Custom" : "Database"}
                        </span>
                        <span>{name}</span>
                     </li>
                  ))}
               </ul>
               <div className={`${styles.usedIn}`}>
                  <b className={`${styles.usedInTitle}`}>Used In {usedInUnitGroups.length} Unit Groups:</b>
                  <ul>
                     {usedInUnitGroups.map(({ title }, key) => (
                        <li key={key}>{title}</li>
                     ))}
                  </ul>
                  <b className={`${styles.usedInTitle}`}>Used In {usedInPropGroups.length} Property Groups:</b>
                  <ul>
                     {usedInPropGroups.map(({ title }, key) => (
                        <li key={key}>{title}</li>
                     ))}
                  </ul>
                  <b className={`${styles.usedInTitle}`}>Used In {usedInFields.length} Fields:</b>
                  <ul>
                     {usedInFields.map(({ name }, key) => (
                        <li key={key}>{name}</li>
                     ))}
                  </ul>
               </div>
            </div>
         </div>
         <div className={`${styles.controlsC}`}>
            <MainButton
               enable={!!name && !formula.includes("PlaceHolder")}
               title={isNew ? "Create" : "Update"}
               onClick={() => handleSubmit()}
               style={{ width: "6rem" }}
               confirmMsg="Confirm Save?"
            />
            {!isNew && (
               <MainButton
                  title={"Delete"}
                  confirmMsg="Confirm DELETE?"
                  type="warn"
                  onClick={() => handleDelete()}
               />
            )}
         </div>
      </div>
   );
};

export default FieldsEditorPopup;

interface IFieldsEditorPopupParams {
   obj: TaioVersionObj;
   id?: string;
   handleClose: () => void;
}
interface IfilteredField {
   id?: string;
   name: string;
}
