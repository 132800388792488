import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";

const useQColumns = () => {
   const { aioAxios } = useAxios();
   return useQuery({
      queryKey: ["version", "columns"],
      queryFn: () =>
         aioAxios
            .get(`/aio/versions/columns`, { headers: { "Cache-Control": "no-cache" } })
            .then<{ columns: string[] }>(({ data }) => data),
   });
};
export default useQColumns;
