import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Properties from "./pages/Properties/Properties";
import Property from "./pages/Properties/Property/Property";
import Versions from "./pages/Versions/Versions";
import VerEditor from "./components/children/versions/verEditor/VerEditor";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Map from "./pages/Map/Map";
import { useContext } from "react";
import Database from "./pages/Database/Database";
import DbTable from "./components/children/database/dbTable/DbTable";
import AppContext from "./contexts/appContext/AppContext";
import BodyFiller from "./components/common/bodyFiller/BodyFiller";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import useAuthTabs from "./hooks/auth0/useAuthTabs";
import PageUnauthorized from "./pages/PageUnauthorized/PageUnauthorized";
import Underwritings from "./pages/Underwritings/Underwritings";
import Underwriting from "./pages/Underwritings/underwriting/Underwriting";
import Tools from "./pages/Tools/Tools";
import UnderwritingBulk from "./pages/Underwritings/underwritingBulk/UnderwritingBulk";
import UwU from "./pages/UwU/UwU";
import UwUSingle from "./pages/UwU/UwUSingle/UwUSingle";
import SimplerWidget from "./pages/SimplerWidget/SimplerWidget";
import UwUb from "./pages/UwUb/UwUb";
import UwUBrowser from "./pages/UwU/UwUBrowser/UwUBrowser";
import AssetsSearch from "./pages/Assets/assetsSearch/AssetsSearch";
import Asset from "./pages/Assets/asset/Asset";
import RenovationsSearch from "./pages/Renovations/renovationsSearch/RenovationsSearch";
import Census from "./pages/Census/Census";
import Settings from "./pages/Settings/Settings";
import AccountSettings from "./components/children/settings/accountSettings/AccountSettings";
import SubscriptionsSettings from "./components/children/settings/subscriptionsSettings/SubscriptionsSettings";
import Body from "./components/common/body/Body";
import UwUBulkOld from "./pages/UwUb/UwUBulk/UwUBulkOld";
import UwUBulk from "./pages/UwUBulk/UwUBulk";
import UwUBulkCreate from "./components/children/uwuBulk/UwUBulkCreate/UwUBulkCreate";
import UwUBulkBrowser from "./components/children/uwuBulk/UwUBulkBrowser/UwUBulkBrowser";
import UwUBulkView from "./components/children/uwuBulk/UwUBulkView/UwUBulkView";
import ApiTable from "./components/children/apiTable/ApiTable";
import Dev from "./pages/Dev/Dev";
import AioUws from "./components/AioUws/AioUws";
import AioVersions from "./components/AioVersions/AioVersions";
import AioVersion from "./components/AioVersions/AioVersion/AioVersion";
import AioUw from "./components/AioUws/AioUw/AioUw";
import AioBulkUws from "./components/AioBulkUws/AioBulkUws";
import AioBulkUwCreate from "./components/AioBulkUws/AioBulkUwCreate/AioBulkUwCreate";
import AioBulkUw from "./components/AioBulkUws/AioBulkUw/AioBulkUw";
import AioMap from "./components/AioMap/AioMap";

const App = () => {
   const { appState } = useContext(AppContext);
   const { fetchingAccessToken, accessToken } = appState;

   const { authTabs } = useAuthTabs();

   const router = createBrowserRouter(
      createRoutesFromElements(
         fetchingAccessToken || !accessToken ? (
            <Route path="*" element={<BodyFiller loading style={{ height: "50vh" }} />} />
         ) : (
            <Route path="/" element={<Body />}>
               <Route path="/" element={<Home />}></Route>
               <Route path="/dev" element={<Dev />} />
               <Route path="tools" element={<Tools />} />
               <Route path="census" element={<Census source="internal" />} />
               <Route path="census-external" element={<Census source="external" />} />
               <Route path="census-internal" element={<Census source="internal" />} />
               <Route path="settings" element={<Settings />}>
                  <Route path="general" element={<></>} />
                  <Route path="account" element={<AccountSettings />} />
                  <Route path="subscriptions" element={<SubscriptionsSettings />} />
               </Route>

               <Route path="aio-underwriting" element={<AioUws />} />
               <Route path="aio-underwriting/:uid" element={<AioUw />} />

               <Route path="aio-bulk-underwriting" element={<AioBulkUws />} />
               <Route path="aio-bulk-underwriting/create" element={<AioBulkUwCreate />} />
               <Route path="aio-bulk-underwriting/:bid" element={<AioBulkUw />} />

               <Route path="aio-versions" element={<AioVersions />} />
               <Route path="aio-versions/:vid" element={<AioVersion />} />

               <Route path="aio-map" element={<AioMap />} />

               {authTabs.includes("apitable") && <Route path="apitable" element={<ApiTable />} />}
               {authTabs.includes("simpler") && <Route path="simpler" element={<SimplerWidget />}></Route>}
               {authTabs.includes("uwub") && (
                  <Route path="uwub" element={<UwUb />}>
                     <Route path="bulk" element={<UwUBulkOld />}>
                        <Route path=":uwsid" element={<UwUSingle bulkInspector />} />
                     </Route>
                  </Route>
               )}
               {authTabs.includes("uwubulk") && (
                  <Route path="uwubulk" element={<UwUBulk />}>
                     <Route path="" element={<UwUBulkBrowser />} />
                     <Route path="create" element={<UwUBulkCreate />} />
                     <Route path=":bid" element={<UwUBulkView />}>
                        <Route path=":uwsid" element={<UwUSingle bulkInspector />} />
                     </Route>
                     {/* <Route path=":uwsid" element={<UwUSingle bulkInspector />} /> */}
                  </Route>
               )}
               {authTabs.includes("uwu") && <Route path="uwu/search" element={<UwUBrowser />} />}
               {authTabs.includes("uwu") && (
                  <Route path="uwu" element={<UwU />}>
                     <Route path=":uwsid" element={<UwUSingle />} />
                  </Route>
               )}
               {authTabs.includes("underwritings") && (
                  <Route path="underwritings" element={<Underwritings />}>
                     <Route path="bulk" element={<UnderwritingBulk />} />
                     <Route path=":uwsid" element={<Underwriting />} />
                  </Route>
               )}
               {authTabs.includes("assets") && <Route path="assets/search" element={<AssetsSearch />} />}
               {authTabs.includes("assets") && <Route path="assets/:aid" element={<Asset />} />}
               {authTabs.includes("renovations") && <Route path="renovations/search" element={<RenovationsSearch />} />}

               {authTabs.includes("properties") && (
                  <Route path="properties" element={<Properties />}>
                     <Route path=":pid" element={<Property />} />
                  </Route>
               )}
               {authTabs.includes("versions") && (
                  <Route path="versions" element={<Versions />}>
                     <Route path=":ver" element={<VerEditor />} />
                  </Route>
               )}
               {authTabs.includes("map") && <Route path="map" element={<Map />}></Route>}
               {authTabs.includes("database") && (
                  <Route path="database" element={<Database />}>
                     <Route path="" element={<DbTable />} />
                  </Route>
               )}
               <Route path="/unauthorized" element={<PageUnauthorized />} />
               <Route path="*" element={<PageNotFound />} />
            </Route>
         )
      )
   );

   return <RouterProvider router={router} />;
};
export default withAuthenticationRequired(App);
// export default App;
