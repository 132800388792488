import { useEffect, useMemo, useState } from "react";
import styles from "./MainInput.module.scss";
import s3afBucketURI from "../../../utils/s3/s3afBucketURI";
import Popup from "../popup/Popup";
import { HexColorPicker } from "react-colorful";
import Tooltip, { ITooltip } from "../tooltip/Tooltip";
import SmallSpinner from "../smallSpinner/SmallSpinner";
import JSONtryParse from "../../../utils/JSON/JSONtryParse";
import { SHu_js_currencyFormat, SHu_js_percentageFormat } from "@simplyhomes/utils";
const MainInput = <T,>({
   showValueOnly,
   value = "" as T,
   handleValue = () => {},
   readonly,
   title,
   type = "text",
   options = [],
   optionSelectBehavior = "overwrite",
   useInternal,
   style,
   bad,
   tooltip,
   onTooltipClick = () => {},
   loading,
   onlyAllowValFromOptions,
   className,
   highlight,
   disableCollapse = true,
   hideSeparators,
   format,
   otherProps,
}: TMainInputProps<T>) => {
   const debug = title === "z";

   const [focused, setFocused] = useState(false);
   const [hovered, setHovered] = useState(false);
   const [showPopup, setShowPopup] = useState(false);

   if (Number.isNaN(value)) value = (value || "").toString() as T;
   const [internalVal, setInternalVal] = useState("");
   useEffect(() => setInternalVal(value as string), [value]);

   const allOptions = typeof options === "string" ? options.split("|").filter(Boolean) : options.filter(Boolean);
   const hasOptions = allOptions.filter(Boolean).length > 0;
   const strVal = (
      focused
         ? value
         : format === "currency"
         ? SHu_js_currencyFormat(value as string)
         : format === "percentage"
         ? SHu_js_percentageFormat(value as string)
         : value
   ) as string;
   const arrVal = (typeof value === "string" ? (value as string)?.split("|").filter(Boolean) : value) as T[];
   const boolVal = typeof value === "boolean" ? value : (JSONtryParse<boolean>(value as string, false) as boolean);

   const strOptions = options as string[];
   const setValue = (v: T) => {
      if (readonly) return;
      handleValue(v);
   };
   const setAllOptions = () => {
      if (readonly) return;
      if (type === "filtersSelect") {
         const optionValues = allOptions.map((o) => o.toString().split("|").pop()).filter(Boolean);
         return handleValue((typeof value === "string" ? optionValues.join("|") : optionValues) as T);
      }
      handleValue((typeof value === "string" ? allOptions.join("|") : allOptions) as T);
   };
   const setNoOptions = () => {
      if (readonly) return;
      if (type === "filtersSelect") {
         return handleValue((typeof value === "string" ? "" : []) as T);
      }
      handleValue((typeof value === "string" ? "" : []) as T);
   };
   const setDdValue = (o: string) => {
      // label|id123123 -> |id123123
      if (readonly) return;
      if (type === "filtersSelect") {
         const v = o.split("|").pop();
         const newValues = arrVal.includes(v as T) ? arrVal.filter((val) => val !== v) : [...arrVal, v];
         return handleValue((typeof value === "string" ? newValues.join("|") : newValues) as T);
      }
      if (type === "multi") {
         const newValues = arrVal.includes(o as T) ? arrVal.filter((val) => val !== o) : [...arrVal, o];
         return handleValue((typeof value === "string" ? newValues.join("|") : newValues) as T);
      }
      handleValue((optionSelectBehavior === "append" ? strVal + o : o) as T);
      setFocused(false);
   };
   const filteredOptions = useMemo(
      () =>
         strOptions.filter((str) => (type === "search" ? str.toLowerCase().includes(internalVal.toLowerCase()) : true)),
      [internalVal, strOptions, type]
   );
   const otherOptions = useMemo(
      () => strOptions.filter((str) => !filteredOptions.includes(str)),
      [filteredOptions, strOptions]
   );

   if (debug) console.log({ focused });
   const showValOnlyStyles: React.CSSProperties = showValueOnly ? { padding: "0 4px" } : {};
   return (
      <div
         className={`
         ${
            type !== "checkbox" &&
            type !== "toggle" &&
            value === "" &&
            !hasOptions &&
            internalVal === "" &&
            !focused &&
            !hovered &&
            !disableCollapse &&
            styles.hideInput
         }
       
         ${styles.contentC} ${bad && styles.bad} ${className}
         ${highlight && styles.highlight}`}
         style={{ ...showValOnlyStyles, ...style }}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
         onClick={() => {
            setFocused(true);
            if (type === "checkbox" || type === "toggle") setValue(!boolVal as T);
         }}
         onFocus={() => setFocused(true)}
         // onBlur={() => setFocused(false)} //!CRITICAL onblur will prevent dropdown options from being clicked onApply
      >
         <div
            className={`${styles.left}   
            ${type === "checkbox" && styles.checkbox}
            ${type === "toggle" && styles.toggle}`}
         >
            {!showValueOnly && (
               <div className={`${styles.titleC}`} title={title}>
                  <span className={`${styles.title} `}>{title}</span>
                  {readonly && <img src={s3afBucketURI("/icons/lock.svg")} alt="" className={`${styles.lock} `} />}
               </div>
            )}
            <div className={`${styles.inputC}`}>
               {loading ? (
                  <SmallSpinner style={{ width: "1rem" }} />
               ) : type === "dropdown" ? (
                  <div className={`${styles.dropdownVal}`}>{strVal}</div>
               ) : type === "multi" ? (
                  <div className={`${styles.dropdownVal}`} onClick={() => setFocused(!focused)}>
                     {arrVal.length}/{allOptions.length} selected
                  </div>
               ) : type === "filtersSelect" ? (
                  <div className={`${styles.dropdownVal}`} onClick={() => setFocused(!focused)}>
                     {arrVal.length}/{allOptions.length} selected
                  </div>
               ) : type === "checkbox" ? (
                  <>
                     <input type="checkbox" checked={boolVal || false} onChange={() => setValue(!boolVal as T)} />
                     <span className={`${styles.checkmark}`}></span>
                  </>
               ) : type === "toggle" ? (
                  <>
                     <input type="checkbox" checked={boolVal || false} onChange={() => setValue(!boolVal as T)} />
                     <div className={`${styles.toggleBall} tr`}></div>
                  </>
               ) : type === "textarea" ? (
                  <textarea value={strVal} onChange={(v) => setValue(v.target.value as T)} />
               ) : // : type === "number" ? (
               //    <input
               //       value={Number(stringVal)}
               //       onChange={(v) => setValue(Number(v.target.value) as T)}
               //       type="number"
               //    />
               // )
               type === "date" ? (
                  <input
                     type="date"
                     value={strVal?.split("T")[0] || ""}
                     onChange={(v) => setValue(v.target.value as T)}
                     className={`${styles.input}`}
                     title={strVal}
                     {...otherProps}
                  />
               ) : type === "color" ? (
                  <div className={`${styles.colorC}`}>
                     {showPopup && (
                        <Popup onClose={() => setShowPopup(false)}>
                           <HexColorPicker color={strVal} onChange={(v) => setValue(v as T)} />
                        </Popup>
                     )}
                     <div
                        onClick={() => setShowPopup(true)}
                        style={{ backgroundColor: strVal || "var(--text-1)" }}
                        className={`${styles.colorPreview}`}
                     ></div>
                     <input
                        type="text"
                        value={strVal}
                        onChange={(e) => setValue(e.target.value as T)}
                        className={`${styles.input}`}
                        title={strVal}
                        {...otherProps}
                     />
                  </div>
               ) : type === "search" ? (
                  <input
                     type="text"
                     value={internalVal}
                     onChange={(e) => !readonly && setInternalVal(e.target.value)}
                     onBlur={
                        () => {}
                        // onlyAllowValFromOptions && options.includes(internalVal as T)
                        //    ? setValue(internalVal as T)
                     }
                     className={`${styles.input}`}
                     title={strVal}
                     {...otherProps}
                  />
               ) : (
                  <input
                     type="text"
                     value={useInternal ? internalVal : strVal}
                     onChange={(e) =>
                        !readonly && (useInternal ? setInternalVal(e.target.value) : setValue(e.target.value as T))
                     }
                     className={`${styles.input}`}
                     onBlur={() => (useInternal ? setValue(internalVal as T) : {})}
                     title={strVal}
                     {...otherProps}
                  />
               )}
            </div>
         </div>
         <div className={`${styles.right}`}>
            {tooltip && <Tooltip {...tooltip} onTooltipClick={onTooltipClick} />}
            {(type === "dropdown" || type === "multi" || type === "search") && (
               <img
                  src={s3afBucketURI("/icons/dd_arrow.svg")}
                  alt=""
                  className={`${styles.ddArrow} ${focused && styles.ddArrowRotate}`}
               />
            )}
            {type === "search" && <img src={s3afBucketURI("/icons/search.svg")} alt="" className={`${styles.lens}`} />}
         </div>
         {focused && hasOptions && (
            <div
               className={`${styles.dropdownOptions}`}
               onMouseLeave={() => {
                  setHovered(false);
                  setFocused(false);
               }}
            >
               <div className={`${styles.ddoScrollContainer}`}>
                  {type === "multi" || type === "filtersSelect" ? (
                     <>
                        <div onClick={setAllOptions}>Add All</div>
                        <div onClick={setNoOptions}>Clear</div>
                     </>
                  ) : (
                     <div onClick={() => setDdValue("")}>Clear</div>
                  )}
                  {filteredOptions.map((o, key) => (
                     <div key={key} onClick={() => setDdValue(o)}>
                        <span className={`ellipsis`}>{hideSeparators ? o.split("|").shift() : o}</span>
                        {((type === "multi" && arrVal.includes(o as T)) ||
                           (type === "filtersSelect" && arrVal.includes(o.split("|").pop() as T)) ||
                           ((type === "dropdown" || type === "search") && value === o)) && (
                           <img className={`${styles.checkbox}`} src={s3afBucketURI("/icons/checkbox.svg")} alt="" />
                        )}
                     </div>
                  ))}
                  {otherOptions.map((str, key) => (
                     <div key={key} onClick={() => setDdValue(str)}>
                        <span className={`ellipsis`}>{hideSeparators ? str.split("|").shift() : str}</span>
                     </div>
                  ))}
               </div>
            </div>
         )}
      </div>
   );
};

export default MainInput;
type TMainInputProps<T> = {
   className?: string;
   value: T;
   handleValue?: (v: T) => void;
   readonly?: boolean;
   title?: string;
   type?: TMainInput;
   options?: string | (string | number)[];
   optionSelectBehavior?: "overwrite" | "append" | "inject";
   style?: React.CSSProperties;
   useInternal?: boolean;
   bad?: boolean;
   tooltip?: ITooltip;
   onTooltipClick?: () => void;
   children?: React.ReactNode;
   onlyAllowValFromOptions?: boolean;
   loading?: boolean;
   highlight?: boolean;
   showValueOnly?: boolean;
   disableCollapse?: boolean;
   hideSeparators?: boolean;
   format?: "currency" | "percentage";
   otherProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
};
export type TMainInput =
   | "text"
   | "dropdown"
   | "checkbox"
   | "number"
   | "textarea"
   | "search"
   | "date"
   | "multi"
   | "color"
   | "toggle"
   | "filtersSelect";
