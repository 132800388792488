import * as formulajs from "@formulajs/formulajs";
import { Node } from "excel-formula-ast";
import { TversionConfig, TversionField } from "../../hooks/querries/versions/useQVersion";
import { TUwData } from "../../types/hsObjects";
import { TUnknownObject } from "../../types/interfaces/unknownObj";
import parseFormula from "../formula/parseFormula";
import isNumberic from "../valTypes/isNumberic";
import JSONtryParse from "../JSON/JSONtryParse";
import axios from "axios";
import parseFieldName from "../fields/parseFieldName";

export const calcUwU = async ({
   accessToken,
   config,
   fieldIds,
   curCache,
   curUw,
   unitIndex,
   curOptions,
   forceRecalcFirstLayer,
   ignoreError,
   handleProgress = () => {},
}: TCalcParams) => {
   const { fields = [], groups = [], units = [] } = config;

   const aioAxios = axios.create({
      headers: { Authorization: `Bearer ${accessToken}` },
      baseURL: process.env.REACT_APP_API_URL,
   });

   let calcResult: { $childUnits: TUwData[] } & TUnknownObject = { $childUnits: [], ...curUw };
   let calcCache = { ...curCache };
   let calcOptions = { ...curOptions };
   const logs: any[] = [];
   const resolveParsedFormula = async ({
      pf,
      debug,
      unitIndex,
   }: TResolveParsedFormulaParams): Promise<string | number | boolean> => {
      if (!pf) return "";
      const { type } = pf;

      switch (type) {
         case "cell":
            const { key } = pf;
            if (key.includes("@")) {
               const field = fields.find(({ id }) => id === key.replace("@", ""));
               const { formula, column: baseKeyCol } = field || {};
               const refVal = await resolveParsedFormula({
                  pf: parseFormula(baseKeyCol || formula || "") as Node,
                  unitIndex,
                  debug,
               });
               if (debug) logs.push({ type: "cell", key, formula, refVal });
               return refVal;
            }
            const keyVal =
               unitIndex != null && calcResult.$childUnits?.[unitIndex]?.[key] != null
                  ? calcResult.$childUnits?.[unitIndex]?.[key]
                  : calcResult[key];

            if (debug) logs.push({ type: "cell", key, keyVal, unitIndex });
            if (isNumberic(keyVal)) return Number(keyVal);
            return keyVal;
         case "number":
            return pf.value;
         case "text":
            return pf.value;
         case "function":
            const { arguments: args } = pf;
            const resolvedArgs = await Promise.all(
               args.map((arg) => resolveParsedFormula({ pf: arg, unitIndex, debug }))
            );
            const customName = pf.name as string;
            switch (customName.toUpperCase()) {
               case "DBLIST":
               case "DBLOOKUP":
               case "DBLOOKUPSUM":
               case "TRACT":
               case "COUNTY":
               case "CITY":
               case "STATE":
               case "ZIPCODE":
               case "RENTOMETER":
                  if (!resolvedArgs.every(Boolean)) return "missingArgs";
                  const cacheKey = customName + "|" + JSON.stringify(resolvedArgs);
                  const cacheVal = calcCache[cacheKey];
                  if (debug) console.log({ type: customName, cacheVal, cacheKey, uwCache: calcCache });

                  if (cacheVal) return cacheVal;
                  // console.log({ cacheKey, cacheVal, uwCache });
                  if (debug) console.log({ cacheKey });
                  const fx = cacheKey.split("|")[0];
                  const args = JSONtryParse<string>(cacheKey.replace(`${fx}|`, ""));
                  if (debug) console.log({ fx, args });
                  const result = await aioAxios
                     .post(`/aio/custom-formula/${fx.toLowerCase()}`, { args })
                     .then(({ data }) => data?.value || "failed")
                     .catch((er) => {
                        if (!ignoreError) console.log({ er });
                        return "error";
                     });
                  calcCache = { ...calcCache, [cacheKey]: result };
                  if (debug) logs.push({ type: customName, args: resolvedArgs, result });

                  // setUwCache((prev) => ({...prev, [cacheKey]: result}))
                  // addToUwCache({ key: cacheKey, value: result });
                  return result;
               case "PPAYDOWN": {
                  const [rate, term, principal, periods] = resolvedArgs;
                  const mPayment = await resolveParsedFormula({
                     pf: parseFormula(`"-1" * PMT( ${rate} / 12 , ${term} * 12 , ${principal} )`) as Node,
                     unitIndex,
                     debug,
                  });
                  let loanBalance = principal;
                  let pPaydown = 0;
                  for (let i = 1; i <= (Number(periods) || 12); i++) {
                     const iPayment = await resolveParsedFormula({
                        pf: parseFormula(`"-1" * IPMT( ${rate} / 12 , ${i} , ${term} * 12 , ${principal} )`) as Node,
                        unitIndex,
                        debug,
                     });
                     const oldLoanBalance = Number(loanBalance);
                     const newLoanBalance = Number(loanBalance) - (Number(mPayment) - Number(iPayment));
                     loanBalance = newLoanBalance;
                     pPaydown = oldLoanBalance - newLoanBalance + pPaydown;
                     // if (debug) console.log({ mPayment, iPayment, loanBalance, pPaydown });
                  }
                  if (debug) console.log({ rate, term, principal, periods, pPaydown });

                  return pPaydown;
               }
               case "BOOLEAN": {
                  return resolvedArgs.every(Boolean);
               }
               case "UNITCOUNT": {
                  return calcResult.$childUnits.length;
               }
               default:
                  break;
            }

            const routerNames = [{ external: "PARENTHESES", internal: "SUM" }];
            const name = (routerNames.find(({ external }) => external === pf.name)?.internal || pf.name) as "IFS";
            try {
               const formulaJsVal = formulajs[name](...resolvedArgs);
               if (debug) logs.push({ type: name, args: resolvedArgs, formulaJsVal });
               return formulaJsVal;
            } catch (error) {
               console.log({ error, name, resolvedArgs });
            }
            return "uncatched Function";
         case "binary-expression":
            const { left, operator, right } = pf;
            const extendedOps = operator as any;
            const rL = await resolveParsedFormula({ pf: left, unitIndex, debug });
            const rR = await resolveParsedFormula({ pf: right, unitIndex, debug });
            if (debug) logs.push({ type: "binary-expression", rL, operator, rR });
            switch (extendedOps) {
               case "&":
                  return `${rL}${rR}`;
               case "=":
                  // eslint-disable-next-line eqeqeq
                  return typeof rL === "boolean"
                     ? rL === JSONtryParse(rR.toString().toLowerCase())
                     : typeof rR === "boolean"
                     ? rR === JSONtryParse(rL.toString().toLowerCase())
                     : rL === rR;
               case "+":
                  return Number(rL) + Number(rR);
               case "-":
                  return Number(rL) - Number(rR);
               case "<":
                  return Number(rL) < Number(rR);
               case "<=":
                  return Number(rL) <= Number(rR);
               case ">":
                  return Number(rL) > Number(rR);
               case ">=":
                  return Number(rL) >= Number(rR);
               case "/":
                  return Number(rL) / Number(rR);
               case "*":
                  return Number(rL) * Number(rR);
               default:
                  break;
            }
            return "uncatched Binary";
         default:
            return "uncatched Type";
      }
   };
   const resolveOneField = async ({ fieldId, unitIndex, debug }: TResolveOneField) => {
      const field = fields.find(({ id }) => id === fieldId);
      if (!field) return;
      const {
         name,
         column: baseCol,
         formula,
         type: fieldType,
         fallback,
         options: fieldOptions,
         unitBehavior = "NONE",
      } = field;

      const amIaChild = unitIndex != null;
      const fieldsInUnits = units.map(({ children }) => children).flat();
      const amIaMother = !amIaChild && fieldsInUnits.includes(fieldId) && calcResult.$childUnits.length > 0;
      const column = baseCol || parseFieldName(name);
      const curVal = amIaChild ? calcResult.$childUnits[unitIndex]?.[column] : calcResult[column];

      const updateResult = (v: any): { newVal: boolean } => {
         if (debug) console.log({ name, newVal: v, curVal });
         if (v === curVal || (curVal?.toString() === "NaN" && v?.toString() === "NaN")) return { newVal: false };
         if (!amIaChild) {
            calcResult = { ...calcResult, [column]: v };
            return { newVal: true };
         }
         const newUnit = { ...calcResult.$childUnits[unitIndex], [column]: v };
         const newChildUnits = calcResult.$childUnits.map((unit, key) => (key === unitIndex ? newUnit : unit));
         calcResult = { ...calcResult, $childUnits: newChildUnits };
         return { newVal: true };
      };
      if (amIaMother && unitBehavior !== "NONE") {
         const unitValues = calcResult.$childUnits.map((unit) => unit[column]);
         if (debug) console.log({ unitValues });
         const unitValSum = unitValues.reduce((a, b) => Number(a || 0) + Number(b || 0), 0) || 0;
         const motherValue =
            unitBehavior === "SUM"
               ? unitValSum
               : unitBehavior === "AVG"
               ? unitValSum / unitValues.length
               : unitBehavior === "MAX"
               ? Math.max(...unitValues)
               : unitBehavior === "MIN"
               ? Math.min(...unitValues)
               : unitBehavior === "FIRST"
               ? unitValues[0]
               : "Behavior?";
         return updateResult(motherValue);
      }
      if (formula) {
         const parsedFormula = parseFormula(formula);
         if (!parsedFormula) return;
         const formulaVal = await resolveParsedFormula({ pf: parsedFormula, debug, unitIndex });
         if (debug) console.log({ logs });
         return updateResult(formulaVal);
      } else {
         if (!!fieldOptions) {
            const newOptions = fieldOptions.startsWith("=")
               ? await resolveParsedFormula({ pf: parseFormula(fieldOptions), debug, unitIndex })
               : fieldOptions;
            calcOptions = { ...calcOptions, [fieldOptions]: newOptions };
         }
         const newVal =
            fieldType === "checkbox" &&
            (curVal === "true" || curVal === "Y" || curVal === true || fallback === "true") &&
            curVal !== "false" &&
            curVal !== false
               ? true
               : fieldType === "checkbox"
               ? false
               : column && curVal !== undefined && curVal !== null
               ? curVal
               : fallback ?? "";
         if (debug) console.log({ name, newVal, curVal });
         return updateResult(newVal);
      }
   };
   for (let i = 0; i < fieldIds.length; i++) {
      const fieldId = fieldIds[i];

      const fieldsInUnits = units.map(({ children }) => children).flat();
      const amIaChild = unitIndex != null;
      const amIaMother = !amIaChild && fieldsInUnits.includes(fieldId) && calcResult.$childUnits.length > 0;

      const curField = fields.find((field) => field.id === fieldId);
      if (!curField) continue;
      const { unitBehavior = "NONE", name, column } = curField;
      const debug = name === "Vacancy Factor (Month)z";
      if (debug) console.log({ calcResult });
      const { newVal } = (await resolveOneField({ fieldId, unitIndex, debug })) || {};
      if (debug) console.log({ column: calcResult.$childUnits?.[i]?.[column || ""], calcResult });
      if (!newVal && !forceRecalcFirstLayer) continue;
      // console.log({ i, uwCache });

      // const allGroupFields = groups
      //    .map(({ children }) => children)
      //    .flat()
      //    .map((fieldId) => fields.find(({ id }) => id === fieldId))
      //    .filter(Boolean) as TversionField[];
      const allUnitFields = units
         .map(({ children }) => children)
         .flat()
         .map((fieldId) => fields.find(({ id }) => id === fieldId))
         .filter(Boolean) as TversionField[];

      // find related prop fields
      // const groupFieldIds: string[] = allGroupFields
      const groupFieldIds: string[] = fields
         .filter(
            (field) =>
               field.id !== fieldId &&
               ((field.column && field.column === curField.column) ||
                  field.formula?.includes(`@${curField.id}`) ||
                  field.options?.includes(`@${curField.id}`) ||
                  (curField.column && field.formula?.includes(curField.column)) ||
                  (curField.column && field.options?.includes(curField.column)))
         )
         .map(({ id }) => id);

      //* run recalc if on property level
      if (!amIaChild) {
         const {
            calcResult: subResult,
            calcCache: subCache,
            calcOptions: subOptions,
         } = await calcUwU({
            accessToken,
            config,
            fieldIds: groupFieldIds,
            curCache: calcCache,
            curUw: calcResult,
            unitIndex,
            curOptions: calcOptions,
         });
         calcResult = { ...subResult };
         calcCache = { ...calcCache, ...subCache };
         calcOptions = subOptions;
      }
      //* find related unit fields
      const unitFieldsIds: string[] = allUnitFields
         .filter(
            (field) =>
               (field.column && field.column === curField.column) ||
               field.formula?.includes(`@${curField.id}`) ||
               field.options?.includes(`@${curField.id}`) ||
               (curField.column && field.formula?.includes(curField.column)) ||
               (curField.column && field.options?.includes(curField.column))
         )
         .map(({ id }) => id);

      //* run recalc for each unit
      for (let u = 0; u < (amIaChild ? 1 : calcResult.$childUnits.length); u++) {
         for (let f = 0; f < unitFieldsIds.length; f++) {
            const fieldId = unitFieldsIds[f];
            const {
               calcResult: subResult,
               calcCache: subCache,
               calcOptions: subOptions,
            } = await calcUwU({
               accessToken,
               config,
               fieldIds: [fieldId],
               curUw: calcResult,
               curCache: calcCache,
               curOptions: calcOptions,
               unitIndex: amIaChild ? unitIndex : u,
            });
            calcResult = { ...subResult };
            calcCache = { ...calcCache, ...subCache };
            calcOptions = subOptions;
         }
      }

      //* update fields on prop level that has unit behavior
      if (amIaChild && unitBehavior !== "NONE") {
         const {
            calcResult: subResult,
            calcCache: subCache,
            calcOptions: subOptions,
         } = await calcUwU({
            accessToken,
            config,
            fieldIds: [fieldId],
            curCache: calcCache,
            curUw: calcResult,
            curOptions: calcOptions,
         });
         calcResult = { ...subResult };
         calcCache = { ...calcCache, ...subCache };
         calcOptions = subOptions;
      }
   }
   // if(fieldIds.includes("1682677352022")) console.log({calcResult})
   return { calcCache, calcResult, calcOptions };
};

type TResolveParsedFormulaParams = {
   pf?: Node;
   debug?: boolean;
   unitIndex?: number;
};

type TResolveOneField = {
   fieldId: string;
   unitIndex?: number;
   debug?: boolean;
};

type TCalcParams = {
   fieldIds: string[];
   accessToken: string;
   config: TversionConfig;

   curUw: TUwData;
   curCache: TUnknownObject;
   curOptions: TUnknownObject;
   unitIndex?: number;

   forceRecalcFirstLayer?: boolean;
   ignoreError?: boolean;
   handleProgress?: (payload: { addTotal?: number; addCount?: number }) => void;
};
