import FileUploader from '../../../common/fileUploader/FileUploader';
import styles from './UploadCsvPopup.module.scss'
const UploadCsvPopup = ({handleFile}:TUploadCsvPopupProps) => {
  return (
    <div className={`${styles.contentC}`}>
      <div className={`header-s`}>Upload the proper CSV</div>
       <FileUploader handleFiles={handleFile} >
         <div className={`${styles.fuC}`}>
            <div>Click or Drag Your Files Here</div>
         </div>
       </FileUploader>
    </div>
  );
};

export default UploadCsvPopup;
type TUploadCsvPopupProps = {
   handleFile :(files: File[]) => void
}