import { useQuery } from "react-query";
import useAxios from "../../axios/useAxios";

const useQUwSnapshots = ({ pid, version }: IuseQUwSnapshotsParams) => {
   const { aioAxios } = useAxios();

   return useQuery({
      enabled: !!pid,
      queryKey: ["PropertySnapshots", pid],
      queryFn: () =>
         aioAxios
            .get(`/aio/properties/snapshots/${pid}`, { params: { version } })
            .then<{ snapshots: string[] }>(({ data }) => data),
   });
};
export default useQUwSnapshots;
interface IuseQUwSnapshotsParams {
   pid: string;
   version: string;
}
