import useAxios from "../axios/useAxios";
import { useSocketIo } from "../socketio/useSocketIo";

export const useResolveUwU = ({ ver }: useResolveUwUParams) => {
   const { aioAxios } = useAxios();
   const { socket } = useSocketIo();

   const recalcUwUFields = async ({
      cache: curCache,
      options: curOptions,
      uws,
      fieldIds,
      unitIndex,
      perfectCaprate,
   }: TRecalcUwUFields) => {
      const body = {
         version: ver,
         fieldIds,
         unitIndex,
         uws,
         cache: curCache,
         options: curOptions,
         perfectCaprate,
         socketId: socket.id,
      };
      return await aioAxios
         .post("/aio/underwritings/calculate", body)
         .then<{ uws: { uw: Record<string, any>; options: Record<string, any> }[]; cache: Record<string, any> }>(
            ({ data }) => data
         )
         .catch((er) => {
            console.log({ er });
            return null;
         });
   };
   return { recalcUwUFields };
};

type useResolveUwUParams = {
   ver: string;
};
export type TRecalcUwUFields = {
   uws: Record<string, any>[];
   cache: Record<string, any>;
   options: Record<string, any>[];
   fieldIds?: string[];
   unitIndex?: number;
   perfectCaprate?: boolean;
};
