import styles from "./SmallSpinner.module.scss";
const SmallSpinner = ({ style }: ISmallSpinnerParams) => {
   return <div className={`${styles.spinner}`} style={style}></div>;
};

export default SmallSpinner;

interface ISmallSpinnerParams {
   style?: React.CSSProperties;
}
