import { UseQueryOptions, useQuery } from "react-query";
import useAxios from "../../../axios/useAxios";
import { TPG_AIO2_AppUnderwritings_Schema } from "@simplyhomes/utils";

export const useQAioUw = (p: useQAioUwParams) => {
   const { aioAxios } = useAxios();
   return useQuery({
      ...p?.options,
      queryKey: ["useQAioUw", p.uid],
      enabled: !!p.uid,
      queryFn: () => aioAxios.get(`/aio/v2/underwritings/${p.uid}`).then<axiosResp>((res) => res.data),
   });
};

type useQAioUwParams = {
   options?: UseQueryOptions<axiosResp, unknown, axiosResp, string[]>;
   uid: string;
};

const cols = ["uid", "address", "version", "created_at", "created_by", "updated_at", "updated_by", "appdata"] as const;
type TUW = Pick<TPG_AIO2_AppUnderwritings_Schema, (typeof cols)[number]>;
type axiosResp = {
   underwriting: TUW;
};
