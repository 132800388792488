import { TUseQChangelogs_Changelog, useQChangelogs } from "../../../../hooks/querries/changelogs/useQChangelogs";
import ReactHtmlParser from "react-html-parser";
import styles from "./Changelogs.module.scss";
import jsTimeSince from "../../../../utils/JS/jsTimeSince";
import { useQUsers } from "../../../../hooks/querries/users/useQUsers";
import { useAuth0 } from "@auth0/auth0-react";
import RichTextTinyMce from "../../../common/richTextTinyMce/RichTextTinyMce";
import { useState } from "react";
import useAxios from "../../../../hooks/axios/useAxios";
import BodyFiller from "../../../common/bodyFiller/BodyFiller";
const Changelogs = () => {
   const { aioAxios } = useAxios();

   const { data: qClsData, isFetching: qClsFetching, refetch: qClsRefetch } = useQChangelogs();
   const { changelogs = [] } = qClsData || {};

   const { data: qUsersData } = useQUsers();
   const { users = [] } = qUsersData || {};

   const { user } = useAuth0();

   const [changelog, setChangelog] = useState("");
   const [submitting, setSubmitting] = useState(false);

   const handleSubmit = async () => {
      setSubmitting(true);
      await aioAxios
         .post(`/aio/changelogs/`, { changelog })
         .then(() => setChangelog(""))
         .catch((er) => console.log({ er }));
      qClsRefetch();
      setSubmitting(false);
   };

   if (qClsFetching)
      return (
         <div className={`${styles.contentC}`}>
            <BodyFiller loading />
         </div>
      );
   return (
      <article className={`${styles.contentC}`}>
         <div className={`${styles.headerC}`}>
            <h1 className={`cardHeader-s`}>Changelogs</h1>
         </div>
         {user?.allRoles?.includes("admin") && (
            <div className={`${styles.tiny}`}>
               <RichTextTinyMce
                  value={changelog}
                  setValue={setChangelog}
                  onSubmit={handleSubmit}
                  submitting={!!submitting}
               />
            </div>
         )}
         <ul className={`${styles.clsC}`}>
            {changelogs.map((cl) => (
               <li key={cl.cid}>
                  <div className={`${styles.clHeader}`}>
                     <b>{users.find((user) => user.sub === cl.sub)?.email.split("@")[0]}</b>
                     <p>
                        {cl.time.split("T")[0]} ({jsTimeSince(new Date(cl.time).getTime())})
                     </p>
                  </div>
                  <div>{ReactHtmlParser(cl.content)}</div>
               </li>
            ))}
         </ul>
      </article>
   );
};

export default Changelogs;
type TChangelogsProps = {};
