import { TAIO_Version2_Config } from "@simplyhomes/utils";
import { createContext } from "react";
import { DropResult } from "react-beautiful-dnd";

export const AioVersionReducer = (state: TAioVersionState, action: TAioVersionAction): TAioVersionState => {
   if ("overwrite" in action && action.overwrite) return { ...state, ...action.overwrite };
   if (!("type" in action)) return { ...state };
   switch (action.type) {
      //@ Config
      case "config_overwrite": {
         return {
            ...state,
            config: action.payload,
            views_activeViewId: action.payload.views?.[0]?.id,
            views_activePGroup: action.payload.views?.[0]?.propGroups?.[0]?.id,
            views_activeUGroup: action.payload.views?.[0]?.unitGroups?.[0]?.id,
         };
      }
      //@ View
      case "config_views_addView": {
         const views = state?.config?.views || [];
         const newViews = [...views];
         const id = Date.now();
         newViews.unshift({ name: "New View", id, propGroups: [], unitGroups: [] });
         return { ...state, config: { ...state.config, views: newViews }, views_activeViewId: id };
      }
      case "config_views_removeView": {
         const { views } = state.config;
         if (!views || !state.views_activeViewId) return { ...state };
         const viewId = state.views_activeViewId;
         const newViews = views.filter((v) => v.id !== viewId);
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_renameView": {
         const { name } = action.payload;
         const { views } = state.config;
         if (!views) return { ...state };
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         view.name = name;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_moveView": {
         const { destination, source } = action.payload;
         const { views } = state.config;
         if (!views || !destination) return { ...state };
         const newViews = [...views];
         const [removed] = newViews.splice(source.index, 1);
         newViews.splice(destination.index, 0, removed);
         const views_activeView =
            state.views_activeViewId === source.index ? destination.index : state.views_activeViewId;
         return { ...state, config: { ...state.config, views: newViews }, views_activeViewId: views_activeView };
      }

      //@ Group
      case "config_views_addGroup": {
         const { level } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const id = Date.now();
         newGroups.unshift({ name: "New Group", id, type: "1", fieldIds: [] });
         view[groupKey] = newGroups;
         return {
            ...state,
            config: { ...state.config, views: newViews },
            [level === "prop" ? "views_activePGroup" : "views_activeUGroup"]: id,
         };
      }
      case "config_views_moveGroup": {
         const { level, result } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view || !result.destination) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const [removed] = newGroups.splice(result.source.index, 1);
         newGroups.splice(result.destination.index, 0, removed);
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_renameGroup": {
         const { level, name } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         group.name = name;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_changeTypeGroup": {
         const { level, type } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         group.type = type as any;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_removeGroup": {
         const { level } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         newGroups.splice(newGroups.indexOf(group), 1);
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }

      //@Field Type 1
      case "config_views_f1_addField": {
         const { level } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         if (group.type !== "1") return { ...state };
         const fieldIds = [...group.fieldIds];
         fieldIds.unshift("");
         group.fieldIds = fieldIds;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_f1_renameField": {
         const { level, fieldId, index } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         if (group.type !== "1") return { ...state };
         const fieldIds = [...group.fieldIds];
         fieldIds[index] = fieldId;
         group.fieldIds = fieldIds;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_f1_removeField": {
         const { level, i } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         if (group.type !== "1") return { ...state };
         const fieldIds = [...group.fieldIds];
         fieldIds.splice(i, 1);
         group.fieldIds = fieldIds;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }
      case "config_views_f1_moveField": {
         const { level, result } = action.payload;
         const { views = [] } = state.config;
         const newViews = [...views];
         const view = newViews.find((v) => v.id === state.views_activeViewId);
         if (!view || !result.destination) return { ...state };
         const groupKey = level === "prop" ? "propGroups" : "unitGroups";
         const groups = view[groupKey];
         const newGroups = [...groups];
         const group = newGroups.find(
            (g) => g.id === (level === "prop" ? state.views_activePGroup : state.views_activeUGroup)
         );
         if (!group) return { ...state };
         if (group.type !== "1") return { ...state };
         const fieldIds = [...group.fieldIds];
         const [removed] = fieldIds.splice(result.source.index, 1);
         fieldIds.splice(result.destination.index, 0, removed);
         group.fieldIds = fieldIds;
         view[groupKey] = newGroups;
         return { ...state, config: { ...state.config, views: newViews } };
      }

      default:
         return { ...state };
   }
};
export const aioVersionDefaultState: TAioVersionState = {
   config: {
      propFields: [],
      unitFields: [],
      views: [],
   },
};
export const AioVersionContext = createContext({
   aioVersionState: aioVersionDefaultState,
   aioVersionDispatch: () => {},
} as {
   aioVersionState: TAioVersionState;
   aioVersionDispatch: React.Dispatch<TAioVersionAction>;
});
type TAioVersionState = {
   version?: string;
   config: TAIO_Version2_Config;
   views_activeViewId?: number;
   views_activePGroup?: number;
   views_activeUGroup?: number;
};
type TAioVersionGroupLevel = "prop" | "unit";
type TAioVersionAction =
   | { overwrite?: Partial<TAioVersionState> }
   | { type: "config_overwrite"; payload: TAIO_Version2_Config }
   //
   | { type: "config_views_addView" }
   | { type: "config_views_removeView" }
   | { type: "config_views_moveView"; payload: DropResult }
   | { type: "config_views_renameView"; payload: { name: string } }
   //
   | { type: "config_views_addGroup"; payload: { level: TAioVersionGroupLevel } }
   | { type: "config_views_removeGroup"; payload: { level: TAioVersionGroupLevel } }
   | { type: "config_views_moveGroup"; payload: { level: TAioVersionGroupLevel; result: DropResult } }
   | { type: "config_views_renameGroup"; payload: { level: TAioVersionGroupLevel; name: string } }
   | { type: "config_views_changeTypeGroup"; payload: { level: TAioVersionGroupLevel; type: string } }
   //
   | { type: "config_views_f1_addField"; payload: { level: TAioVersionGroupLevel } }
   | { type: "config_views_f1_removeField"; payload: { level: TAioVersionGroupLevel; i: number } }
   | { type: "config_views_f1_moveField"; payload: { level: TAioVersionGroupLevel; result: DropResult } }
   | { type: "config_views_f1_renameField"; payload: { level: TAioVersionGroupLevel; fieldId: string; index: number } };
